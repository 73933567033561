import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  Col,
} from "reactstrap";
import Loader from "./Loader";

const Enable2FAModal = ({ loading, onSubmit }) => {
  const [enabled2FA, setEnabled2FA] = useState(false);

  return (
    <Modal isOpen={true}>
      <ModalHeader>Two Factor Authentication</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Col>
            <p>Please enable two factor authentication to continue</p>
            <CustomInput
              type="checkbox"
              id="2faCheckbox"
              label="Enable Two Factor Authentication"
              className="pr-0 cursor-pointer text-muted small accessCheckbox"
              value={enabled2FA}
              onChange={(event) => setEnabled2FA(event.currentTarget.checked)}
            />
          </Col>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button disabled={!enabled2FA} color={"primary"} onClick={onSubmit}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Enable2FAModal;

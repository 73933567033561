import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  program: null,
  programs: [],
  sizePerPage: 9999,
  page: 1,
  search: "",
  refresh: false,
};

const ProgramsContext = createContext(initialState);

const ProgramsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ProgramsContext.Provider value={stateAndDispatch}>
      {children}
    </ProgramsContext.Provider>
  );
};

export const usePrograms = () => useContext(ProgramsContext);

export { ProgramsContext, ProgramsProvider };

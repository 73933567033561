import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const emailInitialState = {
  isOpen: false,
  user: null,
  program: null,
  isActive: false,
  mode: DEFAULT_MODE,
  content: "",
  subject: "",
  replyTo: "",
};

const EmailContext = createContext(emailInitialState);

const EmailProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, emailInitialState);
  return (
    <EmailContext.Provider value={stateAndDispatch}>
      {children}
    </EmailContext.Provider>
  );
};

export const useEmailContext = () => useContext(EmailContext);

export { EmailContext, EmailProvider, emailInitialState };

import React, { useState } from "react";

import { Col, Row, Button, Badge } from "reactstrap";

import TransactionsTable from "../TransactionsTable";
import {
  useFundTransactionModal,
  initialState,
} from "../../../../../providers/fundTransactionModalProvider";
import { useFundContext } from "../../../../../providers/fundsProvider";
import FundMonthCloseModal from "./FundMonthCloseModal";
import InformationModal from "../../../../InformationModal";
import moment from "moment";
import ConfirmationModal from "../../../../ConfirmationModal";
import { fundsApi } from "../../../../../services/fundServices";
import { useAuth } from "../../../../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../../../utils/roles";
import { utils } from "../../../../../utils/utils";
import { useNavigate } from "react-router-dom";

const CREATION_MODE = 1;

const STATUS_OPEN = "1";
const STATUS_CLOSED = "2";

const TYPE_INCOME = "1";
const TYPE_INCOME_DND_ID = "3";

const TYPE_MONTH = "1";

const FundMonthTransactions = ({ fundMonth }) => {
  const navigate = useNavigate();
  const [fundContext, setFundContext] = useFundContext();
  const [fundTransactionModal, setFundTransactionModal] =
    useFundTransactionModal();

  const [fundMonthCloseModal, setFundMonthCloseModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [authContext] = useAuth();

  const user = authContext.currentUser;

  const monthBalance =
    (fundMonth.transactions || []).reduce(
      (p, c) =>
        p +
        c.amount *
          (c.type === TYPE_INCOME || c.type === TYPE_INCOME_DND_ID ? 1 : -1),
      0
    ) || 0;

  const onClose = () => {
    if (monthBalance < 0) {
      return setInformationModal({
        isOpen: true,
        body: "You can't close the month with a negative balance. Please check.",
      });
    }
    setFundContext({ selectedFundMonth: fundMonth });
    setFundMonthCloseModal(true);
  };

  const onDistributeFunds = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        fundsApi.distributeMonthFunds({ id: fundMonth.id }).then(() => {
          fundMonth.fundsDistributed = true;
          let fundMonths = fundContext.fundMonths;
          fundMonths.splice(
            fundMonths.findIndex((fm) => fm.id === fundMonth.id),
            1,
            fundMonth
          );
          setFundContext({ fundMonths });
          setConfirmationModal(initConfirmationModal);
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Distribute ${utils.getMonth(fundMonth.month)}-${
        fundMonth.year
      } Funds`,
      body: `<p>Investor's Distributions will be generated with the information entered.</p>
      <p class="text-warning">In case they exist, they will be overwritten.</p>
      <p>The Distributions will be available from the Program detail section.</p>
      <span class="font-weight-bold">You confirm you want to continue?</span>`,
    });
  };

  const onReopen = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        fundsApi.repenFundMonth({ id: fundMonth.id }).then(() => {
          fundMonth.status = STATUS_OPEN;
          fundMonth.fundsDistributed = false;
          let fundMonths = fundContext.fundMonths;
          fundMonths.splice(
            fundMonths.findIndex((fm) => fm.id === fundMonth.id),
            1,
            fundMonth
          );
          setFundContext({ fundMonths });
          setConfirmationModal(initConfirmationModal);
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Reopen ${utils.getMonth(fundMonth.month)}-${fundMonth.year}`,
      body: `<p class="text-warning font-500">In case that the distributions were already generated, they will be removed.</p><span>You confirm you want to re-open the month?</span>`,
    });
  };

  const isMonthBeforeActiveMonth = (fundMonth) => {
    const activeM = fundContext.fundMonths.find((m) => m.active);
    return moment()
      .set("year", fundMonth.year)
      .set("month", fundMonth.month)
      .isSameOrBefore(
        moment().set("year", activeM.year).set("month", activeM.month)
      );
  };

  return (
    <Col className="mb-4 mx-0 px-0">
      <Row className="mx-0 mb-3 align-items-center justify-content-between">
        <h5 className="mb-0 d-flex flex-column">
          <span>{utils.getMonth(fundMonth.month)} Transactions</span>
          <div>
            <small className="font-size-75">Balance:</small>
            <small
              className={`font-size-75 ml-1 ${
                monthBalance < 0 ? "text-danger" : "text-success"
              }`}
            >
              {utils.formatCurrency(monthBalance)}
            </small>
          </div>
        </h5>
        <div className="d-flex align-items-center">
          {fundMonth.status !== STATUS_OPEN ? (
            <div className="d-flex align-items-center">
              {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
                !utils.userHasRole(user, SPONSOR) && (
                  <>
                    <Button
                      className="ml-4 flex-shrink-0"
                      color={"warning"}
                      onClick={onReopen}
                    >
                      Re-Open Month
                    </Button>
                    {fundMonth.fundsDistributed ? (
                      <>
                        <Button
                          className="ml-4 flex-shrink-0"
                          color={"tertiary"}
                          onClick={onDistributeFunds}
                        >
                          Re-Distribute Funds
                        </Button>
                        <Button
                          className="ml-4 flex-shrink-0"
                          color={"dark"}
                          onClick={() =>
                            navigate(
                              `/back/funds/${fundMonth.fundId}/fund-months/${fundMonth.id}/distributions`
                            )
                          }
                        >
                          View Distributions
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="ml-4 flex-shrink-0"
                        color={"tertiary"}
                        onClick={onDistributeFunds}
                      >
                        Distribute Funds
                      </Button>
                    )}
                  </>
                )}
              <Badge color={"danger"} size={18} pill className="ml-4">
                Closed
              </Badge>
            </div>
          ) : (
            <>
              {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
                !utils.userHasRole(user, SPONSOR) && (
                  <>
                    <Button
                      className="ml-4 flex-shrink-0"
                      color={"secondary"}
                      onClick={() => {
                        setFundContext({ selectedFundMonth: fundMonth });
                        setFundTransactionModal({
                          ...initialState,
                          categories: fundTransactionModal.categories,
                          isModalOpen: true,
                          mode: CREATION_MODE,
                        });
                      }}
                    >
                      Add Transaction
                    </Button>
                    {isMonthBeforeActiveMonth(fundMonth) ? (
                      <Button
                        className="ml-4 flex-shrink-0"
                        color={"warning"}
                        onClick={onClose}
                      >
                        Close Month
                      </Button>
                    ) : null}
                  </>
                )}
            </>
          )}
          {fundMonth.active ? (
            <Badge color={"success"} size={18} pill className="ml-4">
              Active
            </Badge>
          ) : null}
        </div>
      </Row>
      <TransactionsTable period={fundMonth} type={TYPE_MONTH} />
      {fundMonthCloseModal ? (
        <FundMonthCloseModal
          onSubmitted={() => {
            fundMonth.status = STATUS_CLOSED;
            let fundMonths = fundContext.fundMonths;
            fundMonths.splice(
              fundMonths.findIndex((fm) => fm.id === fundMonth.id),
              1,
              fundMonth
            );
            setFundContext({ fundMonths, refresh: !fundContext.refresh });
            setFundMonthCloseModal(false);
          }}
          onClose={() => {
            setFundMonthCloseModal(false);
          }}
        />
      ) : null}
      {informationModal?.isOpen ? (
        <InformationModal
          title={"Heads Up!"}
          body={informationModal.body}
          onClose={() => setInformationModal({ isOpen: false, body: "" })}
        />
      ) : null}
      {confirmationModal?.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Col>
  );
};

export default FundMonthTransactions;

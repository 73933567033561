import React, { memo, useState } from "react";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateInput = ({ value, required = true, onChange = () => {} }) => {
  const [dateSelectorOpen, setDateSelectorOpen] = useState();
  const currentdate = new Date();

  const onSelectedDate = (date) => {
    onChange(date);
    setDateSelectorOpen(false);
  };

  return (
    <div className="col-sm-12 p-0">
      <div className="border rounded bg-white cursor-pointer w-100 position-relative">
        {dateSelectorOpen ? (
          <DayPicker
            required={required}
            className="position-absolute z-index-2 date-picker px-2 d-flex bg-white rounded border justify-content-center m-0"
            mode="single"
            defaultMonth={value || currentdate}
            selected={[value]}
            modifiersClassNames={{
              selected: "my-selected",
              today: "my-today",
              range_start: "my-range_start",
              range_end: "my-range_end",
            }}
            onDayClick={onSelectedDate}
          />
        ) : null}
        <div className="px-2 py-1" onClick={() => setDateSelectorOpen(true)}>
          <FontAwesomeIcon icon={faCalendar} className="mr-2 text-primary" />
          <span>{moment(value || new Date()).format("MM/DD/YYYY")}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(DateInput);

import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { Card, Container, Row, Col, CardHeader } from "reactstrap";
import Header from "../../components/Header";
import HeaderSubtitle from "../../components/HeaderSubtitle";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";

import { useAuth } from "../../providers/authProvider";
import { authApi } from "../../services/authServices";
import { utils } from "../../utils/utils";

const InvestorSelector = () => {
  const navigate = useNavigate();

  const [authContext, setAuthContext] = useAuth();
  const [loading, setLoading] = useState(false);

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const setAuthContextCb = useCallback(
    (data) => {
      setAuthContext(data);
    },
    [setAuthContext]
  );

  useEffect(() => {
    if (!authContext.currentUser) {
      authApi.advisorValidate().then((result) => {
        if (!result.status || !utils.userHasRole(result, "advisor")) {
          return setRedirectToLogin(true);
        }
        setAuthContextCb({ currentUser: { ...result } });
      });
    }
  }, [authContext.currentUser, setAuthContextCb]);

  if (redirectToLogin) {
    return <Navigate to={`/auth/sign-in`} />;
  }

  const advisor = authContext?.currentUser;
  const investors = advisor?.investors?.sort((firstItem, secondItem) =>
    firstItem.name.toLowerCase() > secondItem.name.toLowerCase() ? 1 : -1
  );

  if (!investors) {
    return <Navigate to={`/auth/sign-in`} />;
  }

  const onSelected = (investor) => {
    setLoading(true);
    authApi.loginAs({ userId: investor.id }, "advisor").then(() => {
      authApi.investorValidate().then((result) => {
        if (advisor.investors.find((i) => i.id === investor.id)) {
          //case the advisor is investor
          advisor.investors.splice(
            advisor.investors.findIndex((i) => i.id === investor.id),
            1
          );
        }
        setAuthContext({
          currentUser: { ...result },
          advisements: [investor, ...advisor.investors],
        });
        navigate("/portal");
      });
    });
  };

  return (
    <Container fluid>
      {loading || !authContext.currentUser ? (
        <Loader />
      ) : (
        <>
          <Header>
            <HeaderTitle>
              {authContext.currentUser.isNewUser ? "Welcome" : "Welcome back"},{" "}
              {authContext.currentUser.name}!
            </HeaderTitle>
            <HeaderSubtitle>Investors Overview</HeaderSubtitle>
          </Header>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                {loading ? (
                  <Loader size="sm" />
                ) : (
                  investors.map((investor, index) => (
                    <Card
                      key={index}
                      onClick={() => onSelected(investor)}
                      className="col-12 border rounded py-2 cursor-pointer"
                    >
                      <CardHeader className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-0 text-underline">{investor.name}</h4>
                        <span>
                          {(investor.investments?.length || 0) +
                            (investor.ownerships?.length || 0)}{" "}
                          investments
                        </span>
                      </CardHeader>
                    </Card>
                  ))
                )}
                {!investors.length &&
                !utils.userHasRole(advisor, "investor") ? (
                  <Card className="col-12 border rounded py-2">
                    <CardHeader className="d-flex align-items-center">
                      <h5 className="col-12 mb-0 text-center">
                        No investors to show
                      </h5>
                    </CardHeader>
                  </Card>
                ) : null}
                <Card
                  hidden={!utils.userHasRole(advisor, "investor")}
                  onClick={() => onSelected(advisor)}
                  className="col-12 border rounded py-2 cursor-pointer"
                >
                  <CardHeader className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 text-underline">{advisor.name}</h4>
                    <span>
                      {(advisor.investments?.length || 0) +
                        (advisor.ownerships?.length || 0)}{" "}
                      investments
                    </span>
                  </CardHeader>
                </Card>
              </Col>
            )}
          </Row>
        </>
      )}
    </Container>
  );
};

export default InvestorSelector;

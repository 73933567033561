import React from "react";
import { Table } from "reactstrap";
import Loader from "../../Loader";

const AdvanceTable = ({
  onRowClick,
  isLoading,
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  style,
}) => {
  return (
    <Table {...getTableProps(tableProps)} style={style}>
      <thead className={headerClassName}>
        <tr>
          {headers.map((column, index) => {
            column.headerProps.className = `font-size-85 ${column.headerProps.className}`;
            return (
              <th
                key={index}
                {...column.getHeaderProps({
                  ...column.getSortByToggleProps(column.headerProps),
                  style: { maxWidth: column.width, minWidth: column.width },
                })}
              >
                {column.render("Header")}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ""
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={bodyClassName}>
        {isLoading ? (
          <tr>
            <td colSpan={headers.length}>
              <Loader />
            </td>
          </tr>
        ) : page.length ? (
          page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                className={`${rowClassName || ""}${
                  onRowClick ? "cursor-pointer" : ""
                }`}
                {...row.getRowProps()}
                onClick={onRowClick ? () => onRowClick(row) : null}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps({
                        ...cell.column.cellProps,
                        style: {
                          maxWidth: cell.column.width,
                          minWidth: cell.column.width,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <tr>
            <td
              className="text-muted small text-center"
              colSpan={headers.length}
            >
              No data to show
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default AdvanceTable;

import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
} from "reactstrap";

import Loader from "../../../Loader";
import { surveyApi } from "../../../../services/surveyServices";
import ConfirmationModal from "../../../ConfirmationModal";
import InformationModal from "../../../InformationModal";
import AddNewSurveyModal from "./AddNewSurveyModal/AddNewSurveyModal";
import SurveyDetailsModal from "./SurveyDetailsModal";
import EmailModal from "../../EmailModal";
import {
  useEmailContext,
  emailInitialState,
} from "../../../../providers/emailProvider";
import { emailsUtils } from "../../../../utils/emails";

const ProgramSurveyModal = ({ program, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [emailContext, setEmailContext] = useEmailContext();
  const [refresh, setRefresh] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const initAddNewSurveyModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
  };
  const [addNewSurveyModal, setAddNewSurveyModal] = useState(
    initAddNewSurveyModal
  );

  const [emailModal, setEmailModal] = useState(emailInitialState);

  const initSurveyDetailsModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
  };
  const [surveyDetailsModal, setSurveyDetailsModal] = useState(
    initSurveyDetailsModal
  );

  useEffect(() => {
    setLoading(true);
    surveyApi
      .getAllSurveys(program.id)
      .then((surveys) => setSurveys(surveys))
      .finally(() => setLoading(false));
  }, [program.id, refresh]);

  const createNewSurvey = async (surveyName, questions) => {
    setAddNewSurveyModal(initAddNewSurveyModal);
    const defaultSubject = `${program?.name} - Member Vote`;
    if (emailContext?.subject !== defaultSubject) {
      setEmailContext({
        name: program?.name,
        subject: defaultSubject,
        content: emailsUtils.surveyTemplate(program?.name),
      });
    }
    setEmailModal({
      isOpen: true,
      cancelBtnText: "Back",
      onSubmit: async (emailContext) => {
        try {
          await surveyApi.createSurvey({
            ...emailContext,
            name: surveyName,
            programId: program.id,
            questions,
          });
          setRefresh(!refresh);
          setEmailModal(emailInitialState);
          setInformationModal({
            isOpen: true,
            title: "Create Vote",
            body: "Vote was created and emails were sent.",
          });
        } catch (error) {}
      },
      onClose: () => {
        setEmailModal(emailInitialState);
        onCreateSurvey();
      },
    });
  };

  const onSurveyDetails = (survey) => {
    survey.programName = program?.name;
    setSurveyDetailsModal({
      isOpen: true,
      surveyDetails: survey,
      onDeleteSurvey,
      onClose: () => setSurveyDetailsModal(initSurveyDetailsModal),
    });
  };

  const onDeleteSurvey = (id) => {
    setSurveyDetailsModal(initSurveyDetailsModal);
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setConfirmationModal(initConfirmationModal);
        surveyApi.deleteSurvey(id).then(() => setRefresh(!refresh));
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Remove Vote",
      body: `<span>Do you confirm you want to remove the selected vote with all its associated data?</span>`,
    });
  };

  const onCreateSurvey = () => {
    setAddNewSurveyModal({
      isOpen: true,
      program,
      onSubmit: createNewSurvey,
      onClose: () => setAddNewSurveyModal(initAddNewSurveyModal),
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : addNewSurveyModal.isOpen ? (
    <AddNewSurveyModal {...addNewSurveyModal} />
  ) : emailModal.isOpen ? (
    <EmailModal {...emailModal} />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : surveyDetailsModal.isOpen ? (
    <SurveyDetailsModal {...surveyDetailsModal} />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn} className="d-flex justify-content-between">
        Votes for program {program.name}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {loading ? (
              <Loader size="sm" />
            ) : surveys.length ? (
              <ListGroup flush>
                {surveys.map((survey, i) => {
                  const [question] = survey.questions;
                  return (
                    <ListGroupItem
                      onClick={() => onSurveyDetails(survey)}
                      key={i}
                      className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                      tag="div"
                    >
                      <div>{survey.name}</div>
                      <span>{`${question?.answers.length || 0}/${
                        survey.usersQuantity
                      }`}</span>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            ) : (
              <div className="text-center">No Votes to show</div>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={onClose}>
              Close
            </Button>
            <Button disabled={false} color="primary" onClick={onCreateSurvey}>
              Create Vote
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ProgramSurveyModal;

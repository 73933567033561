import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Form,
} from "reactstrap";

const EditStakeholderModal = ({ stakeholder = {}, onSubmit, onClose }) => {
  const [percentage, setPercentage] = useState(stakeholder.percentage || "");

  const doSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      ...stakeholder,
      percentage,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Add Stakeholder to PropCo</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <Row form className="mx-0">
            <FormGroup className="col-12 px-0 my-2">
              <Label for="email">Program Ownership</Label>
              <Input
                required={true}
                type="number"
                max={100}
                min={0}
                step={0.01}
                placeholder="Enter a percentage.."
                onChange={(event) => setPercentage(event.currentTarget.value)}
                value={percentage}
              />
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          <Button type={"submit"} color={"primary"}>
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditStakeholderModal;

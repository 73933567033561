import React, { useRef, useEffect } from 'react';

const BannerContent = ({ htmlContent }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current.shadowRoot) {
      const shadowRoot = containerRef.current.attachShadow({ mode: 'open' });
      shadowRoot.innerHTML = htmlContent;
    }
  }, [htmlContent]);

  return <div ref={containerRef} />;
};

export default BannerContent;
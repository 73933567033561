/* eslint-disable react/prop-types */
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ReactPaginate from "react-paginate";

export const AdvanceTablePagination = ({
  pageCount,
  currentPage,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalCount,
  rowsPerPageOptions = [5, 10, 15],
  className = "",
  disableAll,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${className}`}
    >
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle color="white" caret>
          {pageSize === totalCount ? "All" : pageSize}
        </DropdownToggle>
        <DropdownMenu>
          {rowsPerPageOptions.map((value) => (
            <DropdownItem
              key={value}
              className="font-weight-normal"
              onClick={() => onPageSizeChange(Number(value))}
            >
              {value}
            </DropdownItem>
          ))}
          {!disableAll ? (
            <DropdownItem
              className="font-weight-normal"
              onClick={() => onPageSizeChange(totalCount)}
            >
              All
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </Dropdown>
      <ReactPaginate
        forcePage={currentPage}
        previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        breakLabel={"..."}
        pageCount={pageCount || currentPage + 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(event) => onPageChange(event.selected + 1)}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={
          "cursor-pointer pagination d-flex justify-content-center align-items-center mb-0"
        }
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default AdvanceTablePagination;

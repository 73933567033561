import React, { useState, useEffect } from "react";
import { documentsApi } from "../../services/documentServices";
import { utils } from "../../utils/utils";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
  Badge,
  CustomInput,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import DocumentPreviewModal from "../admin/documents/review/DocumentPreviewModal";
import { awsApi } from "../../services/awsServices";
import config from "../../config/config";
import { programsApi } from "../../services/programServices";
import { useDocuments } from "../../providers/documentsProvider";
import { useAuth } from "../../providers/authProvider";
import download from "downloadjs";
import moment from "moment";

const FILE_TYPE = "pdf";

const DOCUMENT_TYPE_TAX_K1 = 2;
const DOCUMENT_TYPE_TAX_8886 = 5;
const TYPE_PROGRAM = 3;
const TYPE_INVESTMENT_LETTER = 4;

let years = [];
let year = moment().get("year");

while (years.length < 5) {
  years.push(year);
  year = moment().subtract(years.length, "year").get("year");
}

const Documents = () => {
  const [documentsContext, setDocumentsContext] = useDocuments();
  const [authContext] = useAuth();
  const [programs, setPrograms] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [statementsList, setStatementsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState({});
  const [previewModal, setPreviewModal] = useState({});

  const taxDocuments = documents
    .filter((document) => {
      const typeValidation =
        document.documentTypeId === DOCUMENT_TYPE_TAX_K1 ||
        document.documentTypeId === DOCUMENT_TYPE_TAX_8886;
      const yearValidation = documentsContext.year
        ? document.year === documentsContext.year
        : true;
      const programValidation = documentsContext.programId
        ? document.programId === documentsContext.programId
        : true;
      const sponsorValidation = documentsContext.sponsorId
        ? document.program.sponsorId === documentsContext.sponsorId
        : true;
      return (
        typeValidation &&
        yearValidation &&
        programValidation &&
        sponsorValidation
      );
    })
    .sort((x, y) => {
      return y.year - x.year;
    });

  const programDocuments = documents
    .filter((document) => {
      const typeValidation = document.documentTypeId === TYPE_PROGRAM;
      const yearValidation = documentsContext.year
        ? document.year === documentsContext.year
        : true;
      const programValidation = documentsContext.programId
        ? document.programId === documentsContext.programId
        : true;
      const sponsorValidation = documentsContext.sponsorId
        ? document.program.sponsorId === documentsContext.sponsorId
        : true;
      return (
        typeValidation &&
        yearValidation &&
        programValidation &&
        sponsorValidation
      );
    })
    .sort((x, y) => {
      return y.year - x.year;
    });

  const statements = statementsList
    .filter((statement) => {
      const yearValidation = documentsContext.year
        ? statement?.programStatement?.fundQuarter?.year ===
          documentsContext.year
        : true;
      const programValidation = documentsContext.programId
        ? statement?.programStatement?.programId ===
            documentsContext.programId ||
          statement?.programId === documentsContext.programId
        : true;
      const sponsorValidation = documentsContext.sponsorId
        ? statement?.programStatement?.program?.sponsorId ===
            documentsContext.sponsorId ||
          statement?.program?.sponsorId === documentsContext.sponsorId
        : true;
      return yearValidation && programValidation && sponsorValidation;
    })
    .sort((x, y) => {
      const xYear = x.year || x.programStatement?.fundQuarter?.year;
      const yYear = y.year || y.programStatement?.fundQuarter?.year;
      const xQuarter = x.quarter || x.programStatement?.fundQuarter?.quarter;
      const yQuarter = y.quarter || y.programStatement?.fundQuarter?.quarter;
      if (xYear !== yYear) {
        return yYear - xYear;
      }
      return yQuarter - xQuarter;
    });

  const investmentLetterDocuments = documents.filter(
    (document) => document.documentTypeId === TYPE_INVESTMENT_LETTER
  );

  const onPreview = (fileName, id) => {
    setDocumentsLoading({ ...documentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        "investor"
      )
      .then((result) => {
        setDocumentsLoading({ ...documentsLoading, [id]: false });
        setPreviewModal({
          isOpen: true,
          file: result?.pop().signedRequest,
        });
      });
  };

  const onDownload = (fileName, id) => {
    setDocumentsLoading({ ...documentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        "investor"
      )
      .then((result) => {
        setDocumentsLoading({ ...documentsLoading, [id]: false });
        download(result?.pop().signedRequest);
      });
  };

  useEffect(() => {
    setLoading(true);
    const documentsQueryOptions = {
      pageSize: 9999,
      sortBy: "year",
      direction: "desc",
      userId: authContext.currentUser.id,
    };
    const statementsQueryOptions = {
      pageSize: 9999,
      direction: "desc",
      userId: authContext.currentUser.id,
    };
    documentsApi.getDocuments(documentsQueryOptions).then((documents) => {
      programsApi.getStatements(statementsQueryOptions).then((statements) => {
        programsApi
          .getManualStatements(statementsQueryOptions)
          .then((manualStatements) => {
            const documentPrograms = Object.values(
              documents.data
                .filter(
                  (d) =>
                    d.programId && d.documentTypeId !== TYPE_INVESTMENT_LETTER
                )
                .reduce((p, c) => {
                  if (!p[c.programId]) {
                    p[c.programId] = { id: c.programId, ...c.program };
                  }
                  return p;
                }, {})
            );
            const statementPrograms = Object.values(
              statements.data.reduce((p, c) => {
                if (!p[c.programStatement.programId]) {
                  p[c.programStatement.programId] = {
                    id: c.programStatement.programId,
                    ...c.programStatement.program,
                  };
                }
                return p;
              }, {})
            );
            const manualStatementPrograms = Object.values(
              manualStatements.data.reduce((p, c) => {
                if (!p[c.programId]) {
                  p[c.programId] = { id: c.programId, ...c.program };
                }
                return p;
              }, {})
            );
            setDocuments(documents.data);
            const programs = Object.values(
              [
                ...documentPrograms,
                ...statementPrograms,
                ...manualStatementPrograms,
              ].reduce((p, c) => {
                if (!p[c.id]) {
                  p[c.id] = { ...c };
                }
                return p;
              }, {})
            );
            setPrograms(programs);
            const sponsors = Object.values(
              programs.reduce((p, c) => {
                p[c.sponsorId] = {
                  id: c.sponsorId,
                  name: c.sponsor.name,
                };
                return p;
              }, {})
            );
            setSponsors(sponsors);
            const statementsList = [
              ...statements.data,
              ...manualStatements.data.map((ms) => ({
                ...ms,
                manual: true,
              })),
            ];
            setStatementsList(statementsList);
            setLoading(false);
          });
      });
    });
  }, [authContext.currentUser]);

  return (
    <Container fluid>
      <Header>
        <Row>
          <Col className="col-8">
            <HeaderTitle>Documents</HeaderTitle>
          </Col>
          <Row className="col-4 px-0">
            <div className="flex-grow-1 pl-2">
              <CustomInput
                id="yearSelect"
                type="select"
                name="yearSelect"
                onChange={(event) =>
                  setDocumentsContext({
                    year: parseInt(event.currentTarget.value),
                  })
                }
                value={documentsContext.year}
              >
                <option value={""}>Any Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </CustomInput>
            </div>
            {sponsors.length > 1 ? (
              <div className="flex-grow-1 pl-2">
                <CustomInput
                  id="sponsorSelect"
                  type="select"
                  name="sponsorSelect"
                  onChange={(event) =>
                    setDocumentsContext({
                      sponsorId: parseInt(event.currentTarget.value),
                    })
                  }
                  value={documentsContext.sponsorId || ""}
                >
                  <option value={""}>Any Sponsor</option>
                  {(sponsors || []).map((sponsor, i) => (
                    <option key={i} value={sponsor.id}>
                      {sponsor.name}
                    </option>
                  ))}
                </CustomInput>
              </div>
            ) : null}
            <div className="flex-grow-1 pl-2">
              <CustomInput
                id="programSelect"
                type="select"
                name="programSelect"
                onChange={(event) =>
                  setDocumentsContext({
                    programId: parseInt(event.currentTarget.value),
                  })
                }
                value={documentsContext.programId || ""}
              >
                <option value={""}>Any Program</option>
                {(programs || [])
                  .filter((item) =>
                    documentsContext.sponsorId
                      ? item.sponsorId === documentsContext.sponsorId
                      : true
                  )
                  .map((program, i) => (
                    <option key={i} value={program.id}>
                      {program.name}
                    </option>
                  ))}
              </CustomInput>
            </div>
          </Row>
        </Row>
      </Header>
      <Row>
        <Col>
          <Card hidden>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                Investment Letters
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <ListGroup flush>
                  {investmentLetterDocuments.length ? (
                    investmentLetterDocuments.map((document, index) => (
                      <ListGroupItem
                        key={index}
                        className="pb-2 border-bottom d-flex justify-content-between"
                        tag="div"
                      >
                        {document.name}
                        {documentsLoading[document.id] ? (
                          <Loader size="sm" align="end" />
                        ) : (
                          <div className="d-flex align-items-center">
                            <span
                              className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
                              onClick={() =>
                                onDownload(document.fileName, document.id)
                              }
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                className="text-primary"
                                icon={faFileDownload}
                              />
                            </span>
                            <Badge
                              onClick={() =>
                                onPreview(document.fileName, document.id)
                              }
                              className="cursor-pointer"
                              color={"info"}
                            >
                              Preview
                            </Badge>
                          </div>
                        )}
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem
                      className="rounded pb-2 border-bottom d-flex justify-content-between"
                      tag="div"
                    >
                      No Documents
                    </ListGroupItem>
                  )}
                </ListGroup>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                Program Documents
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <ListGroup flush>
                  {programDocuments.length ? (
                    programDocuments.map((document, index) => (
                      <ListGroupItem
                        key={index}
                        className="pb-2 border-bottom d-flex justify-content-between"
                        tag="div"
                      >
                        {document.name}
                        {documentsLoading[document.id] ? (
                          <Loader size="sm" align="end" />
                        ) : (
                          <div className="d-flex align-items-center">
                            <span
                              className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
                              onClick={() =>
                                onDownload(document.fileName, document.id)
                              }
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                className="text-primary"
                                icon={faFileDownload}
                              />
                            </span>
                            <Badge
                              onClick={() =>
                                onPreview(document.fileName, document.id)
                              }
                              className="cursor-pointer"
                              color={"info"}
                            >
                              Preview
                            </Badge>
                          </div>
                        )}
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem
                      className="rounded pb-2 border-bottom d-flex justify-content-between"
                      tag="div"
                    >
                      No Documents
                    </ListGroupItem>
                  )}
                </ListGroup>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                Tax Documents
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <ListGroup flush>
                  {taxDocuments.length ? (
                    taxDocuments.map((document, index) => (
                      <ListGroupItem
                        key={index}
                        className="pb-2 border-bottom d-flex justify-content-between"
                        tag="div"
                      >
                        {document.name || utils.formatDate(document.name)}
                        {documentsLoading[document.id] ? (
                          <Loader size="sm" align="end" />
                        ) : (
                          <div className="d-flex align-items-center">
                            <span
                              className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
                              onClick={() =>
                                onDownload(document.fileName, document.id)
                              }
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                className="text-primary"
                                icon={faFileDownload}
                              />
                            </span>
                            <Badge
                              onClick={() =>
                                onPreview(document.fileName, document.id)
                              }
                              className="cursor-pointer"
                              color={"info"}
                            >
                              Preview
                            </Badge>
                          </div>
                        )}
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem
                      className="rounded pb-2 border-bottom d-flex justify-content-between"
                      tag="div"
                    >
                      No Documents
                    </ListGroupItem>
                  )}
                </ListGroup>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0">
                Investor Statements
              </CardTitle>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <ListGroup flush>
                  {statements.length ? (
                    statements.map((statement, index) => {
                      const titleParts = [];
                      if (statement.manual) {
                        titleParts.push(statement.program.name);
                      } else {
                        titleParts.push(
                          statement.programStatement.program.name
                        );
                      }
                      if (
                        statement.quarter ||
                        statement.programStatement.fundQuarter
                      ) {
                        titleParts.push(
                          `Q${
                            statement.manual
                              ? statement.quarter
                              : statement.programStatement?.fundQuarter?.quarter
                          }-${
                            statement.manual
                              ? statement.year
                              : statement.programStatement?.fundQuarter?.year
                          }`
                        );
                      } else {
                        titleParts.push(
                          moment(statement.updatedAt).format("YYYY-MM-DD")
                        );
                      }
                      const title = titleParts.join(", ");
                      return (
                        <ListGroupItem
                          key={index}
                          className="pb-2 border-bottom d-flex justify-content-between"
                          tag="div"
                        >
                          <div className="d-flex flex-column align-items-start">
                            {title}
                            {statement.manual ? <Badge>Manual</Badge> : null}
                          </div>
                          {documentsLoading[statement.id] ? (
                            <Loader size="sm" align="end" />
                          ) : (
                            <div className="d-flex align-items-center">
                              <span
                                className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
                                onClick={() =>
                                  onDownload(statement.fileName, statement.id)
                                }
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  className="text-primary"
                                  icon={faFileDownload}
                                />
                              </span>
                              <Badge
                                onClick={() =>
                                  onPreview(statement.fileName, statement.id)
                                }
                                className="cursor-pointer"
                                color={"info"}
                              >
                                Preview
                              </Badge>
                            </div>
                          )}
                        </ListGroupItem>
                      );
                    })
                  ) : (
                    <ListGroupItem
                      className="rounded pb-2 border-bottom d-flex justify-content-between"
                      tag="div"
                    >
                      No Documents
                    </ListGroupItem>
                  )}
                </ListGroup>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={() => setPreviewModal({ isOpen: false, file: null })}
        />
      ) : null}
    </Container>
  );
};

export default Documents;

import React from "react";

import { Table } from "reactstrap";

import { utils } from "../../../utils/utils";

const TABLE_ROWS = 4;

const ReturnsTable = ({ fundQuarters }) => {
  const tableFundQuarters = utils.buildQuartersArray(fundQuarters, TABLE_ROWS);

  return (
    <Table striped className="text-custom-dark text-center">
      <thead>
        <tr>
          <th></th>
          <th>
            <div className="d-flex justify-content-center">
              <span>Quarterly Return</span>
            </div>
          </th>
          <th>
            <div className="d-flex justify-content-center">
              <span>Return After Fees</span>
            </div>
          </th>
          <th>
            <div className="d-flex justify-content-center">
              <span>Year to Date Return</span>
            </div>
          </th>
          <th>
            <div className="d-flex justify-content-center">
              <span>Estimated Annual Return</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="font-weight-normal">
        {tableFundQuarters.map((quarter, index) => {
          return (
            <tr key={index}>
              <td className="font-weight-bold">{`Q${quarter.quarter}-${quarter.year}`}</td>
              <td>{`${Math.max(quarter.returnPercentage, 0).toFixed(2)}%`}</td>
              <td>{`${Math.max(quarter.returnPercentageAfterFees, 0).toFixed(
                2
              )}%`}</td>
              <td>{utils.formatCurrency(quarter.yearToDateReturn || 0)}</td>
              <td>{`${(quarter.projectedAnnualizedReturn || 0).toFixed(
                2
              )}%`}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ReturnsTable;

import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  transactionCategoryId: "",
  amount: "",
  type: "",
  date: new Date(),
  mode: DEFAULT_MODE,
  categories: [],
  isModalOpen: false,
};

const FundCapitalTransactionsModalContext = createContext(initialState);

const FundCapitalTransactionsModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <FundCapitalTransactionsModalContext.Provider value={stateAndDispatch}>
      {children}
    </FundCapitalTransactionsModalContext.Provider>
  );
};

export const useFundCapitalTransactionsModal = () =>
  useContext(FundCapitalTransactionsModalContext);

export {
  FundCapitalTransactionsModalContext,
  FundCapitalTransactionsModalProvider,
  initialState,
};

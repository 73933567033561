import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "reactstrap";
import { useAuth } from "../../providers/authProvider";
import { utils } from "../../utils/utils";

const Page404 = () => {
  const navigate = useNavigate();
  const [authContext] = useAuth();

  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">404</h1>
      <p className="h1">Page not found.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        The page you are looking for might have been removed.
      </p>
      <Button
        onClick={() =>
          authContext.currentUser
            ? !utils.userHasRole(authContext.currentUser, "admin")
              ? navigate("/back/investors")
              : navigate("/portal")
            : navigate("/auth/sign-in")
        }
        color="primary"
        size="lg"
      >
        Return to website
      </Button>
    </div>
  );
};

export default Page404;

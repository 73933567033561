import React from "react";

import { ListGroup, ListGroupItem, Row } from "reactstrap";
import { utils } from "../../../utils/utils";

const TRANSACTION_TYPE_OUTCOME = "2";

const getAssetCurrentCapital = (asset) =>
  asset.capital +
  (asset.transactions || []).reduce(
    (p, c) => p + (c.type === TRANSACTION_TYPE_OUTCOME ? -c.amount : c.amount),
    0
  );

const FundInvestments = ({ program }) => {
  const programFundInvestment = program?.programFundInvestments[0];
  const totalFundInvestments =
    programFundInvestment.fundFundInvestment.fundFundInvestments.reduce(
      (p, c) => p + c.amount,
      0
    ) || 1;
  const programFundOwnership =
    (programFundInvestment.amount || 0) / totalFundInvestments;

  return (
    <Row className="mt-4 flex-grow-1 mx-0 col-12 px-0">
      <ListGroup flush className="flex-grow-1 text-left rounded">
        <ListGroupItem
          className="bg-transparent pb-2 border-0 font-weight-bold text-body list-group-item text-underline px-0"
          tag="div"
        >
          <h4 className="mb-0">
            {programFundInvestment.fundFundInvestment.name}
          </h4>
        </ListGroupItem>
        <ListGroupItem
          className="px-1 bg-transparent pb-1 border-bottom-0 d-flex justify-content-between text-muted"
          tag="div"
        >
          <div className="d-flex align-items-center">
            <div className="max-width-200 text-truncate">
              <small>{program.name}</small>
            </div>
            <div className="ml-1">
              <small>Allocation</small>
            </div>
          </div>
          <div>
            <small>Total Fund</small>
          </div>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom-0 font-weight-bold py-0 d-flex justify-content-between"
          tag="div"
        >
          <div className="pl-0 py-2 col-8 border-right d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column">
              <span>{`${programFundInvestment.fundFundInvestment.name} · ${programFundInvestment.fundFundInvestment.year}`}</span>
              <small className="text-muted">Equity</small>
            </div>
            <div>
              <span>{utils.formatCurrency(programFundInvestment.amount)}</span>
              <span className="mx-2">·</span>
              <span>{(programFundOwnership * 100).toFixed(2)}%</span>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center px-0">
            <span>{utils.formatCurrency(totalFundInvestments)}</span>
          </div>
        </ListGroupItem>
        {programFundInvestment.fundFundInvestment.assets.map((asset, i) => (
          <ListGroupItem
            key={i}
            className="border-top border-bottom-0 py-0 d-flex justify-content-between"
            tag="div"
          >
            <div className="pl-0 py-2 col-8 border-right d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="mr-2">·</span>
                <div className="d-flex flex-column">
                  <span>{asset.name}</span>
                  <small className="text-muted">{asset.category?.name}</small>
                </div>
              </div>
              <div>
                <span>
                  {utils.formatCurrency(
                    getAssetCurrentCapital(asset) * programFundOwnership
                  )}
                </span>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-center px-0">
              <span>{utils.formatCurrency(getAssetCurrentCapital(asset))}</span>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Row>
  );
};

export default FundInvestments;

import React, { useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
  Col,
} from "reactstrap";
import EmailEditor from "react-email-editor";
import { utils } from "../../../utils/utils";
import { awsApi } from "../../../services/awsServices";
import { bannersApi } from "../../../services/bannerServices";

const OPTIONS = {
  tools: {
    image: {
      enabled: false,
    },
    button: {
      enabled: false,
    },
    html: {
      enabled: true,
    },
    menu: {
      enabled: false,
    },
  },
};

const BannerCreation = ({
  createBanner = () => {},
  setInformationModal = () => {},
  setConfirmationModal = () => {},
  data = null,
}) => {
  const [name, setName] = useState(data?.name || "");
  const imageRef = useRef(data?.image || "");
  const modalImageRef = useRef();
  const emailEditorRef = useRef(null);
  const [image, setImage] = useState(data?.image || "");
  const [modalImage, setModalImage] = useState(data?.modalImage || "");
  const [imageSrc, setImageSrc] = useState(data?.image || "");
  const [modalImageSrc, setModalImageSrc] = useState(data?.modalImage || "");
  const [notificationEmail, setNotificationEmail] = useState(
    data?.notificationEmail || ""
  );
  const [isActive, setIsActive] = useState(
    typeof data === "object" ? data?.isActive : true
  );

  const createNewBanner = async (event) => {
    event.preventDefault();
    try {
      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        setConfirmationModal({
          isOpen: true,
          onSubmit: async () => {
            try {
              await bannersApi.createBanner({
                name,
                image,
                modalImage,
                isActive,
                jsonContent: JSON.stringify(design),
                htmlContent: html,
                notificationEmail,
              });
              setConfirmationModal(utils.initModal);
              setInformationModal({
                isOpen: true,
                title: "Create New Banner",
                body: "Banner saved successfully.",
                onClose: () => {
                  setInformationModal(utils.initModal);
                  createBanner(false);
                },
              });
            } catch (error) {
              setConfirmationModal(utils.initModal);
              setInformationModal({
                isOpen: true,
                title: "Oops!",
                body: "Oops! Something went wrong with your request.",
                onClose: () => setInformationModal(utils.initModal),
              });
            }
          },
          onClose: () => setConfirmationModal(utils.initModal),
          title: `Create New Banner`,
          body: `<span>You confirm you want to save Banner changes?</span>`,
        });
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: "Oops! Something went wrong with your request.",
        onClose: () => setInformationModal(utils.initModal),
      });
    }
  };

  const onImageChange = (event, setImage, setImageSrc) => {
    const file = event ? event.currentTarget.files[0] : null;
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setImage(file);
        setImageSrc(URL.createObjectURL(file));
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onReady = (unlayer) => {
    unlayer.setBodyValues({
      contentWidth: "900",
    });

    if (data?.jsonContent) {
      const design = JSON.parse(data?.jsonContent);
      unlayer.loadDesign(design);
    }

    // register event to detect when an image is added to EmailEditor component
    unlayer.registerCallback("image", async function (file, done) {
      const image = file.attachments[0];
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          // upload image
          const response = await awsApi.uploadBannerImage({
            base64Image: reader.result.split(",")[1],
            imageName: image.name,
            type: image.type,
          });
          done({ progress: 100, url: response.url });
        } catch (error) {
          setInformationModal({
            isOpen: true,
            title: "Image Error",
            body: error.message || `There was an error uploading the image.`,
          });
        }
      };

      reader.readAsDataURL(image);
    });
  };

  return (
    <Card className="w-100">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Create New Banner
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={createNewBanner}>
          <Row form className="mx-0">
            <Col sm={6} className="pr-3">
              <FormGroup className="col-12 px-0">
                <Label for="name">Name</Label>
                <Input
                  maxLength={255}
                  required={true}
                  onChange={(event) => setName(event.currentTarget.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Set a banner name"
                  value={name}
                />
              </FormGroup>
            </Col>
            <Col sm={6} className="pl-3">
              <FormGroup className="col-12 px-0">
                <Label for="content">Notification Email</Label>
                <Input
                  required={false}
                  onChange={(event) =>
                    setNotificationEmail(event.currentTarget.value)
                  }
                  type="text"
                  name="notificationEmail"
                  id="notificationEmail"
                  placeholder="Set a banner notification email"
                  value={notificationEmail}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form className="mx-0">
            <Col sm={6} className="pr-3">
              <FormGroup row className="col-12 px-0">
                <Label sm={12} for="image" className="d-block">
                  Banner Image
                </Label>
                <Row className="mx-0 w-100">
                  <Col sm={6}>
                    <Input
                      className="text-truncate mt-2"
                      type="file"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(event) =>
                        onImageChange(event, setImage, setImageSrc)
                      }
                      innerRef={imageRef}
                      name="image"
                      id="image"
                    />
                  </Col>
                  <Col sm={6}>
                    {imageSrc ? (
                      <div className="text-left mt-2">
                        <div
                          className="d-inline-block sponsor-image-program-detail rounded max-width-100  col-3"
                          style={{
                            backgroundImage: `url(${imageSrc})`,
                          }}
                          alt="banner image"
                        ></div>
                        <Button
                          className="close float-none"
                          color="none"
                          onClick={() => {
                            imageRef.current.value = "";
                            setImage(null);
                            setImageSrc(null);
                          }}
                          style={{
                            verticalAlign: "top",
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col sm={6} className="pr-3">
              <FormGroup row className="col-12 px-0">
                <Label sm={12} for="modalImage" className="d-block">
                  Modal Image
                </Label>
                <Row className="mx-0 w-100">
                  <Col sm={6}>
                    <Input
                      className="text-truncate mt-2"
                      type="file"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(event) =>
                        onImageChange(event, setModalImage, setModalImageSrc)
                      }
                      innerRef={modalImageRef}
                      name="modalImage"
                      id="modalImage"
                    />
                  </Col>
                  <Col sm={6}>
                    {modalImageSrc ? (
                      <div className="text-left mt-2">
                        <div
                          className="d-inline-block sponsor-image-program-detail rounded max-width-100  col-3"
                          style={{
                            backgroundImage: `url(${modalImageSrc})`,
                          }}
                          alt="modal image"
                        ></div>
                        <Button
                          className="close float-none"
                          color="none"
                          onClick={() => {
                            modalImageRef.current.value = "";
                            setModalImage(null);
                            setModalImageSrc(null);
                          }}
                          style={{
                            verticalAlign: "top",
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row form className="mx-0">
            <Col sm={6} className="pl-3">
              <FormGroup className="col-12 px-0">
                <Label for="isActive" className="pl-0 pt-2">
                  Active
                </Label>
                <CustomInput
                  className="d-inline-block ml-3"
                  defaultChecked={isActive}
                  onClick={(event) => setIsActive(event.target.checked)}
                  type="switch"
                  id="isActive"
                  name="isActive"
                  label=""
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form className="mx-0">
            <FormGroup className="col-12 px-0">
              <Label for="content">Content</Label>
              <EmailEditor
                ref={emailEditorRef}
                onReady={onReady}
                options={OPTIONS}
              />
            </FormGroup>
          </Row>
          <Row className="m-0 justify-content-end">
            <Button
              color="warning"
              className="mr-3"
              onClick={() => createBanner(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={!name} color="primary">
              Save
            </Button>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default BannerCreation;

import React from "react";

import { Bar } from "react-chartjs-2";
import { utils } from "../../utils/utils";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const getChartData = (quartersData, programFundOwnership) => {
  return {
    datasets: [
      {
        label: "Return %",
        type: "line",
        data: quartersData.map((qEntry) => qEntry.returnPercentage),
        fill: false,
        yAxisID: "y-axis-2",
        datalabels: {
          formatter: (data) => `${(data || 0).toFixed(2)}%`,
          display: true,
          font: {
            weight: "bold",
          },
        },
      },
      {
        type: "bar",
        label: "Return Amount",
        data: quartersData.map(
          (qEntry) => qEntry.returnAmount * programFundOwnership
        ),
        fill: false,
        yAxisID: "y-axis-1",
        datalabels: {
          display: false,
        },
      },
    ],
  };
};

const getChartOptions = (quartersData) => {
  return {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
    },
    responsive: true,
    tooltips: {
      mode: "label",
      callbacks: {
        label: function (tooltipItem, data) {
          let value;
          var dataset = data["datasets"][tooltipItem.datasetIndex];
          if (dataset.yAxisID === "y-axis-2") {
            value = dataset["data"][tooltipItem["index"]];
            return `${value.toFixed(2)}%`;
          } else {
            value = dataset["data"][tooltipItem["index"]];
            return utils.formatCurrency(value || 0);
          }
        },
      },
    },
    plugins: {
      colorschemes: {
        scheme: "tableau.Classic20",
      },
    },
    scales: {
      x: {
        display: true,
        gridLines: {
          display: false,
        },
        labels: quartersData.map(
          (quarterData) => `Q${quarterData.quarter}-${quarterData.year}`
        ),
      },
      y: {
        type: "linear",
        position: "left",
        id: "y-axis-1",
        labels: {
          show: true,
        },
        ticks: {
          callback: (label) => utils.formatCurrency(label),
          maxTicksLimit: 5,
        },
        ticksRight: {
          type: "linear",
          position: "right",
          id: "y-axis-2",
          labels: {
            show: true,
          },
          ticks: {
            callback: (label) => `${label?.toFixed(2)}%`,
            maxTicksLimit: 5,
          },
        },
      },
    },
  };
};

const SFQuarterlyReturnsChart = ({
  quartersData,
  programFundOwnership = 1,
}) => {
  return quartersData.length ? (
    <Card className="mb-0 flex-grow-1 p-2">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0 text-center">
          Strategic Fund Quarterly Returns
        </CardTitle>
      </CardHeader>
      <CardBody className="d-flex col-12 align-items-center">
        <Bar
          data={getChartData(quartersData, programFundOwnership)}
          options={getChartOptions(quartersData, programFundOwnership)}
        />
      </CardBody>
    </Card>
  ) : null;
};

export default SFQuarterlyReturnsChart;

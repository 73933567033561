import React, { useEffect, useCallback, useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import {
  useDocumentsUploadFlow,
  initialState,
} from "../../../../providers/documentUploadFlowProvider";
import { documentsApi } from "../../../../services/documentServices";
import { statementsApi } from "../../../../services/statementServices";
import Loader from "../../../../components/Loader";
import { awsApi } from "../../../../services/awsServices";
import config from "../../../../config/config";
import InformationModal from "../../../../components/InformationModal";
import { useNavigate } from "react-router-dom";

const FILE_TYPE = "pdf";
const MATCH_STEP = 3;

const Upload = () => {
  const navigate = useNavigate();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const [error, setError] = useState();
  const [uploadProgress, setUploadProgress] = useState({
    progress: 0,
    total: 0,
  });

  const getDocuments = useCallback(
    () =>
      Object.keys(documentsUploadFlow.documents).reduce(
        (p, c) => [
          ...p,
          ...documentsUploadFlow.documents[c].reduce(
            (p, c) => (c.removed ? [...p] : [...p, c]),
            []
          ),
        ],
        []
      ),
    [documentsUploadFlow.documents]
  );

  const prepareDocuments = (documents, urls) =>
    documents.map(
      ({
        type,
        programId,
        investor,
        file,
        name,
        fileName,
        year,
        quarterId,
        quarter,
      }) => {
        return {
          programId: programId,
          userId: investor.id,
          userName: investor.name,
          documentTypeId: type,
          url: urls[file.name],
          name,
          quarterId,
          quarter: quarter?.value,
          fileName,
          year,
        };
      }
    );

  const onClosed = () => {
    setError();
    setDocumentsUploadFlow({ step: MATCH_STEP });
  };

  useEffect(() => {
    const documents = getDocuments();
    const filesMapping = {};
    const readyToSignDocuments = documents.map((document) => {
      filesMapping[document.fileName] = document.file;
      return {
        fileName: document.fileName,
        fileType: FILE_TYPE,
        bucket: config.documentsBucket,
        method: "putObject",
      };
    });
    awsApi
      .signDocuments({ documents: readyToSignDocuments }, "admin")
      .then(async (signedURLs) => {
        let elements = signedURLs
          .filter((signedURL) => signedURL.success)
          .map((signedURL) => {
            const file = documents.find(
              (document) => document.fileName === signedURL.fileName
            )?.file;
            return file
              ? { url: signedURL.signedRequest, file, fileUrl: signedURL.url }
              : null;
          });
        const result = [];
        setUploadProgress({
          total: elements.length,
          progress: 0,
        });
        for (const element of elements) {
          try {
            const res = await awsApi.putDocumentsToS3(element);
            result.push(res);
            setUploadProgress((uploadProgress) => ({
              ...uploadProgress,
              progress: uploadProgress.progress + 1,
            }));
          } catch (err) {
            setInformationModal({
              isOpen: true,
              title: "Oops, there was an error with your request",
              body: err?.response?.data[0]?.msg || "Please try again",
              onClose: () => {
                setError();
                setDocumentsUploadFlow({ step: MATCH_STEP });
              },
            });
          }
        }
        const urls = result.reduce((p, c) => {
          p[c.name] = c.url;
          return p;
        }, {});
        const postDocuments = prepareDocuments(documents, urls);
        if (documentsUploadFlow.statements) {
          statementsApi
            .bulkCreateStatements({ statements: postDocuments })
            .then(() => {
              setDocumentsUploadFlow({ ...initialState });
              navigate("/back/statements");
            })
            .catch((err) => {
              setInformationModal({
                isOpen: true,
                title: "Oops, there was an error with your request",
                body: err?.response?.data[0]?.msg || "Please try again",
                onClose: () => {
                  setError();
                  setDocumentsUploadFlow({ step: MATCH_STEP });
                },
              });
            });
        } else {
          documentsApi
            .bulkCreateDocuments({ documents: postDocuments })
            .then(() => {
              setDocumentsUploadFlow({ ...initialState });
              navigate("/back/documents");
            })
            .catch((err) => {
              setInformationModal({
                isOpen: true,
                title: "Oops, there was an error with your request",
                body: err?.response?.data[0]?.msg || "Please try again",
                onClose: () => {
                  setError();
                  setDocumentsUploadFlow({ step: MATCH_STEP });
                },
              });
            });
        }
      });
  }, [getDocuments, setDocumentsUploadFlow, documentsUploadFlow, navigate]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClosed}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>
        {error
          ? "Oops"
          : `Upload in Progress... (${uploadProgress.progress} / ${uploadProgress.total})`}
      </ModalHeader>
      <ModalBody>{error ? error : <Loader size="sm" />}</ModalBody>
      {error ? (
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClosed}>
            Close
          </Button>{" "}
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default Upload;

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Progress, Spinner, UncontrolledAlert } from "reactstrap";

import { utils } from "../../utils/utils";

const PREGENERATION_MODE = 4;
const GENERATION_MODE = 5;
const PREUPLOAD_MODE = 7;
const UPLOAD_MODE = 8;

const ProgramStatementsProgress = ({
  progressData,
  onAbort,
  onPreview,
  onFinish,
  onSend,
}) => {
  const getProgress = () =>
    progressData.total === 0
      ? -1
      : ((progressData.total - progressData.remaining) / progressData.total) *
        100;

  if (
    progressData.mode !== PREGENERATION_MODE &&
    progressData.mode !== PREUPLOAD_MODE &&
    progressData.mode !== UPLOAD_MODE &&
    progressData.mode !== GENERATION_MODE
  ) {
    return null;
  }

  const getText = () => {
    if (progressData.mode === GENERATION_MODE) {
      if (getProgress() < 100) {
        return `Generating Statements for ${progressData.program.name}`;
      }
      return "Ready!";
    }
    if (getProgress() < 100) {
      return `Uploading Statements for ${progressData.program.name}`;
    }
    return "Ready!";
  };

  return (
    <UncontrolledAlert
      color={"tertiary"}
      className="statements-progress col-12 px-0"
    >
      <div className="alert-icon d-flex align-items-center">
        <FontAwesomeIcon icon={faBell} fixedWidth />
      </div>
      <div className="alert-message">
        <div className="d-flex flex-column justify-content-center col-12">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {getProgress() < 100 ? (
                <Spinner color="white" type="grow" size="sm" />
              ) : null}
              <span className="text-white mr-4 ml-2">{getText()}</span>
            </div>
            <div>
              <Button
                className="rounded btn alert-icon btn-sm text-white py-1"
                color="none"
                size="sm"
                onClick={onAbort}
              >
                Abort
              </Button>
              {getProgress() >= 100 ? (
                <Button
                  className="rounded btn alert-icon btn-sm text-white py-1 ml-2"
                  color="none"
                  size="sm"
                  onClick={
                    progressData.mode === GENERATION_MODE ? onPreview : onFinish
                  }
                >
                  {`${
                    progressData.mode === GENERATION_MODE
                      ? "Preview"
                      : "Got it!"
                  }`}
                </Button>
              ) : null}
              {getProgress() >= 100 &&
              progressData.mode === GENERATION_MODE &&
              onSend ? (
                <Button
                  className="rounded btn alert-icon btn-sm text-white py-1 ml-2"
                  color="none"
                  size="sm"
                  onClick={onSend}
                >
                  Send
                </Button>
              ) : null}
            </div>
          </div>
          <Progress
            animated
            color="tertiary"
            className="col-12 mb-2 mt-3 progress px-0 border"
            value={getProgress()}
          >
            {utils.formatPercent(
              progressData.total - progressData.remaining,
              progressData.total
            )}
          </Progress>
          {getProgress() < 100 ? (
            <small>
              In the meanwhile you can continue using the app. Please don't
              close or reload it.
            </small>
          ) : null}
        </div>
      </div>
    </UncontrolledAlert>
  );
};

export default ProgramStatementsProgress;

import React, { useEffect, useState } from "react";

import { useEditInvestmentModal } from "../../../providers/editInvestmentModalProvider";
import { programsApi } from "../../../services/programServices";
import CategoryCreationModal from "../CategoryCreationModal";
import EditInvestmentModal from "./EditInvestmentModal";

const DEFAULT_MODE = 1;
const CATEGORY_CREATION_MODE = 2;

const EditInvestment = () => {
  const [loading, setLoading] = useState(true);
  const [editInvestmentModal, setEditInvestmentModal] =
    useEditInvestmentModal();

  useEffect(() => {
    programsApi.getAllInvestmentTransactionCategories({}).then((result) => {
      setEditInvestmentModal({ categories: result });
      setLoading(false);
    });
  }, [setEditInvestmentModal]);

  const onCategorySubmit = (data) => {
    setLoading(true);
    programsApi.createInvestmentTransactionCategory(data).then((result) => {
      setLoading(false);
      setEditInvestmentModal({
        categories: [...editInvestmentModal.categories, result],
        mode: DEFAULT_MODE,
      });
    });
  };

  switch (editInvestmentModal.mode) {
    case DEFAULT_MODE:
      return <EditInvestmentModal />;
    case CATEGORY_CREATION_MODE:
      return (
        <CategoryCreationModal
          loading={loading}
          onSubmit={onCategorySubmit}
          categories={editInvestmentModal.categories}
          onClose={() => setEditInvestmentModal({ mode: DEFAULT_MODE })}
        />
      );
    default:
      return null;
  }
};

export default EditInvestment;

import React from "react";

import { Table } from "reactstrap";

import Loader from "../../Loader";
import { utils } from "../../../utils/utils";

const FUND_QUARTER_STATUS_CLOSED = "2";
const TRANSACTION_TYPE_OUTCOME = "2";
const FUND_TRANSACTION_TYPE_INCOME = "1";

const getAssetCurrentCapital = (asset) =>
  asset.capital +
  (asset.transactions || []).reduce(
    (p, c) => p + (c.type === TRANSACTION_TYPE_OUTCOME ? -c.amount : c.amount),
    0
  );

const FundAssetsTable = ({ fund, fundQuarters }) => {
  const closedFundQuarters = fundQuarters
    .filter((fq) => fq.status === FUND_QUARTER_STATUS_CLOSED)
    .slice(0, 4);

  const getQuarterLabel = (quarter) => `Q${quarter.quarter}-${quarter.year}`;

  const totalAssetsCapital = fund.assets.reduce(
    (p, c) => p + getAssetCurrentCapital(c),
    0
  );

  let totalQuarterIncomes = closedFundQuarters.map(() => 0);

  const fundAssets = (fund.assets || []).map((asset) => {
    const assetCurrentCapital = getAssetCurrentCapital(asset);
    const fundPercentage =
      (assetCurrentCapital / (fund.balance + totalAssetsCapital || 1)) * 100;
    const quarterIncomes = closedFundQuarters.map((fundQuarter, i) => {
      const value = asset.fundTransactions
        .filter(
          (ft) =>
            ft.fundQuarterId === fundQuarter.id &&
            ft.type === FUND_TRANSACTION_TYPE_INCOME
        )
        .reduce((p, c) => p + c.amount, 0);
      totalQuarterIncomes[i] += value;
      return value;
    });
    return {
      name: asset.name,
      amountInvested: assetCurrentCapital,
      fundPercentage,
      quarterIncomes,
    };
  });

  return !fund ? (
    <Loader size="sm" />
  ) : (
    <Table className="text-custom-dark text-center small">
      <thead>
        <tr>
          <th className="align-middle border-0">Asset</th>
          <th className="align-middle bg-primary text-white border-0">
            Amount Invested
          </th>
          <th className="align-middle bg-primary text-white border-0">
            % of Fund
          </th>
          <th className="align-middle bg-primary text-white border-0">
            Year to Date Return
          </th>
          {closedFundQuarters.map((fundQuarter, index) => (
            <th
              key={index}
              className="align-middle bg-primary text-white border-0"
            >
              {getQuarterLabel(fundQuarter)} Income
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {fundAssets.map((fundAsset, index) => (
          <tr key={index}>
            <td className="align-middle bg-primary text-white border-white border-bottom font-weight-bold">
              {fundAsset.name}
            </td>
            <td className="border-primary border-bottom">
              {utils.formatCurrency(fundAsset.amountInvested)}
            </td>
            <td className="border-primary border-bottom">
              {fundAsset.fundPercentage.toFixed(2)}%
            </td>
            <td className="border-primary border-bottom">
              {utils.formatCurrency(
                fundAsset.quarterIncomes.reduce((p, c) => p + c, 0)
              )}
            </td>
            {fundAsset.quarterIncomes.map((quarterIncome, index) => (
              <td key={index} className="border-primary border-bottom">
                {utils.formatCurrency(quarterIncome)}
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <td className="align-middle bg-primary text-white border-0 font-weight-bold">
            Total
          </td>
          <td>
            {utils.formatCurrency(
              fundAssets.reduce((p, c) => p + c.amountInvested, 0)
            )}
          </td>
          <td>
            {fundAssets.reduce((p, c) => p + c.fundPercentage, 0).toFixed(2)}%
          </td>
          <td>
            {utils.formatCurrency(
              totalQuarterIncomes.reduce((p, c) => p + c, 0)
            )}
          </td>
          {totalQuarterIncomes.map((quarterIncome, index) => (
            <td key={index}>{utils.formatCurrency(quarterIncome)}</td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
};

export default FundAssetsTable;

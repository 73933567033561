import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { utils } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

const TYPE_INCREASE = "1";

const FundInvestment = ({ fundInvestments, hideAssets }) => {
  const navigate = useNavigate();

  if (!fundInvestments?.length) {
    return (
      <Card className="box-shadow-none mb-0">
        <CardHeader className="border-bottom">
          <CardTitle className="my-0 d-flex justify-content-center">
            <span>The Program has no Investments Yet</span>
          </CardTitle>
        </CardHeader>
      </Card>
    );
  }

  const fund = fundInvestments[0].fundFundInvestment;

  const totalFundInvestments = fund?.fundFundInvestments?.reduce(
    (p, c) => p + c.amount,
    0
  );

  const totalInvested = fundInvestments.reduce(
    (p, c) => p + c.amount * (c.type === TYPE_INCREASE ? 1 : -1),
    0
  );

  const programFundOwnership = fundInvestments[0].amount / totalFundInvestments;

  return (
    <Card className="box-shadow-none mb-0 ">
      <CardHeader className="border-bottom">
        <CardTitle className="my-0 d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <span>{`${fund.name} · ${fund.year}`}</span>
            <small className="mt-1 text-muted">Equity</small>
          </div>
          <div className="d-flex flex-column align-items-end justify-content-between">
            <span>{utils.formatCurrency(totalInvested)}</span>
            <Button
              size="sm"
              onClick={() => navigate(`/back/funds/${fund.id}`)}
              className="rounded btn btn-secondary mt-1"
            >
              Go to Fund
            </Button>
          </div>
        </CardTitle>
      </CardHeader>
      {!hideAssets && fund.assets.length ? (
        <CardBody className="py-0 border-bottom">
          <ListGroup flush>
            {fund.assets.map((asset, i) => (
              <ListGroupItem
                key={i}
                className={`${
                  i < fund.assets.length - 1 ? "border-bottom" : ""
                } d-flex justify-content-between px-0 align-items-center`}
                tag="div"
              >
                <div className="d-flex align-items-center">
                  <span className="ml-2 mr-3">·</span>
                  <div className="d-flex flex-column">
                    <span>{asset.name}</span>
                    <small className="mt-n1 text-muted">
                      {asset.category?.name}
                    </small>
                  </div>
                </div>
                <small>
                  {utils.formatCurrency(asset.capital * programFundOwnership)}
                </small>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      ) : null}
    </Card>
  );
};

export default FundInvestment;

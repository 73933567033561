import React, { useCallback, useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
  ListGroup,
  ListGroupItem,
  CustomInput,
  Form,
} from "reactstrap";

import { usersApi } from "../../services/userServices";

import UserBasicAttributes from "./UserBasicAttributes";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import DateInput from "../Forms/DateInput";
import { usePrograms } from "../../providers/programsProvider";
import { USER_ROLES } from "../../utils/constants";
import UserAddress from "./UserAddress";
import moment from "moment";

const DEFAULT_UNIT_VALUE = 1000;
const USER_STATUS_INACTIVE = 1;
const STAKEHOLDER_MODE = 3;

const EXISTING_USER = "1";
const NEW_USER = "2";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const AddProgramActorModal = ({
  onSubmit,
  onClose,
  title = "Add Stakeholder to PropCo",
  actorType = USER_ROLES.STAKEHOLDER,
}) => {
  const [programsContext] = usePrograms();
  const [percentage, setPercentage] = useState(undefined);
  const [capital, setCapital] = useState("");
  const [dateEffective, setDateEffective] = useState(new Date());
  const [user, setUser] = useState();
  const [usersOptions, setUsersOptions] = useState([]);

  const [mode, setMode] = useState(EXISTING_USER);
  const [search, setSearch] = useState("");
  const [optionsLoading, setOptionsLoading] = useState(false);

  const onAttrEdit = useCallback(
    (field, value) => {
      setUser((user) => {
        return { ...user, [field]: value };
      });
    },
    [setUser]
  );

  useEffect(() => {
    setOptionsLoading(true);
    Promise.all(
      [USER_ROLES.INVESTOR, USER_ROLES.STAKEHOLDER, USER_ROLES.ADVISOR].map(
        (userType) =>
          usersApi.getUsers({
            role: userType,
            search: search,
            page: 0,
            pageSize: search.length >= 2 ? 999999999 : 10,
          })
      )
    ).then(([stakeholders, investors, advisors]) => {
      let users;
      switch (actorType) {
        case USER_ROLES.INVESTOR:
          users = programsContext.program.programInvestments;
          break;
        case USER_ROLES.STAKEHOLDER:
          users = programsContext.program.stakeholders;
          break;
        default:
          users = programsContext.program.stakeholders;
      }
      let options = [...stakeholders.data, ...investors.data, ...advisors.data]
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .filter((item, pos, ary) => !pos || item.id !== ary[pos - 1].id)
        .filter(
          (user) =>
            !users.find(
              (st) => st.userId === user.id || st.investorId === user.id
            )
        )
        .map((user) => {
          return {
            label: user.name,
            value: { userId: user.id, investedAs: user.name },
          };
        });
      setUsersOptions(options);
      setOptionsLoading(false);
    });
  }, [
    search,
    programsContext.program.stakeholders,
    programsContext.program.programInvestments,
    actorType,
  ]);

  const handleSubmit = (event) => {
    event && event.preventDefault();
    const isNewUser = mode === NEW_USER;
    onSubmit({
      ...(isNewUser
        ? { ...user, status: USER_STATUS_INACTIVE }
        : { userId: user.userId, investedAs: user.investedAs }),
      ...(actorType === USER_ROLES.STAKEHOLDER
        ? {
            isStakeholder: true,
            percentage,
          }
        : {}),
      ...(actorType === USER_ROLES.INVESTOR
        ? {
            isInvestor: true,
            capital,
            units: capital / DEFAULT_UNIT_VALUE,
            dateEffective: moment(dateEffective).format("YYYY-MM-DD"),
          }
        : {}),
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <ModalBody className="text-center">
        <FormGroup row>
          <Label sm={4} className="text-sm-left">
            User Type
          </Label>
          <Col sm={8}>
            <CustomInput
              required={true}
              type="select"
              id="exampleCustomSelect"
              name="customSelect"
              onChange={(event) => {
                setMode(event.currentTarget.value);
                setUser({});
              }}
              value={mode}
            >
              <option value={EXISTING_USER}>Existing User</option>
              <option value={NEW_USER}>New User</option>
            </CustomInput>
          </Col>
        </FormGroup>
        {actorType === USER_ROLES.INVESTOR ? (
          <>
            <FormGroup row>
              <Label sm={4} className="text-sm-left d-flex align-items-center">
                Investment Amount
              </Label>
              <Col sm={8}>
                <Input
                  required={true}
                  type="number"
                  min={0}
                  step={500}
                  placeholder="Enter amount.."
                  onChange={(event) =>
                    setCapital(parseInt(event.currentTarget.value, 10))
                  }
                  value={capital || ""}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={4} className="text-sm-left d-flex align-items-center">
                Investment Units
              </Label>
              <Col sm={8}>
                <Input
                  required={true}
                  disabled={true}
                  type="number"
                  placeholder="Investment units.."
                  value={capital / DEFAULT_UNIT_VALUE}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={4} className="text-sm-left d-flex align-items-center">
                Effective Date
              </Label>
              <div className="col-sm-8">
                <DateInput
                  value={dateEffective}
                  onChange={(date) => setDateEffective(date)}
                />
              </div>
            </FormGroup>
          </>
        ) : null}
        {actorType === USER_ROLES.STAKEHOLDER ? (
          <FormGroup row>
            <Label sm={4} className="text-sm-left d-flex align-items-center">
              Program Ownership
            </Label>
            <Col sm={8}>
              <Input
                required={true}
                type="number"
                max={
                  99 -
                  programsContext.program.stakeholders.reduce(
                    (p, c) => p + c.percentage,
                    0
                  )
                }
                min={0}
                step={0.01}
                placeholder="Enter a percentage.."
                onChange={(event) => setPercentage(event.currentTarget.value)}
                value={percentage}
              />
            </Col>
          </FormGroup>
        ) : null}
        {mode === EXISTING_USER ? (
          <Form onSubmit={handleSubmit}>
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left d-flex align-items-center">
                User
              </Label>
              <Col sm={8}>
                <Select
                  placeholder={"Search for users.."}
                  noOptionsMessage={() => "No users found"}
                  styles={style}
                  className="col-12 px-0 border rounded"
                  options={usersOptions}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  defaultValue={user}
                  isSearchable
                  inputValue={search}
                  onInputChange={(value) => setSearch(value)}
                  onChange={(selected) => setUser(selected.value)}
                  isLoading={optionsLoading}
                  required={true}
                />
              </Col>
            </FormGroup>
          </Form>
        ) : (
          <>
            <ListGroup flush className="text-left border rounded">
              <ListGroupItem
                className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                tag="div"
              >
                Personal Info
              </ListGroupItem>
              <ListGroupItem className="pb-2 border-bottom" tag="div">
                <UserBasicAttributes
                  includeSafeData
                  mode={STAKEHOLDER_MODE}
                  user={user}
                  onAttrEdit={onAttrEdit}
                  hideButton
                />
              </ListGroupItem>
            </ListGroup>
            <ListGroup flush className="text-left border rounded mt-2">
              <ListGroupItem
                className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                tag="div"
              >
                Address
              </ListGroupItem>
              <ListGroupItem className="pb-2 border-bottom" tag="div">
                <UserAddress user={user} onAttrEdit={onAttrEdit} hideButton />
              </ListGroupItem>
            </ListGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Row className="w-100 d-flex justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={mode === EXISTING_USER && !user}
            type="submit"
            color="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default AddProgramActorModal;

import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const onBoardApi = {
  startOnBoarding: ({ token, email, ssNumber }) => {
    return axios(`/api/onboard/activate`, {
      baseURL: config.apiURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      data: { token, email, ssNumber },
    })
      .then((result) => result.data)
      .catch((err) => {
        throw Error(err);
      });
  },
  updateUser: (data) => {
    return axios(`/api/onboard/users/${data.id}`, {
      baseURL: config.apiURL,
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      method: "put",
      data: data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getAdvisements: (data) => {
    return axios(
      `/api/onboard/advisements?${
        data.investorId ? "investorId" : "advisorId"
      }=${data.investorId || data.advisorId}`,
      {
        baseURL: config.apiURL,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  removeAdvisement: (data) => {
    return axios(`/api/onboard/advisements/${data.id}`, {
      baseURL: config.apiURL,
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};

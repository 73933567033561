import React, { useCallback, useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  CustomInput,
  ListGroup,
  ListGroupItem,
  UncontrolledAlert,
  Row,
} from "reactstrap";
import { fundsApi } from "../../../../services/fundServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faArrowUp,
  faArrowDown,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../../../utils/utils";
import Loader from "../../../Loader";
import DateInput from "../../../Forms/DateInput";
import {
  useFundAssetDetailModal,
  initialState,
} from "../../../../providers/fundAssetDetailModalProvider";
import CurrencyInput from "react-currency-input-field";
import InformationModal from "../../../InformationModal";
import { useFundContext } from "../../../../providers/fundsProvider";

const MAX_VALUE = 999999999;

const TRANSACTION_CATEGORY_CREATION_MODE = 2;
const ASSET_CATEGORY_CREATION_MODE = 4;
const DELETION_MODE = 3;

const TYPE_INCOME = {
  id: 1,
  name: "Increase",
};

const TYPE_OUTCOME = {
  id: 2,
  name: "Decrease",
};

const TYPES = [TYPE_INCOME, TYPE_OUTCOME];

const FundAssetDetailModal = ({ loading: startLoading }) => {
  const [fundAssetDetailModal, setFundAssetDetailModal] =
    useFundAssetDetailModal();
  const [fundContext, setFundContext] = useFundContext();

  const [loading, setLoading] = useState(startLoading);
  const [errorAssetCapital, setErrorAssetCapital] = useState(false);
  const [errorAssetTransactionCapital, setErrorAssetTransactionCapital] =
    useState(false);
  const [capital, setCapital] = useState();

  const [asset, setAsset] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });

  const unallocatedFundCapital = fundContext.fund.balance;
  const assetCurrentCapital = asset
    ? parseFloat(asset.capital) +
      (asset.transactions || []).reduce(
        (p, c) =>
          p + (parseInt(c.type) === TYPE_OUTCOME.id ? -c.amount : c.amount),
        0
      )
    : 0;

  const onDismissAssetAlert = () => {
    if (errorAssetCapital) {
      setCapital("");
    }
    setErrorAssetCapital(false);
  };

  const onDismissTransactionAlert = () => {
    if (errorAssetTransactionCapital) {
      setFundAssetDetailModal({ amount: "" });
    }
    setErrorAssetTransactionCapital(false);
  };

  const setFundAssetDetailModalCb = useCallback(
    (data) => {
      setFundAssetDetailModal(data);
    },
    [setFundAssetDetailModal]
  );

  const setAssetCb = useCallback(
    (data) => {
      setAsset(data);
      setCapital(data.capital);
    },
    [setAsset]
  );

  useEffect(() => {
    fundsApi
      .getFundAsset({
        fundId: fundAssetDetailModal.fundId,
        assetId: fundAssetDetailModal.assetId,
      })
      .then((asset) => {
        if (
          !fundAssetDetailModal.fundAssetCategoryId ||
          fundAssetDetailModal.fundAssetCategoryId === ""
        ) {
          setFundAssetDetailModalCb({
            fundAssetCategoryId: asset.fundAssetCategoryId,
          });
        }
        setAssetCb({ ...asset });
        setLoading(false);
      });
  }, [
    fundAssetDetailModal.fundId,
    fundAssetDetailModal.assetId,
    fundAssetDetailModal.fundAssetCategoryId,
    setAssetCb,
    setFundAssetDetailModalCb,
    setLoading,
  ]);

  const onSubmitTransaction = (event) => {
    event.preventDefault();
    setLoading(true);
    fundsApi
      .createFundAssetTransaction({
        fundId: asset.fundId,
        assetId: asset.id,
        amount: fundAssetDetailModal.amount,
        type: fundAssetDetailModal.type,
        fundAssetTransactionCategoryId:
          fundAssetDetailModal.transactionCategoryId,
        date: utils.formatDate(fundAssetDetailModal.date, "YYYY-MM-DD"),
      })
      .then(() => {
        fundsApi
          .getFundAsset({ assetId: asset.id, fundId: asset.fundId })
          .then((asset) => {
            setAsset(asset);
            const balance =
              fundContext.fund.balance +
              parseFloat(fundAssetDetailModal.amount) *
                (parseInt(fundAssetDetailModal.type) === TYPE_OUTCOME.id
                  ? 1
                  : -1);
            let fundAssets = fundContext.fund.assets;
            fundAssets.splice(
              fundAssets.findIndex((a) => a.id === asset.id),
              1,
              asset
            );
            setFundContext({
              fund: { ...fundContext.fund, balance, assets: [...fundAssets] },
            });
            setFundAssetDetailModal({
              amount: "",
              type: "",
              transactionCategoryId: "",
            });
            setLoading(false);
          });
      });
  };

  const onSubmitFundAsset = (event) => {
    event.preventDefault();
    if (
      asset.capital === capital &&
      fundAssetDetailModal.fundAssetCategoryId === asset.fundAssetCategoryId
    ) {
      return;
    }
    setLoading(true);
    fundsApi
      .updateFundAsset({
        id: asset.id,
        name: asset.name,
        capital,
        fundId: asset.fundId,
        fundAssetCategoryId: fundAssetDetailModal.fundAssetCategoryId,
      })
      .then(() => {
        fundsApi
          .getFundAsset({ assetId: asset.id, fundId: asset.fundId })
          .then((asset) => {
            const balance =
              fundContext.fund.balance +
              parseFloat(fundAssetDetailModal.assetCapital) -
              parseFloat(asset.capital);
            let fundAssets = fundContext.fund.assets;
            fundAssets.splice(
              fundAssets.findIndex((a) => a.id === asset.id),
              1,
              asset
            );
            setFundContext({
              fund: { ...fundContext.fund, balance, assets: [...fundAssets] },
            });
            setAsset(asset);
            setLoading(false);
          });
      });
  };

  const onDeleteTransaction = (transaction) => {
    setLoading(true);
    fundsApi
      .deleteFundAssetTransaction({
        fundId: asset.fundId,
        assetId: asset.id,
        transactionId: transaction.id,
      })
      .then(() => {
        setLoading(false);
        let updatedAsset = { ...asset };
        updatedAsset.transactions
          ? updatedAsset.transactions.splice(
              updatedAsset.transactions.findIndex(
                (t) => t.id === transaction.id
              ),
              1
            )
          : (updatedAsset.transactions = []);
        const balance =
          fundContext.fund.balance +
          parseFloat(transaction.amount) *
            (parseInt(transaction.type) === TYPE_OUTCOME.id ? -1 : 1);
        let assets = [...fundContext.fund.assets];
        assets.splice(
          assets.findIndex((a) => a.id === asset.id),
          1,
          asset
        );
        setFundContext({
          fund: { ...fundContext.fund, assets: [...assets], balance },
        });
        setAsset(updatedAsset);
      });
  };

  const onFundAssetDelete = () => {
    if (asset.fundTransactions.length) {
      return setInformationModal({
        isOpen: true,
        body: "This Asset cannot be deleted because has transactions associated.",
        onClose: () => {
          setInformationModal({ isOpen: false, body: null });
        },
      });
    }
    setFundAssetDetailModal({ assetName: asset.name, mode: DELETION_MODE });
  };

  const onAssetCapitalChange = (value) => {
    setErrorAssetCapital(unallocatedFundCapital + assetCurrentCapital < value);
    if (!value || value <= MAX_VALUE) {
      setCapital(value);
    }
  };

  const onTransactionAmountChange = (value) => {
    setErrorAssetTransactionCapital(
      parseInt(fundAssetDetailModal.type) === TYPE_OUTCOME.id
        ? value > assetCurrentCapital
        : value > unallocatedFundCapital
    );
    if (!value || value < MAX_VALUE) {
      setFundAssetDetailModal({ amount: value });
    }
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setFundAssetDetailModal({ isModalOpen: false })}
    >
      &times;
    </Button>
  );

  return informationModal?.isOpen ? (
    <InformationModal
      title={"Heads Up!"}
      body={informationModal.body}
      onClose={() => setInformationModal({ isOpen: false, body: "" })}
    />
  ) : asset ? (
    <Modal
      isOpen={true}
      onClosed={() => setFundAssetDetailModal({ ...initialState })}
    >
      <ModalHeader close={closeBtn}>
        <Col className="mb-n2 px-0">
          <h5 className="mb-0">{asset.name}</h5>
          <small className="text-muted">{asset.category.name}</small>
        </Col>
      </ModalHeader>
      <ModalBody className="text-center py-4">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <>
            <Form onSubmit={onSubmitFundAsset}>
              <FormGroup row className="d-flex align-items-center">
                <Label
                  sm={4}
                  className="text-sm-left d-flex flex-column justify-content-center"
                >
                  <span>Asset Initial Capital</span>
                  <small className="font-size-6 text-warning">
                    Max{" "}
                    {utils.formatCurrency(
                      unallocatedFundCapital + assetCurrentCapital,
                      5
                    )}
                  </small>
                </Label>
                <Col sm={8}>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    required={true}
                    allowNegativeValue={false}
                    maxLength={20}
                    className="form-control"
                    placeholder="Enter the initial capital.."
                    value={capital}
                    onValueChange={onAssetCapitalChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Category
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="categorySelect"
                    type="select"
                    name="categorySelect"
                    onChange={(event) =>
                      setFundAssetDetailModal({
                        fundAssetCategoryId: event.currentTarget.value,
                      })
                    }
                    value={fundAssetDetailModal.fundAssetCategoryId}
                  >
                    <option value="">Select...</option>
                    {fundAssetDetailModal.assetCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </CustomInput>
                  <div className="d-flex justify-content-between">
                    <Button
                      color={"info"}
                      size={"sm"}
                      className="mt-2 rounded"
                      onClick={() =>
                        setFundAssetDetailModal({
                          mode: ASSET_CATEGORY_CREATION_MODE,
                        })
                      }
                    >
                      New Category
                    </Button>
                    <Button
                      disabled={errorAssetCapital}
                      color={"primary"}
                      className="mt-2 rounded"
                      size={"sm"}
                      type="submit"
                    >
                      Save Asset
                    </Button>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="d-flex align-items-center">
                <Label
                  sm={4}
                  className="text-sm-left d-flex flex-column justify-content-center"
                >
                  Balance
                </Label>
                <Col sm={8} className="font-weight-bold text-left">
                  {utils.formatCurrency(assetCurrentCapital)}
                </Col>
              </FormGroup>
            </Form>
            <Col className="d-flex flex-column align-items-start border rounded px-0">
              <ListGroup flush className="col-12 p-0">
                <ListGroupItem
                  className="text-underline py-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                  tag="div"
                >
                  Transactions
                </ListGroupItem>
                {asset.transactions?.map((transaction, index) => (
                  <ListGroupItem
                    key={index}
                    className="p-2 border-bottom d-flex justify-content-between"
                    tag="div"
                  >
                    <div>
                      {parseInt(transaction.type) === TYPE_INCOME.id ? (
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          fixedWidth
                          className="mr-2 text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          fixedWidth
                          className="mr-2 text-danger"
                        />
                      )}
                      <span>{utils.formatDate(transaction.date)}</span>
                      <span className="mx-2">·</span>
                      <span>{transaction.category?.name}</span>
                    </div>
                    <div>
                      <span>{utils.formatCurrency(transaction.amount)}</span>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        fixedWidth
                        className="ml-2 text-danger cursor-pointer"
                        onClick={() => onDeleteTransaction(transaction)}
                      />
                    </div>
                  </ListGroupItem>
                ))}
                {!asset.transactions?.length ? (
                  <ListGroupItem
                    className="py-2 d-flex justify-content-between"
                    tag="div"
                  >
                    <span>No transactions to show</span>
                  </ListGroupItem>
                ) : null}
              </ListGroup>
              <Form
                className="col-12 p-3 text-left"
                onSubmit={onSubmitTransaction}
              >
                <span className="font-weight-bold text-underline">
                  Add Transaction
                </span>
                <FormGroup row className="mt-3 d-flex align-items-center">
                  <Label
                    sm={4}
                    className="text-sm-left d-flex flex-column justify-content-center"
                  >
                    <span>Amount</span>
                    {fundAssetDetailModal.type ? (
                      <small className="font-size-6 text-warning">
                        Max{" "}
                        {utils.formatCurrency(
                          parseInt(fundAssetDetailModal.type) ===
                            TYPE_OUTCOME.id
                            ? assetCurrentCapital
                            : unallocatedFundCapital,
                          5
                        )}
                      </small>
                    ) : null}
                  </Label>
                  <Col sm={8}>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      required={true}
                      allowNegativeValue={false}
                      maxLength={20}
                      className="form-control"
                      placeholder="Enter the amount.."
                      value={fundAssetDetailModal.amount}
                      onValueChange={onTransactionAmountChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="mt-3">
                  <Label sm={4} className="text-sm-left">
                    Date
                  </Label>
                  <Col sm={8}>
                    <DateInput
                      value={fundAssetDetailModal.date}
                      onChange={(date) => setFundAssetDetailModal({ date })}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={4} className="text-sm-left">
                    Type
                  </Label>
                  <Col sm={8}>
                    <CustomInput
                      required={true}
                      id="typeSelect"
                      type="select"
                      name="typeSelect"
                      onChange={(event) =>
                        setFundAssetDetailModal({
                          type: event.currentTarget.value,
                        })
                      }
                      value={fundAssetDetailModal.type}
                    >
                      <option value="">Select...</option>
                      {TYPES.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup row className="mb-0">
                  <Label sm={4} className="text-sm-left">
                    Category
                  </Label>
                  <Col sm={8}>
                    <CustomInput
                      required={true}
                      id="categorySelect"
                      type="select"
                      name="categorySelect"
                      onChange={(event) =>
                        setFundAssetDetailModal({
                          transactionCategoryId: event.currentTarget.value,
                        })
                      }
                      value={
                        fundAssetDetailModal.transactionCategoryId ||
                        fundAssetDetailModal.transactionCategories.find(
                          (c) => c.name === asset.category.name
                        )?.id
                      }
                    >
                      <option value="">Select...</option>
                      {fundAssetDetailModal.transactionCategories.map(
                        (category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        )
                      )}
                    </CustomInput>
                    <div className="d-flex justify-content-between">
                      <Button
                        color={"info"}
                        size={"sm"}
                        className="mt-2 rounded"
                        onClick={() =>
                          setFundAssetDetailModal({
                            mode: TRANSACTION_CATEGORY_CREATION_MODE,
                          })
                        }
                      >
                        New Category
                      </Button>
                      <Button
                        color={"primary"}
                        className="mt-2 rounded"
                        size={"sm"}
                        type="submit"
                      >
                        Add Transaction
                      </Button>
                    </div>
                  </Col>
                </FormGroup>
                <UncontrolledAlert
                  toggle={onDismissTransactionAlert}
                  isOpen={errorAssetTransactionCapital}
                  color="warning"
                  className="mt-3 mb-0"
                >
                  <div className="alert-icon d-flex align-items-center">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <span>
                      {parseInt(fundAssetDetailModal.type) === TYPE_OUTCOME.id
                        ? `Transaction amount can't be higher than the Asset capital of ${utils.formatCurrency(
                            assetCurrentCapital,
                            5
                          )}`
                        : `Transaction amount can't be higher than the Fund unallocated capital of ${utils.formatCurrency(
                            unallocatedFundCapital,
                            5
                          )}`}
                    </span>
                  </div>
                </UncontrolledAlert>
              </Form>
            </Col>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Col>
          <UncontrolledAlert
            toggle={onDismissAssetAlert}
            isOpen={errorAssetCapital}
            color="warning"
          >
            <div className="alert-icon d-flex align-items-center">
              <FontAwesomeIcon icon={faBell} fixedWidth />
            </div>
            <div className="alert-message text-left">
              <span>{`Asset capital can't be higher than the Fund unallocated capital of ${utils.formatCurrency(
                unallocatedFundCapital + assetCurrentCapital,
                5
              )}`}</span>
            </div>
          </UncontrolledAlert>
          <Row className="justify-content-between">
            <Button
              color={"secondary"}
              onClick={() => setFundAssetDetailModal({ isModalOpen: false })}
            >
              Close
            </Button>
            <Button
              className="ml-2"
              color={"danger"}
              onClick={onFundAssetDelete}
            >
              <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  ) : (
    <Loader />
  );
};

export default FundAssetDetailModal;

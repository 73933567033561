import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  CustomInput,
} from "reactstrap";
import { fundsApi } from "../../../../../../services/fundServices";
import {
  initialState,
  useFundTransactionModal,
} from "../../../../../../providers/fundTransactionModalProvider";
import Loader from "../../../../../Loader";
import CurrencyInput from "react-currency-input-field";
import { useFundContext } from "../../../../../../providers/fundsProvider";
import { utils } from "../../../../../../utils/utils";
import DateInput from "../../../../../Forms/DateInput";

const CATEGORY_CREATION_MODE = 2;

const MAX_VALUE = 999999999;

const TYPE_INCOME = {
  id: "1",
  name: "Income",
};
const TYPE_EXPENSE = {
  id: "2",
  name: "Expense",
};
const TYPE_INCOME_DDD = {
  id: "3",
  name: "Income DND",
};

const TYPES = [TYPE_INCOME, TYPE_EXPENSE, TYPE_INCOME_DDD];

const FundQuarterTransactionCreationModal = () => {
  const [loading, setLoading] = useState(false);
  const [fundTransactionModal, setFundTransactionModal] =
    useFundTransactionModal();

  const [fundContext, setFundContext] = useFundContext();
  const [selectedFundQuarter, setSelectedFundQuarter] = useState(
    fundContext.selectedFundQuarter
  );

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    fundsApi
      .createFundTransaction({
        fundAssetId: fundTransactionModal.fundAssetId,
        fundId: fundContext.fund.id,
        fundQuarterId: selectedFundQuarter.id,
        type: fundTransactionModal.type,
        amount: fundTransactionModal.amount,
        date: utils.formatDate(fundTransactionModal.date, "YYYY-MM-DD"),
        fundTransactionCategoryId:
          fundTransactionModal.fundTransactionCategoryId,
      })
      .then(() =>
        fundsApi
          .getFundQuarters({
            fundId: fundContext.fund.id,
            id: selectedFundQuarter.id,
          })
          .then((selectedFundQuarter) => {
            setLoading(false);
            setFundTransactionModal({ ...initialState });
            let fundQuarters = [...fundContext.fundQuarters];
            fundQuarters.splice(
              fundContext.fundQuarters.findIndex(
                (fc) => fc.id === selectedFundQuarter.id
              ),
              1,
              selectedFundQuarter
            );
            setFundContext({ fundQuarters, selectedFundQuarter });
          })
      );
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setFundTransactionModal({ isModalOpen: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal
      isOpen={true}
      onClosed={() => setFundTransactionModal({ isModalOpen: false })}
    >
      <ModalHeader close={closeBtn}>
        <span>Add New Transaction</span>
        <br />
        <small>
          Q{selectedFundQuarter.quarter}-{selectedFundQuarter.year}
        </small>
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Quarter
                </Label>
                <Col sm={7}>
                  <CustomInput
                    id="quarterSelect"
                    type="select"
                    name="quarterSelect"
                    onChange={(event) =>
                      setSelectedFundQuarter(
                        fundContext.fundQuarters.find(
                          (quarter) =>
                            quarter.id === parseInt(event.currentTarget.value)
                        )
                      )
                    }
                    value={selectedFundQuarter.id}
                  >
                    {fundContext.fundQuarters.map((fundQuarter) => (
                      <option key={fundQuarter.id} value={fundQuarter.id}>
                        Q{fundQuarter.quarter}-{fundQuarter.year}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Type
                </Label>
                <Col sm={7}>
                  <CustomInput
                    required={true}
                    id="typeSelect"
                    type="select"
                    name="typeSelect"
                    onChange={(event) =>
                      setFundTransactionModal({
                        type: event.currentTarget.value,
                      })
                    }
                    value={fundTransactionModal.type}
                  >
                    <option value="">Select...</option>
                    {TYPES.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3">
                <Label sm={5} className="text-sm-left">
                  Date
                </Label>
                <Col sm={7}>
                  <DateInput
                    value={fundTransactionModal.date}
                    onChange={(date) => setFundTransactionModal({ date })}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Fund Asset
                </Label>
                <Col sm={7}>
                  <CustomInput
                    required={true}
                    id="assetSelect"
                    type="select"
                    name="assetSelect"
                    onChange={(event) =>
                      setFundTransactionModal({
                        fundAssetId: event.currentTarget.value,
                      })
                    }
                    value={fundTransactionModal.fundAssetId || ""}
                  >
                    <option value="">Select...</option>
                    {fundContext.fund.assets.map((asset) => (
                      <option key={asset.id} value={asset.id}>
                        {asset.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              {fundTransactionModal.type === TYPE_EXPENSE.id ? (
                <FormGroup row>
                  <Label sm={5} className="text-sm-left">
                    Category
                  </Label>
                  <Col sm={7}>
                    <CustomInput
                      required={true}
                      id="categorySelect"
                      type="select"
                      name="categorySelect"
                      onChange={(event) =>
                        setFundTransactionModal({
                          fundTransactionCategoryId: event.currentTarget.value,
                        })
                      }
                      value={fundTransactionModal.fundTransactionCategoryId}
                    >
                      <option value="">Select...</option>
                      {fundTransactionModal.categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </CustomInput>
                    <Button
                      color={"info"}
                      size={"sm"}
                      className="float-left mt-2 rounded"
                      onClick={() =>
                        setFundTransactionModal({
                          mode: CATEGORY_CREATION_MODE,
                        })
                      }
                    >
                      New Category
                    </Button>
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row className="mb-0">
                <Label sm={5} className="text-sm-left">
                  Amount
                </Label>
                <Col sm={7}>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    required={true}
                    allowNegativeValue={false}
                    maxLength={20}
                    className="form-control"
                    placeholder="Enter the amount.."
                    value={fundTransactionModal.amount}
                    onValueChange={(value) => {
                      if (!value || value < MAX_VALUE) {
                        setFundTransactionModal({ amount: value });
                      }
                    }}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            color={"secondary"}
            onClick={() => setFundTransactionModal({ isModalOpen: false })}
          >
            Cancel
          </Button>{" "}
          <Button color={"primary"} type="submit">
            Create
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundQuarterTransactionCreationModal;

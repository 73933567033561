import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap";

import brand from "../../../assets/img/brand.png";

import { useAuth } from "../../../providers/authProvider";
import { authApi } from "../../../services/authServices";
import Loader from "../../../components/Loader";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sponsorsApi } from "../../../services/sponsorServices";

export const LoginForm = () => {
  const { sponsorName } = useParams();
  const [authContext, setAuthContext] = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sponsor, setSponsor] = useState();

  const onDismiss = () => setError(false);

  const doLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    authApi
      .login({
        username: authContext.credentials.email,
        password: authContext.credentials.pwd,
      })
      .then((result) => {
        setLoading(false);
        return result
          ? setAuthContext({
              currentUser: { ...result },
              advisements: result.investors,
              sms: result.sms,
            })
          : null;
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (sponsorName) {
        setLoading(true);
        const sponsor = await sponsorsApi.getSponsors({
          name: sponsorName.replaceAll("_", " "),
        });
        setSponsor(sponsor?.data[0]);
        setLoading(false);
      }
    };
    fetchData();
  }, [sponsorName]);

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Welcome back!</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            {loading ? (
              <Loader size="sm" />
            ) : (
              <div className="text-center">
                <div
                  className="rounded p-0 col-10"
                  style={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${sponsor?.webLogo || brand})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                    alignSelf: "center",
                    height: "150px",
                    width: "80%",
                    margin: "0 auto",
                  }}
                />
              </div>
            )}
            <Form onSubmit={doLogin}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  required={true}
                  maxLength="50"
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  onChange={(event) =>
                    setAuthContext({
                      credentials: {
                        ...authContext.credentials,
                        email: event.currentTarget.value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  required={true}
                  maxLength="50"
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  onChange={(event) =>
                    setAuthContext({
                      credentials: {
                        ...authContext.credentials,
                        pwd: event.currentTarget.value,
                      },
                    })
                  }
                />
                <small>
                  <Link to="/auth/reset">Forgot password?</Link>
                </small>
              </FormGroup>
              <div className="text-center mt-3">
                <UncontrolledAlert
                  isOpen={error}
                  toggle={onDismiss}
                  color="warning"
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <strong>Hey!</strong>
                    <span className="ml-2">
                      It seems your credentials are not correct.
                    </span>
                  </div>
                </UncontrolledAlert>
                {loading ? (
                  <Loader size="sm" />
                ) : (
                  <Button color="primary" type="submit" size="lg">
                    Sign in
                  </Button>
                )}
              </div>
            </Form>
          </div>
          <div className="text-center">
            <Link to="/onboard/manual">Not Registered?</Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

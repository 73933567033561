import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sortBy: "fileName",
  direction: "asc",
  sizePerPage: "All",
  page: 1,
  search: "",
  type: "REGULAR",
  investorId: null,
  programId: null,
};

const StatementsContext = createContext(initialState);

const StatementsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <StatementsContext.Provider value={stateAndDispatch}>
      {children}
    </StatementsContext.Provider>
  );
};

export const useStatements = () => useContext(StatementsContext);

export { StatementsContext, StatementsProvider, initialState };

import React, { useEffect, useState } from "react";

import { useEditProgramStatusModal } from "../../../providers/editProgramStatusModalProvider";
import { programsApi } from "../../../services/programServices";
import ProgramStatusCreationModal from "./ProgramStatusCreationModal";
import EditProgramStatusModal from "./EditProgramStatusModal";

const DEFAULT_MODE = 1;
const STATUS_CREATION_MODE = 2;

const EditProgramStatus = ({ onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [editProgramStatusModal, setEditProgramStatusModal] =
    useEditProgramStatusModal();

  useEffect(() => {
    programsApi.getProgramStatus().then((result) => {
      setEditProgramStatusModal({ statuses: result });
      setLoading(false);
    });
  }, [setEditProgramStatusModal]);

  const onStatusSubmit = (data) => {
    setLoading(true);
    programsApi.createProgramStatus(data).then((result) => {
      setLoading(false);
      setEditProgramStatusModal({
        statuses: [...editProgramStatusModal.statuses, result],
        createdStatus: result.id,
        mode: DEFAULT_MODE,
      });
    });
  };

  switch (editProgramStatusModal.mode) {
    case DEFAULT_MODE:
      return <EditProgramStatusModal onSubmit={onSubmit} />;
    case STATUS_CREATION_MODE:
      return (
        <ProgramStatusCreationModal
          loading={loading}
          onSubmit={onStatusSubmit}
        />
      );
    default:
      return null;
  }
};

export default EditProgramStatus;

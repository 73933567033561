import React, { useState } from "react";

import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
} from "reactstrap";

import Loader from "../Loader";

const PAYMENT_ACH = 1;
const PAYMENT_CHECK = 2;

const UserBankAttributes = ({ loading, user, onSubmit, onAttrEdit }) => {
  const [showValues, setShowValues] = useState(false);

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <Row form>
        <Col md={12}>
          <FormGroup className="col-12 px-0">
            <Label for="bankAccount">Bank Account Number</Label>
            <Input
              maxLength={60}
              required={true}
              onChange={(event) =>
                onAttrEdit("bankAccountNumber", event.currentTarget.value)
              }
              type={showValues ? "text" : "password"}
              name="bankAccount"
              value={user.bankAccountNumber || ""}
              id="bankAccount"
              placeholder="Bank Account.."
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup className="col-12 px-0">
            <Label for="bankAccount">Routing Number</Label>
            <Input
              maxLength={60}
              required={true}
              onChange={(event) =>
                onAttrEdit("routingNumber", event.currentTarget.value)
              }
              type={showValues ? "text" : "password"}
              name="routingNumber"
              value={user.routingNumber || ""}
              id="routingNumber"
              placeholder="Routing Number.."
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="col-12 px-0">
            <Label for="bankAccount">Payment Method</Label>
            <CustomInput
              required={true}
              type="select"
              id="exampleCustomSelect"
              name="customSelect"
              onChange={(event) =>
                onAttrEdit("paymentMethod", event.currentTarget.value)
              }
              value={user.paymentMethod}
            >
              <option value="">Select...</option>
              <option value={PAYMENT_ACH}>ACH</option>
              <option value={PAYMENT_CHECK}>Mailed Check</option>
            </CustomInput>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col
          md={12}
          className="d-flex justify-content-between align-items-center"
        >
          {loading ? (
            <Loader align="start" size="sm" />
          ) : (
            <Button className="rounded" size="sm" type="submit" color="primary">
              Save Changes
            </Button>
          )}
          <Button
            type="button"
            size="sm"
            className="hide-selector rounded btn-light font-weight-bold text-primary border"
          >
            <CustomInput
              defaultChecked={showValues}
              onClick={(event) => setShowValues(event.target.checked)}
              type="switch"
              id="showBankValues"
              name="showBankValues"
              label={"Show Values"}
              className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
            />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserBankAttributes;

import React, { memo } from "react";
import CurrencyInput from "react-currency-input-field";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";

const BaseInput = ({
  required = true,
  label,
  type = "text",
  options = [],
  onChange,
  ...props
}) => {
  let inputField;
  switch (type) {
    case "currency":
      inputField = (
        <CurrencyInput
          intlConfig={{ locale: "en-US", currency: "USD" }}
          required={required}
          allowNegativeValue={false}
          maxLength={20}
          className="form-control"
          placeholder="Enter the amount.."
          onValueChange={(value) => onChange(value)}
          {...props}
        />
      );
      break;
    default:
      inputField = (
        <Input type={type} required={required} onChange={onChange} {...props}>
          {type === "select"
            ? options.map((option, index) => (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              ))
            : null}
        </Input>
      );
  }
  return (
    <FormGroup row>
      <Label sm={5} className="text-sm-left d-flex align-items-center">
        <span>{label}</span>
        {required ? <small className="ml-1 text-danger">*</small> : null}
      </Label>
      <Col sm={7}>{inputField}</Col>
    </FormGroup>
  );
};

export default memo(BaseInput);

import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Enable2FAModal from "../../../components/Enable2FAModal";

import { useAuth } from "../../../providers/authProvider";
import { LoginForm } from "./LoginForm";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import SMSFactor from "./SMSFactor";
import { usersApi } from "../../../services/userServices";
import { utils } from "../../../utils/utils";
import {
  CUSTOMER_SUPPORT,
  ADMIN,
  SPONSOR,
  PRINTER,
} from "../../../utils/roles";

const ROLE_ADMIN = "1";
const ROLE_SPONSOR = "5";
const ROLE_CUSTOMER_SUPPORT = "6";
const TWO_FACTOR_REQUIRED = "TWO_FACTOR_REQUIRED";
const TWO_FACTOR_SMS_REQUIRED = "TWO_FACTOR_SMS_REQUIRED";
const TWO_FACTOR_INVALID = "TWO_FACTOR_INVALID";

const redirectTo = (location, role) => {
  const root =
    role === ROLE_ADMIN ||
    role === ROLE_CUSTOMER_SUPPORT ||
    role === ROLE_SPONSOR
      ? "back"
      : "portal"; // CS
  if (location.search && location.search.indexOf(`/${root}/`) > -1) {
    const params = new URLSearchParams(location.search);
    return <Navigate to={params.get("returnURL")} />;
  }
  return (
    <Navigate
      to={
        role === ROLE_ADMIN || role === ROLE_CUSTOMER_SUPPORT
          ? `/${root}/investors`
          : role === ROLE_SPONSOR
          ? `/${root}/programs`
          : `/${root}`
      }
    />
  );
};

const SignIn = () => {
  const location = useLocation();
  const [authContext, setAuthContext] = useAuth();
  const [loading, setLoading] = useState();

  if (!authContext.currentUser) {
    return <LoginForm />;
  }

  if (authContext.currentUser.status === TWO_FACTOR_REQUIRED) {
    return <TwoFactorAuthentication />;
  }

  if (authContext.currentUser.status === TWO_FACTOR_SMS_REQUIRED) {
    return <SMSFactor />;
  }

  if (
    !utils.userHasRole(authContext.currentUser, PRINTER) &&
    !utils.userHasRole(authContext.currentUser, ADMIN) &&
    !utils.userHasRole(authContext.currentUser, SPONSOR) &&
    !utils.userHasRole(authContext.currentUser, CUSTOMER_SUPPORT) &&
    authContext.currentUser.email &&
    !authContext.currentUser.enabled2FA
  ) {
    return (
      <Enable2FAModal
        loading={loading}
        onSubmit={() => {
          setLoading(true);
          usersApi
            .updateUser(
              {
                enabled2FA: true,
                start2FAFlow: true,
                id: authContext.currentUser.id,
              },
              utils.userHasRole(authContext.currentUser, "advisor")
                ? "advisor"
                : "investor"
            )
            .then(() => {
              setLoading(false);
              setAuthContext({
                ...authContext,
                currentUser: {
                  ...authContext.currentUser,
                  status: TWO_FACTOR_REQUIRED,
                },
              });
            });
        }}
      />
    );
  }

  if (authContext.currentUser.status === TWO_FACTOR_INVALID) {
    return <LoginForm />;
  }

  if (
    utils.userHasRole(authContext.currentUser, "advisor") &&
    authContext.currentUser.sessionOwner?.id !== authContext.currentUser.id
  ) {
    return <Navigate to="/investor-selector" />;
  }

  if (
    utils.userHasRole(authContext.currentUser, PRINTER) &&
    authContext.currentUser.sessionOwner?.id !== authContext.currentUser.id
  ) {
    return <Navigate to="/ready-to-print" />;
  }

  return redirectTo(
    location,
    utils.userHasRole(authContext.currentUser, ADMIN)
      ? ROLE_ADMIN
      : utils.userHasRole(authContext.currentUser, CUSTOMER_SUPPORT)
      ? ROLE_CUSTOMER_SUPPORT
      : utils.userHasRole(authContext.currentUser, SPONSOR)
      ? ROLE_SPONSOR
      : false
  );
};

export default SignIn;

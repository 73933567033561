import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const authApi = {
  login: (data) =>
    axios("/api/auth/sign-in", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    }).then((result) => result.data),
  logout: (role) =>
    axios(`/api/auth/${role}/logout`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
    }).then((result) => result.data),
  loginAs: (data, role) =>
    axios(`/api/auth/${role}/login-as`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  adminValidate: () =>
    axios("/api/auth/admin/validate", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  advisorValidate: () =>
    axios("/api/auth/advisor/validate", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  printerValidate: () =>
    axios("/api/auth/printer/validate", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  investorValidate: () =>
    axios("/api/auth/investor/validate", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  investorKeepAlive: () =>
    axios("/api/auth/investor/keep-alive", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  keepAlive: () =>
    axios("/api/auth/keep-alive", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  sendSMSCode: (data) =>
    axios("/api/auth/send-SMS", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    }).then((result) => result.data),
};

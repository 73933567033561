import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  FormText,
  Col,
  Form,
  Row,
  CustomInput,
} from "reactstrap";

import {
  initialState,
  useDocumentsUploadFlow,
} from "../../providers/documentUploadFlowProvider";
import { Navigate } from "react-router-dom";
import { documentsApi } from "../../services/documentServices";
import Loader from "../Loader";
import { programsApi } from "../../services/programServices";
import { awsApi } from "../../services/awsServices";
import config from "../../config/config";
import { useDocuments } from "../../providers/documentsProvider";
import TaxDocumentsMatchingModal from "./settings/TaxDocumentsMatchingModal";
import StatementsMatchingModal from "./settings/StatementsMatchingModal";

import { fundsApi } from "../../services/fundServices";

const DOCUMENT_TYPE_PROGRAM = 3;
const FILE_TYPE = "pdf";

const UPLOAD_TYPE_STEP = 1;

const PROGRAM_SELECTION_STEP = 2;
const INVESTOR_SELECTION_STEP = 6;

const PROGRAM_UPLOAD_STEP = 3;
const INVESTOR_STATEMENTS_UPLOAD_STEP = 7;
const INVESTORS_UPLOAD_STEP = 4;

const INVESTORS_REVIEW_STEP = 5;
const STATEMENTS_UPLOAD = 8;

const DOCUMENT_TYPE_TAX_K1 = 2;
const DOCUMENT_TYPE_TAX_8886 = 5;

const groupStatements = (documents, files) =>
  documents.reduce((p, c) => {
    if (p[c.programName]) {
      p[c.programName].push({
        ...c,
        file: files.find((f) => f.name === c.fileName),
        name: `${c.investor.name} - ${c.year}-Q${c.quarter.value} Investor Statement`,
      });
    } else {
      p[c.programName] = [
        {
          ...c,
          file: files.find((f) => f.name === c.fileName),
          name: `${c.investor.name} - ${c.year}-Q${c.quarter.value} Investor Statement`,
        },
      ];
    }
    return p;
  }, {});

const groupDocuments = (documents, files) =>
  documents.reduce((p, c) => {
    const type =
      c.fileName.indexOf("8886") > -1
        ? DOCUMENT_TYPE_TAX_8886
        : DOCUMENT_TYPE_TAX_K1;
    if (p[c.programName]) {
      p[c.programName].push({
        ...c,
        file: files.find((f) => f.name === c.fileName),
        type,
        name: `${capitalize(c.programName)}, ${c.year} ${
          type === DOCUMENT_TYPE_TAX_K1 ? "Form 1065 K-1" : "Form 8886"
        } Tax Document`,
      });
    } else {
      p[c.programName] = [
        {
          ...c,
          file: files.find((f) => f.name === c.fileName),
          type,
          name: `${capitalize(c.programName)}, ${c.year} ${
            type === DOCUMENT_TYPE_TAX_K1 ? "Form 1065 K-1" : "Form 8886"
          } Tax Document`,
        },
      ];
    }
    return p;
  }, {});

const capitalize = (string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

const destructureDocumentsName = (filesArray) => {
  ///[ProgramName]-[DocumentName]-[Year]-[RegisteredName]
  //year - Form 8886 - programName - investorName - SSN => 2020 - Form 8886 - TIMBER RIDGE PARTNERS LLC - ALLEN B BALIAN - 1762.pdf
  //year K-1 programName \d investorName => 2020 K-1 TIMBER RIDGE PARTNERS LLC 1 PALMER AARON.pdf
  let documents = [];
  let errors = [];
  filesArray.forEach((file) => {
    const regexps = [
      /(.+)- Form 8886 -(.+)-(.+)-(.+)\.pdf/, // => with SSN
      /(.+) K-?1 (.+) \d+ (.+)\.pdf/,
    ];
    const matchingRegExp = regexps.find((regexp) => regexp.test(file.name));
    if (!matchingRegExp) {
      errors.push(file.name);
    } else {
      let [, year, programName, investorName, investorSSN] =
        file.name.match(matchingRegExp);
      const investorNameRegExp = /(.+), (.+)/;
      if (investorNameRegExp.test(investorName)) {
        const match = investorName.match(investorNameRegExp);
        investorName = `${match[2]} ${match[1]}`;
      }
      documents.push({
        fileName: file.name,
        investorName,
        investorSSN,
        programName,
        year,
      });
    }
  });
  return {
    documents,
    errors,
  };
};

const destructureStatementsName = (filesArray) => {
  ///[Investor name] - [Quater year]-Q[Quarter number] Investor Statement.pdf
  // Investor Test - 2022-Q4 Investor Statement.pdf
  let statements = [];
  let errors = [];
  filesArray.forEach((file) => {
    const regex = /(.+) - (\d{4,4})-(Q\d{1,1}) Investor Statement.pdf/;
    const fileName = file.name.replaceAll("%20", " ");
    if (!regex.test(fileName)) {
      errors.push({ fileName });
    } else {
      let [, investorName, year, quarter] = fileName.match(regex);
      const quarterValue = quarter.replace("Q", "");
      statements.push({
        fileName,
        program: null,
        investor: { label: investorName },
        quarter: { label: quarterValue, value: quarterValue },
        year,
      });
    }
  });
  return {
    statements,
    errors,
  };
};

const validateInvestorsDocuments = (files) => {
  const filesArray = [...files];
  const { documents, errors } = destructureDocumentsName(filesArray);
  return documentsApi
    .validateInvestorsDocuments(documents)
    .then((validationResult) => {
      return {
        programs: validationResult.programs,
        documents: validationResult.documents.filter((vr) => !vr.error),
        errors: [
          ...errors.map((e) => {
            return {
              fileName: e,
            };
          }),
          ...validationResult.documents
            .filter((doc) => doc.error)
            .map((doc) => {
              return {
                fileName: doc.fileName,
                programId: doc.programId,
                programName: doc.programName,
                year: doc.year,
              };
            }),
        ],
      };
    });
};

const InvestorsUploadModal = () => {
  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const [loading, setLoading] = useState(false);
  const [errorsDocuments, setErrorsDocuments] = useState();
  const [validDocuments, setValidDocuments] = useState();
  const [programs, setPrograms] = useState();
  const [taxDocumentsMatching, setTaxDocumentsMatching] = useState();

  const onNext = (event) => {
    setLoading(true);
    event.preventDefault();
    validateInvestorsDocuments(documentsUploadFlow.files).then(
      ({ errors, documents, programs }) => {
        setLoading(false);
        setErrorsDocuments(errors);
        setValidDocuments(documents);
        setPrograms(programs);
        setTaxDocumentsMatching(true);
      }
    );
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setDocumentsUploadFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return (
    <>
      <Modal isOpen={!taxDocumentsMatching}>
        <ModalHeader close={closeBtn}>Upload Documents</ModalHeader>
        {loading ? (
          <div className="my-4">
            <Loader size="sm" />
          </div>
        ) : (
          <Form onSubmit={onNext}>
            <ModalBody>
              <FormGroup className="mb-1">
                <Label className="font-weight-bold">Upload Documents</Label>
                <Input
                  required={true}
                  multiple={true}
                  type="file"
                  accept="application/pdf"
                  className="mb-1 text-truncate"
                  onChange={(event) =>
                    setDocumentsUploadFlow({ files: event.currentTarget.files })
                  }
                />
                <FormText color="muted">Select files</FormText>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Col>
                <Row className="justify-content-between">
                  <Button
                    color={"secondary"}
                    onClick={() =>
                      setDocumentsUploadFlow({ step: UPLOAD_TYPE_STEP })
                    }
                  >
                    Back
                  </Button>{" "}
                  <Button color={"primary"} type="submit">
                    Next
                  </Button>
                </Row>
              </Col>
            </ModalFooter>
          </Form>
        )}
      </Modal>
      {taxDocumentsMatching ? (
        <TaxDocumentsMatchingModal
          defaultPrograms={programs}
          defaultDocuments={[...errorsDocuments, ...validDocuments]}
          onSubmit={(matches) => {
            setTaxDocumentsMatching(false);
            let groupedDocuments = groupDocuments(
              [
                ...matches
                  .filter((doc) => doc.program && doc.investor)
                  .map((doc) => {
                    return {
                      fileName: doc.fileName || doc.filename,
                      programId: doc.program.value,
                      investor: {
                        name: doc.investor.label,
                        id: doc.investor.value,
                      },
                      programName: doc.program.label,
                      year: doc.year,
                    };
                  }),
              ],
              Array.from(documentsUploadFlow.files)
            );
            setDocumentsUploadFlow({
              documents: groupedDocuments,
              step: INVESTORS_REVIEW_STEP,
            });
          }}
          onClose={() => {
            setTaxDocumentsMatching(false);
          }}
        />
      ) : null}
    </>
  );
};

const UploadTypeModal = () => {
  const [, setDocumentsUploadFlow] = useDocumentsUploadFlow();

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setDocumentsUploadFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="sm">
      <ModalHeader close={closeBtn}>Upload Documents</ModalHeader>
      <ModalBody>
        <Col className="d-flex flex-column">
          <Button
            color={"info"}
            onClick={() =>
              setDocumentsUploadFlow({ step: PROGRAM_SELECTION_STEP })
            }
          >
            Upload for Program
          </Button>
          <Button
            className="my-3"
            color={"secondary"}
            onClick={() =>
              setDocumentsUploadFlow({ step: INVESTOR_STATEMENTS_UPLOAD_STEP })
            }
          >
            Upload Statements
          </Button>
          <Button
            color={"tertiary"}
            onClick={() =>
              setDocumentsUploadFlow({ step: INVESTORS_UPLOAD_STEP })
            }
          >
            Upload for Investors/Stakeholders
          </Button>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button
              color={"primary"}
              onClick={() => setDocumentsUploadFlow({ active: false })}
            >
              Cancel
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

const InvestorSelectionModal = () => {
  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const [loading, setLoading] = useState(false);

  const [programs, setPrograms] = useState([]);
  const [fundQuarters, setFundQuarters] = useState([]);

  useEffect(() => {
    setLoading(true);
    programsApi.getAllPrograms({ pageSize: 999 }).then((result) => {
      setPrograms(result.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (
      documentsUploadFlow.program &&
      documentsUploadFlow.program.programFundInvestments.length
    ) {
      setLoading(true);
      fundsApi
        .getAllFundQuarters({
          fundId: documentsUploadFlow.program.programFundInvestments[0].fundId,
          pageSize: 999,
        })
        .then((fundQuarters) => {
          setLoading(false);
          setFundQuarters(fundQuarters.data);
        });
    }
  }, [documentsUploadFlow.program]);

  const onNext = (event) => {
    event.preventDefault();
    if (documentsUploadFlow.investor) {
      setDocumentsUploadFlow({ step: INVESTOR_STATEMENTS_UPLOAD_STEP });
    } else {
      //error
    }
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setDocumentsUploadFlow({ active: false })}
    >
      &times;
    </Button>
  );

  const getAvailableInvestors = (program) =>
    program
      ? [
          ...program.programInvestments.map((pi) => pi.investor),
          ...program.stakeholders.map((pi) => pi.user),
        ].sort((x, y) => (y.name.toLowerCase() < x.name.toLowerCase() ? 1 : -1))
      : [];

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Upload Statements</ModalHeader>
      {loading ? (
        <div className="my-4">
          <Loader size="sm" />
        </div>
      ) : (
        <Form onSubmit={onNext}>
          <ModalBody>
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left">
                Program
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  type="select"
                  id="programSelect"
                  name="programSelect"
                  onChange={(event) =>
                    setDocumentsUploadFlow({
                      program: programs.find(
                        (program) =>
                          program.id === parseInt(event.currentTarget.value)
                      ),
                    })
                  }
                  value={documentsUploadFlow.program?.id}
                >
                  <option value="">Select...</option>
                  {programs.map((program) => (
                    <option key={program.id} value={program.id}>
                      {program.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
            {documentsUploadFlow.program?.id ? (
              <FormGroup row className="my-3">
                <Label sm={4} className="text-sm-left">
                  Quarter
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    type="select"
                    id="fundQuarterSelect"
                    name="fundQuarterSelect"
                    onChange={(event) =>
                      setDocumentsUploadFlow({
                        fundQuarter: fundQuarters.find(
                          (quarter) =>
                            quarter.id === parseInt(event.currentTarget.value)
                        ),
                      })
                    }
                    value={documentsUploadFlow.fundQuarter?.id}
                  >
                    <option value="">Select...</option>
                    {fundQuarters.map((fundQuarter) => (
                      <option key={fundQuarter.id} value={fundQuarter.id}>
                        {`${fundQuarter.year}-Q${fundQuarter.quarter}`}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
            ) : null}
            {documentsUploadFlow.program?.id ? (
              <FormGroup row className="mb-0">
                <Label
                  sm={4}
                  className="text-sm-left d-flex align-items-center"
                >
                  Investor
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    type="select"
                    id="investorSelect"
                    name="investorSelect"
                    onChange={(event) => {
                      const availableInvestors = getAvailableInvestors(
                        documentsUploadFlow.program
                      );
                      setDocumentsUploadFlow({
                        investor: availableInvestors.find(
                          (investor) =>
                            investor.id === parseInt(event.currentTarget.value)
                        ),
                      });
                    }}
                    value={documentsUploadFlow.investor?.id}
                  >
                    <option value="">Select...</option>
                    {getAvailableInvestors(documentsUploadFlow.program).map(
                      (investor) => (
                        <option key={investor.id} value={investor.id}>
                          {investor.name}
                        </option>
                      )
                    )}
                  </CustomInput>
                </Col>
              </FormGroup>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row className="justify-content-between">
                <Button
                  color={"secondary"}
                  onClick={() =>
                    setDocumentsUploadFlow({ step: UPLOAD_TYPE_STEP })
                  }
                >
                  Back
                </Button>{" "}
                <Button color={"primary"} type="submit">
                  Next
                </Button>
              </Row>
            </Col>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

const ProgramSelectionModal = () => {
  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    setLoading(true);
    programsApi.getAllPrograms({ pageSize: 999 }).then((result) => {
      setPrograms(result.data);
      setLoading(false);
    });
  }, []);

  const onNext = (event) => {
    event.preventDefault();
    setDocumentsUploadFlow({ step: PROGRAM_UPLOAD_STEP });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setDocumentsUploadFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Upload Program Document</ModalHeader>
      {loading ? (
        <div className="my-4">
          <Loader size="sm" />
        </div>
      ) : (
        <Form onSubmit={onNext}>
          <ModalBody>
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left">
                Program
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  type="select"
                  id="customSelect"
                  name="customSelect"
                  onChange={(event) =>
                    setDocumentsUploadFlow({
                      program: programs.find(
                        (program) =>
                          program.id === parseInt(event.currentTarget.value)
                      ),
                    })
                  }
                  value={documentsUploadFlow.program?.id}
                >
                  <option value="">Select...</option>
                  {programs.map((program) => (
                    <option key={program.id} value={program.id}>
                      {program.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row className="justify-content-between">
                <Button
                  color={"secondary"}
                  onClick={() =>
                    setDocumentsUploadFlow({ step: UPLOAD_TYPE_STEP })
                  }
                >
                  Back
                </Button>{" "}
                <Button color={"primary"} type="submit">
                  Next
                </Button>
              </Row>
            </Col>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

const prepareProgramDocuments = (files, program) => [
  ...files.flatMap((file) =>
    (program.programInvestments || []).map((programInvestment) => {
      return {
        programId: program.id,
        userId: programInvestment.investorId,
        documentTypeId: DOCUMENT_TYPE_PROGRAM,
        url: file.url,
        name: file.name,
        fileName: file.name,
        year: new Date().getFullYear(),
      };
    })
  ),
  ...files.flatMap((file) =>
    (program.stakeholders || []).map((programStakeholder) => {
      return {
        programId: program.id,
        userId: programStakeholder.userId,
        documentTypeId: DOCUMENT_TYPE_PROGRAM,
        url: file.url,
        name: file.name,
        fileName: file.name,
        year: new Date().getFullYear(),
      };
    })
  ),
];

const ProgramUploadModal = () => {
  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const [documentsContext, setDocumentsContext] = useDocuments();
  const [loading, setLoading] = useState(false);

  const onNext = (event) => {
    event.preventDefault();
    setLoading(true);
    const files = Array.from(documentsUploadFlow.files);
    const filesMapping = {};
    const readyToSignDocuments = files.map((file) => {
      filesMapping[file.name] = file;
      return {
        fileName: file.name,
        fileType: FILE_TYPE,
        bucket: config.documentsBucket,
        method: "putObject",
      };
    });
    awsApi
      .signDocuments({ documents: readyToSignDocuments }, "admin")
      .then((signedURLs) => {
        let elements = signedURLs
          .filter((signedURL) => signedURL.success)
          .map((signedURL) => {
            const file = filesMapping[signedURL.fileName];
            return file
              ? { url: signedURL.signedRequest, file, fileUrl: signedURL.url }
              : null;
          });
        let promises = elements.map((element) =>
          awsApi.putDocumentsToS3(element)
        );
        Promise.all(promises)
          .then((result) => {
            const postDocuments = prepareProgramDocuments(
              result,
              documentsUploadFlow.program
            );
            documentsApi
              .bulkCreateDocuments({ documents: postDocuments })
              .then(() => {
                setLoading(false);
                setDocumentsUploadFlow({ ...initialState });
                setDocumentsContext({ ...documentsContext });
              });
          })
          .catch(() => {});
      });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setDocumentsUploadFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Upload Program Document</ModalHeader>
      {loading ? (
        <div className="my-4">
          <Loader size="sm" />
        </div>
      ) : (
        <Form onSubmit={onNext}>
          <ModalBody>
            <FormGroup className="mb-1">
              <Label className="font-weight-bold">Upload Document</Label>
              <Input
                required={true}
                multiple={true}
                type="file"
                accept="application/pdf"
                className="mb-1 text-truncate"
                onChange={(event) =>
                  setDocumentsUploadFlow({ files: event.currentTarget.files })
                }
              />
              <FormText color="muted">Select files</FormText>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row className="justify-content-between">
                <Button
                  color={"secondary"}
                  onClick={() => setDocumentsUploadFlow({ active: false })}
                >
                  Cancel
                </Button>{" "}
                <Button color={"primary"} type="submit">
                  Next
                </Button>
              </Row>
            </Col>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

const InvestorStatementUploadModal = () => {
  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const [statementsMatching, setStatementsMatching] = useState();
  const [errorsDocuments, setErrorsDocuments] = useState();
  const [validDocuments, setValidDocuments] = useState();

  const onNext = async (event) => {
    event.preventDefault();
    const { statements, errors } = destructureStatementsName([
      ...documentsUploadFlow.files,
    ]);
    setErrorsDocuments(errors);
    setValidDocuments(statements);
    setStatementsMatching(true);
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setDocumentsUploadFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return (
    <>
      <Modal isOpen={!statementsMatching}>
        <ModalHeader close={closeBtn}>Upload Investor Statements</ModalHeader>
        {
          <Form onSubmit={onNext}>
            <ModalBody>
              <FormGroup className="mb-1">
                <Label className="font-weight-bold">Upload Documents</Label>
                <Input
                  required={true}
                  multiple={true}
                  type="file"
                  accept="application/pdf"
                  className="mb-1 text-truncate"
                  onChange={(event) =>
                    setDocumentsUploadFlow({ files: event.currentTarget.files })
                  }
                />
                <FormText color="muted">Select files</FormText>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Col>
                <Row className="justify-content-between">
                  <Button
                    color={"secondary"}
                    onClick={() => setDocumentsUploadFlow({ active: false })}
                  >
                    Cancel
                  </Button>{" "}
                  <Button color={"primary"} type="submit">
                    Next
                  </Button>
                </Row>
              </Col>
            </ModalFooter>
          </Form>
        }
      </Modal>
      {statementsMatching ? (
        <StatementsMatchingModal
          defaultDocuments={[...errorsDocuments, ...validDocuments]}
          onSubmit={(matches) => {
            setStatementsMatching(false);
            const documentsToGroup = [
              ...matches
                .filter((doc) => doc.program && doc.investor)
                .map((doc) => {
                  return {
                    fileName: doc.fileName || doc.filename,
                    programId: doc.program.value,
                    investor: {
                      name: doc.investor.label,
                      id: doc.investor.value,
                    },
                    programName: doc.program.label,
                    year: doc.year,
                    quarter: doc.quarter,
                  };
                }),
            ];
            const groupedStatements = groupStatements(
              documentsToGroup,
              Array.from(documentsUploadFlow.files)
            );
            setDocumentsUploadFlow({
              statements: true,
              documents: groupedStatements,
              step: STATEMENTS_UPLOAD,
            });
          }}
          onClose={() => {
            setStatementsMatching(false);
          }}
        />
      ) : null}
    </>
  );
};

const DocumentUploadFlow = () => {
  const [documentsUploadFlow] = useDocumentsUploadFlow();
  switch (documentsUploadFlow.step) {
    case UPLOAD_TYPE_STEP:
      return <UploadTypeModal />;
    case PROGRAM_SELECTION_STEP:
      return <ProgramSelectionModal />;
    case INVESTOR_SELECTION_STEP:
      return <InvestorSelectionModal />;
    case PROGRAM_UPLOAD_STEP:
      return <ProgramUploadModal />;
    case INVESTOR_STATEMENTS_UPLOAD_STEP:
      return <InvestorStatementUploadModal />;
    case INVESTORS_UPLOAD_STEP:
      return <InvestorsUploadModal />;
    case STATEMENTS_UPLOAD:
      return <Navigate to={"/back/statements/review"} />;
    default:
      return <Navigate to={"/back/documents/review"} />;
  }
};

export default DocumentUploadFlow;

import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { Button, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { programsApi } from "../../services/programServices";
import moment from "moment";
import Loader from "../../components/Loader";
import download from "downloadjs";

const ProgramStatements = ({ program }) => {
  const [loading, setLoading] = useState();
  const [programStatements, setProgramStatements] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    getProgramStatements(program.id);
  }, [program.id]);

  const getProgramStatements = async (programId) => {
    setLoading(true);
    const programStatements = await programsApi.getProgramStatements({
      programId,
      quarter: moment().get("Q"),
      year: moment().get("year"),
    });
    setProgramStatements(programStatements.data);
    setLoading(false);
  };

  const onDownload = () => {
    setDownloadLoading(true);
    programsApi.downloadStatements({ id: program.id }).then((result) => {
      download(result, "attachement.zip", "application/zip");
      setDownloadLoading(false);
      return result;
    });
  };

  return loading ? (
    <Loader size="sm" />
  ) : (
    <Row className="d-flex w-100 bg-white px-4 py-2 justify-content-between my-2">
      {programStatements && (
        <>
          <div className="d-flex flex-column">
            <div>
              <span className="section-title">{program.name}</span>
            </div>
            <div>
              <span className="section-subtitle">
                <b>{programStatements.length}</b> Statement
                {programStatements.length !== 1 && "s"}
              </span>
            </div>
          </div>
          <div className="d-flex flex-direction-column">
            <Button
              color="info"
              className="m-2"
              onClick={onDownload}
              disabled={!programStatements.length}
            >
              {downloadLoading ? (
                <Spinner size="sm" align="end" />
              ) : (
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faFileDownload}
                />
              )}
              <span className="ml-2">
                Download{" "}
                {programStatements.length === 1
                  ? "Statement"
                  : "All Statements"}
              </span>
            </Button>
          </div>
        </>
      )}
    </Row>
  );
};

export default ProgramStatements;

import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
} from "reactstrap";
import Select from "react-select";
import { dataLoadingApi } from "../../../services/dataLoadingServices";
import Loader from "../../Loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const pageSize = 10;

const adaptDocumentsFormat = (data) =>
  data.map((data) => {
    return {
      filename: data.filename || data.fileName,
      program: data.program
        ? {
            value: data.program.id,
            label: data.program.name,
            year: data.year || data.program.year,
          }
        : data.programId && data.programName
        ? { value: data.programId, label: data.programName }
        : null,
      investor: data.investor
        ? { value: data.investor.id, label: data.investor.name }
        : null,
      year: data.year,
    };
  });

const TaxDocumentsMatchingModal = ({
  defaultPrograms,
  defaultDocuments,
  onSubmit,
  onClose,
}) => {
  const [values, setValues] = useState(
    adaptDocumentsFormat(defaultDocuments || [])
  );
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(
    defaultDocuments ? Math.ceil(defaultDocuments.length / pageSize) : 1
  );
  const [count, setCount] = useState(
    defaultDocuments ? defaultDocuments.length : 0
  );
  const [loading, setLoading] = useState();
  const [singleLoading, setSingleLoading] = useState({});
  const [programs, setPrograms] = useState(defaultPrograms || []);

  const paginateItems = (items) =>
    items.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

  useEffect(() => {
    if (!defaultDocuments) {
      setLoading(true);
      dataLoadingApi
        .getDocumentsMismatch({ page: page - 1, pageSize })
        .then((result) => {
          const { documentsMismatch, programs } = result;
          setCount(documentsMismatch?.count);
          setValues(adaptDocumentsFormat(documentsMismatch.data));
          setTotalPages(documentsMismatch.totalPages);
          setPrograms(programs);
          setLoading(false);
        });
    }
  }, [page, defaultDocuments]);

  const onInvestorChange = (index, investor) => {
    if (investor && investor.value) {
      let vals = [...values];
      const realIndex = index + (page - 1) * pageSize;
      const currentValue = values[realIndex];
      const newValue = {
        ...currentValue,
        investor,
      };
      vals.splice(realIndex, 1, newValue);
      setValues(vals);
      setSingleLoading({ [realIndex]: true });
      dataLoadingApi
        .saveDocument({
          filename: newValue.filename || newValue.fileName,
          programId: newValue.program.value,
          investorId: newValue.investor.value,
        })
        .then(() => {
          setSingleLoading({ [realIndex]: false });
        });
    }
  };

  const onProgramChange = (index, program) => {
    let vals = [...values];
    const realIndex = index + (page - 1) * pageSize;
    const currentValue = values[realIndex];
    vals.splice(realIndex, 1, {
      filename: currentValue.filename || currentValue.fileName,
      program,
      year: program.year,
    });
    setValues(vals);
  };

  const onYearChange = (index, year) => {
    let vals = [...values];
    const realIndex = index + (page - 1) * pageSize;
    const currentValue = values[realIndex];
    const newValue = {
      ...currentValue,
      year: year.value,
    };
    vals.splice(realIndex, 1, newValue);
    setValues(vals);
  };

  const getInvestorsOptions = (value) => {
    const currentProgram = programs.find(
      (p) => p.id === parseInt(value?.program?.value)
    );
    if (!currentProgram) {
      return [];
    }
    return (
      currentProgram?.investors || [
        ...(currentProgram?.programInvestments || []).map((pi) => pi.investor),
        ...(currentProgram?.stakeholders || []).map((ps) => ps.user),
      ] ||
      []
    );
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <ModalHeader close={closeBtn} className="text-body">
        <div className="d-flex flex-column">
          Tax Documents Matching
          <small className="text-muted">{count} in total</small>
        </div>
      </ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Table striped className="text-custom-dark text-left col-12 small">
            <thead className="col-12">
              <tr>
                <th style={{ width: "40%" }}>Filename</th>
                <th style={{ width: "20%" }}>Program</th>
                <th style={{ width: "10%" }}>Year</th>
                <th style={{ width: "20%" }}>Investor</th>
              </tr>
            </thead>
            <tbody className="col-12">
              {(defaultDocuments ? paginateItems(values) : values).map(
                (value, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ width: "40%" }}>
                        {value.filename || value.fileName}
                      </td>
                      <td style={{ width: "20%" }}>
                        <Select
                          isDisabled={singleLoading[i]}
                          value={value.program || ""}
                          onChange={(selected) => onProgramChange(i, selected)}
                          options={programs.map((program) => {
                            return {
                              value: program.id,
                              label: program.name,
                              year: program.year,
                            };
                          })}
                          placeholder="Program.."
                          isSearchable
                        />
                      </td>
                      <td style={{ width: "10%" }}>
                        <Select
                          isDisabled={!defaultDocuments}
                          value={
                            value.year
                              ? { value: value.year, label: value.year }
                              : ""
                          }
                          onChange={(selected) => onYearChange(i, selected)}
                          options={[
                            {
                              value: moment().get("year"),
                              label: moment().get("year"),
                            },
                            {
                              value: moment().get("year") - 1,
                              label: moment().get("year") - 1,
                            },
                            {
                              value: moment().get("year") - 2,
                              label: moment().get("year") - 2,
                            },
                            {
                              value: moment().get("year") - 3,
                              label: moment().get("year") - 3,
                            },
                            {
                              value: moment().get("year") - 4,
                              label: moment().get("year") - 4,
                            },
                            {
                              value: moment().get("year") - 5,
                              label: moment().get("year") - 5,
                            },
                            {
                              value: moment().get("year") - 6,
                              label: moment().get("year") - 6,
                            },
                          ]}
                          placeholder="Year.."
                        />
                      </td>
                      <td style={{ width: "20%" }}>
                        {singleLoading[i] ? (
                          <Loader size="sm" />
                        ) : (
                          <Select
                            value={value.investor || ""}
                            onChange={(selected) =>
                              onInvestorChange(i, selected)
                            }
                            options={getInvestorsOptions(value).map(
                              (investor) => {
                                return {
                                  value: investor.id,
                                  label: investor.name,
                                };
                              }
                            )}
                            placeholder="Investor.."
                            isSearchable
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        )}
        <ReactPaginate
          forcePage={page - 1}
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
          breakLabel={"..."}
          pageCount={totalPages || page + 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(event) => setPage(event.selected + 1)}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          containerClassName={
            "cursor-pointer pagination d-flex justify-content-center align-items-center mb-0"
          }
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>
        <Button color={"primary"} onClick={() => onSubmit(values)}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TaxDocumentsMatchingModal;

import React, { useCallback, useEffect, useState } from "react";

import FundAssetDetailModal from "./FundAssetDetailModal";
import CategoryCreationModal from "../../CategoryCreationModal";
import { fundsApi } from "../../../../services/fundServices";
import { useFundAssetDetailModal } from "../../../../providers/fundAssetDetailModalProvider";
import FundAssetDeletionConfirmationModal from "./FundAssetDeletionConfirmationModal";

const DEFAULT_MODE = 1;
const TRANSACTION_CATEGORY_CREATION_MODE = 2;
const DELETION_MODE = 3;
const ASSET_CATEGORY_CREATION_MODE = 4;

const FundAssetDetail = () => {
  const [fundAssetDetailModal, setFundAssetDetailModal] =
    useFundAssetDetailModal();
  const [loading, setLoading] = useState(true);

  const setFundAssetDetailModalCb = useCallback(
    (data) => {
      setFundAssetDetailModal(data);
    },
    [setFundAssetDetailModal]
  );

  useEffect(() => {
    fundsApi.getFundAssetCategories({}).then((assetCategories) =>
      fundsApi
        .getFundAssetTransactionCategories({})
        .then((transactionCategories) => {
          setFundAssetDetailModalCb({
            transactionCategories: transactionCategories.data,
            assetCategories: assetCategories.data,
          });
          setLoading(false);
        })
    );
  }, [setFundAssetDetailModalCb]);

  const onTransactionCategorySubmit = (data) => {
    setLoading(true);
    fundsApi.createFundAssetTransactionCategory(data).then((result) => {
      setLoading(false);
      setFundAssetDetailModal({
        transactionCategories: [
          ...fundAssetDetailModal.transactionCategories,
          result,
        ],
        transactionCategoryId: result.id,
        mode: DEFAULT_MODE,
      });
    });
  };

  const onAssetCategorySubmit = (data) => {
    setLoading(true);
    fundsApi.createFundAssetCategory(data).then((result) => {
      setLoading(false);
      setFundAssetDetailModal({
        assetCategories: [...fundAssetDetailModal.assetCategories, result],
        fundAssetCategoryId: result.id,
        mode: DEFAULT_MODE,
      });
    });
  };

  switch (fundAssetDetailModal.mode) {
    case DEFAULT_MODE:
      return <FundAssetDetailModal loading={loading} />;
    case TRANSACTION_CATEGORY_CREATION_MODE:
      return (
        <CategoryCreationModal
          loading={loading}
          onSubmit={onTransactionCategorySubmit}
          categories={fundAssetDetailModal.transactionCategories}
          onClose={() => setFundAssetDetailModal({ mode: DEFAULT_MODE })}
        />
      );
    case ASSET_CATEGORY_CREATION_MODE:
      return (
        <CategoryCreationModal
          loading={loading}
          onSubmit={onAssetCategorySubmit}
          categories={fundAssetDetailModal.assetCategories}
          onClose={() => setFundAssetDetailModal({ mode: DEFAULT_MODE })}
        />
      );
    case DELETION_MODE:
      return <FundAssetDeletionConfirmationModal />;
    default:
      return null;
  }
};

export default FundAssetDetail;

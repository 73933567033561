import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const FA_CATEGORIES_TAB = 1;

const initialState = {
  fundAssetCategories: { sizePerPage: "All", page: 1 },
  fundAssetTransactionCategories: { sizePerPage: "All", page: 1 },
  tab: FA_CATEGORIES_TAB,
};

const CategoriesContext = createContext(initialState);

const CategoriesProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CategoriesContext.Provider value={stateAndDispatch}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => useContext(CategoriesContext);

export { CategoriesContext, CategoriesProvider, initialState };

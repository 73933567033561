import React from "react";
import classNames from "classnames";

import {
  faSignOutAlt,
  faHome,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../providers/authProvider";
import brand from "../../assets/img/brand.png";
import { authApi } from "../../services/authServices";
import SidebarItem from "../customer/sidebar/SidebarItem";
import SidebarUser from "../customer/sidebar/SidebarUser";
import { utils } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const VIEW_MODE_COMPLETE = "2";

const Sidebar = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();

  const logout = () => {
    authApi.logout("advisor");
    setAuthContext({ currentUser: null, advisements: null });
    navigate("/auth/sign-in");
  };

  const { viewMode } = utils.getSessionData(authContext.currentUser);

  return (
    <nav className={classNames("sidebar")}>
      <div className="sidebar-content">
        <div className="sidebar-brand p-0 d-flex align-items-center">
          <img src={brand} className="rounded p-0 col-12" alt={"Vulcan Fund"} />
        </div>
        <SidebarUser />
        <ul className="sidebar-nav">
          <SidebarItem name="Dashboard" to="/investor-selector" icon={faHome} />
          {viewMode === VIEW_MODE_COMPLETE ? (
            <div className="bg-lighter border-top border-bottom py-1 my-2">
              <li className="sidebar-header">{authContext.currentUser.name}</li>
              <SidebarItem
                name="My Account"
                classes="bg-transparent"
                icon={faUserAlt}
                to={"/account"}
              />
            </div>
          ) : null}
          <SidebarItem name="Logout" icon={faSignOutAlt} action={logout} />
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;

import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Col,
  Input,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Loader from "../Loader";
import { useEmailContext } from "../../providers/emailProvider";
import { utils } from "../../utils/utils";
import { settingsApi } from "../../services/settingServices";
import RichTextEditor from "../RichTextEditor";

const DEFAULT_MODE = 1;
const PREVIEW_MODE = 2;
const SUCCESS_MODE = 3;

export const EmailModalDefaultMode = ({ onClose, cancelBtnText }) => {
  const [email, setEmail] = useEmailContext();
  const onSubmit = (event) => {
    event.preventDefault();
    setEmail({ mode: PREVIEW_MODE });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Form onSubmit={onSubmit}>
      <ModalHeader close={closeBtn}>Send email to {email?.name}</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label sm={4} className="text-sm-left">
            Subject
          </Label>
          <Col sm={8}>
            <Input
              required
              type="text"
              maxLength="50"
              placeholder="Enter the subject.."
              onChange={(event) =>
                setEmail({ subject: event.currentTarget.value })
              }
              value={email.subject}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4} className="text-sm-left">
            Reply To
          </Label>
          <Col sm={8}>
            <Input
              required
              type="email"
              maxLength="50"
              placeholder="Enter the reply email.."
              onChange={(event) =>
                setEmail({ replyTo: event.currentTarget.value })
              }
              value={email.replyTo}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={12} className="text-sm">
            You can use the variable {"{{userName}}"} to be replaced by the name
            of the target user <br />
            You can use the variable {"{{programName}}"} to be replaced by the
            name of the current program <br />
            You can use the variable {"{{sponsorName}}"} to be replaced by the
            name of the sponsor of the current program
          </Label>
        </FormGroup>
        <RichTextEditor
          value={email.content}
          onChange={(content) => setEmail({ content })}
        />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          {cancelBtnText ? cancelBtnText : "Cancel"}
        </Button>{" "}
        <Button
          color={"primary"}
          disabled={
            !email.subject?.length ||
            !email.replyTo?.length ||
            !email.content?.length
          }
          type="submit"
        >
          Next
        </Button>
      </ModalFooter>
    </Form>
  );
};

export const EmailModalPreviewMode = ({
  sendBtnText,
  onSubmit,
  changeModeAfterSubmit = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [emailContext, setEmailContext] = useEmailContext();

  const onSend = async () => {
    setLoading(true);
    const email = { ...emailContext };
    try {
      if (email.testEmailTo) {
        await settingsApi.sendTestEmail({ ...email, to: email.testEmailTo });
        setEmailContext({ mode: SUCCESS_MODE });
        setTimeout(() => {
          setEmailContext({ mode: PREVIEW_MODE });
        }, 1500);
      } else {
        const result = await onSubmit(email);
        setEmailContext({ result });
        if (changeModeAfterSubmit) {
          setEmailContext({ mode: SUCCESS_MODE });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalHeader>Email Preview</ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <div
            className="email-preview mb-n3 word-break-all"
            dangerouslySetInnerHTML={{ __html: emailContext.content }}
          ></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color={"secondary"}
          onClick={() => setEmailContext({ mode: DEFAULT_MODE })}
        >
          Back
        </Button>{" "}
        <InputGroup className="container-input-button d-none">
          <InputGroupAddon addonType="prepend">
            <Button
              disabled={!utils.emailIsValid(emailContext.testEmailTo)}
              onClick={() => {}}
              color={"tertiary"}
            >
              Send Test Email
            </Button>
          </InputGroupAddon>
          <Input
            type="email"
            placeholder="Test email to.."
            onChange={(event) =>
              setEmailContext({ testEmailTo: event.currentTarget.value })
            }
            value={emailContext.testEmailTo}
          />
        </InputGroup>
        <Button
          color={"primary"}
          disabled={emailContext.testEmailTo}
          onClick={onSend}
        >
          {sendBtnText ? sendBtnText : "Send"}
        </Button>
      </ModalFooter>
    </>
  );
};

export const EmailModalSuccessMode = ({ onClose }) => {
  const [, setEmailContext] = useEmailContext();
  return (
    <>
      <ModalHeader>Alright!</ModalHeader>
      <ModalBody className="text-center">
        <p className="text-success mb-0">Emails sent successfully!</p>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color={"primary"}
          onClick={() => {
            onClose();
            setEmailContext({ mode: DEFAULT_MODE });
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

const Content = ({
  onClose,
  onSubmit,
  cancelBtnText,
  sendBtnText,
  changeModeAfterSubmit,
}) => {
  const [email] = useEmailContext();
  switch (email.mode) {
    case DEFAULT_MODE:
      return (
        <EmailModalDefaultMode
          onClose={onClose}
          cancelBtnText={cancelBtnText}
        />
      );
    case PREVIEW_MODE:
      return (
        <EmailModalPreviewMode
          sendBtnText={sendBtnText}
          onSubmit={onSubmit}
          changeModeAfterSubmit={changeModeAfterSubmit}
        />
      );
    case SUCCESS_MODE:
      return <EmailModalSuccessMode onClose={onClose} />;
    default:
      return <EmailModalDefaultMode onClose={onClose} />;
  }
};

const EmailModal = ({
  onClose,
  onSubmit,
  cancelBtnText,
  sendBtnText,
  changeModeAfterSubmit,
}) => {
  return (
    <Modal isOpen={true}>
      <Content
        onClose={onClose}
        onSubmit={onSubmit}
        cancelBtnText={cancelBtnText}
        sendBtnText={sendBtnText}
        changeModeAfterSubmit={changeModeAfterSubmit}
      />
    </Modal>
  );
};

export default EmailModal;

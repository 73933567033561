import React, { useEffect, useState } from "react";

import { CardHeader, ListGroupItem, ListGroup, Button, Row } from "reactstrap";
import { useProgramCreationFlow } from "../../../../providers/programCreationFlowProvider";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../../components/InformationModal";

const NAME_STEP = 1;
const ADVISORS_REVIEW_STEP = 6;
const TYPE_HEDGE_FUND = 3;

const ProgramInvestorsReview = () => {
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [existingInvestors, setExistingInvestors] = useState();
  const [newInvestors, setNewInvestors] = useState();

  useEffect(() => {
    const existingInvestors = programCreationFlow.program.investors.filter(
      (investor) => investor.id && investor.units
    );
    setExistingInvestors(existingInvestors);
  }, [programCreationFlow.program.investors]);

  useEffect(() => {
    const newInvestors = programCreationFlow.program.investors.filter(
      (investor) => !investor.id && investor.units
    );
    setNewInvestors(newInvestors);
  }, [programCreationFlow.program.investors]);

  useEffect(() => {
    if (
      existingInvestors &&
      newInvestors &&
      !existingInvestors?.length &&
      !newInvestors?.length
    ) {
      setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: "There are no investors with units more than zero to add.",
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setProgramCreationFlow({ step: NAME_STEP, active: false });
        },
      });
    }
  }, [setProgramCreationFlow, existingInvestors, newInvestors]);

  return !programCreationFlow.program ? null : (
    <>
      <CardHeader className="py-0 h5 mt-4 mb-0 text-underline body-color">
        Existing investors
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem
          className="d-flex justify-content-between border-0"
          tag="div"
        >
          <span className="small text-muted">Investor Name</span>
          {parseInt(programCreationFlow.program.programTypeId) ===
          TYPE_HEDGE_FUND ? (
            <span className="small text-muted mr-5 pr-2">Amount</span>
          ) : (
            <span className="small text-muted">Units / Amount</span>
          )}
        </ListGroupItem>
        <div className="border-left border-right border-bottom rounded">
          {existingInvestors?.length ? (
            existingInvestors.map((investor, i) => {
              const investmentUnits = `${investor.units.toFixed(2)} units`;
              const investmentCapital = utils.formatCurrency(investor.capital);
              const investmentText =
                programCreationFlow.program.programTypeId !== TYPE_HEDGE_FUND
                  ? [investmentUnits, investmentCapital].join(" / ")
                  : investmentCapital;
              return (
                <ListGroupItem
                  key={i}
                  className={`border-top bg-lighter border-bottom-0 d-flex justify-content-between`}
                  tag="div"
                >
                  <span>{investor.name}</span>
                  <span>{investmentText}</span>
                </ListGroupItem>
              );
            })
          ) : (
            <ListGroupItem
              className={`border-top bg-lighter border-bottom-0 d-flex justify-content-center`}
              tag="div"
            >
              None
            </ListGroupItem>
          )}
        </div>
      </ListGroup>
      <CardHeader className="py-0 h5 mb-0 mt-4 text-underline body-color">
        New investors
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem
          className="d-flex justify-content-between border-0"
          tag="div"
        >
          <span className="small text-muted">Investor Name</span>
          {parseInt(programCreationFlow.program.programTypeId) ===
          TYPE_HEDGE_FUND ? (
            <span className="small text-muted mr-5 pr-2">Amount</span>
          ) : (
            <span className="small text-muted">Units / Amount</span>
          )}
        </ListGroupItem>
        <div className="border-left border-right border-bottom rounded">
          {newInvestors?.length ? (
            newInvestors.map((investor, i) => {
              const investmentUnits = `${investor.units.toFixed(2)} units`;
              const investmentCapital = utils.formatCurrency(investor.capital);
              const investmentText =
                programCreationFlow.program.programTypeId !== TYPE_HEDGE_FUND
                  ? [investmentUnits, investmentCapital].join(" / ")
                  : investmentCapital;
              return (
                <ListGroupItem
                  key={i}
                  className={`border-top bg-lighter border-bottom-0 d-flex justify-content-between`}
                  tag="div"
                >
                  <span>{investor.name}</span>
                  <span>{investmentText}</span>
                </ListGroupItem>
              );
            })
          ) : (
            <ListGroupItem
              className={`border-top bg-lighter border-bottom-0 d-flex justify-content-center`}
              tag="div"
            >
              None
            </ListGroupItem>
          )}
        </div>
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() =>
            setProgramCreationFlow({ step: NAME_STEP, active: false })
          }
        >
          Cancel
        </Button>{" "}
        <Button
          color={"primary"}
          onClick={() => setProgramCreationFlow({ step: ADVISORS_REVIEW_STEP })}
        >
          Next
        </Button>
        {informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              informationModal.onClose
                ? informationModal.onClose()
                : setInformationModal({ isOpen: false, title: "", body: "" })
            }
          />
        ) : null}
      </Row>
    </>
  );
};

export default ProgramInvestorsReview;

import React from "react";

import { CardHeader, ListGroupItem, ListGroup, Button, Row } from "reactstrap";
import { useProgramCreationFlow } from "../../../../providers/programCreationFlowProvider";

const INVESTORS_REVIEW_STEP = 5;
const MATCH_REVIEW_STEP = 7;

const ProgramAdvisorsReview = () => {
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();

  const existingAdvisors = programCreationFlow.program.investors.filter(
    (investor) => investor.advisor && investor.advisor.id
  );
  const newAdvisors = programCreationFlow.program.investors.filter(
    (investor) => investor.advisor && !investor.advisor.id
  );

  return !programCreationFlow.program ? null : (
    <>
      <CardHeader className="py-0 h5 mt-4 mb-0 text-underline body-color">
        Existing advisors
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem
          className="d-flex justify-content-between border-0"
          tag="div"
        >
          <span className="small text-muted">Advisor Name</span>
          <span className="small text-muted">Investor Name</span>
        </ListGroupItem>
        <div className="border-left border-right border-bottom rounded">
          {existingAdvisors.length ? (
            existingAdvisors.map((investor, i) => (
              <ListGroupItem
                key={i}
                className={`border-top bg-lighter border-bottom-0 d-flex justify-content-between`}
                tag="div"
              >
                <span>{investor.advisor.name}</span>
                <span>{investor.name}</span>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem
              className={`border-top bg-lighter border-bottom-0 d-flex justify-content-center`}
              tag="div"
            >
              None
            </ListGroupItem>
          )}
        </div>
      </ListGroup>
      <CardHeader className="py-0 h5 mb-0 mt-4 text-underline body-color">
        New advisors
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem
          className="d-flex justify-content-between border-0"
          tag="div"
        >
          <span className="small text-muted">Advisor Name</span>
          <span className="small text-muted">Investor Name</span>
        </ListGroupItem>
        <div className="border-left border-right border-bottom rounded">
          {newAdvisors.length ? (
            newAdvisors.map((investor, i) => (
              <ListGroupItem
                key={i}
                className={`border-top bg-lighter border-bottom-0 d-flex justify-content-between`}
                tag="div"
              >
                <span>{investor.advisor.name}</span>
                <span>{investor.name}</span>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem
              className={`border-top bg-lighter border-bottom-0 d-flex justify-content-center`}
              tag="div"
            >
              None
            </ListGroupItem>
          )}
        </div>
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() =>
            setProgramCreationFlow({ step: INVESTORS_REVIEW_STEP })
          }
        >
          Back
        </Button>{" "}
        <Button
          className="ml-2"
          color={"primary"}
          onClick={() => setProgramCreationFlow({ step: MATCH_REVIEW_STEP })}
        >
          Next
        </Button>
      </Row>
    </>
  );
};

export default ProgramAdvisorsReview;

import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import Loader from "./Loader";

const ConfirmationModal = ({
  onSubmit,
  onClose,
  title,
  body,
  confirmColor,
  confirmText = "Confirm",
  cancelText = "Cancel",
  closable = true,
  rawBody = false,
  disabledSubmit = false,
}) => {
  const [loading, setLoading] = useState(false);

  const doSubmit = () => {
    setLoading(true);
    onSubmit();
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : rawBody ? (
          body
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        {closable ? (
          <Button color={"secondary"} onClick={onClose}>
            {cancelText}
          </Button>
        ) : null}
        <Button
          disabled={loading || disabledSubmit}
          color={confirmColor || "primary"}
          onClick={doSubmit}
        >
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;

import React, { useEffect, useState } from "react";

import { Row, Col, ListGroupItem, ListGroup } from "reactstrap";

import { utils } from "../../../utils/utils";
import Loader from "../../Loader";
import { fundsApi } from "../../../services/fundServices";

import SFQuarterlyReturnsChart from "../SFQuarterlyReturnsChart";

const TABLE_ROWS = 4;

const MyReturns = ({ program }) => {
  const [loading, setLoading] = useState();
  const fund = program?.programFundInvestments[0]?.fundFundInvestment;
  const [quartersData, setQuartersData] = useState([]);

  const totalFundInvestments = fund.fundFundInvestments.reduce(
    (p, c) => p + c.amount,
    0
  );
  const programFundOwnership =
    (program?.programFundInvestments[0]?.amount || 0) / totalFundInvestments;

  useEffect(() => {
    if (program?.fundInvestment) {
      setLoading(true);
      fundsApi
        .getFundQuarters({
          fundId: program.programFundInvestments[0].fundId,
          pageSize: 999,
        })
        .then((result) => {
          setLoading(false);
          const fundQuarters = utils
            .buildQuartersArray(result.data, TABLE_ROWS)
            .reverse();
          setQuartersData(fundQuarters);
        });
    }
  }, [program]);

  const lastQuarterData = quartersData[quartersData.length - 1];

  return (
    <Row className="mx-0 flex-grow-1 mt-5">
      {loading ? (
        <Loader size="sm" />
      ) : quartersData.length ? (
        <>
          <Col className="col-5 d-flex flex-wrap p-0 justify-content-around align-content-evenly">
            <ListGroup flush className="flex-grow-1 text-center rounded col-6">
              <ListGroupItem
                className="bg-light border-bottom text-body list-group-item d-flex flex-column"
                tag="div"
              >
                <span>Last Quarter</span>
                <span>Return</span>
              </ListGroupItem>
              <ListGroupItem
                className="pb-2 border-0 text-body list-group-item"
                tag="div"
              >
                <h4>{(lastQuarterData?.returnPercentage).toFixed(2)}%</h4>
              </ListGroupItem>
            </ListGroup>
            <ListGroup flush className="flex-grow-1 text-center rounded col-6">
              <ListGroupItem
                className="bg-light border-bottom text-body list-group-item d-flex flex-column"
                tag="div"
              >
                <span>Last Quarter Return</span>
                <span>After Fees</span>
              </ListGroupItem>
              <ListGroupItem
                className="pb-2 border-0 text-body list-group-item"
                tag="div"
              >
                <h4>
                  {(lastQuarterData?.returnPercentageAfterFees).toFixed(2)}%
                </h4>
              </ListGroupItem>
            </ListGroup>
            <ListGroup
              flush
              className="mt-3 flex-grow-1 text-center rounded col-6"
            >
              <ListGroupItem
                className="bg-light border-bottom text-body list-group-item d-flex flex-column"
                tag="div"
              >
                <span>Year to Date</span>
                <span>Return</span>
              </ListGroupItem>
              <ListGroupItem
                className="pb-2 border-0 text-body list-group-item"
                tag="div"
              >
                <h4>
                  {utils.formatCurrency(
                    lastQuarterData?.distributions.reduce(
                      (p, c) => p + c.amount,
                      0
                    )
                  )}
                </h4>
              </ListGroupItem>
            </ListGroup>
            <ListGroup
              flush
              className="mt-3 flex-grow-1 text-center rounded col-6"
            >
              <ListGroupItem
                className="bg-light border-bottom text-body list-group-item d-flex flex-column"
                tag="div"
              >
                <span>Estimated Annual</span>
                <span>Return</span>
              </ListGroupItem>
              <ListGroupItem
                className="pb-2 border-0 text-body list-group-item"
                tag="div"
              >
                <h4>
                  {(lastQuarterData?.projectedAnnualizedReturn).toFixed(2)}%
                </h4>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col className="col-7 pl-3 d-flex pr-0">
            <SFQuarterlyReturnsChart
              quartersData={quartersData}
              programFundOwnership={programFundOwnership}
            />
          </Col>
        </>
      ) : null}
    </Row>
  );
};

export default MyReturns;

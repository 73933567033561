import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
  UncontrolledAlert,
  CustomInput,
} from "reactstrap";
import { fundsApi } from "../../services/fundServices";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyInput from "react-currency-input-field";
import Loader from "../Loader";
import { utils } from "../../utils/utils";

const MAX_VALUE = 999999999;
const TYPE_HEDGE_FUND = 3;

const FundInvestmentModal = ({ program, onClose }) => {
  const programFundInvestment = program.programFundInvestments[0];

  const [amount, setAmount] = useState(programFundInvestment?.amount || "");
  const [fund, setFund] = useState(programFundInvestment?.fundId || "");
  const [funds, setFunds] = useState([]);
  const [errorAmount, setErrorAmount] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (parseInt(program.programTypeId) === TYPE_HEDGE_FUND) {
      fundsApi.getFunds({ isPrivateEquity: true }).then((result) => {
        setFunds(result.data);
        setLoading(false);
      });
    } else {
      fundsApi
        .getFunds({ year: program.year, isPrivateEquity: "false" })
        .then((result) => {
          const fund = result.data[0];
          setFund(fund?.id);
          setLoading(false);
        });
    }
  }, [program.year, program.programTypeId]);

  const onSubmitFundInvestment = (event) => {
    event.preventDefault();
    setLoading(true);
    if (!programFundInvestment) {
      return fundsApi
        .createFundInvestment({ amount, fundId: fund, programId: program.id })
        .then(() => {
          setLoading(false);
          onClose(true);
        });
    }
    fundsApi
      .updateFundInvestment({
        id: programFundInvestment?.id,
        fundId: fund,
        amount,
      })
      .then(() => {
        setLoading(false);
        onClose(true);
      });
  };

  const onAmountChange = (value) => {
    setErrorAmount(program.capital < value);
    if (!value || value <= MAX_VALUE) {
      setAmount(value);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        {programFundInvestment ? "Investment" : "Invest"} into Strategic Fund{" "}
        {program.year}
      </ModalHeader>
      <Form onSubmit={onSubmitFundInvestment}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              {parseInt(program.programTypeId) === TYPE_HEDGE_FUND ? (
                <FormGroup row>
                  <Label
                    sm={5}
                    className="text-sm-left d-flex flex-column justify-content-center"
                  >
                    <span>Hedge Fund Investments Fund</span>
                  </Label>
                  <Col
                    sm={7}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <CustomInput
                      required={true}
                      type="select"
                      id="PEFundSelect"
                      name="PEFundSelect"
                      onChange={(e) => setFund(e.currentTarget.value)}
                      value={fund}
                    >
                      <option value={""}>Select one...</option>
                      {funds.map((fund) => (
                        <option value={fund.id}>{fund.name}</option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row className={programFundInvestment ? "" : "mb-0"}>
                <Label
                  sm={5}
                  className="text-sm-left d-flex flex-column justify-content-center"
                >
                  <span>Amount</span>
                  <small className="font-size-6 text-warning">
                    Max {utils.formatCurrency(program.capital, 5)}
                  </small>
                </Label>
                <Col
                  sm={7}
                  className="d-flex align-items-center justify-content-start"
                >
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    required={true}
                    allowNegativeValue={false}
                    maxLength={20}
                    className="form-control"
                    placeholder="Enter the amount.."
                    value={amount}
                    onValueChange={onAmountChange}
                  />
                </Col>
              </FormGroup>
              {programFundInvestment ? (
                <>
                  <FormGroup row className="mb-0 small">
                    <Label
                      sm={5}
                      className="text-sm-left d-flex flex-column justify-content-center"
                    >
                      <span>Last Updated Date:</span>
                    </Label>
                    <Col
                      sm={7}
                      className="d-flex align-items-center justify-content-start"
                    >
                      <span>
                        {utils.formatDate(
                          programFundInvestment.updatedAt,
                          "YYYY-MM-DD, HH:mm"
                        )}
                      </span>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-0 small">
                    <Label
                      sm={5}
                      className="text-sm-left d-flex flex-column justify-content-center"
                    >
                      <span>Creation Date:</span>
                    </Label>
                    <Col
                      sm={7}
                      className="d-flex align-items-center justify-content-start"
                    >
                      <span>
                        {utils.formatDate(
                          programFundInvestment.createdAt,
                          "YYYY-MM-DD, HH:mm"
                        )}
                      </span>
                    </Col>
                  </FormGroup>
                </>
              ) : null}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={() => onClose()}
              isOpen={
                !loading &&
                ((program.programTypeId !== TYPE_HEDGE_FUND && !fund) ||
                  errorAmount)
              }
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>
                  {!fund
                    ? `${`There is no Strategic Fund for ${program.year}`}`
                    : "Transaction amount can't be higher than the Program capital"}
                </span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose()}>
                Cancel
              </Button>{" "}
              <Button
                disabled={errorAmount || loading || !fund}
                color={"primary"}
                type="submit"
              >
                Confirm
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundInvestmentModal;

import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import Loader from "../../../components/Loader";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bannersApi } from "../../../services/bannerServices";
import { utils } from "../../../utils/utils";
import BannerEdition from "./BannerEdition";
const UPDATE_ACTION = "update";
const DELETE_ACTION = "delete";

const BannersList = ({
  bannerCreationButton = () => {},
  setInformationModal = () => {},
  setConfirmationModal = () => {},
}) => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [bannerToEdit, setBannerToEdit] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await bannersApi.getBanners({ pageSize: 100 });
      setBanners(response?.data || []);
      setLoading(false);
    };
    fetchData();
  }, [bannerToEdit, refresh]);

  const duplicateBanner = (banner) => {
    banner.name = `${banner.name} copy`;
    bannerCreationButton(banner);
  };

  const bannerAction = async (banner, type) => {
    try {
      let modalTitle = type === UPDATE_ACTION ? "Update" : "Delete";
      let modalMessage =
        type === UPDATE_ACTION ? "save Banner changes?" : "delete the Banner?";
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            if (type === UPDATE_ACTION) {
              await bannersApi.updateBanner({
                ...banner,
                isActive: !banner.isActive,
              });
            } else {
              await bannersApi.deleteBanner(banner);
            }
            setConfirmationModal(utils.initModal);
            setInformationModal({
              isOpen: true,
              title: `${modalTitle} Banner`,
              body: `Banner ${type}d successfully.`,
              onClose: () => {
                setInformationModal(utils.initModal);
              },
            });
            setRefresh(!refresh);
          } catch (error) {
            setConfirmationModal(utils.initModal);
            setInformationModal({
              isOpen: true,
              title: "Oops!",
              body: "Oops! Something went wrong with your request.",
              onClose: () => setInformationModal(utils.initModal),
            });
          }
        },
        onClose: () => setConfirmationModal(utils.initModal),
        title: `${modalTitle} Banner`,
        body: `<span>You confirm you want to ${modalMessage}?</span>`,
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: "Oops! Something went wrong with your request.",
        onClose: () => setInformationModal(utils.initModal),
      });
    }
  };

  const downloadReport = async (banner) => {
    try {
      // download the spreadsheet
      const report = await bannersApi.getReport(banner);
      const bannerName = banner.name.replaceAll(" ", "_")

      // save the spreadsheet
      const fileName = `${moment().valueOf()}_${bannerName}_report.xlsx`;
      const url = window.URL.createObjectURL(new Blob([report.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: "Oops! Something went wrong with your request.",
        onClose: () => setInformationModal(utils.initModal),
      });
    }
  };

  return (
    <Card className="w-100">
      {loading ? (
        <Loader />
      ) : bannerToEdit ? (
        <BannerEdition
          data={bannerToEdit}
          setInformationModal={setInformationModal}
          setConfirmationModal={setConfirmationModal}
          createBanner={setBannerToEdit}
        />
      ) : (
        <Table className="my-0 text-custom-dark text-center">
          <thead>
            <tr>
              <th colSpan={4} className="text-left">
                <span className="font-size-1-2">Banners</span>
              </th>
            </tr>
            {banners.length ? (
              <tr className="text-muted font-size-75">
                <th className="text-left">Banner Name</th>
                <th>Notification Email</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            ) : (
              <tr>
                <th colSpan={4}>There are no Banners yet.</th>
              </tr>
            )}
          </thead>
          <tbody>
            {banners.map((banner, index) => (
              <tr key={`banner-${index}`}>
                <td className="text-left">{banner.name}</td>
                <td>{banner.notificationEmail || "-"}</td>
                <td>{banner.isActive ? "Yes" : "No"}</td>
                <td>
                  <UncontrolledDropdown className="d-inline-block">
                    <DropdownToggle color="white">
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faEllipsisV}
                      />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={() => {
                          setBannerToEdit(banner);
                        }}
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          bannerAction(banner, UPDATE_ACTION);
                        }}
                      >
                        {banner.isActive ? "Deactivate" : "Activate"}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          duplicateBanner(banner);
                        }}
                      >
                        Duplicate
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          downloadReport(banner);
                        }}
                      >
                        Report
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          bannerAction(banner, DELETE_ACTION);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Card>
  );
};

export default BannersList;

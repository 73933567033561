import React, { useState, useEffect } from "react";

import { Navigate } from "react-router-dom";
import { useProgramCreationFlow } from "../../providers/programCreationFlowProvider";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Label,
  FormText,
  Col,
  Form,
  UncontrolledAlert,
  Row,
} from "reactstrap";
import { sponsorsApi } from "../../services/sponsorServices";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../InformationModal";
import { usePrograms } from "../../providers/programsProvider";
import SponsorModal from "./SponsorModal";
import { programsApi } from "../../services/programServices";
import { utils } from "../../utils/utils";
import DateInput from "../Forms/DateInput";

const NAME_STEP = 1;
const SPONSOR_STEP = 2;
const SPONSOR_CREATION_STEP = 3;
const MBD_STEP = 4;
const INVESTORS_REVIEW_STEP = 5;

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;
const TYPE_ALTERNATIVE = 4;

const MAX_PAGE_SIZE = 999;

const ProgramNameModal = () => {
  const [programsContext] = usePrograms();
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();
  const [nameExists, setNameExists] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  const onNameChange = (event) => {
    const name = event.currentTarget.value;
    setNameExists(
      programsContext.programs.find((p) => p.name === name) !== undefined
    );
    setProgramCreationFlow({ name });
  };

  const onDismiss = () => {
    setProgramCreationFlow({ name: "" });
    setNameExists(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setProgramCreationFlow({ step: SPONSOR_STEP });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setProgramCreationFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Create New Program</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Program Name</span>
              <span className="ml-2 text-danger">*</span>
            </Label>
            <Col sm={8}>
              <Input
                required={true}
                maxLength="50"
                placeholder="Enter the name.."
                onChange={onNameChange}
                value={programCreationFlow.name}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Program Year</span>
              <span className="ml-2 text-danger">*</span>
            </Label>
            <Col sm={8}>
              <Input
                required={true}
                type="number"
                max={new Date().getFullYear() + 1}
                min={new Date().getFullYear() - 20}
                placeholder="Enter the year.."
                onChange={(event) => {
                  setProgramCreationFlow({
                    year: parseInt(event.currentTarget.value),
                  });
                  currentDate.setYear(event.currentTarget.value);
                  setCurrentDate(currentDate);
                }}
                value={programCreationFlow.year}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Type</span>
              <span className="ml-2 text-danger">*</span>
            </Label>
            <Col
              sm={8}
              className="d-flex flex-column align-items-baseline justify-content-center"
            >
              <CustomInput
                value={programCreationFlow.programTypeId || ''}
                required={true}
                onChange={(event) =>
                  setProgramCreationFlow({
                    programTypeId: event.target.value,
                  })
                }
                type="select"
                id="typeSelect"
                name="typeSelect"
              >
                <option value="">Select the type</option>
                <option value={TYPE_PRIVATE_EQUITY}>Private Equity</option>
                <option value={TYPE_REAL_ESTATE}>Real Estate Investment</option>
                <option value={TYPE_HEDGE_FUND}>Hedge Fund</option>
                <option value={TYPE_ALTERNATIVE}>Alternative Investment</option>
              </CustomInput>
            </Col>
          </FormGroup>
          {parseInt(programCreationFlow.programTypeId) ===
            TYPE_PRIVATE_EQUITY ||
          parseInt(programCreationFlow.programTypeId) === TYPE_REAL_ESTATE ||
          parseInt(programCreationFlow.programTypeId) === TYPE_HEDGE_FUND ? (
            <FormGroup row className="mt-3">
              <Label sm={4} className="text-sm-left">
                <span>Created on Date</span>
                <span className="ml-2 text-danger">*</span>
              </Label>
              <Col
                sm={8}
                className="d-flex flex-column align-items-baseline justify-content-center"
              >
                <DateInput
                  value={programCreationFlow?.createdOnDate}
                  onChange={(date) =>
                    setProgramCreationFlow({ createdOnDate: date })
                  }
                />
              </Col>
            </FormGroup>
          ) : null}
          {parseInt(programCreationFlow.programTypeId) === TYPE_HEDGE_FUND ? (
            <FormGroup row className="mt-3 mb-0">
              <Label sm={4} className="text-sm-left">
                <span>Closed on Date</span>
                <span className="ml-2 text-danger">*</span>
              </Label>
              <Col
                sm={8}
                className="d-flex flex-column align-items-baseline justify-content-center"
              >
                <DateInput
                  value={programCreationFlow?.closedOnDate}
                  onChange={(date) =>
                    setProgramCreationFlow({ closedOnDate: date })
                  }
                />
              </Col>
            </FormGroup>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={onDismiss}
              isOpen={nameExists}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>This name is already in use</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button
                color={"info"}
                onClick={() => setProgramCreationFlow({ active: false })}
              >
                Cancel
              </Button>{" "}
              <Button disabled={nameExists} color={"primary"} type="submit">
                Next
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const ProgramSponsorModal = () => {
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    sponsorsApi.getSponsors({ pageSize: MAX_PAGE_SIZE }).then((result) => {
      setSponsors(result.data);
      setLoading(false);
    });
  }, []);

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setProgramCreationFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Create New Program</ModalHeader>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          setProgramCreationFlow({ step: MBD_STEP });
        }}
      >
        <ModalBody className="mt-3 mr-3 ml-3 mb-0">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <FormGroup row>
              <Label sm={4} className="text-sm-left">
                Program Sponsor
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  type="select"
                  id="exampleCustomSelect"
                  name="customSelect"
                  onChange={(event) =>
                    setProgramCreationFlow({
                      sponsor: event.currentTarget.value,
                    })
                  }
                  value={programCreationFlow.sponsor}
                >
                  <option value="">Select...</option>
                  {sponsors.map((sponsor) => (
                    <option key={sponsor.id} value={sponsor.id}>
                      {sponsor.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
          )}
          <Button
            className="mb-2"
            color={"info"}
            onClick={() =>
              setProgramCreationFlow({ step: SPONSOR_CREATION_STEP })
            }
          >
            Add Sponsor
          </Button>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            color={"info"}
            onClick={() => setProgramCreationFlow({ active: false })}
          >
            Cancel
          </Button>
          <div>
            <Button
              color={"secondary"}
              onClick={() => setProgramCreationFlow({ step: NAME_STEP })}
            >
              Back
            </Button>{" "}
            <Button color={"primary"} type="submit">
              Next
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const ProgramMBDModal = () => {
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });
  const [loading, setLoading] = useState(false);

  const onUpload = (event) => {
    event.preventDefault();
    setLoading(true);
    programsApi
      .processMBD({ jsonSheet: programCreationFlow.mbd })
      .then((result) => {
        if (result.error) {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Heads Up!",
            body: result.error,
          });
        }
        const program = {
          name: programCreationFlow.name,
          units: result.investors.reduce((p, c) => p + c.units, 0),
          sponsorId: programCreationFlow.sponsor,
          year: programCreationFlow.year,
          programTypeId: programCreationFlow.programTypeId,
          investors: result.investors,
          advisements: result.advisements,
          blotter: result.blotter,
        };
        if (parseInt(programCreationFlow.programTypeId) === TYPE_HEDGE_FUND) {
          program.createdOnDate = utils.formatDate(
            programCreationFlow.createdOnDate,
            "YYYY-MM-DD"
          );
          program.closedOnDate = utils.formatDate(
            programCreationFlow.closedOnDate,
            "YYYY-MM-DD"
          );
        }
        setLoading(false);
        if (result.messages.length) {
          return setInformationModal({
            isOpen: true,
            title: "Heads Up!",
            body: result.messages.join("<hr/>"),
            onClose: () =>
              setProgramCreationFlow({ program, step: INVESTORS_REVIEW_STEP }),
          });
        }
        setProgramCreationFlow({ program, step: INVESTORS_REVIEW_STEP });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({ isOpen: true, title: "Heads Up!", body: err });
      });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setProgramCreationFlow({ active: false })}
    >
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Create New Program</ModalHeader>
      <Form onSubmit={onUpload}>
        <ModalBody className="text-left">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <FormGroup className="mb-1">
              <Label className="font-weight-bold">Upload MBD Blotter</Label>
              <Input
                required={true}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className="mb-1 text-truncate"
                onChange={(event) =>
                  setProgramCreationFlow({ mbd: event.currentTarget.files[0] })
                }
              />
              <FormText color="muted">Select a file</FormText>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            color={"info"}
            onClick={() => setProgramCreationFlow({ active: false })}
          >
            Cancel
          </Button>
          <div>
            <Button
              color={"secondary"}
              onClick={() => setProgramCreationFlow({ step: SPONSOR_STEP })}
            >
              Back
            </Button>{" "}
            <Button color={"primary"} type="submit">
              Upload
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const ProgramCreationModal = () => {
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();
  if (!programCreationFlow.active) {
    return <></>;
  }

  const onSponsorSubmit = (sponsorData) => {
    sponsorData.ceoAttribute = sponsorData.ceo;
    delete sponsorData.ceo;
    sponsorsApi.createSponsor(sponsorData).then((result) => {
      setProgramCreationFlow({ step: SPONSOR_STEP, sponsor: result.id });
    });
  };

  switch (programCreationFlow.step) {
    case NAME_STEP:
      return <ProgramNameModal />;
    case SPONSOR_STEP:
      return <ProgramSponsorModal />;
    case SPONSOR_CREATION_STEP:
      return (
        <SponsorModal
          onSubmit={onSponsorSubmit}
          onClose={() => setProgramCreationFlow({ step: SPONSOR_STEP })}
        />
      );
    case MBD_STEP:
      return <ProgramMBDModal />;
    case INVESTORS_REVIEW_STEP:
      return <Navigate to={"/back/program/review"} />;
    default:
      setProgramCreationFlow({ active: false });
  }
  return null;
};

export default ProgramCreationModal;

import React, { useEffect, useState } from "react";

import { useFundAssetCreationModal } from "../../../../providers/fundAssetCreationModalProvider";
import { fundsApi } from "../../../../services/fundServices";
import CategoryCreationModal from "../../CategoryCreationModal";
import FundAssetCreationModal from "./FundAssetCreationModal";

const DEFAULT_MODE = 1;
const CATEGORY_CREATION_MODE = 2;

const FundAssetCreation = () => {
  const [loading, setLoading] = useState(true);
  const [fundAssetCreationModal, setFundAssetCreationModal] =
    useFundAssetCreationModal();

  useEffect(() => {
    fundsApi.getFundAssetCategories({}).then((result) => {
      setFundAssetCreationModal({ categories: result.data });
      setLoading(false);
    });
  }, [setFundAssetCreationModal]);

  const onCategorySubmit = (data) => {
    setLoading(true);
    fundsApi.createFundAssetCategory(data).then((result) => {
      setLoading(false);
      setFundAssetCreationModal({
        categories: [...fundAssetCreationModal.categories, result],
        fundAssetCategoryId: result.id,
        mode: DEFAULT_MODE,
      });
    });
  };

  switch (fundAssetCreationModal.mode) {
    case DEFAULT_MODE:
      return <FundAssetCreationModal />;
    case CATEGORY_CREATION_MODE:
      return (
        <CategoryCreationModal
          loading={loading}
          onSubmit={onCategorySubmit}
          categories={fundAssetCreationModal.categories}
          onClose={() => setFundAssetCreationModal({ mode: DEFAULT_MODE })}
        />
      );
    default:
      return null;
  }
};

export default FundAssetCreation;

import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { usePrograms } from "../../../../providers/programsProvider";
import { utils } from "../../../../utils/utils";

import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { investorDistributionsApi } from "../../../../services/investorDistributionServices";
import Loader from "../../../../components/Loader";

const PieMoic = () => {
  const [programsContext] = usePrograms();
  const { program } = programsContext;

  const [distributions, setDistributions] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    investorDistributionsApi
      .getDistributions({
        pageSize: 9999,
        programId: program.id,
      })
      .then((result) => {
        setDistributions(result.data);
        setLoading(false);
      });
  }, [program.id, setDistributions]);

  const getData = () => {
    const ret = distributions.reduce((p, c) => p + c.amount, 0);
    const data = [program.capital, ret];
    const labels = ["Program Total", "Program Return"];

    return { data, labels };
  };

  const getChart = () => {
    const { data, labels } = getData();
    return {
      data: {
        labels,
        datasets: [
          {
            fill: true,
            data,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          colorschemes: {
            scheme: "tableau.Classic20",
          },
          datalabels: {
            formatter: (value) => utils.formatCurrency(value),
            display: true,
            color: "white",
            font: {
              weight: "bold",
              familiy: "Rubik",
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => `$${tooltipItem.formattedValue}`,
            },
          },
        },
      },
    };
  };

  const chart = getChart();

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0 text-center">
          MOIC ={" "}
          {(
            (distributions.reduce((p, c) => p + c.amount, 0) /
              program.capital) *
            100
          ).toFixed(1)}
          x
        </CardTitle>
      </CardHeader>
      <CardBody className="col-12">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Pie height={230} data={chart.data} options={chart.options} />
        )}
      </CardBody>
    </Card>
  );
};

export default PieMoic;

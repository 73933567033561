import React from "react";
import { Container, Row, Spinner } from "reactstrap";

const Loader = ({ size = "md", align = "center" }) => (
  <Container fluid className={`${size !== "sm" ? "vh-75" : ""} d-flex`}>
    <Row
      className={`justify-content-${align} align-self-center w-100 text-center`}
    >
      <Spinner size={size} color="primary" />
    </Row>
  </Container>
);

export default Loader;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Loader from "../../Loader";
import { programsApi } from "../../../services/programServices";
import { useProgramCloseDocuments } from "../../../providers/programCloseDocumentsProvider";
import { pdfjs, Document, Page } from "react-pdf";
import {
  faChevronLeft,
  faChevronRight,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download from "downloadjs";

import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useAuth } from "../../../providers/authProvider";
import EmailModal from "../EmailModal";
import { useEmailContext } from "../../../providers/emailProvider";

// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const INITIAL_MODE = 1;
const PREGENERATION_MODE = 4;
const GENERATION_MODE = 5;
const PREVIEW_MODE = 6;
const PREUPLOAD_MODE = 7;
const UPLOAD_MODE = 8;
const SUCCESS_MODE = 9;
const EMAIL_MODAL_DEFAULT_MODE = 9;

const PreviewMode = () => {
  const [email, setEmail] = useEmailContext();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [programCloseDocuments, setProgramCloseDocuments] =
    useProgramCloseDocuments();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onSend = () => {
    setEmail({ mode: 1 });
    const defaultSubject = "Investment Closing Letter";
    if (email?.subject !== defaultSubject) {
      setEmail({
        name: programCloseDocuments?.program?.name,
        subject: defaultSubject,
        content: `
                <p>Dear {{userName}},</p>
                <p>This email is to inform you that your investment in ${programCloseDocuments?.program?.name} closed on June 16th, 2021. A confirmation of your investment is attached. Please let us know if you have any questions or concerns.</p>
                <p>As always, thank you for putting your trust in Green Rock. Should you have any questions or concerns, please feel free to email us at support@green-rock.com or call us at 205.580.1180.</p>
                <p>Sincerely,<br/>
                <strong>Vulcan Fund Solutions Team</strong><br/>
                2015 3rd Avenue North <br/>
                Birmingham, AL 35203</p>
              `,
      });
    }

    setProgramCloseDocuments({ mode: EMAIL_MODAL_DEFAULT_MODE });
  };

  const onDownload = () => {
    setDownloadLoading(true);
    programsApi
      .downloadCloseDocuments({ id: programCloseDocuments.program.id })
      .then((result) => {
        download(
          result,
          `CloseDocuments-${programCloseDocuments.program.name}.zip`,
          "application/zip"
        ); //this is third party it will prompt download window in browser.
        setDownloadLoading(false);
        return result;
      });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setProgramCloseDocuments({ mode: PREGENERATION_MODE })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="md" className="close-documents-modal">
      <ModalHeader close={closeBtn}>
        <span>Close Document Preview</span>
        {downloadLoading ? (
          <div className="col-4">
            <Loader size="sm" align="end" />
          </div>
        ) : (
          <Button className="font-size-75" color={"info"} onClick={onDownload}>
            <FontAwesomeIcon icon={faFileDownload} /> Download All Close
            Documents
          </Button>
        )}
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="col-12 text-center px-0">
          <Document
            file={programCloseDocuments.previewFile}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="d-flex justify-content-between p-3 align-items-center text-body">
            <FontAwesomeIcon
              className="cursor-pointer text-body"
              icon={faChevronLeft}
              onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
            />
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <FontAwesomeIcon
              className="cursor-pointer text-body"
              icon={faChevronRight}
              onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color={"secondary"}
          onClick={() => setProgramCloseDocuments({ mode: PREGENERATION_MODE })}
        >
          Back
        </Button>{" "}
        <Button color={"primary"} onClick={onSend}>
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const PreProgressMode = ({ onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>Alright!</ModalHeader>
      <ModalBody>
        <p>
          Close documents are being generated, this will take some time
          depending on the amount of Investors.
        </p>
        <span>
          Once done, you can continue with the flow by clicking the "Preview"
          button above the progress bar
        </span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Got it!
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const InitialMode = ({ onClose }) => {
  const [authContext] = useAuth();
  const [
    programCloseDocuments,
    setProgramCloseDocuments,
    onCloseDocumentsGeneration,
  ] = useProgramCloseDocuments();

  const onNext = () => {
    try {
      setProgramCloseDocuments({ mode: PREGENERATION_MODE });
      onCloseDocumentsGeneration(authContext.currentUser.socketToken, () => {
        programsApi.generateCloseDocuments({
          id: programCloseDocuments.program.id,
          socketToken: authContext.currentUser.socketToken,
        });
      });
    } catch (error) {
      console.error("new error", error);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>
        Send {programCloseDocuments.program.name} Close Documents
      </ModalHeader>
      <ModalBody>
        <p>You selected to send the program close documents to investors.</p>
        <span>
          The documents will be generated and a preview will be shown before
          sending.
        </span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button color={"primary"} type="submit" onClick={onNext}>
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const PreUploadMode = ({ onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>Alright!</ModalHeader>
      <ModalBody>
        <p>
          Close documents are being uploaded, this will take some time depending
          on the amount of Investors.
        </p>
        <span>
          Once done, they will be sent to the Investors automatically.
        </span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Got it!
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const SuccessMode = ({ onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>Alright!</ModalHeader>
      <ModalBody>
        <span>Close documents sent successfully.</span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Content = ({ onClose }) => {
  const [
    programCloseDocuments,
    setProgramCloseDocuments,
    ,
    onCloseDocumentsUpload,
  ] = useProgramCloseDocuments();
  const [authContext] = useAuth();

  const onSubmitEmail = (emailContext) => {
    setProgramCloseDocuments({
      mode: PREUPLOAD_MODE,
      remaining: 0,
      total: 0,
      socket: null,
    });
    onCloseDocumentsUpload(authContext.currentUser.socketToken, () => {
      programsApi.sendCloseDocuments({
        socketToken: authContext.currentUser.socketToken,
        id: programCloseDocuments.program.id,
        closeDocuments: programCloseDocuments.closeDocuments,
        subject: emailContext.subject,
        replyTo: emailContext.replyTo,
        content: emailContext.content,
      });
    });
    onClose();
  };

  switch (programCloseDocuments.mode) {
    case INITIAL_MODE:
      return <InitialMode onClose={onClose} />;
    case PREGENERATION_MODE:
      return (
        <PreProgressMode
          onClose={() => setProgramCloseDocuments({ mode: GENERATION_MODE })}
        />
      );
    case PREVIEW_MODE:
      return <PreviewMode />;
    case PREUPLOAD_MODE:
      return (
        <PreUploadMode
          onClose={() => setProgramCloseDocuments({ mode: UPLOAD_MODE })}
        />
      );
    case EMAIL_MODAL_DEFAULT_MODE:
      return (
        <EmailModal
          onClose={() => setProgramCloseDocuments({ mode: PREVIEW_MODE })}
          onSubmit={onSubmitEmail}
          cancelBtnText="Back"
          sendBtnText="Upload & Send"
        />
      );
    case SUCCESS_MODE:
      return <SuccessMode onClose={onClose} />;
    default:
      return null;
  }
};

const ProgramCloseDocumentsModal = ({ onClose }) => (
  <Content onClose={onClose} />
);

export default ProgramCloseDocumentsModal;

import React, { useReducer, createContext, useContext } from "react";
import config from "../config/config";
import socketIOClient from "socket.io-client";

const INITIAL_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  isActive: false,
  mode: INITIAL_MODE,
  previewFile: null,
  program: null,
  total: 0,
  remaining: 0,
  closeDocuments: [],
  socket: null,
};

const ProgramCloseDocumentsContext = createContext(initialState);

const ProgramCloseDocumentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onCloseDocumentsGeneration = (socketToken, callback) => {
    const socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      dispatch({ socket });
      callback();
    });

    socket.on("closeDocumentGenerated", (data) => {
      dispatch({ ...data });
      if (data.remaining === 0) {
        return socket.disconnect();
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  const onCloseDocumentsUpload = (socketToken, callback) => {
    const socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      dispatch({ socket });
      callback();
    });

    socket.on("ack", () => {
      socket.emit("ack", socketToken);
    });

    socket.on("closeDocumentUploaded", (data) => {
      dispatch({ ...data });
      if (data.remaining === 0) {
        return socket.disconnect();
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  return (
    <ProgramCloseDocumentsContext.Provider
      value={[
        state,
        dispatch,
        onCloseDocumentsGeneration,
        onCloseDocumentsUpload,
      ]}
    >
      {children}
    </ProgramCloseDocumentsContext.Provider>
  );
};

export const useProgramCloseDocuments = () =>
  useContext(ProgramCloseDocumentsContext);

export {
  ProgramCloseDocumentsContext,
  ProgramCloseDocumentsProvider,
  initialState,
};

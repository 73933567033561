import React, { useEffect, useState } from "react";

import { usePrograms } from "../../../../providers/programsProvider";
import { utils } from "../../../../utils/utils";
import FundInvestment from "../FundInvestment";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ListGroupItem,
} from "reactstrap";

const INVESTMENT_TYPE_INCREASE = "1";

const TYPE_ALTERNATIVE_INVESTMENT = 4;
const TYPE_HEDGE_FUND = 3;

const Holdings = () => {
  const [programsContext] = usePrograms();
  const [groupedFundInvestments, setGroupedFundInvestments] = useState();

  const { program } = programsContext;

  useEffect(() => {
    if (parseInt(program.programTypeId) === TYPE_HEDGE_FUND) {
      setGroupedFundInvestments(
        program.programFundInvestments.reduce((p, c) => {
          if (!p[c.fundId]) {
            p[c.fundId] = [];
          }
          p[c.fundId].push(c);
          return p;
        }, {})
      );
    }
  }, [program.programTypeId, program.programFundInvestments]);

  const getFundInvestments = () =>
    programsContext.program.programFundInvestments.reduce(
      (p, c) =>
        p + c.amount * (c.type === INVESTMENT_TYPE_INCREASE ? 1 : -1) || 0,
      0
    );

  const programCapital = () =>
    utils.formatCurrency(
      programsContext.program.capital -
        getFundInvestments() -
        (parseInt(program.programTypeId) === TYPE_HEDGE_FUND
          ? 0
          : programsContext.program.auditReserve)
    );

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Holdings
        </CardTitle>
      </CardHeader>
      <CardBody>
        <ListGroupItem
          className="border-top-0 border-left-0 border-right-0 border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          <div className="d-flex flex-column text-truncate col-6 px-0">
            <span className="text-truncate">
              {parseInt(program.programTypeId) === TYPE_HEDGE_FUND
                ? "Capital to be Deployed"
                : "Capitalized Cost"}
            </span>
          </div>
          <div>{programCapital()}</div>
        </ListGroupItem>
        {parseInt(program.programTypeId) === TYPE_ALTERNATIVE_INVESTMENT ? (
          <ListGroupItem
            className="border-top-0 border-left-0 border-right-0 border-bottom d-flex justify-content-between align-items-center"
            tag="div"
          >
            <div className="d-flex flex-column text-truncate col-6 px-0">
              <span className="text-truncate">Audit Reserve</span>
            </div>
            <div>{utils.formatCurrency(program.auditReserve || 0)}</div>
          </ListGroupItem>
        ) : null}
        {parseInt(program.programTypeId) === TYPE_ALTERNATIVE_INVESTMENT
          ? program.programFundInvestments.map((fundInvestment, index) => (
              <FundInvestment
                fundInvestments={[fundInvestment]}
                hideAssets={parseInt(program.programTypeId) === TYPE_HEDGE_FUND}
                key={index}
              />
            ))
          : groupedFundInvestments
          ? Object.keys(groupedFundInvestments).map((fundId, index) => (
              <FundInvestment
                fundInvestments={groupedFundInvestments[fundId]}
                hideAssets={parseInt(program.programTypeId) === TYPE_HEDGE_FUND}
                key={index}
              />
            ))
          : null}
        <ListGroupItem
          className="border-top-0 border-left-0 border-right-0 border-bottom-0 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <div className="d-flex flex-column">
            <span>Total</span>
            <small className="mt-n1 text-muted">Program Total</small>
          </div>
          <span>{utils.formatCurrency(program.capital)}</span>
        </ListGroupItem>
      </CardBody>
    </Card>
  );
};

export default Holdings;

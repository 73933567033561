import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
} from "reactstrap";

import Loader from "../../Loader";
import { useAuth } from "../../../providers/authProvider";
import { usersApi } from "../../../services/userServices";
import InformationModal from "../../InformationModal";

const ENABLED_2FA = "1";
const DISABLED_2FA = "2";
const NO_PHONE_ERROR = "no phone set";

const Security = ({ setSetting = () => {} }) => {
  const [authContext, setAuthContext] = useAuth();
  const [user, setUser] = useState({ ...authContext.currentUser });

  const [pwdLoading, setPwdLoading] = useState(false);
  const [twoFALoading, setTwoFALoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onChangePassword = (event) => {
    setErrorMessage("");
    event.preventDefault();
    setPwdLoading(true);
    const updateUserData = {
      id: user.id,
      currentPassword: user.currentPassword,
      newPassword: user.newPassword,
    };

    usersApi
      .updateUser(updateUserData, "admin")
      .then((result) => {
        setPwdLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "Password changed successfully",
          onClose: () => setAuthContext({ currentUser: { ...result } }),
        });
      })
      .catch((err) => {
        setPwdLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  const onChangeTwoFA = (value) => {
    setErrorMessage("");
    setTwoFALoading(true);
    const enabled2FA = value === ENABLED_2FA ? true : false;
    const updateUserData = {
      id: user.id,
      enabled2FA,
    };

    usersApi
      .updateUser(updateUserData, "admin")
      .then(() => {
        user.enabled2FA = enabled2FA;
        setAuthContext({ currentUser: { ...user } });
        setTwoFALoading(false);
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: `Two Factor Authentication ${
            enabled2FA ? "enabled" : "disabled"
          } successfully`,
        });
      })
      .catch((err) => {
        setTwoFALoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  const onChange2FAType = (value) => {
    setErrorMessage("");
    setTwoFALoading(true);
    const updateUserData = {
      id: user.id,
      type2FA: value,
    };

    usersApi
      .updateUser(updateUserData, "admin")
      .then(() => {
        user.type2FA = value;
        setAuthContext({ currentUser: { ...user } });
        setTwoFALoading(false);
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message === NO_PHONE_ERROR
        ) {
          setErrorMessage(err.message);
        }
        setTwoFALoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  useEffect(() => {
    setUser({ ...authContext.currentUser });
  }, [authContext.currentUser]);

  return (
    <Card className="col-5 box-shadow-none tab-card">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Password
        </CardTitle>
      </CardHeader>
      <CardBody>
        {pwdLoading ? (
          <Loader align="start" size="sm" />
        ) : (
          <Form onSubmit={onChangePassword}>
            <Row form className="mx-0">
              <FormGroup className="col-12 px-0">
                <Label for="currentPassword">Current Password</Label>
                <Input
                  maxLength={60}
                  required={true}
                  onChange={(event) =>
                    setUser({
                      ...user,
                      currentPassword: event.currentTarget.value,
                    })
                  }
                  type="password"
                  name="currentPassword"
                  id="currentPassword"
                  placeholder="Current Password.."
                />
              </FormGroup>
            </Row>
            <Row form className="mx-0">
              <FormGroup className="col-12 px-0">
                <Label for="newPassword">New Password</Label>
                <Input
                  maxLength={60}
                  required={true}
                  onChange={(event) =>
                    setUser({ ...user, newPassword: event.currentTarget.value })
                  }
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="New Password.."
                />
              </FormGroup>
            </Row>
            <Row form className="mx-0">
              <FormGroup className="col-12 px-0">
                <Label for="newPassword">Confirm New Password</Label>
                <Input
                  maxLength={60}
                  required={true}
                  className={
                    user.reNewPassword?.length && user.newPassword?.length
                      ? user.reNewPassword !== user.newPassword
                        ? "border-danger"
                        : "border-success"
                      : ""
                  }
                  onChange={(event) =>
                    setUser({
                      ...user,
                      reNewPassword: event.currentTarget.value,
                    })
                  }
                  type="password"
                  name="reNewPassword"
                  id="reNewPassword"
                  placeholder="Confirm New Password.."
                />
              </FormGroup>
            </Row>
            <Button
              type="submit"
              disabled={user.reNewPassword !== user.newPassword}
              color="info"
            >
              Change Password
            </Button>
          </Form>
        )}
      </CardBody>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Two Factor Authentication
        </CardTitle>
      </CardHeader>
      <CardBody>
        {twoFALoading ? (
          <Loader align="start" size="sm" />
        ) : (
          <Form>
            <FormGroup row className="d-none">
              <Label sm={6} className="text-sm-left">
                Two Factor Authentication Enabled
              </Label>
              <Col sm={6}>
                <CustomInput
                  required={true}
                  type="select"
                  id="2FA"
                  name="2FA"
                  onChange={(e) => onChangeTwoFA(e.currentTarget.value)}
                  value={user.enabled2FA ? ENABLED_2FA : DISABLED_2FA}
                >
                  <option value={ENABLED_2FA}>Enabled</option>
                  <option value={DISABLED_2FA}>Disabled</option>
                </CustomInput>
              </Col>
            </FormGroup>
            {user.enabled2FA ? (
              <FormGroup row>
                <Label sm={6} className="text-sm-left">
                  Method
                </Label>
                <Col sm={6}>
                  <CustomInput
                    required={true}
                    type="select"
                    id="type2FASelect"
                    name="customSelect"
                    onChange={(e) => onChange2FAType(e.currentTarget.value)}
                    value={user.type2FA || ""}
                  >
                    <option value="Email">Email</option>
                    <option value="SMS">SMS</option>
                  </CustomInput>
                  {errorMessage ? (
                    <p className="pt-2 disabled" disabled>
                      There is no a phone number set. Set it
                      <span
                        className="cursor-pointer text-primary"
                        onClick={() => {
                          setErrorMessage("");
                          setSetting({ tab: "1" });
                        }}
                      >
                        {` here`}
                      </span>
                    </p>
                  ) : user.type2FA === "SMS" ? (
                    <p className="pt-2 disabled">
                      Two Factor Authentication code will be sent to:
                      {` ${user.phone}`}
                    </p>
                  ) : null}
                </Col>
              </FormGroup>
            ) : null}
          </Form>
        )}
      </CardBody>
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Card>
  );
};

export default Security;

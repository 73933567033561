import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Progress, Spinner, UncontrolledAlert } from "reactstrap";

import {
  useProgramCloseDocuments,
  initialState,
} from "../../providers/programCloseDocumentsProvider";
import { utils } from "../../utils/utils";

const INITIAL_MODE = 1;
const PREGENERATION_MODE = 4;
const GENERATION_MODE = 5;
const PREVIEW_MODE = 6;
const PREUPLOAD_MODE = 7;
const UPLOAD_MODE = 8;
const SUCCESS_MODE = 9;

const ProgramCloseDocumentsProgress = () => {
  const [programCloseDocuments, setProgramCloseDocuments] =
    useProgramCloseDocuments(initialState);

  const getProgress = () =>
    programCloseDocuments.total === 0
      ? -1
      : ((programCloseDocuments.total - programCloseDocuments.remaining) /
          programCloseDocuments.total) *
        100;

  if (
    programCloseDocuments.mode !== PREGENERATION_MODE &&
    programCloseDocuments.mode !== PREUPLOAD_MODE &&
    programCloseDocuments.mode !== UPLOAD_MODE &&
    programCloseDocuments.mode !== GENERATION_MODE
  ) {
    return null;
  }

  const getText = () => {
    if (programCloseDocuments.mode === GENERATION_MODE) {
      if (getProgress() < 100) {
        return `Generating close documents for ${programCloseDocuments.program.name}`;
      }
      return "Ready!";
    }
    if (getProgress() < 100) {
      return `Uploading close documents for ${programCloseDocuments.program.name}`;
    }
    return "Ready!";
  };

  return (
    <UncontrolledAlert color={"tertiary"} className="statements-progress">
      <div className="alert-icon d-flex align-items-center">
        <FontAwesomeIcon icon={faBell} fixedWidth />
      </div>
      <div className="alert-message">
        <div className="d-flex flex-column justify-content-center col-12">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {getProgress() < 100 ? (
                <Spinner color="white" type="grow" size="sm" />
              ) : null}
              <span className="text-white mr-4 ml-2">{getText()}</span>
            </div>
            <div>
              <Button
                className="rounded btn alert-icon btn-sm text-white py-1"
                color="none"
                size="sm"
                onClick={() => {
                  programCloseDocuments.socket?.disconnect();
                  setProgramCloseDocuments({
                    isActive: false,
                    socket: null,
                    mode: INITIAL_MODE,
                  });
                }}
              >
                Abort
              </Button>
              {getProgress() >= 100 ? (
                <Button
                  className="rounded btn alert-icon btn-sm text-white py-1 ml-2"
                  color="none"
                  size="sm"
                  onClick={() => {
                    setProgramCloseDocuments({
                      socket: null,
                      mode:
                        programCloseDocuments.mode === GENERATION_MODE
                          ? PREVIEW_MODE
                          : SUCCESS_MODE,
                    });
                  }}
                >
                  {`${
                    programCloseDocuments.mode === GENERATION_MODE
                      ? "Preview"
                      : "Got it!"
                  }`}
                </Button>
              ) : null}
            </div>
          </div>
          <Progress
            animated
            color="tertiary"
            className="col-12 mb-2 mt-3 progress px-0 border"
            value={getProgress()}
          >
            {utils.formatPercent(
              programCloseDocuments.total - programCloseDocuments.remaining,
              programCloseDocuments.total
            )}
          </Progress>
          {getProgress() < 100 ? (
            <small>
              In the meanwhile you can continue using the app. Please don't
              close or reload it.
            </small>
          ) : null}
        </div>
      </div>
    </UncontrolledAlert>
  );
};

export default ProgramCloseDocumentsProgress;

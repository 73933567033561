import React, { useReducer, createContext, useContext } from "react";
import config from "../config/config";
import socketIOClient from "socket.io-client";

const INITIAL_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  isActive: false,
  mode: INITIAL_MODE,
  previewFile: null,
  quarter: "",
  letter: "",
  program: null,
  total: 0,
  remaining: 0,
  statements: [],
  socket: null,
};

const ProgramStatementsContext = createContext(initialState);

const ProgramStatementsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onStatementsGeneration = (socketToken, callback) => {
    const socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      dispatch({ socket });
      callback();
    });

    socket.on("statementGenerated", (data) => {
      dispatch({ ...data });
      if (data.remaining === 0) {
        return socket.disconnect();
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  const onStatementsUpload = (socketToken, callback) => {
    const socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      dispatch({ socket });
      callback();
    });

    socket.on("ack", () => {
      socket.emit("ack", socketToken);
    });

    socket.on("statementUploaded", (data) => {
      console.log(data);
      dispatch({ ...data });
      if (data.remaining === 0) {
        return socket.disconnect();
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  return (
    <ProgramStatementsContext.Provider
      value={[state, dispatch, onStatementsGeneration, onStatementsUpload]}
    >
      {children}
    </ProgramStatementsContext.Provider>
  );
};

export const useProgramStatements = () => useContext(ProgramStatementsContext);

export { ProgramStatementsContext, ProgramStatementsProvider, initialState };

import React from "react";
import { Pie } from "react-chartjs-2";

import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";

const MAX_TEXT_LENGTH = 20;

const TYPE_OUTCOME = {
  id: "2",
};

const truncateText = (text) =>
  text.length > MAX_TEXT_LENGTH
    ? `${text.slice(0, MAX_TEXT_LENGTH - 1)}...`
    : text;

const getAssetCurrentCapital = (asset) =>
  asset.capital +
  (asset.transactions || []).reduce(
    (p, c) => p + (c.type === TYPE_OUTCOME.id ? -c.amount : c.amount),
    0
  );

const InvestmentAllocation = ({ assets, legendPosition }) => {
  const total = assets.reduce((p, c) => p + getAssetCurrentCapital(c), 0);
  const chart = {
    data: {
      labels: assets.map((asset) => truncateText(asset.name)),
      datasets: [
        { data: assets.map((asset) => getAssetCurrentCapital(asset)) },
      ],
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        position: legendPosition,
      },
      plugins: {
        colorschemes: {
          scheme: "tableau.Classic20",
        },
        datalabels: {
          formatter: (data) => `${((data / total) * 100).toFixed(2)}%`,
          display: true,
          color: "white",
          font: {
            weight: "bold",
            familiy: "Rubik",
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => `$${tooltipItem.formattedValue}`,
          },
        },
      },
    },
  };
  return (
    <Card className="mb-0 flex-grow-1 justify-content-center align-items-center min-vh-50">
      {assets.length ? (
        <>
          <CardHeader>
            <CardTitle tag="h5" className="mb-0 text-center">
              Investment Allocation
            </CardTitle>
          </CardHeader>
          <CardBody className="d-flex col-12 align-items-center">
            <Pie data={chart.data} options={chart.options} />
          </CardBody>
        </>
      ) : (
        <span className="font-weight-bold">The Fund has no assets yet</span>
      )}
    </Card>
  );
};

export default InvestmentAllocation;

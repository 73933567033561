import React, { useState, useEffect, useCallback } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  Card,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  UncontrolledAlert,
  CustomInput,
} from "reactstrap";
import Loader from "../../Loader";
import { onBoardApi } from "../../../services/onboardServices";
import { useOnBoardingFlow } from "../../../providers/onBoardingFlowProvider";
import {
  faTrashAlt,
  faBell,
  faTrashRestoreAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderTitle from "../../HeaderTitle";
import Header from "../../Header";
import Content from "../../Content";
import Footer from "../../Footer";
import Wrapper from "../../Wrapper";
import Main from "../../../components/Main";
import TermsConditions from "./TermsConditions";
import InformationModal from "../../InformationModal";
import Enable2FAModal from "../../Enable2FAModal";
import { utils } from "../../../utils/utils";

const EMAIL_STEP = 1;
const PASSWORD_STEP = 2;
const RELATIONS_STEP = 3;
const CONDITIONS_STEP = 4;
const COMPLETE_STEP = 5;

const USER_ROLE_ADVISOR = "2";
const USER_ROLE_INVESTOR = "3";

const states = require("../../../assets/states.json");

const EmailModal = () => {
  const navigate = useNavigate();
  const [onBoardingFlow, setOnBoardingFlow] = useOnBoardingFlow();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onNext = (event) => {
    event.preventDefault();
    setLoading(true);
    onBoardApi
      .startOnBoarding({
        token: onBoardingFlow.token,
        email: onBoardingFlow.email,
      })
      .then((user) => {
        setLoading(false);
        const programs = [
          ...(user.investments || []),
          ...(user.ownerships || []),
        ];
        const sponsor = programs.length === 1 ? programs[0].sponsor : null;
        setOnBoardingFlow({
          id: user.id,
          name: user.name,
          address: user.address,
          phone: user.phone,
          city: user.city,
          state: user.state,
          zipcode: user.zipcode,
          userRole: utils.userHasRole(user, "investor")
            ? USER_ROLE_INVESTOR
            : USER_ROLE_ADVISOR,
          step: PASSWORD_STEP,
          sponsor,
        });
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const onChange = (event) => {
    setError(false);
    setOnBoardingFlow({ email: event.currentTarget.value });
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>Invite Accepted</ModalHeader>
      <Form onSubmit={onNext}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <FormGroup row className="m-0">
              <Label className="text-sm-left mb-0 mr-2 d-flex align-items-center">
                Email
              </Label>
              <Col sm={8}>
                <Input
                  required={true}
                  type="email"
                  maxLength="50"
                  placeholder="Enter email address.."
                  onChange={onChange}
                  value={onBoardingFlow.email}
                />
              </Col>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={() => setError(false)}
              isOpen={error}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>Incorrect email</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button
                color={"secondary"}
                onClick={() => navigate("/auth/sign-in")}
              >
                Cancel
              </Button>{" "}
              <Button color={"primary"} type="submit">
                Start
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const PasswordModal = () => {
  const navigate = useNavigate();
  const [onBoardingFlow, setOnBoardingFlow] = useOnBoardingFlow();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onNext = (event) => {
    event.preventDefault();
    setLoading(true);
    onBoardApi
      .updateUser({
        token: onBoardingFlow.token,
        id: onBoardingFlow.id,
        name: onBoardingFlow.name,
        address: onBoardingFlow.address,
        city: onBoardingFlow.city,
        state: onBoardingFlow.state,
        zipcode: onBoardingFlow.zipcode,
        phone: onBoardingFlow.phone,
        newPassword: password,
      })
      .then(() => {
        setLoading(false);
        setOnBoardingFlow({ step: RELATIONS_STEP });
      });
  };

  const onBack = () => {
    if (onBoardingFlow.onBoardManual) {
      navigate("/onboard/manual");
    } else {
      setOnBoardingFlow({
        step: EMAIL_STEP,
      });
    }
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>Setup Account</ModalHeader>
      <Form onSubmit={onNext}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              {onBoardingFlow.sponsor ? (
                <div className="text-center mb-3">
                  <div
                    className="rounded p-0 col-10"
                    style={{
                      backgroundPosition: "center",
                      backgroundImage: `url(${onBoardingFlow.sponsor.webLogo})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer",
                      alignSelf: "center",
                      height: "150px",
                      width: "80%",
                      margin: "0 auto",
                    }}
                  />
                </div>
              ) : null}
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Name
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter name.."
                    onChange={(event) =>
                      setOnBoardingFlow({ name: event.currentTarget.value })
                    }
                    value={onBoardingFlow.name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Address
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter address.."
                    onChange={(event) =>
                      setOnBoardingFlow({ address: event.currentTarget.value })
                    }
                    value={onBoardingFlow.address || ""}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  City
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter city.."
                    onChange={(event) =>
                      setOnBoardingFlow({ city: event.currentTarget.value })
                    }
                    value={onBoardingFlow.city || ""}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  State
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    type="select"
                    id="stateCustomSelect"
                    name="customSelect"
                    onChange={(event) =>
                      setOnBoardingFlow({ state: event.currentTarget.value })
                    }
                    value={onBoardingFlow.state || ""}
                  >
                    <option value="">Select...</option>

                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Zip Code
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength={50}
                    type="text"
                    placeholder="Enter zipcode.."
                    onChange={(event) =>
                      setOnBoardingFlow({ zipcode: event.currentTarget.value })
                    }
                    value={onBoardingFlow.zipcode || ""}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Phone
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter phone.."
                    onChange={(event) =>
                      setOnBoardingFlow({ phone: event.currentTarget.value })
                    }
                    value={onBoardingFlow.phone || ""}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Password
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type="password"
                    maxLength="50"
                    placeholder="Enter password.."
                    onChange={(event) => setPassword(event.currentTarget.value)}
                    value={password}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-sm-left">
                  Confirm Password
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type="password"
                    className={`${
                      rePassword.length
                        ? password === rePassword
                          ? "border-success"
                          : "border-danger"
                        : ""
                    }`}
                    maxLength="50"
                    placeholder="Confirm password.."
                    onChange={(event) =>
                      setRePassword(event.currentTarget.value)
                    }
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between align-items-center">
          <Button color={"secondary"} onClick={onBack}>
            Back
          </Button>{" "}
          <Button
            disabled={password !== rePassword}
            color={"primary"}
            type="submit"
          >
            Next
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const RelationsModal = () => {
  const [onBoardingFlow, setOnBoardingFlow] = useOnBoardingFlow();
  const [advisements, setAdvisements] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onBoardApi
      .getAdvisements({
        token: onBoardingFlow.token,
        [onBoardingFlow.userRole === USER_ROLE_INVESTOR
          ? "investorId"
          : "advisorId"]: onBoardingFlow.id,
        pageSize: 999,
      })
      .then((result) => {
        if (!result.data.length) {
          return setOnBoardingFlow({
            step: CONDITIONS_STEP,
            removedAdvisements: [],
          });
        }
        setAdvisements(result.data);
        setLoading(false);
      });
  }, [
    setOnBoardingFlow,
    onBoardingFlow.token,
    onBoardingFlow.id,
    onBoardingFlow.userRole,
  ]);

  const onRemove = (index) => {
    advisements[index].removed = true;
    setAdvisements([...advisements]);
  };

  const onUndo = (index) => {
    delete advisements[index].removed;
    setAdvisements([...advisements]);
  };

  const onNext = () => {
    const removedAdvisements = advisements.filter((a) => a.removed);
    setOnBoardingFlow({ step: CONDITIONS_STEP, removedAdvisements });
  };

  const advisementKey =
    onBoardingFlow.userRole === USER_ROLE_INVESTOR ? "advisor" : "investor";

  return (
    <Modal isOpen={true} size={"lg"}>
      <ModalHeader>Setup Account</ModalHeader>
      <ModalBody className="pb-0">
        <h5 className="mb-3 text-center text-body text-underline text-capitalize">{`${advisementKey}s`}</h5>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <>
            {onBoardingFlow.sponsor ? (
              <div className="text-center mb-3">
                <div
                  className="rounded p-0 col-10"
                  style={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${onBoardingFlow.sponsor.webLogo})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                    alignSelf: "center",
                    height: "150px",
                    width: "80%",
                    margin: "0 auto",
                  }}
                />
              </div>
            ) : null}
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {advisements.map((advisement, i) => (
                  <tr key={i}>
                    <td>{advisement[advisementKey]?.name}</td>
                    <td>{advisement[advisementKey]?.email}</td>
                    <td>{advisement[advisementKey]?.address || "-"}</td>
                    <td>{advisement[advisementKey]?.phone || "-"}</td>
                    <td className="table-action text-center">
                      {advisement?.removed ? (
                        <FontAwesomeIcon
                          icon={faTrashRestoreAlt}
                          onClick={() => onUndo(i)}
                          fixedWidth
                          className="align-middle mr-1 text-success cursor-pointer"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={() => onRemove(i)}
                          fixedWidth
                          className="align-middle mr-1 text-danger cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between align-items-center">
        <Button
          color={"secondary"}
          onClick={() => setOnBoardingFlow({ step: PASSWORD_STEP })}
        >
          Back
        </Button>{" "}
        <Button color={"primary"} onClick={onNext}>
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ConditionsModal = () => {
  const [onBoardingFlow, setOnBoardingFlow] = useOnBoardingFlow();
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [dontProvideAccessToAdvisors, setDontProvideAccessToAdvisors] =
    useState(false);
  const [twoFaModal, setTwoFaModal] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onComplete = () => {
    setLoading(true);
    let promises = onBoardingFlow.removedAdvisements.map((advisement) =>
      onBoardApi.removeAdvisement({
        token: onBoardingFlow.token,
        id: advisement.id,
      })
    );
    promises.push(
      onBoardApi.updateUser({
        enabled2FA: true,
        token: onBoardingFlow.token,
        id: onBoardingFlow.id,
        completeOnBoard: true,
        dontProvideAccessToAdvisors,
        activationToken: null,
      })
    );
    Promise.all(promises).then(() => {
      setLoading(false);
      setOnBoardingFlow({ step: COMPLETE_STEP });
    });
  };

  return twoFaModal ? (
    <Enable2FAModal loading={loading} onSubmit={onComplete} />
  ) : informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader>Terms & Conditions</ModalHeader>
      <ModalBody>
        {onBoardingFlow.sponsor ? (
          <div className="text-center mb-3">
            <div
              className="rounded p-0 col-10"
              style={{
                backgroundPosition: "center",
                backgroundImage: `url(${onBoardingFlow.sponsor.webLogo})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
                alignSelf: "center",
                height: "150px",
                width: "80%",
                margin: "0 auto",
              }}
            />
          </div>
        ) : null}
        <TermsConditions />
      </ModalBody>
      <ModalFooter className="justify-content-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <>
            <div className="my-3 d-flex justify-content-between align-items-center col-12 px-0">
              <div>
                <CustomInput
                  type="checkbox"
                  id="accessCheckbox"
                  label="Do not provide Investment Advisors access to my data"
                  className="pr-0 cursor-pointer text-muted small accessCheckbox mb-2"
                  value={dontProvideAccessToAdvisors}
                  onChange={(event) =>
                    setDontProvideAccessToAdvisors(event.currentTarget.checked)
                  }
                />
              </div>
              {!accepted ? (
                <Button
                  size="sm"
                  color={"success"}
                  onClick={() => setAccepted(true)}
                >
                  Accept Terms & Conditions
                </Button>
              ) : (
                <Button
                  size="sm"
                  color={"danger"}
                  onClick={() => setAccepted(false)}
                >
                  Decline Terms & Conditions
                </Button>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center col-12 px-0">
              <Button
                color={"secondary"}
                onClick={() => setOnBoardingFlow({ step: RELATIONS_STEP })}
              >
                Back
              </Button>{" "}
              <Button
                disabled={!accepted}
                color={"primary"}
                onClick={() => setTwoFaModal(true)}
              >
                Next
              </Button>
            </div>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

const CompleteModal = () => {
  const [onBoardingFlow] = useOnBoardingFlow();
  const navigate = useNavigate();

  return (
    <Modal isOpen={true}>
      <ModalHeader>On Board Complete!</ModalHeader>
      <ModalBody className="text-center">
        {onBoardingFlow.sponsor ? (
          <div className="text-center mb-3">
            <div
              className="rounded p-0 col-10"
              style={{
                backgroundPosition: "center",
                backgroundImage: `url(${onBoardingFlow.sponsor.webLogo})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
                alignSelf: "center",
                height: "150px",
                width: "80%",
                margin: "0 auto",
              }}
            />
          </div>
        ) : null}
        <Button
          color={"success"}
          onClick={() =>
            navigate(
              onBoardingFlow.sponsor
                ? `/auth/sign-in/${onBoardingFlow.sponsor.name
                    .replaceAll(" ", "_")
                    .toLowerCase()}`
                : "/auth/sign-in"
            )
          }
        >
          Sign-in here!
        </Button>
      </ModalBody>
    </Modal>
  );
};

const OnBoardingModal = () => {
  const params = useParams();
  const [onBoardingFlow, setOnBoardingFlow] = useOnBoardingFlow();

  const setOnBoardingFlowCb = useCallback(
    (data) => setOnBoardingFlow(data),
    [setOnBoardingFlow]
  );

  useEffect(() => {
    setOnBoardingFlowCb({ token: params.token });
  }, [params.token, setOnBoardingFlowCb]);

  switch (onBoardingFlow.step) {
    case EMAIL_STEP:
      return <EmailModal />;
    case PASSWORD_STEP:
      return <PasswordModal />;
    case RELATIONS_STEP:
      return <RelationsModal />;
    case CONDITIONS_STEP:
      return <ConditionsModal />;
    case COMPLETE_STEP:
      return <CompleteModal />;
    default:
      return <Navigate to={"/auth/sign-in"} />;
  }
};

const OnBoarding = () => (
  <React.Fragment>
    <Wrapper>
      <Main>
        <Content>
          <Container fluid>
            <Header>
              <HeaderTitle>Welcome!</HeaderTitle>
            </Header>
            <Row>
              <Col>
                <Card>
                  <CardBody className="min-vh-25">
                    <OnBoardingModal />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Content>
        <Footer />
      </Main>
    </Wrapper>
  </React.Fragment>
);

export default OnBoarding;

import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Col,
  Input,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import Loader from "../../Loader";
import { programsApi } from "../../../services/programServices";
import {
  useProgramEmail,
  initialState,
} from "../../../providers/programEmailProvider";
import { utils } from "../../../utils/utils";
import { settingsApi } from "../../../services/settingServices";

import RichTextEditor from "../../RichTextEditor";
import InformationModal from "../../InformationModal";

const DEFAULT_MODE = 1;
const PREVIEW_MODE = 2;
const SUCCESS_MODE = 3;
const SUCCESS_MODE_TEST = 4;

const DefaultMode = ({ program, onClose, sendEmailAll }) => {
  const [programEmail, setProgramEmail] = useProgramEmail();
  const programName = sendEmailAll
    ? `All ${program[0].year} Programs`
    : program.name;
  const onSubmit = (event) => {
    event.preventDefault();
    setProgramEmail({ mode: PREVIEW_MODE });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <Form onSubmit={onSubmit}>
        <ModalHeader close={closeBtn}>Send email to {programName}</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Subject</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Col sm={8}>
              <Input
                required
                type="text"
                maxLength="50"
                placeholder="Enter the subject.."
                onChange={(event) =>
                  setProgramEmail({ subject: event.currentTarget.value })
                }
                value={programEmail.subject}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Reply To</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Col sm={8}>
              <Input
                required={!sendEmailAll}
                type="email"
                maxLength="50"
                placeholder="Enter the reply email.."
                onChange={(event) =>
                  setProgramEmail({ replyTo: event.currentTarget.value })
                }
                value={programEmail.replyTo}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Attachment</span>
            </Label>
            <Col sm={8}>
              <Input
                type="file"
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                placeholder="Select pdf or doc file.."
                onChange={(event) =>
                  setProgramEmail({ attachment: event.currentTarget.files[0] })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} className="text-sm">
              You can use the variable {"{{userName}}"} to be replaced by the
              name of the target user.
              <br />
              You can use the variable {"{{programName}}"} to be replaced by the
              name of the current program.
              <br />
              You can use the variable {"{{sponsorName}}"} to be replaced by the
              name of the sponsor of the current program.
            </Label>
          </FormGroup>
          <div className="border rounded flex-grow-1">
            <RichTextEditor
              value={programEmail.content}
              onChange={(content) => setProgramEmail({ content })}
              id="program-email"
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button
            color={"primary"}
            disabled={
              !programEmail.subject?.length ||
              !programEmail.content?.length ||
              (sendEmailAll ? false : !programEmail.replyTo?.length)
            }
            type="submit"
          >
            Next
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const PreviewMode = ({ program, sendEmailAll }) => {
  const [loading, setLoading] = useState(false);
  const [programEmail, setProgramEmail] = useProgramEmail();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const programName = program.length ? program[0].name : program.name;
  const sponsorName = program.length
    ? program[0].sponsor?.name
    : program.sponsor?.name;

  const emailContent = programEmail.content
    .replaceAll("{{programName}}", programName)
    .replaceAll("{{sponsorName}}", sponsorName || "Green Rock")
    .replaceAll("<p>", '<p style="margin-top:0; margin-bottom:0;">');

  const onSendTestEmail = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const email = { ...programEmail };
    try {
      await settingsApi.sendTestEmail({ ...email, to: email.testEmailTo });
      setInformationModal({
        isOpen: true,
        title: "Email Preview",
        body: "Test email sent successfully.",
        onClose: () => {
          setInformationModal({
            isOpen: false,
            title: "",
            body: "",
            onClose: null,
          });
          setProgramEmail({ testEmailTo: null });
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSend = async () => {
    setLoading(true);
    const email = { ...programEmail };
    try {
      const result = await (sendEmailAll
        ? programsApi.sendEmailAll
        : programsApi.sendEmail)({
        ...email,
        id: programEmail.id,
        programs: program,
      });
      setProgramEmail({ mode: SUCCESS_MODE, result });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader>Email Preview</ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <div
            className="word-break-all"
            dangerouslySetInnerHTML={{ __html: emailContent }}
          ></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color={"secondary"}
          onClick={() => setProgramEmail({ mode: DEFAULT_MODE })}
        >
          Back
        </Button>{" "}
        <Form onSubmit={onSendTestEmail} className="d-flex">
          <InputGroup className="container-input-button col-12 px-0">
            <InputGroupAddon addonType="prepend">
              <Button
                disabled={!utils.emailIsValid(programEmail.testEmailTo)}
                type="submit"
                color={"info"}
              >
                Send Test Email
              </Button>
            </InputGroupAddon>
            <Input
              type="email"
              placeholder="Test email to.."
              onChange={(event) =>
                setProgramEmail({ testEmailTo: event.currentTarget.value })
              }
              value={programEmail.testEmailTo || ""}
            />
          </InputGroup>
        </Form>
        <Button
          color={"primary"}
          disabled={!!programEmail.testEmailTo}
          onClick={onSend}
        >
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const SuccessMode = ({ onClose }) => {
  return (
    <Modal isOpen={true}>
      <ModalHeader>Alright!</ModalHeader>
      <ModalBody className="text-center">
        <p className="text-success mb-0">Emails sent successfully!</p>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        {onClose ? (
          <Button color={"primary"} onClick={onClose}>
            Close
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

const Content = ({ program, onClose, sendEmailAll }) => {
  const [programEmail] = useProgramEmail();
  switch (programEmail.mode) {
    case DEFAULT_MODE:
      return (
        <DefaultMode
          program={program}
          onClose={onClose}
          sendEmailAll={sendEmailAll}
        />
      );
    case PREVIEW_MODE:
      return <PreviewMode program={program} sendEmailAll={sendEmailAll} />;
    case SUCCESS_MODE:
      return <SuccessMode onClose={onClose} />;
    case SUCCESS_MODE_TEST:
      return <SuccessMode />;
    default:
      return <DefaultMode onClose={onClose} />;
  }
};

const ProgramEmailModal = ({ program, onClose }) => {
  const [sendEmailAll] = useState(Array.isArray(program));
  const [, setProgramEmail] = useProgramEmail();
  const closeEmailModal = () => {
    setProgramEmail(initialState);
    onClose();
  };
  return (
    <Content
      program={program}
      onClose={closeEmailModal}
      sendEmailAll={sendEmailAll}
    />
  );
};

export default ProgramEmailModal;

import React, { memo, useCallback, useEffect, useState } from "react";

import { Button, Col, Row, Form, FormGroup, Label, ListGroupItem } from "reactstrap";
import { usePrograms } from "../../../providers/programsProvider";
import { fundsApi } from "../../../services/fundServices";
import BaseInput from "../../Forms/BaseInput";
import DateInput from "../../Forms/DateInput";
import Loader from "../../Loader";

const TYPE_INCREASE = "1";
const TYPE_DECREASE = "2";

const initialTransactionState = {
  amount: "",
  date: new Date(),
  fundId: "",
  type: TYPE_INCREASE,
};

const CreateHedgeFundTransaction = ({
  onRefresh,
  onFundSelected,
  availableAmountToInvest = 0,
  availableAmountToWithdraw = {},
}) => {
  const [programsContext, setProgramContext] = usePrograms();
  const { program } = programsContext;

  const [transaction, setTransaction] = useState(initialTransactionState);
  const [loading, setLoading] = useState();

  const [availableFunds, setAvailableFunds] = useState([]);

  useEffect(() => {
    fundsApi.getFunds({ isPrivateEquity: true }).then((funds) => {
      setAvailableFunds(funds.data);
    });
  }, [program.programFundInvestments]);

  const handleCreateTransaction = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        setLoading(true);
        const createdFundInvestment = await fundsApi.createFundInvestment({
          ...transaction,
          programId: program.id,
        });
        setLoading(false);
        const newProgram = { ...program };
        newProgram.programFundInvestments.push(createdFundInvestment);
        setProgramContext(newProgram);
        setTransaction(initialTransactionState);
        onFundSelected("");
        onRefresh();
      } catch (err) {
        console.log(err);
      }
    },
    [transaction, program, setProgramContext, onFundSelected, onRefresh]
  );

  return (
    <>
      <ListGroupItem
        className="pb-2 font-weight-bold border-bottom-0"
        tag="div"
      >
        Add Transaction
      </ListGroupItem>
      <ListGroupItem>
        <Form onSubmit={handleCreateTransaction}>
          <BaseInput
            label="Hedge Fund"
            type="select"
            options={[
              { value: "", label: "Select Investment Fund" },
              ...availableFunds.map((fund) => ({
                value: fund.id,
                label: fund.name,
              })),
            ]}
            value={transaction.fundId}
            onChange={(event) => {
              onFundSelected(event.currentTarget.value);
              setTransaction({
                ...transaction,
                fundId: event.currentTarget.value,
              });
            }}
          />
          <BaseInput
            label="Transaction Type"
            type="select"
            options={[
              { value: TYPE_INCREASE, label: "Add Funds" },
              { value: TYPE_DECREASE, label: "Withdraw Funds" },
            ]}
            value={transaction.type}
            onChange={(event) => {
              const totalAvailableAmountToWithdraw = Object.values(
                availableAmountToWithdraw
              ).reduce((p, c) => p + c, 0);
              setTransaction({
                ...transaction,
                amount:
                  event.currentTarget.value === TYPE_INCREASE
                    ? Math.min(transaction.amount, availableAmountToInvest)
                    : Math.min(
                        transaction.amount,
                        totalAvailableAmountToWithdraw
                      ),
                type: event.currentTarget.value,
              });
            }}
          />
          <BaseInput
            label="Amount"
            type="currency"
            placeholder="Amount"
            value={transaction.amount}
            onChange={(value) => {
              const totalAvailableAmountToWithdraw = Object.values(
                availableAmountToWithdraw
              ).reduce((p, c) => p + c, 0);
              const maxValue =
                transaction.type === TYPE_INCREASE
                  ? availableAmountToInvest
                  : totalAvailableAmountToWithdraw;
              Number(value) <= maxValue
                ? setTransaction({
                    ...transaction,
                    amount: value,
                  })
                : setTransaction({
                    ...transaction,
                    amount: maxValue,
                  });
            }}
          />
          <FormGroup row className="mt-3">
            <Label sm={5} className="text-sm-left">
              <span>Date</span>
              <span className="ml-2 text-danger">*</span>
            </Label>
            <Col
              sm={7}
              className="d-flex flex-column align-items-baseline justify-content-center"
            >
              <DateInput
                value={transaction.date}
                onChange={(date) =>
                  setTransaction({
                    ...transaction,
                    date,
                  })
                }
              />
            </Col>
          </FormGroup>
          <Row className="d-flex justify-content-end">
            {loading ? (
              <div className="min-width-50">
                <Loader size="sm" />
              </div>
            ) : (
              <Button
                type="submit"
                color="primary"
                size="sm"
                className="rounded"
              >
                Add Transaction
              </Button>
            )}
          </Row>
        </Form>
      </ListGroupItem>
    </>
  );
};

export default memo(CreateHedgeFundTransaction);

import React, { useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  UncontrolledAlert,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import Loader from "../../Loader";
import { programsApi } from "../../../services/programServices";
import { useProgramStatements } from "../../../providers/programStatementsProvider";
import EmailModal from "../EmailModal";
import {
  useEmailContext,
  emailInitialState,
} from "../../../providers/emailProvider";
import { pdfjs, Document, Page } from "react-pdf";
import {
  faBell,
  faChevronLeft,
  faChevronRight,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download from "downloadjs";
import { useAuth } from "../../../providers/authProvider";
import CurrencyInput from "react-currency-input-field";
import RichTextEditor from "../../RichTextEditor";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_VALUE = 999999999;

const INITIAL_MODE = 1;
const LETTER_MODE = 2;
const DATA_MODE = 3;
const PREGENERATION_MODE = 4;
const GENERATION_MODE = 5;
const PREVIEW_MODE = 6;
const PREUPLOAD_MODE = 7;
const UPLOAD_MODE = 8;
const SUCCESS_MODE = 9;

const LetterMode = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [authContext] = useAuth();
  const [programStatements, setProgramStatements, onStatementsGeneration] =
    useProgramStatements();
  const letterRef = useRef();

  const onSubmit = (event) => {
    event.preventDefault();
    if (programStatements.letter?.length / 1000 / 1000 > 1) {
      return setError(true);
    }
    setLoading(true);
    setProgramStatements({ mode: PREGENERATION_MODE });
    onStatementsGeneration(authContext.currentUser.socketToken, () => {
      programsApi.generateStatements({
        id: programStatements.program.id,
        letter: programStatements.letter,
        auditReserveAccruedInterest:
          programStatements.auditReserveAccruedInterest,
        socketToken: authContext.currentUser.socketToken,
      });
    });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setProgramStatements({ mode: DATA_MODE })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="lg">
      {loading ? (
        <>
          <ModalHeader close={closeBtn}>
            Send {programStatements.program.name} Statements
          </ModalHeader>
          <div className="d-flex flex-column justify-content-center align-items-middle py-2">
            <Loader size="sm" />
          </div>
          <ModalFooter className="justify-content-between">
            <Button
              color={"secondary"}
              onClick={() => setProgramStatements({ mode: DATA_MODE })}
            >
              Cancel
            </Button>
          </ModalFooter>
        </>
      ) : (
        <Form onSubmit={onSubmit}>
          <ModalHeader>
            Send {programStatements.program.name} Statements
          </ModalHeader>
          <ModalBody>
            <p>
              Please enter the letter from CEO to include in the Statements:
            </p>
            <UncontrolledAlert className="my-2" isOpen={error} color="warning">
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>
                  The size of the letter is too big. Please make sure all the
                  content together don't exceed 1 MB.
                </span>
              </div>
            </UncontrolledAlert>
            <div className="border rounded flex-grow-1">
              <RichTextEditor
                value={programStatements.letter}
                onChange={(letter) =>
                  setProgramStatements({
                    ...programStatements,
                    letter,
                  })
                }
                name="letter"
                id="letter"
                ref={letterRef}
                required
              />
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button
              color={"secondary"}
              onClick={() => setProgramStatements({ mode: DATA_MODE })}
            >
              Back
            </Button>{" "}
            <Button color={"primary"} type="submit">
              Next
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

const PreviewMode = () => {
  const [loading, setLoading] = useState(false);
  const [authContext] = useAuth();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [programStatements, setProgramStatements, , onStatementsUpload] =
    useProgramStatements();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [emailContext, setEmailContext] = useEmailContext();
  const [emailModal, setEmailModal] = useState(emailInitialState);

  const onNext = () => {
    const defaultSubject = `${programStatements.program.name} Quarterly Statement`;
    if (emailContext?.subject !== defaultSubject) {
      setEmailContext({
        name: programStatements.program.name,
        subject: defaultSubject,
        content: `
                    <p>Dear Member,</p>
                    <p>Attached is a copy of the quarterly statement related to your investment in ${programStatements.program.name}. You will receive a copy of this statement along with your distribution in the mail over next couple weeks.</p>
                    <p>Please feel free to contact us if you have any questions.</p>
                    <p>Sincerely,<br/>
                    <strong>Vulcan Fund Solutions Team</strong><br/>
                    2015 3rd Avenue North <br/>
                    Birmingham, AL 35203</p>
              `,
      });
    }
    setEmailModal({
      isOpen: true,
    });
  };

  const onSubmitEmail = (emailContext) => {
    setLoading(true);
    setProgramStatements({
      mode: PREUPLOAD_MODE,
      remaining: 0,
      total: 0,
      socket: null,
    });
    onStatementsUpload(authContext.currentUser.socketToken, () => {
      programsApi.sendStatements({
        socketToken: authContext.currentUser.socketToken,
        letter: programStatements.letter,
        auditReserveAccruedInterest:
          programStatements.auditReserveAccruedInterest,
        fundQuarterId: programStatements.quarter.id,
        id: programStatements.program.id,
        statements: programStatements.statements,
        subject: emailContext.subject,
        replyTo: emailContext.replyTo,
        content: emailContext.content,
      });
      setEmailModal(emailInitialState);
    });
  };

  const onDownload = () => {
    setDownloadLoading(true);
    programsApi
      .downloadStatements({ id: programStatements.program.id })
      .then((result) => {
        download(result, "attachement.zip", "application/zip"); //this is third party it will prompt download window in browser.
        setDownloadLoading(false);
        return result;
      });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setProgramStatements({ mode: LETTER_MODE })}
    >
      &times;
    </Button>
  );

  return emailModal.isOpen ? (
    <EmailModal
      onClose={() => {
        setProgramStatements({ mode: SUCCESS_MODE });
        setEmailModal(emailInitialState);
      }}
      onSubmit={onSubmitEmail}
      cancelBtnText="Back"
      sendBtnText="Upload & Send"
    />
  ) : (
    <Modal isOpen={true} size="md" className="statements-modal">
      <ModalHeader close={closeBtn}>
        <span>Statement Preview</span>
        {downloadLoading ? (
          <div className="col-4">
            <Loader size="sm" align="end" />
          </div>
        ) : (
          <Button
            className="font-size-75"
            disabled={loading}
            color={"info"}
            onClick={onDownload}
          >
            <FontAwesomeIcon icon={faFileDownload} />
            <span className="ml-1">Download All Statements</span>
          </Button>
        )}
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="col-12 text-center px-0">
          {loading ? (
            <div className="d-flex flex-column justify-content-center align-items-middle py-2">
              <Loader size="sm" />
              <span className="text-center mt-2">Sending Statements..</span>
            </div>
          ) : (
            <>
              <Document
                file={programStatements.previewFile}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <div className="d-flex justify-content-between p-3 align-items-center text-body">
                <FontAwesomeIcon
                  className="cursor-pointer text-body"
                  icon={faChevronLeft}
                  onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                />
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <FontAwesomeIcon
                  className="cursor-pointer text-body"
                  icon={faChevronRight}
                  onClick={() =>
                    setPageNumber(Math.min(pageNumber + 1, numPages))
                  }
                />
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          disabled={loading}
          color={"secondary"}
          onClick={() => setProgramStatements({ mode: LETTER_MODE })}
        >
          Back
        </Button>{" "}
        <Button disabled={loading} color={"primary"} onClick={onNext}>
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const PreProgressMode = ({ onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>Alright!</ModalHeader>
      <ModalBody>
        <p>
          Statements are being generated, this will take some time depending on
          the amount of Investors.
        </p>
        <span>
          Once done, you can continue with the flow by clicking the "Preview"
          button above the progress bar
        </span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Got it!
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const InitialMode = ({ onClose }) => {
  const [programStatements, setProgramStatements] = useProgramStatements();
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>
        Send {programStatements.program.name} Statements
      </ModalHeader>
      <ModalBody>
        <p>
          You selected to send the program statements for{" "}
          {programStatements.quarter ? (
            <>
              <strong className="text-warning">{`Q${programStatements.quarter.quarter}-${programStatements.quarter.year}`}</strong>{" "}
              to investors/stakeholders.
            </>
          ) : (
            programStatements.program.name
          )}
        </p>
        <span>
          They will receive an email containing the program statement with the
          latest data entered.
        </span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button
          color={"primary"}
          type="submit"
          onClick={() => setProgramStatements({ mode: DATA_MODE })}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const DataMode = () => {
  const [programStatements, setProgramStatements] = useProgramStatements();

  const onSubmit = (event) => {
    event.preventDefault();
    setProgramStatements({ mode: LETTER_MODE });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setProgramStatements({ mode: INITIAL_MODE })}
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="md">
      <Form onSubmit={onSubmit}>
        <ModalHeader close={closeBtn}>
          Send {programStatements.program.name} Statements
        </ModalHeader>
        <ModalBody>
          <FormGroup row className="mb-0">
            <Label sm={6} className="text-sm-left">
              Audit Reserve Account Balance
            </Label>
            <Col sm={6}>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                required={true}
                allowNegativeValue={false}
                maxLength={20}
                className="form-control"
                placeholder="Enter the amount.."
                value={programStatements.auditReserveAccruedInterest}
                onValueChange={(value) => {
                  if (!value || value < MAX_VALUE) {
                    setProgramStatements({
                      auditReserveAccruedInterest: value,
                    });
                  }
                }}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            color={"secondary"}
            onClick={() => setProgramStatements({ mode: INITIAL_MODE })}
          >
            Back
          </Button>{" "}
          <Button
            color={"primary"}
            type="submit"
            onClick={() => setProgramStatements({ mode: LETTER_MODE })}
          >
            Next
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const PreUploadMode = ({ onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>Alright!</ModalHeader>
      <ModalBody>
        <p>
          Statements are being uploaded, this will take some time depending on
          the amount of Investors.
        </p>
        <span>
          Once done, they will be sent to the Investors automatically.
        </span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Got it!
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const SuccessMode = ({ onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} size="md">
      <ModalHeader close={closeBtn}>Alright!</ModalHeader>
      <ModalBody>
        <span>Statements sent successfully.</span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Content = ({ onClose }) => {
  const [programStatements, setProgramStatements] = useProgramStatements();
  switch (programStatements.mode) {
    case INITIAL_MODE:
      return <InitialMode onClose={onClose} />;
    case DATA_MODE:
      return <DataMode />;
    case LETTER_MODE:
      return <LetterMode />;
    case PREGENERATION_MODE:
      return (
        <PreProgressMode
          onClose={() => setProgramStatements({ mode: GENERATION_MODE })}
        />
      );
    case PREVIEW_MODE:
      return <PreviewMode />;
    case PREUPLOAD_MODE:
      return (
        <PreUploadMode
          onClose={() => setProgramStatements({ mode: UPLOAD_MODE })}
        />
      );
    case SUCCESS_MODE:
      return <SuccessMode onClose={onClose} />;
    default:
      return null;
  }
};

const ProgramStatementsModal = ({ onClose }) => <Content onClose={onClose} />;

export default ProgramStatementsModal;

import React, { useState, useCallback, useEffect } from "react";

import { Col, Row, CustomInput } from "reactstrap";

import { fundsApi } from "../../../../services/fundServices";
import { useFundTransactionModal } from "../../../../providers/fundTransactionModalProvider";
import { useFundContext } from "../../../../providers/fundsProvider";
import Loader from "../../../Loader";
import FundQuarterTransactions from "./quarters/FundQuarterTransactions";
import FundQuarterTransactionModal from "./quarters/fundQuarterTransactionModal/FundQuarterTransactionModal";

const MAX_PAGE_SIZE = 999;

const FundQuartersByYear = () => {
  const [fundContext, setFundContext] = useFundContext();
  const [fundTransactionModal] = useFundTransactionModal();
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);

  const setFundContextCb = useCallback(
    (data) => {
      setFundContext(data);
    },
    [setFundContext]
  );

  useEffect(() => {
    setLoading(true);
    fundsApi
      .getAllFundQuarters({
        fundId: fundContext.fund.id,
        pageSize: MAX_PAGE_SIZE,
      })
      .then((result) => {
        const fundQuarters = result.data;
        const activeFundQuarter = fundQuarters.find(
          (quarter) => quarter.active
        );
        const years = Object.keys(
          fundQuarters.reduce((p, c) => {
            p[c.year] = true;
            return p;
          }, {})
        ).sort((x, y) => x - y);
        setYears(years);
        setFundContextCb({ fundQuarters, year: activeFundQuarter?.year });
        setLoading(false);
      });
  }, [setFundContextCb, fundContext.fund]);

  return loading ? (
    <Loader size="sm" />
  ) : (
    <Col className="mb-4 mx-0 px-0">
      <Row className="mx-0 mb-3">
        <h3 className="mb-0 d-flex align-items-center justify-content-between col-12 px-0">
          <span>Fiscal Year Transactions</span>
          <div className="mt-n2">
            <CustomInput
              id="quarterSelect"
              type="select"
              name="quarterSelect"
              onChange={(event) =>
                setFundContext({ year: parseInt(event.currentTarget.value) })
              }
              value={fundContext.year}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </CustomInput>
          </div>
        </h3>
      </Row>
      {(fundContext.fundQuarters || [])
        .filter((fundQuarter) => fundQuarter.year === fundContext.year)
        .sort((x, y) => x.quarter - y.quarter)
        .map((fundQuarter, index) => (
          <FundQuarterTransactions key={index} fundQuarter={fundQuarter} />
        ))}
      {fundTransactionModal.isModalOpen ? (
        <FundQuarterTransactionModal />
      ) : null}
    </Col>
  );
};

export default FundQuartersByYear;

import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  mode: DEFAULT_MODE,
  statuses: [],
  isModalOpen: false,
};

const EditProgramStatusModalContext = createContext(initialState);

const EditProgramStatusModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <EditProgramStatusModalContext.Provider value={stateAndDispatch}>
      {children}
    </EditProgramStatusModalContext.Provider>
  );
};

export const useEditProgramStatusModal = () =>
  useContext(EditProgramStatusModalContext);

export {
  EditProgramStatusModalContext,
  EditProgramStatusModalProvider,
  initialState,
};

import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  Row,
  InputGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { useStakeholders } from "../../providers/stakeholdersProvider";
import {
  faEllipsisV,
  faMailBulk,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usersApi } from "../../services/userServices";
import Loader from "../../components/Loader";
import { authApi } from "../../services/authServices";
import { useAuth } from "../../providers/authProvider";
import InformationModal from "../../components/InformationModal";
import {
  useUserEmail,
  initialState as userEmailInitialState,
} from "../../providers/userEmailProvider";
import UserEmailModal from "../../components/admin/UserEmailModal";
import InvestorModalDetails from "../../components/admin/InvestorDetailsModal";
import { utils } from "../../utils/utils";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../utils/roles";
import Badge from "reactstrap/lib/Badge";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";

const states = require("../../assets/states.json");

const USER_STATUS_ACTIVE = "3";
const USER_ROLE_STAKEHOLDER = 4;
const MAX_PAGE_SIZE = 100;

const TABLE_COLUMNS = [
  {
    accessor: "firstName",
    Header: "First Name",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { firstName } = rowData.row.original;
      return firstName || "-";
    },
  },
  {
    accessor: "lastName",
    Header: "Last Name",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { lastName } = rowData.row.original;
      return lastName || "-";
    },
  },
  {
    accessor: "email",
    Header: "Email",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { email } = rowData.row.original;
      return email || "-";
    },
  },
  {
    accessor: "state",
    Header: "State",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { state } = rowData.row.original;
      return states.find((item) => item.abbreviation === state)?.name || "-";
    },
  },
  {
    accessor: "city",
    Header: "City",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { city } = rowData.row.original;
      return city || "-";
    },
  },
  {
    accessor: "zipcode",
    Header: "Zip",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { zipcode } = rowData.row.original;
      return zipcode || "-";
    },
  },
  {
    accessor: "address",
    Header: "Address",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { address } = rowData.row.original;
      return address || "-";
    },
  },
  {
    accessor: "phone",
    Header: "Phone",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { phone } = rowData.row.original;
      return phone || "-";
    },
  },
  {
    accessor: "lastActivity",
    Header: "Activity",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { lastActivity } = rowData.row.original;
      return lastActivity ? (
        utils.formatDate(lastActivity, "MM/DD/YYYY HH:mm")
      ) : (
        <Badge color={"warning"}>No Activity</Badge>
      );
    },
  },
];

const Stakeholders = () => {
  const [authContext, setAuthContext] = useAuth();
  const [stakeholdersContext, setStakeholdersContext] = useStakeholders();
  const [refresh, setRefresh] = useState();
  const [stakeholders, setStakeholders] = useState({});
  const [selectedStakeholder, setSelectedStakeholder] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(true);
  const [loading, setLoading] = useState({});
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [userEmailContext, setUserEmailContext] = useUserEmail();

  const user = authContext.currentUser;

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const actions = (stakeholder, loading) => {
    return loading[stakeholder.id] ? (
      <div className="min-width-50">
        <Loader size="sm" align="end" />
      </div>
    ) : (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle color="white">
          <FontAwesomeIcon className="text-primary" icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => setSelectedStakeholder(stakeholder)}>
            See Details
          </DropdownItem>
          <DropdownItem onClick={() => loginAs(stakeholder)}>
            See Stakeholder Portal
          </DropdownItem>
          <DropdownItem onClick={() => sendEmail(stakeholder)}>
            Send Email
          </DropdownItem>
          <DropdownItem onClick={() => resendInvite(stakeholder)}>
            Resend Invite
          </DropdownItem>
          <DropdownItem onClick={() => resetPassword(stakeholder)}>
            Password Reset
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const onSort = useCallback(
    ([data]) => {
      if (data && data.id) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          stakeholdersContext.sortBy === sortBy?.id &&
          stakeholdersContext.direction === direction
        ) {
          return;
        }
        setStakeholdersContext({ sortBy, direction });
      } else {
        setStakeholdersContext({
          sortBy: null,
          direction: null,
        });
      }
    },
    [
      stakeholdersContext.direction,
      stakeholdersContext.sortBy,
      setStakeholdersContext,
    ]
  );

  useEffect(() => {
    const hasCSRole = utils.userHasRole(user, CUSTOMER_SUPPORT);
    const hasSponsorRole = utils.userHasRole(user, SPONSOR);
    setGeneralLoading(true);
    usersApi
      .getUsers(
        {
          role: USER_ROLE_STAKEHOLDER,
          search: stakeholdersContext.search,
          page: stakeholdersContext.page - 1,
          pageSize: getSizePerPage(stakeholdersContext.sizePerPage),
          sortBy: stakeholdersContext.sortBy,
          direction: stakeholdersContext.direction,
        },
        hasCSRole ? "customer-support" : hasSponsorRole ? "sponsor" : "admin"
      )
      .then((result) => {
        setStakeholders(result);
        setGeneralLoading(false);
      });
  }, [
    refresh,
    stakeholdersContext.search,
    stakeholdersContext.page,
    stakeholdersContext.sizePerPage,
    stakeholdersContext.sortBy,
    stakeholdersContext.direction,
    user,
  ]);

  const loginAs = (stakeholder) => {
    setLoading({ [stakeholder.id]: true });
    const hasCSRole = utils.userHasRole(user, CUSTOMER_SUPPORT);
    const hasSponsorRole = utils.userHasRole(user, SPONSOR);
    authApi
      .loginAs(
        { userId: stakeholder.id },
        hasCSRole ? "customer-support" : hasSponsorRole ? "sponsor" : "admin"
      )
      .then((result) => {
        setAuthContext({ currentUser: { ...result } });
        setLoading({ [stakeholder.id]: false });
        window.open(
          `${window.location.origin}/${
            utils.userHasRole(stakeholder, "advisor")
              ? "investor-selector"
              : "portal"
          }`,
          "_blank"
        );
      });
  };

  const resendInvite = (stakeholder) => {
    if (stakeholder.status === USER_STATUS_ACTIVE) {
      return setInformationModal({
        isOpen: true,
        title: "Heads up!",
        body: "User is already active.",
      });
    }
    setLoading({ [stakeholder.id]: true });
    usersApi
      .resendInvite({ id: stakeholder.id })
      .then(() => {
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "Invite resent successfully.",
        });
        setLoading({ [stakeholder.id]: false });
      })
      .catch((err) => {
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body: "There was an error sending email.",
        });
        setLoading({ [stakeholder.id]: false });
      });
  };

  const resetPassword = (stakeholder) => {
    if (stakeholder.status !== USER_STATUS_ACTIVE) {
      return setInformationModal({
        isOpen: true,
        title: "Heads up!",
        body: "User is not active.",
      });
    }
    setLoading({ [stakeholder.id]: true });
    usersApi.resetPassword({ email: stakeholder.email }).then(() => {
      setInformationModal({
        isOpen: true,
        title: "Alright!",
        body: "Reset email sent successfully.",
      });
      setLoading({ [stakeholder.id]: false });
    });
  };

  const sendEmail = (user) => {
    setUserEmailContext({ ...userEmailInitialState, user, isActive: true });
  };

  const sendEmailAll = () => {
    const user = stakeholders.data;
    setUserEmailContext({
      ...userEmailInitialState,
      user,
      isActive: true,
      type: "Stakeholder",
    });
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-inline-block">Stakeholders</HeaderTitle>
        <Button color="info" className="m-2 float-right" onClick={sendEmailAll}>
          <FontAwesomeIcon icon={faMailBulk} />
          <span className="ml-2">Email All</span>
        </Button>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3 col-4 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={stakeholdersContext.search}
                    onChange={(evt) =>
                      setStakeholdersContext({
                        search: evt.target.value,
                        page: 1,
                      })
                    }
                    className="border-0"
                    placeholder="Search for.."
                  />
                </InputGroup>
                <div className="d-flex align-items-center">
                  <small className="text-muted mr-2">
                    Showing {stakeholders?.count} stakeholders
                  </small>
                  <Button
                    size="sm"
                    className="mr-2 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {generalLoading ? (
                <Loader />
              ) : stakeholders?.data.length ? (
                <AdvanceTableWrapper
                  columns={[
                    ...TABLE_COLUMNS,
                    {
                      accessor: "id",
                      Header: "",
                      width: 15,
                      headerProps: { className: "text-right text-truncate" },
                      cellProps: {
                        className: "text-center p-0",
                      },
                      disableSortBy: true,
                      Cell: (rowData) => actions(rowData.row.original, loading),
                    },
                  ]}
                  data={stakeholders.data || []}
                  sortable
                  onSort={onSort}
                  pageSize={getSizePerPage(stakeholdersContext.sizePerPage)}
                  defaultSort={{
                    sortBy: stakeholdersContext.sortBy,
                    direction: stakeholdersContext.direction,
                  }}
                >
                  <Card className="d-flex">
                    <CardBody className="overflow-auto min-height-300p">
                      {stakeholders?.data?.length ? (
                        <AdvanceTable
                          table
                          isLoading={generalLoading}
                          headerClassName="small"
                          tableProps={{
                            striped: false,
                            className: "mb-0",
                          }}
                        />
                      ) : (
                        <div className="text-center">No results</div>
                      )}
                    </CardBody>
                    <CardFooter>
                      <AdvanceTablePagination
                        totalCount={stakeholders?.count}
                        pageCount={stakeholders?.totalPages}
                        currentPage={stakeholdersContext.page - 1}
                        onPageChange={(page) =>
                          setStakeholdersContext({ page })
                        }
                        pageSize={getSizePerPage(
                          stakeholdersContext.sizePerPage
                        )}
                        onPageSizeChange={(sizePerPage) =>
                          setStakeholdersContext({ page: 1, sizePerPage })
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                      />
                    </CardFooter>
                  </Card>
                </AdvanceTableWrapper>
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {userEmailContext.isActive ? (
        <UserEmailModal
          onClose={() => setUserEmailContext({ isActive: false })}
        />
      ) : null}
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {selectedStakeholder ? (
        <InvestorModalDetails
          investor={selectedStakeholder}
          onSubmit={(stakeholder) => {
            stakeholders.data.splice(
              stakeholders.data.findIndex((i) => i.id === stakeholder.id),
              1,
              stakeholder
            );
            setStakeholders({ ...stakeholders });
          }}
          onClose={() => setSelectedStakeholder(false)}
        />
      ) : null}
    </Container>
  );
};

export default Stakeholders;

import React, { useState } from "react";

import { usePrograms } from "../../../../providers/programsProvider";
import AddProgramActorModal from "../../../../components/admin/AddProgramActorModal";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { programsApi } from "../../../../services/programServices";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../components/Loader";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { utils } from "../../../../utils/utils";
import EditStakeholderModal from "../../../../components/admin/EditStakeholderModal";
import InformationModal from "../../../../components/InformationModal";
import { useAuth } from "../../../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../../utils/roles";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const PropCo = () => {
  const [programsContext, setProgramContext] = usePrograms();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModal, setEditModal] = useState({
    open: false,
    stakeholder: null,
  });
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [authContext] = useAuth();

  const user = authContext.currentUser;

  const onDelete = (id) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setLoading(true);
        programsApi
          .deleteStakeholder({ id, programId: programsContext.program.id })
          .then(() => {
            setLoading(false);
            const program = programsContext.program;
            program.stakeholders.splice(
              program.stakeholders.findIndex((s) => s.id === id),
              1
            );
            setProgramContext({ ...program });
            setConfirmationModal(initConfirmationModal);
          });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Delete Program Stakeholder`,
      body: `<span>You confirm you want to delete the stakeholder?</span>`,
    });
  };

  const onEdit = (stakeholder) => setEditModal({ open: true, stakeholder });

  return (
    <Card>
      <CardHeader>
        <CardTitle
          tag="h5"
          className="mb-0 justify-content-between d-flex align-items-center"
        >
          <span>PropCo</span>
          {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
            !utils.userHasRole(user, SPONSOR) && (
              <Button
                size="sm"
                className="rounded"
                color="primary"
                onClick={() => setCreateModalOpen(true)}
              >
                <span>Add</span>
              </Button>
            )}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <ListGroup flush>
            <ListGroupItem
              className="border-bottom d-flex justify-content-between"
              tag="div"
            >
              <div className="small text-muted px-0 col-6">Name</div>
              <div className="d-flex px-0 col-6">
                <div className="small text-muted px-0 col-8 text-right">
                  Ownership
                </div>
                <div className="small text-muted px-0 col-4 text-right">
                  Actions
                </div>
              </div>
            </ListGroupItem>
            {programsContext.program.stakeholders.length ? (
              programsContext.program.stakeholders.map((stakeholder, i) => (
                <ListGroupItem
                  key={i}
                  className="border-bottom d-flex justify-content-between"
                  tag="div"
                >
                  <div className="text-truncate px-0 col-6 text-left">
                    {stakeholder.user.name}
                  </div>
                  <div className="d-flex px-0 col-6">
                    <div className="px-0 col-8 text-right">
                      {utils.formatPercent(stakeholder.percentage, 100)}
                    </div>
                    {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
                      !utils.userHasRole(user, SPONSOR) && (
                        <div className="d-flex px-0 col-4 align-items-center justify-content-end">
                          <FontAwesomeIcon
                            onClick={() => onEdit({ ...stakeholder })}
                            className="text-primary cursor-pointer"
                            icon={faPen}
                            fixedWidth
                          />
                          <FontAwesomeIcon
                            onClick={() => onDelete(stakeholder.id)}
                            className="text-danger cursor-pointer ml-2"
                            icon={faTrashAlt}
                            fixedWidth
                          />
                        </div>
                      )}
                  </div>
                </ListGroupItem>
              ))
            ) : (
              <ListGroupItem
                className="border-bottom d-flex justify-content-center"
                tag="div"
              >
                PropCo has no Stakeholders
              </ListGroupItem>
            )}
            <ListGroupItem
              className="border-bottom d-flex justify-content-between font-weight-bold"
              tag="div"
            >
              <span>InvestCo</span>
              <div className="d-flex">
                <span>
                  {utils.formatPercent(
                    100 -
                      programsContext.program.stakeholders.reduce(
                        (p, c) => p + parseFloat(c.percentage),
                        0
                      ),
                    100
                  )}
                </span>
              </div>
            </ListGroupItem>
          </ListGroup>
        )}
      </CardBody>
      {createModalOpen ? (
        <AddProgramActorModal
          onSubmit={(stakeholder) => {
            setLoading(true);
            programsApi
              .createStakeholder({
                ...stakeholder,
                programId: programsContext.program.id,
              })
              .then((stakeholder) => {
                const program = programsContext.program;
                program.stakeholders = [...program.stakeholders, stakeholder];
                setProgramContext({ ...program });
                setLoading(false);
              })
              .catch((err) => {
                if (err.response?.data?.length) {
                  setLoading(false);
                  return setInformationModal({
                    isOpen: true,
                    title: "Add Stakeholder",
                    body: err.response?.data[0].msg,
                    onClose: () => {
                      setInformationModal({
                        isOpen: false,
                        title: "",
                        body: "",
                      });
                      setCreateModalOpen(true);
                    },
                  });
                }
              });
            setCreateModalOpen(false);
          }}
          onClose={() => setCreateModalOpen(false)}
        />
      ) : null}
      {editModal.open ? (
        <EditStakeholderModal
          stakeholder={editModal.stakeholder}
          onSubmit={(stakeholder) => {
            setLoading(true);
            programsApi
              .updateStakeholder({
                ...stakeholder,
                programId: programsContext.program.id,
              })
              .then((stakeholder) => {
                const program = programsContext.program;
                program.stakeholders.splice(
                  program.stakeholders.findIndex(
                    (st) => st.id === stakeholder.id
                  ),
                  1,
                  stakeholder
                );
                setProgramContext({ ...program });
                setLoading(false);
              });
            setEditModal({ open: false });
          }}
          onClose={() => setEditModal({ open: false })}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Card>
  );
};

export default PropCo;

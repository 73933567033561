import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Input,
  Form,
} from "reactstrap";
import { fundsApi } from "../../../../../services/fundServices";
import Loader from "../../../../Loader";
import { useFundContext } from "../../../../../providers/fundsProvider";
import ConfirmationModal from "../../../../ConfirmationModal";
import { utils } from "../../../../../utils/utils";

const FundMonthCloseModal = ({ onSubmitted, onClose }) => {
  const [fundContext] = useFundContext();
  const selectedFundMonth = fundContext.selectedFundMonth;

  const [loading, setLoading] = useState(false);
  const [incomeFee, setIncomeFee] = useState(selectedFundMonth.incomeFee);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSubmit = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setLoading(true);
        fundsApi
          .closeFundMonth({
            incomeFee,
            id: selectedFundMonth.id,
          })
          .then(() => {
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            onSubmitted();
          });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Close ${utils.getMonth(selectedFundMonth.month)}-${
        selectedFundMonth.year
      }`,
      body: `<p class="text-underline">You selected to close ${utils.getMonth(
        selectedFundMonth.month
      )} of ${
        selectedFundMonth.year
      }.</p><span class="font-weight-bold">You confirm you want to continue?</span>`,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        Close {utils.getMonth(selectedFundMonth.month)}-{selectedFundMonth.year}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row className="mb-0">
                <Label sm={5} className="text-sm-left">
                  Management Fee %
                </Label>
                <Col sm={7}>
                  <Input
                    required={true}
                    type="number"
                    max={100}
                    min={0}
                    step={0.01}
                    placeholder="Enter a percentage.."
                    onChange={(event) =>
                      setIncomeFee(event.currentTarget.value)
                    }
                    value={incomeFee === 0 ? "" : incomeFee}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button color={"primary"} type={"submit"}>
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundMonthCloseModal;

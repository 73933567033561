import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Loader from "../../../Loader";
import { programsApi } from "../../../../services/programServices";
import { pdfjs, Document, Page } from "react-pdf";
import {
  faChevronLeft,
  faChevronRight,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download from "downloadjs";

import { useStatementsFlow } from "../../../../providers/statementsFlowProvider";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ON_CLOSE_PREVIEW_ACTION = "onClosePreviewAction";

const ProgramPreview = ({ programId, onClose }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [statementsFlow, setStatementsFlow] = useStatementsFlow();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDownload = () => {
    setDownloadLoading(true);
    programsApi.downloadStatements({ id: programId }).then((result) => {
      download(result, "attachement.zip", "application/zip"); //this is third party it will prompt download window in browser.
      setDownloadLoading(false);
      return result;
    });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() =>
        setStatementsFlow({
          action: ON_CLOSE_PREVIEW_ACTION,
          payload: programId,
        })
      }
    >
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="md" className="statements-modal">
      <ModalHeader close={closeBtn}>
        <span>Statement Preview</span>
        {downloadLoading ? (
          <div className="col-4">
            <Loader size="sm" align="end" />
          </div>
        ) : (
          <Button className="font-size-75" color={"info"} onClick={onDownload}>
            <FontAwesomeIcon icon={faFileDownload} />
            <span className="ml-1">Download All Statements</span>
          </Button>
        )}
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="col-12 text-center px-0">
          <Document
            file={statementsFlow.programs[programId].previewFile}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="d-flex justify-content-between p-3 align-items-center text-body">
            <FontAwesomeIcon
              className="cursor-pointer text-body"
              icon={faChevronLeft}
              onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
            />
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <FontAwesomeIcon
              className="cursor-pointer text-body"
              icon={faChevronRight}
              onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProgramPreview;

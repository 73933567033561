import React, { useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
  Col,
} from "reactstrap";

import Loader from "../../Loader";
import { useAuth } from "../../../providers/authProvider";
import { usersApi } from "../../../services/userServices";
import InformationModal from "../../InformationModal";
import { useAccount } from "../../../providers/accountProvider";
import { utils } from "../../../utils/utils";

const PAYMENT_ACH = 1;
const PAYMENT_CHECK = 2;

const BankPayment = () => {
  const [authContext, setAuthContext] = useAuth();
  const [accountContext, setAccountContext] = useAccount();
  const [editingBA, setEditingBA] = useState();
  const [editingRN, setEditingRN] = useState();

  const { cookieRole } = utils.getSessionData(authContext.currentUser);

  const [bankLoading, setBankLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onChangeSettings = (event) => {
    event.preventDefault();
    setBankLoading(true);

    const updateUserData = {
      id: accountContext.user.id,
      bankAccountNumber: accountContext.user.bankAccountNumber,
      routingNumber: accountContext.user.routingNumber,
    };

    usersApi
      .updateUser(updateUserData, cookieRole)
      .then((result) => {
        setBankLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "Settings updated successfully",
          onClose: () =>
            setAccountContext({
              user: { ...accountContext.user, ...updateUserData },
            }),
        });
      })
      .catch((err) => {
        setBankLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  const onChangePaymentMethod = (paymentMethod) => {
    setPaymentLoading(true);
    const updateUserData = {
      id: accountContext.user.id,
      paymentMethod,
    };

    usersApi
      .updateUser(updateUserData, cookieRole)
      .then(() => {
        const updatedUser = { ...accountContext.user, paymentMethod };
        setAccountContext({ user: updatedUser });
        let currentUser = { ...authContext.currentUser };
        if (updateUserData.id === currentUser.sessionOwner?.id) {
          currentUser.sessionOwner = {
            ...currentUser.sessionOwner,
            ...updatedUser,
          };
        } else {
          currentUser = { ...currentUser, ...updatedUser };
        }
        setAuthContext({ currentUser });
        setPaymentLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: `Setting updated successfully`,
        });
      })
      .catch((err) => {
        setPaymentLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  return (
    <Card className="col-5 box-shadow-none tab-card">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Bank Account
        </CardTitle>
      </CardHeader>
      <CardBody>
        {bankLoading ? (
          <Loader align="start" size="sm" />
        ) : (
          <Form onSubmit={onChangeSettings}>
            <FormGroup className="col-12 px-0">
              <Label for="bankAccount">Bank Account Number</Label>
              <Input
                maxLength={60}
                required={true}
                onFocus={() => setEditingBA(true)}
                onBlur={() => setEditingBA(false)}
                type={editingBA ? "text" : "password"}
                onChange={(event) =>
                  setAccountContext({
                    user: {
                      ...accountContext.user,
                      bankAccountNumber: event.currentTarget.value,
                    },
                  })
                }
                name="bankAccount"
                value={accountContext.user.bankAccountNumber || ""}
                id="bankAccount"
                placeholder="Bank Account.."
              />
            </FormGroup>
            <FormGroup className="col-12 px-0">
              <Label for="bankAccount">Routing Number</Label>
              <Input
                maxLength={60}
                required={true}
                onFocus={() => setEditingRN(true)}
                onBlur={() => setEditingRN(false)}
                type={editingRN ? "text" : "password"}
                onChange={(event) =>
                  setAccountContext({
                    user: {
                      ...accountContext.user,
                      routingNumber: event.currentTarget.value,
                    },
                  })
                }
                name="routingNumber"
                value={accountContext.user.routingNumber || ""}
                id="routingNumber"
                placeholder="Routing Number.."
              />
            </FormGroup>
            <Button type="submit" color="info">
              Save
            </Button>
          </Form>
        )}
      </CardBody>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Transfer Settings
        </CardTitle>
      </CardHeader>
      <CardBody>
        {paymentLoading ? (
          <Loader align="start" size="sm" />
        ) : (
          <Form>
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left">
                Payment Method
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  type="select"
                  id="exampleCustomSelect"
                  name="customSelect"
                  onChange={(event) =>
                    onChangePaymentMethod(event.target.value)
                  }
                  value={accountContext.user.paymentMethod}
                >
                  <option value="">Select...</option>
                  <option value={PAYMENT_ACH}>ACH</option>
                  <option value={PAYMENT_CHECK}>Mailed Check</option>
                </CustomInput>
              </Col>
            </FormGroup>
          </Form>
        )}
      </CardBody>
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Card>
  );
};

export default BankPayment;

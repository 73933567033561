import React, { useCallback, useEffect, useState, useMemo } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Button,
  Badge,
  CustomInput,
} from "reactstrap";

import Header from "../Header";
import HeaderTitle from "../HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faDownload,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader";
import { utils } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import {
  ACTIONS,
  useDistributions,
} from "../../providers/distributionsProvider";
import ConfirmationModal from "../ConfirmationModal";
import { investorDistributionsApi } from "../../services/investorDistributionServices";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";
import { CSVLink } from "react-csv";
import _ from "lodash";

const MAX_PAGE_SIZE = 100;

const emailColumn = {
  dataField: "investor.email",
  Header: "Email",
  headerProps: { className: "text-truncate" },
};

const InvestorDistributionsTable = ({ name, parentType, parentId }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [distributionsContext, setDistributionsContext] = useDistributions();
  const [distributionsLoading, setDistributionsLoading] = useState({});
  const [csvData, setCsvData] = useState([]);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const setDistributionsContextCb = useCallback(
    (data) => setDistributionsContext(data),
    [setDistributionsContext]
  );

  useEffect(() => {
    if (distributionsContext.programId) {
      setLoading(true);
      investorDistributionsApi
        .getDistributions({
          pageSize: getSizePerPage(distributionsContext.sizePerPage),
          page: distributionsContext.page - 1,
          search: distributionsContext.search,
          sortBy: distributionsContext.sortBy,
          direction: distributionsContext.direction,
          paid: distributionsContext.status,
          year: distributionsContext.year,
          quarter: distributionsContext.quarter,
          programId: distributionsContext.programId,
        })
        .then((result) => {
          setDistributionsContextCb({ distributions: result });
          setLoading(false);
        });
    }
  }, [
    setDistributionsContextCb,
    distributionsContext.refresh,
    distributionsContext.sizePerPage,
    distributionsContext.page,
    distributionsContext.search,
    distributionsContext.sortBy,
    distributionsContext.direction,
    distributionsContext.status,
    distributionsContext.year,
    distributionsContext.quarter,
    distributionsContext.programId,
  ]);

  const onTogglePaid = useCallback(
    (distribution) => {
      setDistributionsLoading({
        ...distributionsLoading,
        [distribution.id]: true,
      });
      investorDistributionsApi
        .updateDistribution({
          paid: !distribution.paid,
          ...distribution,
        })
        .then(() => {
          distribution.paid = !distribution.paid;
          setDistributionsLoading({
            ...distributionsLoading,
            [distribution.id]: false,
          });
        });
    },
    [distributionsLoading]
  );

  const onMarkAllPaid = () => {
    if (!distributionsContext.distributions.data.length) {
      return;
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setLoading(true);
        investorDistributionsApi
          .updatePaidAllDistributions({
            paid: true,
            ids: distributionsContext.distributions.data
              .map((distribution) => distribution.id)
              .toString(),
          })
          .then(() => {
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            setDistributionsContext({ refresh: !distributionsContext.refresh });
          });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Mark All Distributions as Paid",
      body: `<p>Do you confirm you want to mark all distributions as paid?</p>
              <div class="text-left text-warning">
                <small class="font-weight-bold">Note that this action will affect only the current page.\nIf you wish to affect all the distributions, execute this action having selected the option 'All' from the page size selector.</small>
              </div>`,
    });
  };

  const onSort = useCallback(
    ([data]) => {
      if (data && data.id) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          distributionsContext.sortBy === sortBy?.id &&
          distributionsContext.direction === direction
        ) {
          return;
        }
        setDistributionsContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setDistributionsContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      distributionsContext.direction,
      distributionsContext.sortBy,
      setDistributionsContext,
    ]
  );

  const columns = useMemo(
    () => [
      {
        dataField: "investor.name",
        accessor: "investor.name",
        Header: "Investor",
        headerProps: { className: "text-truncate" },
        Cell: (rowData) => {
          const { investor } = rowData.row.original;
          return investor.name;
        },
      },
      {
        dataField: "programDistribution",
        accessor: "quarter",
        Header: "Distribution Quarter",
        headerProps: { className: "text-truncate" },
        disableSortBy: true,
        csvFormatter: (programDistribution) =>
          `Q${programDistribution.quarter}-${programDistribution.year}`,
        Cell: (rowData) => {
          const { programDistribution } = rowData.row.original;
          return `Q${programDistribution.quarter}-${programDistribution.year}`;
        },
      },
      {
        dataField: "updatedAt",
        accessor: "updatedAt",
        Header: "Creation Date",
        headerProps: { className: "text-truncate" },
        disableSortBy: true,
        csvFormatter: (updatedAt) => utils.formatDate(updatedAt, "MM/DD/YYYY"),
        Cell: (rowData) => {
          const { updatedAt } = rowData.row.original;
          return utils.formatDate(updatedAt, "MM/DD/YYYY");
        },
      },
      {
        dataField: "amount",
        accessor: "amount",
        Header: "Amount",
        headerProps: { className: "text-truncate" },
        csvFormatter: (amount) => utils.formatCurrency(amount),
        Cell: (rowData) => {
          const { amount } = rowData.row.original;
          return utils.formatCurrency(amount);
        },
      },
      {
        dataField: "paid",
        accessor: "paid",
        Header: "Status",
        headerProps: { className: "text-truncate" },
        csvFormatter: (paid) => (paid ? "Paid" : "Not Paid"),
        Cell: (rowData) => {
          const { paid } = rowData.row.original;
          return (
            <Badge color={paid ? "success" : "warning"} size={18} pill>
              {paid ? "Paid" : "Not Paid"}
            </Badge>
          );
        },
      },
      {
        dataField: "",
        accessor: "status",
        Header: "Change Status",
        headerProps: { className: "text-center text-truncate" },
        cellProps: { className: "text-center" },
        disableSortBy: true,
        Cell: (rowData) => {
          const distribution = rowData.row.original;
          const { id, paid } = distribution;
          return !id ? (
            <Loader size="sm" />
          ) : !paid ? (
            <Button
              size="sm"
              className="btn btn-success rounded font-weight-bold"
              onClick={() => onTogglePaid(distribution)}
            >
              Mark As Paid
            </Button>
          ) : (
            <Button
              size="sm"
              className="btn btn-warning rounded font-weight-bold"
              onClick={() => onTogglePaid(distribution)}
            >
              Mark As Not Paid
            </Button>
          );
        },
      },
    ],
    [onTogglePaid]
  );

  useEffect(() => {
    if (!distributionsContext.distributions.data.length) return;
    const csvColumns = [...columns, emailColumn];
    const csvHeaders = csvColumns
      .filter((column) => column.dataField && column.Header)
      .map((column) => column.Header);
    const rows = distributionsContext.distributions.data.map((row) => {
      const newRow = [];
      csvColumns.forEach((column) => {
        if (column.dataField) {
          let value = _.get(row, column.dataField);
          if (column.csvFormatter) {
            value = column.csvFormatter(value);
          }
          newRow.push(value);
        }
      });
      return newRow;
    });
    setCsvData([csvHeaders, ...rows]);
  }, [columns, distributionsContext.distributions.data]);

  return loading || !distributionsContext?.distributions?.data ? (
    <Loader />
  ) : (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <div className="p-0 d-flex flex-column align-items-start">
            <span>{name} - Distributions</span>
            <Button
              onClick={() => navigate(`/back/${parentType}/${parentId}`)}
              className="mt-1 rounded"
              color="info"
              size="sm"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="ml-2">Back</span>
            </Button>
          </div>
          <div className="p-0 d-flex align-items-center col-6 px-0 justify-content-end">
            <CustomInput
              className="ml-4"
              id="yearSelect"
              type="select"
              name="yearSelect"
              onChange={(event) =>
                setDistributionsContext({
                  year: event.currentTarget.value,
                })
              }
              value={distributionsContext.year || ""}
            >
              <option value="">Any Year</option>
              {Array.from(Array(20).keys()).map((year) => (
                <option value={year + 2010}>{year + 2010}</option>
              ))}
            </CustomInput>
            <CustomInput
              className="ml-4"
              id="quarterSelect"
              type="select"
              name="quarterSelect"
              onChange={(event) =>
                setDistributionsContext({
                  quarter: event.currentTarget.value,
                })
              }
              value={distributionsContext.quarter || ""}
            >
              <option value="">Any Quarter</option>
              <option value={1}>First</option>
              <option value={2}>Second</option>
              <option value={3}>Third</option>
              <option value={4}>Fourth</option>
            </CustomInput>
            <CustomInput
              className="ml-4"
              id="statusSelect"
              type="select"
              name="statusSelect"
              onChange={(event) =>
                setDistributionsContext({
                  status: event.currentTarget.value,
                })
              }
              value={distributionsContext.status || ""}
            >
              <option value="">Any Status</option>
              <option value="1">Paid</option>
              <option value="0">Not Paid</option>
            </CustomInput>
            <CSVLink
              data={csvData}
              filename={`${name}_distributions.csv`}
              className="ml-4 flex-shrink-0 d-flex align-items-center btn btn-secondary"
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className="mx-2">Export CSV</span>
            </CSVLink>
            <Button
              color="success"
              className="ml-4 flex-shrink-0 d-flex align-items-center"
              onClick={onMarkAllPaid}
            >
              <FontAwesomeIcon icon={faCheck} />
              <span className="mx-2">Mark All Paid</span>
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {distributionsContext.distributions.data.length ? (
                <AdvanceTableWrapper
                  columns={columns}
                  data={distributionsContext.distributions.data || []}
                  pageSize={getSizePerPage(distributionsContext.sizePerPage)}
                  sortable
                  onSort={onSort}
                  defaultSort={{
                    sortBy: distributionsContext.sortBy,
                    direction: distributionsContext.direction,
                  }}
                >
                  <Card>
                    <CardBody className="overflow-auto min-height-300p">
                      {distributionsContext.distributions.data?.length ? (
                        <AdvanceTable
                          table
                          isLoading={loading}
                          headerClassName="small"
                          tableProps={{
                            className: "mb-0",
                          }}
                        />
                      ) : (
                        <div className="text-center">No results</div>
                      )}
                    </CardBody>
                    <CardFooter>
                      <AdvanceTablePagination
                        totalCount={
                          distributionsContext.distributions.data?.count
                        }
                        pageCount={
                          distributionsContext.distributions.data?.totalPages
                        }
                        currentPage={distributionsContext.page - 1}
                        disableAll={true}
                        onPageChange={(page) =>
                          setDistributionsContext({ page })
                        }
                        pageSize={getSizePerPage(
                          distributionsContext.sizePerPage
                        )}
                        onPageSizeChange={(sizePerPage) =>
                          setDistributionsContext({
                            action: ACTIONS.PAGE_SIZE_CHANGE,
                            payload: { sizePerPage },
                          })
                        }
                      />
                    </CardFooter>
                  </Card>
                </AdvanceTableWrapper>
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default InvestorDistributionsTable;

import React, { useState } from "react";

import {
  CardHeader,
  ListGroupItem,
  ListGroup,
  Button,
  Row,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import {
  useDocumentsUploadFlow,
  initialState,
} from "../../../../providers/documentUploadFlowProvider";
import InformationModal from "../../../../components/InformationModal";
import DocumentPreviewModal from "./DocumentPreviewModal";
import { useNavigate } from "react-router-dom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MATCH_STEP = 3;

const UploadReview = () => {
  const navigate = useNavigate();

  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: null,
  });
  const [previewModal, setPreviewModal] = useState({});

  const onCancel = () => {
    let route = "/back/documents";
    if (documentsUploadFlow.statements) {
      route = "/back/statements";
    }
    setDocumentsUploadFlow({ ...initialState });
    navigate(route);
  };

  const programs = Object.keys(documentsUploadFlow.documents);

  const onModalClose = () => {
    if (!programs.length) {
      setDocumentsUploadFlow({ ...initialState });
      return navigate("/back/documents");
    }
    setDocumentsUploadFlow({ errorModal: false });
  };

  const onPreview = (document) => {
    closeInformationModal();
    setPreviewModal({
      isOpen: true,
      file: document.file,
      onClose: () => {
        setPreviewModal({ isOpen: false, file: null, onClose: () => {} });
        onSeeDocuments(document.programName);
      },
    });
  };

  const onSeeDocuments = (program) => {
    setInformationModal({
      size: "lg",
      isOpen: true,
      title: `Documents for ${program}`,
      rawBody: true,
      body: documentsUploadFlow.documents[program].map((document, index) => (
        <div
          key={index}
          className="my-2 d-flex justify-content-between align-items-center"
        >
          <small>
            {document.name} for {document.investor.name}.
          </small>
          <Badge
            onClick={() => onPreview(document)}
            className="cursor-pointer"
            color={"info"}
          >
            Preview
          </Badge>
        </div>
      )),
    });
  };

  const closeInformationModal = () =>
    setInformationModal({ isOpen: false, title: "", body: "" });

  return (
    <>
      <CardHeader className="py-0 h4 mb-2 text-underline body-color row">
        {programs.reduce(
          (p, c) => p + documentsUploadFlow.documents[c].length,
          0
        )}{" "}
        {documentsUploadFlow.statements ? "Statements" : "Documents"} Found
      </CardHeader>
      <ListGroup flush className="mt-3">
        {programs.map((program, index) => {
          const documents = documentsUploadFlow.documents[program];
          return (
            <ListGroupItem
              key={index}
              className="mt-1 p-1 border-bottom d-flex justify-content-between"
              tag="div"
            >
              <span className="ml-2">{program}</span>
              <div>
                <span>{documents.length} docs</span>
                <UncontrolledDropdown className="d-inline-block ml-4">
                  <DropdownToggle color="white">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faEllipsisV}
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => onSeeDocuments(program)}>
                      See Details
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button color={"secondary"} onClick={onCancel}>
          Cancel
        </Button>{" "}
        <Button
          color={"primary"}
          onClick={() => setDocumentsUploadFlow({ step: MATCH_STEP })}
        >
          Next
        </Button>
      </Row>
      {documentsUploadFlow.errorModal ? (
        <InformationModal
          title={"Heads Up!"}
          body={documentsUploadFlow.errorBody}
          onClose={onModalClose}
        />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          size={informationModal.size}
          title={informationModal.title}
          rawBody={informationModal.rawBody}
          body={informationModal.body}
          onClose={closeInformationModal}
        />
      ) : null}
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={previewModal.onClose}
        />
      ) : null}
    </>
  );
};

export default UploadReview;

import React, { useEffect } from "react";

import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import PersonalInfo from "../../components/admin/settings/Personal";
import Security from "../../components/admin/settings/Security";
import Global from "../../components/admin/settings/Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAstronaut,
  faUserLock,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { useSettings } from "../../providers/settingsProvider";
import { useAuth } from "../../providers/authProvider";
import { utils } from "../../utils/utils";
import { CUSTOMER_SUPPORT } from "../../utils/roles";

const PERSONAL_TAB = "1";
const GLOBAL_TAB = "2";
const SECURITY_TAB = "3";

const Settings = () => {
  const [settingContext, setSettingContext] = useSettings();
  const [authContext] = useAuth();

  const authenticatedUser = authContext.currentUser;

  useEffect(() => {
    if (
      authenticatedUser &&
      utils.userHasRole(authenticatedUser, CUSTOMER_SUPPORT)
    ) {
      setSettingContext({ tab: SECURITY_TAB });
    }
  }, [authenticatedUser, setSettingContext]);

  return (
    <Container fluid className="my-setting">
      <Header>
        <HeaderTitle>Settings</HeaderTitle>
      </Header>
      <Row className="tab tab-vertical setting-tabs">
        <Col className="flex-grow-0 px-0">
          <Nav tabs>
            {!utils.userHasRole(authenticatedUser, CUSTOMER_SUPPORT) ? (
              <>
                <NavItem>
                  <NavLink
                    className={`${
                      settingContext.tab === PERSONAL_TAB
                        ? "active text-primary bg-white"
                        : "text-muted"
                    } cursor-pointer`}
                    onClick={() => setSettingContext({ tab: PERSONAL_TAB })}
                  >
                    <FontAwesomeIcon icon={faUserAstronaut} />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      settingContext.tab === GLOBAL_TAB
                        ? "active text-primary bg-white"
                        : "text-muted"
                    } cursor-pointer`}
                    onClick={() => setSettingContext({ tab: GLOBAL_TAB })}
                  >
                    <FontAwesomeIcon icon={faGlobe} />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      settingContext.tab === SECURITY_TAB
                        ? "active text-primary bg-white"
                        : "text-muted"
                    } cursor-pointer`}
                    onClick={() => setSettingContext({ tab: SECURITY_TAB })}
                  >
                    <FontAwesomeIcon icon={faUserLock} />
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <NavItem>
                <NavLink
                  className={`${
                    settingContext.tab === SECURITY_TAB
                      ? "active text-primary bg-white"
                      : "text-muted"
                  } cursor-pointer`}
                  onClick={() => setSettingContext({ tab: SECURITY_TAB })}
                >
                  <FontAwesomeIcon icon={faUserLock} />
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Col>
        <Col className="flex-grow-1 px-0">
          <TabContent activeTab={settingContext.tab}>
            {!utils.userHasRole(authenticatedUser, CUSTOMER_SUPPORT) ? (
              <>
                <TabPane tabId={PERSONAL_TAB}>
                  <PersonalInfo />
                </TabPane>
                <TabPane tabId={GLOBAL_TAB}>
                  <Global />
                </TabPane>
                <TabPane tabId={SECURITY_TAB}>
                  <Security setSetting={setSettingContext}/>
                </TabPane>
              </>
            ) : (
              <TabPane tabId={SECURITY_TAB}>
                <Security />
              </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Col from "reactstrap/lib/Col";
import { useEditInvestmentModal } from "../../../providers/editInvestmentModalProvider";

import { usePrograms } from "../../../providers/programsProvider";
import { programsApi } from "../../../services/programServices";
import { utils } from "../../../utils/utils";

import ConfirmationModal from "../../ConfirmationModal";
import InformationModal from "../../InformationModal";

import CreateInvestmentTransaction from "./CreateInvestmentTransaction";
import InvestmentTransaction from "./InvestmentTransaction";

const DEFAULT_UNIT_VALUE = 1000;
const DEFAULT_MODE = 1;

const EditInvestmentModal = () => {
  const [editInvestmentModal, setEditInvestmentModal] =
    useEditInvestmentModal();
  const [programsContext, setProgramContext] = usePrograms();

  const investment = useMemo(
    () =>
      programsContext.program.programInvestments.find(
        (inv) => inv.id === editInvestmentModal.investmentId
      ) || {},
    [programsContext, editInvestmentModal.investmentId]
  );

  const initConfirmationModal = useMemo(
    () => ({
      isOpen: false,
      onSubmit: null,
      onClose: null,
      title: "",
      body: "",
    }),
    []
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const handleDeleteInvestment = useCallback(async () => {
    if (investment.capital !== 0) {
      return setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: "To delete the investment, the investment Amount must be zero.",
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await programsApi.deleteInvestment({
          id: investment.id,
          programId: investment.programId,
        });
        const program = { ...programsContext.program };
        const investmentIndex = program.programInvestments.findIndex(
          (inv) => inv.id === investment.id
        );
        program.programInvestments.splice(investmentIndex, 1);
        setProgramContext({
          ...program,
          refresh: !programsContext.refresh,
        });
        setEditInvestmentModal({
          investmentId: null,
          mode: DEFAULT_MODE,
          categories: [],
          isModalOpen: false,
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Delete Program Investment`,
      body: `<span>You confirm you want to delete the investment?</span>`,
    });
  }, [
    setEditInvestmentModal,
    investment,
    programsContext,
    setProgramContext,
    setConfirmationModal,
    initConfirmationModal,
  ]);

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() =>
        setEditInvestmentModal({
          investmentId: null,
          mode: DEFAULT_MODE,
          categories: [],
          isModalOpen: false,
        })
      }
    >
      &times;
    </Button>
  );
  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Edit Program Investment</ModalHeader>
      <ModalBody>
        <Row form className="mx-0">
          <FormGroup
            row
            className="mx-0 mb-1 d-flex align-items-center justify-content-between col-12 form-group"
          >
            <Label sm={4} className="text-sm-left col-sm-4 px-0">
              Investment Amount
            </Label>
            <Col sm={8} className="font-weight-bold text-right px-0">
              {utils.formatCurrency(investment.capital)}
            </Col>
          </FormGroup>
          <FormGroup
            row
            className="mx-0 mb-1 d-flex align-items-center justify-content-between col-12 form-group"
          >
            <Label sm={4} className="text-sm-left col-sm-4 px-0">
              Investment Units
            </Label>
            <Col sm={8} className="font-weight-bold text-right px-0">
              {investment.capital / DEFAULT_UNIT_VALUE}
            </Col>
          </FormGroup>
        </Row>
        <ListGroup flush className="text-left border rounded mt-2">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body text-center bg-light"
            tag="div"
          >
            Transactions
          </ListGroupItem>
          {!investment.transactions.length ? (
            <ListGroupItem
              className="pb-2 border-bottom font-weight-bold text-body text-center"
              tag="div"
            >
              No transactions to show
            </ListGroupItem>
          ) : (
            investment.transactions
              .sort((ta, tb) => (ta.date > tb.date ? 1 : -1))
              .map((transaction, index) => (
                <InvestmentTransaction
                  key={index}
                  investment={investment}
                  transaction={transaction}
                  setInformationModal={setInformationModal}
                />
              ))
          )}
          <CreateInvestmentTransaction
            investment={investment}
            setInformationModal={setInformationModal}
          />
        </ListGroup>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button
          color="secondary"
          onClick={() =>
            setEditInvestmentModal({
              investmentId: null,
              mode: DEFAULT_MODE,
              categories: [],
              isModalOpen: false,
            })
          }
        >
          Close
        </Button>
        <Button color="danger" onClick={handleDeleteInvestment}>
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faTrashAlt}
            fixedWidth
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditInvestmentModal;

import React, { useState } from "react";

import {
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import { fundsApi } from "../../services/fundServices";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FundCreationModal = ({ funds, onClose }) => {
  const currentYear = new Date().getFullYear();
  const [name, setName] = useState("Strategic Fund");
  const [year, setYear] = useState(currentYear);
  const [isPrivateEquity, setIsPrivateEquity] = useState();
  const [exists, setExists] = useState(
    funds.find(
      (f) => f.year === currentYear && f.isPrivateEquity === isPrivateEquity
    ) !== undefined
  );

  const onYearChange = (year) => {
    if (isPrivateEquity) {
      setExists(
        funds.find((f) => f.name === name && f.isPrivateEquity) !== undefined
      );
    } else {
      setExists(
        funds.find((f) => f.year === year && !f.isPrivateEquity) !== undefined
      );
    }
    setYear(year);
  };

  const onDismiss = () => {
    if (isPrivateEquity) {
      setName();
      setExists(false);
    } else {
      setYear(currentYear);
      setExists(
        funds.find((f) => f.year === currentYear && !f.isPrivateEquity) !==
          undefined
      );
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    fundsApi
      .createFund({ name, initialCapital: 0, year, isPrivateEquity })
      .then(() => onClose(true));
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Create New Strategic Fund</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {isPrivateEquity ? (
            <FormGroup row>
              <Label sm={5} className="text-sm-left">
                Fund Name
              </Label>
              <Col sm={7}>
                <Input
                  required
                  maxLength={100}
                  type="text"
                  placeholder="Enter the name.."
                  onChange={(event) => setName(event.currentTarget.value)}
                  value={name}
                />
              </Col>
            </FormGroup>
          ) : null}
          <FormGroup row>
            <Label sm={5} className="text-sm-left">
              Fund Year
            </Label>
            <Col sm={7}>
              <Input
                required
                type="number"
                max={new Date().getFullYear() + 3}
                min={new Date().getFullYear() - 20}
                placeholder="Enter the year.."
                onChange={(event) =>
                  onYearChange(parseInt(event.currentTarget.value))
                }
                value={year || ""}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Label sm={5} className="text-sm-left">
              Hedge Fund Investments
            </Label>
            <Col
              sm={7}
              className="d-flex flex-column align-items-baseline justify-content-center"
            >
              <CustomInput
                defaultChecked={isPrivateEquity}
                onClick={(event) => {
                  setIsPrivateEquity(event.target.checked);
                  onYearChange();
                  setName();
                  setExists(false);
                }}
                type="switch"
                id="pe"
                name="pe"
                label=""
                className="cursor-pointer"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={onDismiss}
              isOpen={exists}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>There is already a fund for the selected year</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose(false)}>
                Cancel
              </Button>{" "}
              <Button
                disabled={exists || !name || !name.length || !year}
                color={"primary"}
                type="submit"
              >
                Confirm
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundCreationModal;

import React, { useReducer, createContext, useContext } from "react";

const FIRST_STEP = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  id: "",
  name: "",
  email: "",
  address: "",
  zipcode: "",
  city: "",
  state: "",
  phone: "",
  token: "",
  ssn: "",
  onBoardManual: false,
  userRole: 0,
  removedAdvisements: [],
  step: FIRST_STEP,
};

const OnBoardingFlowContext = createContext(initialState);

const OnBoardingFlowProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <OnBoardingFlowContext.Provider value={stateAndDispatch}>
      {children}
    </OnBoardingFlowContext.Provider>
  );
};

export const useOnBoardingFlow = () => useContext(OnBoardingFlowContext);

export { OnBoardingFlowContext, OnBoardingFlowProvider };

import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: "All",
  page: 1,
  search: "",
  sortBy: "name",
  direction: "asc",
};

const StakeholdersContext = createContext(initialState);

const StakeholdersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <StakeholdersContext.Provider value={stateAndDispatch}>
      {children}
    </StakeholdersContext.Provider>
  );
};

export const useStakeholders = () => useContext(StakeholdersContext);

export { StakeholdersContext, StakeholdersProvider };

import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap";

import brand from "../../../assets/img/brand.png";
import { onBoardApi } from "../../../services/onboardServices";
import { useOnBoardingFlow } from "../../../providers/onBoardingFlowProvider";
import { utils } from "../../../utils/utils";
import Loader from "../../Loader";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const OnBoardManual = () => {
  const navigate = useNavigate();
  const [onBoardingFlow, setOnBoardingFlow] = useOnBoardingFlow();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onDismiss = () => setError(false);

  const PASSWORD_STEP = 2;

  const USER_ROLE_ADVISOR = "2";
  const USER_ROLE_INVESTOR = "3";

  useEffect(() => {
    setOnBoardingFlow({ onBoardManual: true });
  }, [setOnBoardingFlow]);

  const onChange = (event, fieldName) => {
    let dataToSet = {};
    dataToSet[fieldName] = event.currentTarget.value;
    setError(false);
    setOnBoardingFlow(dataToSet);
  };

  const onNext = (event) => {
    event.preventDefault();
    setLoading(true);
    onBoardApi
      .startOnBoarding({
        ssNumber: onBoardingFlow.ssn,
        email: onBoardingFlow.email,
        token: "onBoardManual",
      })
      .then((user) => {
        setLoading(false);
        setOnBoardingFlow({
          id: user.id,
          name: user.name,
          address: user.address,
          phone: user.phone,
          city: user.city,
          state: user.state,
          zipcode: user.zipcode,
          userRole: utils.userHasRole(user, "investor")
            ? USER_ROLE_INVESTOR
            : USER_ROLE_ADVISOR,
          step: PASSWORD_STEP,
        });
        navigate("/onboard/" + user.activationToken);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <p className="lead">Sign up to continue</p>
      </div>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={brand}
                className="rounded p-0 col-10"
                alt={"Vulcan Fund"}
              />
            </div>
            <Form onSubmit={onNext}>
              <FormGroup>
                <Label className="d-flex flex-column">
                  <span>Email</span>
                  <small className="text-primary">
                    Use the email address you provided on subscription docs.
                  </small>
                </Label>
                <Input
                  required={true}
                  maxLength="50"
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  onChange={(event) => onChange(event, "email")}
                  value={onBoardingFlow.email}
                />
              </FormGroup>
              <FormGroup>
                <Label>SSN/EIN</Label>
                <Input
                  required={true}
                  maxLength="4"
                  minLength="4"
                  bsSize="lg"
                  type="text"
                  name="ssn"
                  placeholder="Enter the last four digits of your SSN/EIN"
                  onChange={(event) => onChange(event, "ssn")}
                />
              </FormGroup>
              <div className="text-right mt-3">
                <UncontrolledAlert
                  isOpen={error}
                  toggle={onDismiss}
                  color="warning"
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <span className="ml-2">You are already registered.</span>
                  </div>
                </UncontrolledAlert>
                {loading ? (
                  <Loader size="sm" />
                ) : (
                  <Button color="primary" type="submit" size="lg">
                    Start
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default OnBoardManual;

import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  CustomInput,
  Input,
} from "reactstrap";

import InformationModal from "../../InformationModal";
import { useNavigate } from "react-router-dom";

const AddDistributionsModal = ({ onClose }) => {
  const navigate = useNavigate();

  const [year, setYear] = useState();
  const [quarter, setQuarter] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSubmit = async (evt) => {
    evt.preventDefault();
    navigate(`/back/add-distributions/${year}/${quarter}`);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={() => onClose()}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn} className="d-flex justify-content-between">
        Add Distributions
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          <FormGroup row>
            <Label
              sm={4}
              className="text-sm-left d-flex flex-column align-items-start"
            >
              <span>Year</span>
            </Label>
            <Col sm={8}>
              <Input
                required={true}
                placeholder="Enter a year.."
                type="number"
                id="yearSelect"
                name="yearSelect"
                onChange={(e) => setYear(e.currentTarget.value)}
                value={year || ""}
                min={2000}
                max={3000}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Label
              sm={4}
              className="text-sm-left d-flex flex-column align-items-start"
            >
              <span>Quarter</span>
            </Label>
            <Col sm={8}>
              <CustomInput
                required={true}
                type="select"
                id="quarterSelect"
                name="quarterSelect"
                onChange={(e) => setQuarter(e.currentTarget.value)}
                value={quarter || ""}
              >
                <option value="">Select a quarter..</option>
                <option value={1}>First</option>
                <option value={2}>Second</option>
                <option value={3}>Third</option>
                <option value={4}>Fourth</option>
              </CustomInput>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          <Button color={"primary"} type="submit">
            Next
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddDistributionsModal;

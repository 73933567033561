import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const surveyApi = {
  getAllSurveys: (programId) => {
    return axios(`/api/surveys/${programId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getQuestionTypes: () => {
    return axios("/api/surveys/question-types", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getSurveyByToken: (token) => {
    return axios(`/api/surveys/token/${token}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  resendEmail: (data) => {
    return axios(`/api/surveys/${data.surveyId}/resend/${data.userId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  resendAll: (data) => {
    return axios(`/api/surveys/${data.surveyId}/resend/all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createSurvey: (data) => {
    return axios("/api/surveys", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createAnswer: (data) => {
    return axios("/api/surveys/answer", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteSurvey: (id) => {
    return axios(`/api/surveys/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};

import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const dataLoadingApi = {
  saveDocument: (data) => {
    return axios(`/api/documentsMismatch`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getDocumentsMismatch: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/documentsMismatch?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  saveStatement: (data) => {
    return axios(`/api/statementsMismatch`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getStatementsMismatch: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/statementsMismatch?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};

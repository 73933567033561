import React, { useState } from "react";

import { CardHeader, ListGroupItem, ListGroup, Button, Row } from "reactstrap";

import { useProgramCreationFlow } from "../../../../providers/programCreationFlowProvider";
import AddProgramActorModal from "../../../../components/admin/AddProgramActorModal";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../../components/InformationModal";

const MATCH_REVIEW_STEP = 7;
const FINAL_REVIEW_STEP = 9;

const ProgramStakeholders = () => {
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();
  const [modalOpen, setModalOpen] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const inconsistentField = (field, stakeholder) => {
    const fields = ["name", "email", "ssNumber"];
    const existingStakeholder = programCreationFlow.stakeholders.find(
      (st) => st[field] === stakeholder[field]
    );
    if (existingStakeholder) {
      return fields.find((f) => existingStakeholder[f] !== stakeholder[f]);
    }
    return false;
  };

  return !programCreationFlow.program ? null : (
    <>
      <CardHeader className="py-0 h5 my-4 text-underline body-color text-center col-12">
        PropCo Stakeholders
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem
          className="d-flex justify-content-between small text-muted border-0"
          tag="div"
        >
          <span>Name</span>
          <span>Ownership</span>
        </ListGroupItem>
        <div className="border-left border-right border-bottom rounded">
          {programCreationFlow.stakeholders.length ? (
            programCreationFlow.stakeholders.map((stakeholder, i) => (
              <ListGroupItem
                key={i}
                className="border-top bg-lighter border-bottom-0 d-flex justify-content-between"
                tag="div"
              >
                <span>{stakeholder.name}</span>
                <span>{utils.formatPercent(stakeholder.percentage, 100)}</span>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem
              className="border-top bg-lighter border-bottom-0 d-flex justify-content-center"
              tag="div"
            >
              No Stakeholders added for PropCo
            </ListGroupItem>
          )}
          <ListGroupItem
            className="font-weight-bold border-top bg-lighter border-bottom-0 d-flex justify-content-between"
            tag="div"
          >
            <span>InvestCo</span>
            <span>
              {utils.formatPercent(
                100 -
                  programCreationFlow.stakeholders.reduce(
                    (p, c) => p + parseFloat(c.percentage),
                    0
                  ),
                100
              )}
            </span>
          </ListGroupItem>
        </div>
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() => setProgramCreationFlow({ step: MATCH_REVIEW_STEP })}
        >
          Back
        </Button>
        <Button disabled color={"info"} onClick={() => setModalOpen(true)}>
          New Stakeholder
        </Button>
        <Button
          color={"primary"}
          onClick={() => setProgramCreationFlow({ step: FINAL_REVIEW_STEP })}
        >
          Next
        </Button>
      </Row>
      {modalOpen ? (
        <AddProgramActorModal
          onSubmit={(stakeholder) => {
            if (inconsistentField("name")) {
              return setInformationModal({
                isOpen: true,
                title: "Create Stakeholder",
                body: `A user with the same name but different email or SS Number is being added in the progran, data must be consistent.`,
              });
            }
            if (inconsistentField("email")) {
              return setInformationModal({
                isOpen: true,
                title: "Create Stakeholder",
                body: `A user with the same email but different name or SS Number is being added in the progran, data must be consistent.`,
              });
            }
            if (inconsistentField("ssNumber")) {
              return setInformationModal({
                isOpen: true,
                title: "Create Stakeholder",
                body: `A user with the same SS Number but different email or name is being added in the progran, data must be consistent.`,
              });
            }

            setProgramCreationFlow({
              stakeholders: [
                ...programCreationFlow.stakeholders,
                {
                  ...stakeholder,
                  isStakeholder: true,
                },
              ],
            });
            setModalOpen(false);
          }}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          rawBody={informationModal.rawBody}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default ProgramStakeholders;

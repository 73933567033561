import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const sponsorsApi = {
  getSponsors: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/sponsors?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createSponsor: (data) => {
    const form_data = new FormData();
    form_data.append("name", data.name);
    form_data.append("ceoAttribute", data.ceoAttribute);
    form_data.append("physicalAddress", data.physicalAddress);
    form_data.append("webAddress", data.webAddress);
    form_data.append("phoneNumber", data.phoneNumber);
    form_data.append("supportEmail", data.supportEmail);
    form_data.append("statementLogo", data.statementLogo || null);
    form_data.append("webLogo", data.webLogo || null);
    form_data.append("statementHeader", data.statementHeader || null);
    form_data.append("statementBackground", data.statementBackground || null);
    form_data.append("color", data.color || null);
    return axios("/api/sponsors", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateSponsor: (data) => {
    const form_data = new FormData();
    form_data.append("name", data.name);
    form_data.append("ceoAttribute", data.ceoAttribute);
    form_data.append("physicalAddress", data.physicalAddress);
    form_data.append("webAddress", data.webAddress);
    form_data.append("phoneNumber", data.phoneNumber);
    form_data.append("supportEmail", data.supportEmail);
    form_data.append("statementLogo", data.statementLogo || null);
    form_data.append("webLogo", data.webLogo || null);
    form_data.append("icon", data.icon || null);
    form_data.append("statementHeader", data.statementHeader || null);
    form_data.append("statementBackground", data.statementBackground || null);
    form_data.append("color", data.color || null);
    return axios(`/api/sponsors/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteSponsor: ({ id }) =>
    axios(`/api/sponsors/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
};

import React, { useState } from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/customer/sidebar/Sidebar";
import Main from "../components/Main";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { authApi } from "../services/authServices";
import Loader from "../components/Loader";
import { utils } from "../utils/utils";

const Customer = ({ children }) => {
  const [authContext, setAuthContext] = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  if (redirectToLogin) {
    return <Navigate to={`/auth/sign-in`} />;
  }

  if (!authContext.currentUser) {
    authApi.investorValidate().then((result) => {
      if (
        !result?.status ||
        (!utils.userHasRole(result, "investor") &&
          !utils.userHasRole(result, "stakeholder"))
      ) {
        return setRedirectToLogin(true);
      }
      setAuthContext({ currentUser: { ...result } });
    });
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar />
        <Main role="customer">
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Customer;

import React, { useState } from "react";

import { FormGroup, Label, CustomInput, Button } from "reactstrap";

import { useAuth } from "../../../providers/authProvider";
import { authApi } from "../../../services/authServices";
import { utils } from "../../../utils/utils";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";

const backToAdmin = (navigate, sessionOwner, setAuthContext) => {
  authApi.logout("investor");
  navigate("/back/investors");
  setAuthContext({
    currentUser: { ...sessionOwner },
  });
};

const SideBarUserDefault = () => {
  const [authContext] = useAuth();
  return (
    <div className="sidebar-user p-3 d-flex flex-column align-items-center justify-content-center">
      <div className="user-name rounded-circle bg-light img-thumbnail text-muted d-flex align-items-center justify-content-center">
        <span>{utils.nameInitials(authContext.currentUser.name)}</span>
      </div>
      <div className="font-weight-bold text-custom-dark mt-2">
        {authContext.currentUser.name}
      </div>
    </div>
  );
};

const SideBarUserSelector = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();
  const [loading, setLoading] = useState(false);
  const advisements =
    authContext.advisements || authContext.currentUser.sessionOwner.investors;

  const loginAs = (event) => {
    setLoading(true);
    authApi
      .loginAs({ userId: event.currentTarget.value }, "advisor")
      .then(() => {
        authApi.investorValidate().then((result) => {
          setLoading(false);
          setAuthContext({ currentUser: { ...result } });
        });
      });
  };

  return (
    <div className="sidebar-user d-flex flex-column align-items-center pt-0">
      <div className="user-name rounded-circle bg-light img-thumbnail text-muted d-flex align-items-center justify-content-center">
        <span>{utils.nameInitials(authContext.currentUser.name)}</span>
      </div>
      <FormGroup className="mb-0 mt-2">
        <Label className="font-weight-bold text-custom-dark text-muted font-size-75">
          Logged In As
        </Label>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <CustomInput
            id="investorSelector"
            type="select"
            className="d-flex"
            value={authContext.currentUser.id}
            onChange={loginAs}
          >
            {utils.userHasRole(
              authContext.currentUser.sessionOwner,
              "investor"
            ) ? (
              <option value={authContext.currentUser.sessionOwner.id}>
                {authContext.currentUser.sessionOwner.name}
              </option>
            ) : null}
            {advisements.map((investor, index) => (
              <option key={index} value={investor.id}>
                {investor.name}
              </option>
            ))}
          </CustomInput>
        )}
      </FormGroup>
      {utils.userHasRole(
        authContext.currentUser.sessionOwner.sessionOwner,
        "admin"
      ) ? (
        <Button
          onClick={() =>
            backToAdmin(
              navigate,
              authContext.currentUser.sessionOwner,
              setAuthContext
            )
          }
          color="danger"
          className="mt-2"
        >
          Go Back to Admin
        </Button>
      ) : null}
    </div>
  );
};

const SideBarUserAdmin = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();

  return (
    <div className="sidebar-user p-3 d-flex flex-column align-items-center justify-content-center">
      <div className="user-name rounded-circle bg-light img-thumbnail text-muted d-flex align-items-center justify-content-center">
        <span>{utils.nameInitials(authContext.currentUser.name)}</span>
      </div>
      <div className="text-custom-dark mt-2 d-flex align-items-center flex-column">
        <span className="text-muted font-size-75">Logged In As</span>
        <span className="font-weight-bold">{authContext.currentUser.name}</span>
        <Button
          onClick={() =>
            backToAdmin(
              navigate,
              authContext.currentUser.sessionOwner,
              setAuthContext
            )
          }
          color="danger"
          className="mt-2"
        >
          Go Back to Admin
        </Button>
      </div>
    </div>
  );
};

const SideBarUser = () => {
  const [authContext] = useAuth();
  if (authContext.currentUser.fakeLogin) {
    if (utils.userHasRole(authContext.currentUser.sessionOwner, "advisor")) {
      return <SideBarUserSelector />;
    }
    return <SideBarUserAdmin />;
  }
  return <SideBarUserDefault />;
};

export default SideBarUser;

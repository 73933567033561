import React, { useRef, useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { programsApi } from "../services/programServices";
import RichTextEditor from "./RichTextEditor";
import InformationModal from "./InformationModal";

const ManageCeoLetterModal = ({ programStatement, onClose }) => {
  const letterRef = useRef();
  const [letter, setLetter] = useState(programStatement.letter);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSubmit = async () => {
    try {
      await programsApi.updateProgramStatement({
        ...programStatement,
        letter,
      });
      setInformationModal({
        isOpen: true,
        title: "Manage CEO Letter",
        body: "Letter updated successfully",
        onClose,
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Manage CEO Letter",
        body:
          err?.response?.data[0]?.msg || "There was an error updating the user",
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column">
          <span>Manage CEO Letter</span>
          <small className="text-warning">
            {`Updating for the latest statement sent ${
              programStatement.fundQuarter
                ? `(Q${programStatement.fundQuarter.quarter}-${programStatement.fundQuarter.year})`
                : ""
            }`}
          </small>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="border rounded flex-grow-1">
          <RichTextEditor
            value={letter}
            onChange={(letter) => setLetter(letter)}
            name="letter"
            id="letter"
            ref={letterRef}
            required
          />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>{" "}
        <Button color={"success"} onClick={onSubmit}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ManageCeoLetterModal;

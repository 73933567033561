import React, { useReducer, createContext, useContext } from "react";

const UPLOAD_TYPE_STEP = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  errorModal: false,
  errorBody: "",
  statements: false,
  documents: [],
  active: false,
  files: [],
  step: UPLOAD_TYPE_STEP,
  program: "",
  fundQuarter: null,
  investor: null,
};

const DocumentsUploadFlowContext = createContext(initialState);

const DocumentsUploadFlowProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <DocumentsUploadFlowContext.Provider value={stateAndDispatch}>
      {children}
    </DocumentsUploadFlowContext.Provider>
  );
};

export const useDocumentsUploadFlow = () =>
  useContext(DocumentsUploadFlowContext);

export {
  DocumentsUploadFlowContext,
  DocumentsUploadFlowProvider,
  initialState,
};

import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { usePrograms } from "../../../providers/programsProvider";
import InformationModal from "../../../components/InformationModal";
import {
  Button,
  Container,
  Form,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import {
  faCheck,
  faChevronLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { programDistributionsApi } from "../../../services/programDistributionServices";
import { investorDistributionsApi } from "../../../services/investorDistributionServices";

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;

const AddDistributions = () => {
  const { year, quarter } = useParams();
  const navigate = useNavigate();

  const [programsContext] = usePrograms();

  const [loading, setLoading] = useState();
  const [investors, setInvestors] = useState([]);
  const [allPaid, setAllPaid] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSubmit = async (evt) => {
    evt.preventDefault();
    try {
      setLoading(true);
      if (allPaid) {
        investors.map((i) => {
          i.paid = true;
          return i;
        });
      }
      await programDistributionsApi.createDistribution({
        investors,
        programId: programsContext.program.id,
        year,
        quarter,
      });
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: `Add Distributions`,
        body: "Distributions added successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          navigate(
            `/back/programs/${programsContext.program.id}/distributions`
          );
        },
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Oops, there was an error with your request",
        body: err?.response?.data[0]?.msg || "Please try again",
      });
    }
  };

  useEffect(() => {
    const fetchFormatData = async () => {
      if (!programsContext.program) {
        navigate("/back/programs");
      } else if (
        parseInt(programsContext.program.programTypeId) !==
          TYPE_PRIVATE_EQUITY &&
        parseInt(programsContext.program.programTypeId) !== TYPE_REAL_ESTATE
      ) {
        setInformationModal({
          isOpen: true,
          title: "Heads Up!",
          body: "The Program has not the correct type.",
          onClose: () => navigate("/back/programs"),
        });
      } else {
        const distributions = await investorDistributionsApi.getDistributions({
          programId: programsContext.program.id,
          year,
          quarter,
        });
        const distributionsPerInvestor = (
          distributions.count ? distributions.data : []
        ).reduce((p, c) => {
          p[c.userId] = c;
          return p;
        }, {});
        const investors = programsContext.program.programInvestments.map(
          (programInvestment) => ({
            investorId: programInvestment.investor.id,
            investorName: programInvestment.investor.name,
            amount:
              distributionsPerInvestor[programInvestment.investor.id]?.amount ||
              "",
            paid: distributionsPerInvestor[programInvestment.investor.id]?.paid,
          })
        );
        setInvestors(investors);
      }
    };
    fetchFormatData();
  }, [programsContext.program, quarter, year, navigate]);

  const onMarkAllPaid = () => setAllPaid(!allPaid);

  return loading ? (
    <Loader />
  ) : (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <div className="p-0 d-flex flex-column align-items-start">
            <span>Add Distributions</span>
            <Button
              onClick={() => navigate(-1)}
              className="mt-1 rounded"
              color="info"
              size="sm"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="ml-2">Back</span>
            </Button>
          </div>
          <div>
            <Button
              color={allPaid ? `danger` : "success"}
              size="sm"
              className="rounded ml-4 flex-shrink-0 d-flex align-items-center"
              onClick={onMarkAllPaid}
            >
              <FontAwesomeIcon icon={allPaid ? faTimes : faCheck} />
              <span className="mx-2">
                {allPaid ? "Unmark" : "Mark"} All Paid
              </span>
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      <Row>
        <Form className="w-100" onSubmit={onSubmit}>
          <ListGroup className="col-12 p-0">
            <ListGroupItem
              className="text-underline py-2 font-weight-bold d-flex justify-content-between bg-light"
              tag="div"
            >
              <span>Investor</span>
              <span>Amount</span>
            </ListGroupItem>
            {investors.map((investor, i) => (
              <ListGroupItem
                key={i}
                className="py-2 d-flex justify-content-between align-items-center"
                tag="div"
              >
                <span>{investor.investorName}</span>
                <Input
                  required={true}
                  value={investor.amount}
                  onChange={(evt) => {
                    const newInvestors = [...investors];
                    newInvestors.splice(i, 1, {
                      ...investor,
                      amount: evt.currentTarget.value,
                    });
                    setInvestors(newInvestors);
                  }}
                  type="number"
                  name={`${investor.investorId}-amount`}
                  id={`${investor.investorId}-amount`}
                  placeholder="Enter the amount.."
                  className="width-200"
                />
              </ListGroupItem>
            ))}
          </ListGroup>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <Button color="warning">Discard</Button>
            <Button type="submit" color="success">
              Save
            </Button>
          </div>
        </Form>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default AddDistributions;

import React from "react";

import { Badge, ListGroup, ListGroupItem, Row } from "reactstrap";
import { utils } from "../../../utils/utils";

const TYPE_ALTERNATIVE = 4;

const MyInvestment = ({ program }) => {
  const programInvestment = program.programInvestments[0];
  const programStakeholder = program.stakeholders[0];
  const investorProgramOwnership =
    programInvestment && program.units
      ? programInvestment.units / program.units
      : 0;
  const programFundInvestment = program.programFundInvestments[0]?.amount || 0;

  return (
    <div className="d-flex flex-column col-12 px-0">
      {programInvestment ? (
        <Row className="mx-0 flex-grow-1 mb-3">
          <ListGroup flush className="flex-grow-1 text-left rounded">
            <ListGroupItem
              className="bg-transparent px-0 pb-2 border-0 font-weight-bold text-body d-flex justify-content-between align-items-center"
              tag="div"
            >
              <h4 className="mb-0 text-body">My Investment</h4>
            </ListGroupItem>
            <ListGroupItem
              className="pb-2 border-bottom align-items-center d-flex justify-content-between"
              tag="div"
            >
              <span>{programInvestment?.units} Units</span>
              <span>{utils.formatCurrency(programInvestment.capital)}</span>
              {program.programStatus ? (
                <Badge color="success">{program.programStatus.name}</Badge>
              ) : null}
            </ListGroupItem>
            {program.programTypeId === TYPE_ALTERNATIVE ? (
              <ListGroupItem
                className="pb-2 d-flex justify-content-between"
                tag="div"
              >
                <span>Additional Investment</span>
                <span>
                  {utils.formatCurrency(
                    investorProgramOwnership * programFundInvestment
                  )}
                </span>
              </ListGroupItem>
            ) : null}
          </ListGroup>
        </Row>
      ) : null}
      {programStakeholder ? (
        <Row className="mx-0 flex-grow-1">
          <ListGroup flush className="flex-grow-1 text-left rounded">
            <ListGroupItem
              className="bg-transparent px-0 pb-2 border-0 font-weight-bold text-body list-group-item"
              tag="div"
            >
              <h4 className="mb-0 text-body">Property Company</h4>
            </ListGroupItem>
            <ListGroupItem
              className="pb-2 border-bottom d-flex justify-content-between"
              tag="div"
            >
              <span>Ownership</span>
              <span>{programStakeholder.percentage}%</span>
            </ListGroupItem>
          </ListGroup>
        </Row>
      ) : null}
    </div>
  );
};

export default MyInvestment;

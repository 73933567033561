import React, { useState } from "react";

import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
} from "reactstrap";
import InputMask from "react-input-mask";

import { utils } from "../../utils/utils";
import Loader from "../Loader";
import { useAuth } from "../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../utils/roles";

const INVESTOR_MODE = 1;
const STAKEHOLDER_MODE = 3;

const UserBasicAttributes = ({
  mode = INVESTOR_MODE,
  includeSafeData,
  loading,
  foundByEmail,
  foundByName,
  foundBySSNumber,
  user,
  onSubmit,
  onAttrEdit,
  children,
  hideButton,
}) => {
  const [showValues, setShowValues] = useState(false);
  const [authContext] = useAuth();

  const authenticatedUser = authContext.currentUser;

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <Row form>
        <Col md={8}>
          <FormGroup>
            <Label for="name">Registered Name</Label>
            <Input
              disabled={user.name?.length && (foundByEmail || foundBySSNumber)}
              maxLength={80}
              required={true}
              type="text"
              onChange={(event) =>
                onAttrEdit("name", event.currentTarget.value)
              }
              name="text"
              id="name"
              placeholder="Registered Name"
              value={user.name || ""}
            />
          </FormGroup>
        </Col>
        <Col
          md={4}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="user-name rounded-circle bg-light img-thumbnail text-muted d-flex align-items-center justify-content-center">
            <span>{utils.nameInitials(user.name)}</span>
          </div>
        </Col>
      </Row>
      <Row form>
        <FormGroup className="col-6">
          <Label for="firstName">First Name</Label>
          <Input
            maxLength={60}
            onChange={(event) =>
              onAttrEdit("firstName", event.currentTarget.value)
            }
            type="firstName"
            name="firstName"
            id="firstName"
            placeholder="First Name"
            value={user.firstName || ""}
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label for="lastName">Last Name</Label>
          <Input
            maxLength={60}
            onChange={(event) =>
              onAttrEdit("lastName", event.currentTarget.value)
            }
            type="lastName"
            name="lastName"
            id="lastName"
            placeholder="Last Name"
            value={user.lastName || ""}
          />
        </FormGroup>
      </Row>
      <Row form className="mx-0">
        <FormGroup className="col-12 px-0">
          <Label for="email">Email</Label>
          <Input
            disabled={user.email?.length && (foundByName || foundBySSNumber)}
            maxLength={60}
            onChange={(event) => onAttrEdit("email", event.currentTarget.value)}
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={user.email || ""}
          />
        </FormGroup>
      </Row>
      <Row form className="mx-0">
        <FormGroup className="col-12 px-0">
          <Label for="phone">Phone</Label>
          {user.phone?.length &&
          (foundByName || foundByEmail || foundBySSNumber) ? (
            <Input
              disabled={true}
              value={user.phone || ""}
              type="text"
              name="phone"
              id="phone"
              placeholder="Phone"
            />
          ) : (
            <InputMask
              mask="+9 (999) 999-9999"
              value={user.phone || ""}
              type="text"
              onChange={(event) =>
                onAttrEdit("phone", event.currentTarget.value)
              }
              name="phone"
              id="phone"
              placeholder="Phone"
            >
              {(inputProps) => <Input {...inputProps} type="text" />}
            </InputMask>
          )}
        </FormGroup>
      </Row>
      {mode === INVESTOR_MODE || mode === STAKEHOLDER_MODE ? (
        <>
          <Row form className="mx-0">
            <FormGroup className="col-12 px-0">
              <Label
                for="notificationEmails"
                className="d-flex justify-content-between align-items-baseline"
              >
                <span>Notification Emails</span>
                <small className="text-warning font-size-6">
                  * Separated by commas
                </small>
              </Label>
              <Input
                disabled={
                  user.notificationEmails?.length &&
                  (foundByName || foundByEmail || foundBySSNumber)
                }
                maxLength={255}
                onChange={(event) =>
                  onAttrEdit("notificationEmails", event.currentTarget.value)
                }
                type="text"
                name="notificationEmails"
                id="notificationEmails"
                placeholder="Notification emails"
                value={user.notificationEmails || ""}
              />
            </FormGroup>
          </Row>
          {includeSafeData ? (
            <Row
              form
              className="mx-0 mb-2 d-flex align-items-end justify-content-start"
            >
              <FormGroup className="px-0 mb-0 flex-grow-1">
                <Label for="ssNumber">S.S. Number</Label>
                <Input
                  disabled={
                    utils.userHasRole(authenticatedUser, CUSTOMER_SUPPORT) ||
                    utils.userHasRole(authenticatedUser, SPONSOR) ||
                    (user.ssNumber?.length && (foundByName || foundByEmail))
                  }
                  maxLength={60}
                  required={true}
                  value={user.ssNumber || ""}
                  type={showValues ? "text" : "password"}
                  onChange={(event) =>
                    onAttrEdit("ssNumber", event.currentTarget.value)
                  }
                  name="ssNumber"
                  id="ssNumber"
                  placeholder="S.S. Number"
                />
              </FormGroup>
              <Button
                type="button"
                style={{ marginBottom: "1px" }}
                size="sm"
                className="ml-2 hide-selector rounded btn-light font-weight-bold text-primary border"
              >
                <CustomInput
                  defaultChecked={showValues}
                  onClick={(event) => setShowValues(event.target.checked)}
                  type="switch"
                  id="showSSNValue"
                  name="showSSNValue"
                  label={"Show"}
                  className="cursor-pointer d-flex align-items-center font-weight-bold text-primary"
                />
              </Button>
            </Row>
          ) : null}
          {children}
        </>
      ) : null}
      {hideButton ? null : loading ? (
        <Loader align="start" size="sm" />
      ) : (
        <Button
          size="sm"
          className="mt-1 rounded float-left"
          type="submit"
          color="primary"
        >
          Save Changes
        </Button>
      )}
    </Form>
  );
};

export default UserBasicAttributes;

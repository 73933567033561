import React, { useState } from "react";
import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Navbar from "../components/admin/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { authApi } from "../services/authServices";
import Loader from "../components/Loader";
import { utils } from "../utils/utils";
import { CUSTOMER_SUPPORT, SPONSOR } from "../utils/roles";

const Admin = ({ children }) => {
  const [authContext, setAuthContext] = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  if (redirectToLogin) {
    return <Navigate to={`/auth/sign-in`} />;
  }

  if (!authContext.currentUser) {
    authApi.adminValidate().then((result) => {
      if (!result.status || !utils.userHasRole(result, "admin")) {
        if (
          !utils.userHasRole(result, CUSTOMER_SUPPORT) &&
          !utils.userHasRole(result, SPONSOR)
        ) {
          return setRedirectToLogin(true);
        }
      }
      setAuthContext({ currentUser: { ...result } });
    });
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Main role="admin" className="d-flex flex-column">
          <Navbar />
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Admin;

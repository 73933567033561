import React, { useState } from "react";

import { ListGroup, Col, ListGroupItem, Row, Button } from "reactstrap";
import { useAuth } from "../../../../providers/authProvider";
import { useStatementsFlow } from "../../../../providers/statementsFlowProvider";
import { programsApi } from "../../../../services/programServices";
import ConfirmationModal from "../../../ConfirmationModal";
import Loader from "../../../Loader";

const ON_PROGRESS_STEP_ACTION = "onProgressStepAction";
const ON_LETTER_STEP_ACTION = "onLetterStepAction";

const Generation = () => {
  const [authContext] = useAuth();
  const [statementsFlow, setStatementsFlow, onStatementsGeneration] =
    useStatementsFlow();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [loading, setLoading] = useState(false);

  const generateStatements = async (data, callback) => {
    if (!data.length) {
      return callback();
    }
    const current = data.pop();
    await programsApi.generateStatements(current);
    return generateStatements(data, callback);
  };

  const onGenerate = () =>
    setConfirmationModal({
      isOpen: true,
      onSubmit: () =>
        onStatementsGeneration(authContext.currentUser.socketToken, () => {
          setLoading(true);
          const data = Object.values(statementsFlow.programs).map(
            ({ program, auditReserve }) => ({
              id: program.id,
              letter: statementsFlow.letter,
              auditReserveAccruedInterest: auditReserve,
              socketToken: authContext.currentUser.socketToken,
            })
          );
          generateStatements(data, () => {
            setLoading(false);
            setStatementsFlow({
              action: ON_PROGRESS_STEP_ACTION,
            });
          });
        }),
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Generate Statements",
      body: `
                <p>Statements are being generated, this will take some time depending on the amount of Investors.</p>
                <span>Once done, you can continue with the flow by clicking the "Preview" button above the progress bar</span>
            `,
    });

  return loading ? (
    <Loader />
  ) : (
    <Col className="flex-grow-1 col-12 height-100 d-flex flex-column justify-content-between">
      <div>
        <h4 className="mb-3">Programs Ready to Generate</h4>
        <ListGroup flush className="text-left border rounded">
          {Object.values(statementsFlow.programs).map(({ program }) => (
            <ListGroupItem
              key={program.id}
              className="pb-2 border-bottom text-body d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>{program.name}</span>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() =>
            setStatementsFlow({
              action: ON_LETTER_STEP_ACTION,
            })
          }
        >
          Back
        </Button>{" "}
        <Button className="ml-2" color={"primary"} onClick={onGenerate}>
          Next
        </Button>
      </Row>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Col>
  );
};

export default Generation;

import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  UncontrolledAlert,
  Row,
  CustomInput,
} from "reactstrap";
import Loader from "../../Loader";
import Input from "reactstrap/lib/Input";
import { usePrograms } from "../../../providers/programsProvider";
import { programsApi } from "../../../services/programServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";
import EmailModal from "../EmailModal";
import {
  useEmailContext,
  emailInitialState,
} from "../../../providers/emailProvider";
import { emailsUtils } from "../../../utils/emails";

const MAX_VALUE = 999999999;
const TYPE_HEDGE_FUND = 3;

const ProgramEditModal = ({ program, onClose }) => {
  const [programsContext] = usePrograms();
  const [name, setName] = useState(program.name);
  const [published, setPublished] = useState(program.published);
  const [auditReserve, setAuditReserve] = useState(program.auditReserve);
  const [nameExists, setNameExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailContext, setEmailContext] = useEmailContext();
  const [investorModal, setInvestorModal] = useState(emailInitialState);
  const [advisorModal, setAdvisorModal] = useState(emailInitialState);
  let investorModalData = {};

  const onNameChange = (event) => {
    const name = event.currentTarget.value;
    setNameExists(
      programsContext.programs.find(
        (p) => p.name === name && p.name !== program.id
      ) !== undefined
    );
    setName(name);
  };

  const onDismiss = () => {
    setName(program.name);
    setNameExists(false);
  };

  const openInvestorModal = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!program.published && published) {
      const defaultSubject = `Welcome to ${program?.sponsor?.name}!`;
      if (emailContext?.subject !== defaultSubject) {
        setEmailContext({
          ...emailInitialState,
          name: `Investors from ${program?.name}`,
          subject: defaultSubject,
          content: emailsUtils.publishingProgramInvestor(
            program?.sponsor?.name
          ),
        });
      }
      setInvestorModal({
        isOpen: true,
        cancelBtnText: "Back",
        onSubmit: async (emailContext) => {
          investorModalData = {
            investorSubject: emailContext.subject,
            investorReplyTo: emailContext.replyTo,
            investorContent: emailContext.content,
          };
          await openAdvisorModal();
        },
        onClose: () => {
          setInvestorModal(emailInitialState);
        },
      });
    } else {
      try {
        await programsApi.updateProgram({
          id: program.id,
          name,
          auditReserve,
          published,
        });
        setLoading(true);
        const updatedProgram = { ...program, name, auditReserve, published };
        onClose(updatedProgram);
      } catch (error) {}
    }
  };

  const openAdvisorModal = async () => {
    setInvestorModal(emailInitialState);
    const defaultSubject = `Welcome to Vulcan Fund Solutions!`;
    if (emailContext?.subject !== defaultSubject) {
      setEmailContext({
        ...emailInitialState,
        name: `Advisors from ${program?.name}`,
        subject: defaultSubject,
        content: emailsUtils.publishingProgramAdvisor(),
      });
    }
    setAdvisorModal({
      isOpen: true,
      cancelBtnText: "Back",
      onSubmit: async (emailContext) => {
        const advisorModalData = {
          advisorSubject: emailContext.subject,
          advisorReplyTo: emailContext.replyTo,
          advisorContent: emailContext.content,
        };
        try {
          await programsApi.updateProgram({
            ...investorModalData,
            ...advisorModalData,
            id: program.id,
            name,
            auditReserve,
            published,
          });
          setAdvisorModal(emailInitialState);
          setEmailContext({ ...emailInitialState });
          const updatedProgram = { ...program, name, auditReserve, published };
          onClose(updatedProgram);
        } catch (error) {}
      },
      onClose: () => {
        setAdvisorModal(emailInitialState);
        openInvestorModal();
      },
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={() => onClose()}>
      &times;
    </Button>
  );

  return advisorModal.isOpen ? (
    <EmailModal {...advisorModal} />
  ) : investorModal.isOpen ? (
    <EmailModal
      {...investorModal}
      changeModeAfterSubmit={false}
      sendBtnText="Next"
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn} className="d-flex justify-content-between">
        Edit Program {program.name}
      </ModalHeader>
      <Form onSubmit={openInvestorModal}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Name
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter the name.."
                    onChange={onNameChange}
                    value={name}
                  />
                </Col>
              </FormGroup>
              {!parseInt(program.programTypeId) === TYPE_HEDGE_FUND ? (
                <FormGroup row>
                  <Label sm={4} className="text-sm-left">
                    Audit Reserve
                  </Label>
                  <Col sm={8}>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      required={true}
                      allowNegativeValue={false}
                      maxLength={20}
                      className="form-control"
                      placeholder="Enter the reserve amount.."
                      value={auditReserve}
                      onValueChange={(value) => {
                        if (!value || value < MAX_VALUE) {
                          setAuditReserve(value);
                        }
                      }}
                    />
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-sm-left">
                  Published
                </Label>
                <Col
                  sm={8}
                  className="d-flex flex-column align-items-baseline justify-content-center"
                >
                  <CustomInput
                    defaultChecked={program.published}
                    onClick={(event) => setPublished(event.target.checked)}
                    type="switch"
                    id="published"
                    name="published"
                    label="Published"
                    className="cursor-pointer"
                  />
                  {!program.published && published ? (
                    <div className="d-flex flex-column align-items-baseline text-left">
                      <small className="text-warning">
                        Invitation emails will be sent for non activated
                        investors.
                      </small>
                    </div>
                  ) : null}
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={onDismiss}
              isOpen={nameExists}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>This name is already in use</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose()}>
                Cancel
              </Button>
              <Button disabled={nameExists} color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ProgramEditModal;

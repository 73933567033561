import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const usersApi = {
  sendEmail: (data) =>
    axios(`/api/users/${data.id}/email`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  sendEmailAll: (data) =>
    axios(`/api/users/send-email-all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  sendSupportEmail: (data) =>
    axios(`/api/users/customer-support/send-email`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  getRoles: () => {
    return axios(`/api/users/roles`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getUsers: (data, role = "admin") => {
    const query = utils.generateQueryString(data);
    return axios(
      data.id ? `/api/users/${role}/${data.id}` : `/api/users/${role}?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createUser: (data) => {
    return axios(`/api/users`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateUser: (data, role = "admin") => {
    return axios(`/api/users/${role}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteUser: (data) => {
    return axios(`/api/users/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteAdvisement: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/advisements${data.id ? `/${data.id}` : ""}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createAdvisement: (data, role) => {
    return axios(`/api/advisements/${role}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  changePassword: (data) => {
    return axios(`/api/users/${data.id}/password`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getAdvisors: () => {
    return axios(`/api/users/advisors`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getInvestors: () => {
    return axios(`/api/users/investors`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getSponsors: () => {
    return axios(`/api/sponsors`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  resendInvite: (data) => {
    return axios(`/api/users/${data.id}/invite`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  forgotPassword: (data) => {
    return axios(`/api/users/password/forgot?email=${data.email}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  resetPassword: (data) => {
    return axios(`/api/users/password/reset?email=${data.email}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  recoverPassword: (data) => {
    return axios(`/api/users/password/recover`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => {
        throw Error(err);
      });
  },
};

import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const programsApi = {
  //program status
  getProgramStatus: () => {
    return axios(`/api/programs/status`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createProgramStatus: (data) =>
    axios("/api/programs/status", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  //BLOTTER
  processMBD: ({ programName, jsonSheet }) => {
    const form_data = new FormData();
    form_data.append("jsonSheet", jsonSheet);
    form_data.append("programName", programName);
    return axios("/api/programs/process-mbd", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => err);
  },
  //PROGRAM
  createProgram: (data) =>
    axios("/api/programs", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  updateProgram: (data) =>
    axios(`/api/programs/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data: {
        name: data.name,
        auditReserve: data.auditReserve,
        published: data.published,
        investorSubject: data.investorSubject,
        investorReplyTo: data.investorReplyTo,
        investorContent: data.investorContent,
        advisorSubject: data.advisorSubject,
        advisorReplyTo: data.advisorReplyTo,
        advisorContent: data.advisorContent,
        programStatusId: data.programStatusId,
        landingPageDesign: data.landingPageDesign,
        landingPageHtml: data.landingPageHtml,
      },
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteProgram: ({ id }) =>
    axios(`/api/programs/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  sendEmail: (data) => {
    const form_data = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      form_data.append(key, value);
    });
    return axios(`/api/programs/${data.id}/email`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  sendEmailAll: (data) => {
    const form_data = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      form_data.append(key, key === "programs" ? JSON.stringify(value) : value);
    });
    return axios(`/api/programs/send-email-all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getAllPrograms: (data) => {
    const query = utils.generateQueryString(data);
    return axios(
      data.id ? `/api/programs/all/${data.id}` : `/api/programs/all?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getPrograms: (data) => {
    const query = utils.generateQueryString(data);
    return axios(
      data.id ? `/api/programs/${data.id}` : `/api/programs?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //STATEMENTS
  getProgramStatements: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/program-statements?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateProgramStatement: (data) => {
    return axios(`/api/program-statements/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getStatements: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/statements?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getManualStatements: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/statements/manual?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  bulkDeleteStatement: (ids) => {
    return axios(`/api/statements/bulk/${ids}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteStatement: (data) => {
    return axios(`/api/statements/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  bulkDeleteManualStatement: (ids) => {
    return axios(`/api/statements/manual/bulk/${ids}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteManualStatement: (data) => {
    return axios(`/api/statements/manual/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getAllStatements: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/statements/all?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getAllManualStatements: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/statements/manual/all?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  downloadStatements: (data) => {
    return axios(`/api/programs/${data.id}/download-statements`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
      responseType: "arraybuffer",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  generateStatementPreview: (data) => {
    const form_data = new FormData();
    form_data.append("letter", data.letter);
    form_data.append(
      "auditReserveAccruedInterest",
      data.auditReserveAccruedInterest
    );
    return axios(`/api/programs/${data.id}/generate-statement-preview`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      responseType: "blob",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  generateStatements: (data) => {
    const form_data = new FormData();
    form_data.append("letter", data.letter);
    form_data.append(
      "auditReserveAccruedInterest",
      data.auditReserveAccruedInterest
    );
    form_data.append("socketToken", data.socketToken);
    return axios(`/api/programs/${data.id}/generate-statements`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  sendStatements: (data) => {
    return axios(`/api/programs/${data.id}/send-statements`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //CLOSE DOCUMENTS
  downloadCloseDocuments: (data) => {
    return axios(`/api/programs/${data.id}/download-close-documents`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
      responseType: "arraybuffer",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  generateCloseDocuments: (data) => {
    const form_data = new FormData();
    form_data.append("socketToken", data.socketToken);
    return axios(`/api/programs/${data.id}/generate-close-documents`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: form_data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  sendCloseDocuments: (data) => {
    return axios(`/api/programs/${data.id}/send-close-documents`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  ///STAKEHOLDERS
  createStakeholder: (data) =>
    axios(`/api/programs/${data.programId}/stakeholders`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  updateStakeholder: (data) =>
    axios(`/api/programs/${data.programId}/stakeholders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteStakeholder: (data) =>
    axios(`/api/programs/${data.programId}/stakeholders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  // INVESTMENTS
  createInvestment: (data) =>
    axios(`/api/programs/${data.programId}/investments`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteInvestment: (data) =>
    axios(`/api/programs/${data.programId}/investments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  // INVESTMETS TRANSACTIONS
  createInvestmentTransaction: (data) =>
    axios(
      `/api/programs/${data.programId}/investments/${data.investmentId}/transactions`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteInvestmentTransaction: (data) =>
    axios(
      `/api/programs/${data.programId}/investments/${data.investmentId}/transactions/${data.id}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  // INVESTMETS TRANSACTION CATEGORIES
  createInvestmentTransactionCategory: (data) =>
    axios(`/api/programs/investments/transactions/categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  getAllInvestmentTransactionCategories: () =>
    axios(`/api/programs/investments/transactions/categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteInvestmentTransactionCategory: (id) =>
    axios(`/api/programs/investments/transactions/categories/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
};

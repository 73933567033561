import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Button,
} from "reactstrap";

import Loader from "../../Loader";
import { usersApi } from "../../../services/userServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashRestoreAlt,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../ConfirmationModal";
import CreateAdvisorModal from "./CreateAdvisorModal";

const Advisors = () => {
  const [advisements, setAdvisements] = useState([]);
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();

  const [addAdvisorModal, setAddAdvisorModal] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    setLoading(true);
    usersApi.getAdvisors().then((result) => {
      setAdvisements(
        result?.sort((firstItem, secondItem) =>
          firstItem.advisor.name > secondItem.advisor.name ? 1 : -1
        )
      );
      setLoading(false);
    });
  }, [refresh]);

  const onConfirm = () => {
    const removedAdvisements = advisements.filter(
      (advisement) => advisement.removed
    );
    if (!removedAdvisements.length) {
      return;
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setLoading(true);
        const promises = removedAdvisements.map((advisement) =>
          usersApi.deleteAdvisement({ id: advisement.id })
        );
        Promise.all(promises).then(() => {
          setLoading(false);
          setAdvisements(
            advisements.filter((advisement) => !advisement.removed)
          );
          setConfirmationModal(initConfirmationModal);
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Remove Advisors",
      body: `<p>You selected to remove one or more advisors from your account.</p>
      <p>This means they won't have access to your account anymore.</p>
      <span class="font-weight-bold">You confirm you want to continue?</span>`,
    });
  };

  const onRemove = (index) => {
    advisements[index].removed = true;
    setAdvisements([...advisements]);
  };

  const onUndo = (index) => {
    delete advisements[index].removed;
    setAdvisements([...advisements]);
  };

  return (
    <Card className="col-12 min-vh-25">
      <CardHeader>
        <CardTitle
          tag="h5"
          className="mb-0 d-flex justify-content-between align-items-center"
        >
          <span>Advisors</span>
          <span>
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => setAddAdvisorModal(true)}
              fixedWidth
              className="align-middle cursor-pointer text-primary"
            />
          </span>
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Loader size="sm" />
        ) : advisements.length ? (
          <>
            <Table className="border mb-0">
              <thead className="bg-lighter">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {advisements.map((advisement, i) => (
                  <tr key={i}>
                    <td className="max-width-100 text-truncate">
                      {advisement.advisor.name}
                    </td>
                    <td className="max-width-100 text-truncate">
                      {advisement.advisor.email}
                    </td>
                    <td className="max-width-100 text-truncate">
                      {advisement.advisor.address || "-"}
                    </td>
                    <td className="max-width-100 text-truncate">
                      {advisement.advisor.phone || "-"}
                    </td>
                    <td className="table-action text-center">
                      {advisement?.removed ? (
                        <FontAwesomeIcon
                          icon={faTrashRestoreAlt}
                          onClick={() => onUndo(i)}
                          fixedWidth
                          className="align-middle mr-1 text-success cursor-pointer"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={() => onRemove(i)}
                          fixedWidth
                          className="align-middle mr-1 text-danger cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button onClick={onConfirm} className="mt-3" color="success">
              Save Changes
            </Button>
          </>
        ) : (
          <div className="text-center">You have no advisors</div>
        )}
      </CardBody>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {addAdvisorModal ? (
        <CreateAdvisorModal
          onSubmit={() => {
            setRefresh(!refresh);
            setAddAdvisorModal(false);
          }}
          onClose={() => setAddAdvisorModal(false)}
        />
      ) : null}
    </Card>
  );
};

export default Advisors;

import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  user: null,
  isActive: false,
  mode: DEFAULT_MODE,
  content: "",
  subject: "",
  replyTo: "",
};

const UserEmailContext = createContext(initialState);

const UserEmailProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <UserEmailContext.Provider value={stateAndDispatch}>
      {children}
    </UserEmailContext.Provider>
  );
};

export const useUserEmail = () => useContext(UserEmailContext);

export { UserEmailContext, UserEmailProvider, initialState };

import React, { useState } from "react";
import SidebarCategory from "./SidebarCategory";
import SidebarItem from "./SidebarItem";
import { useLocation } from "react-router-dom";

const GREEN_ROCK_NAME = "Green Rock";

const initOpenRoutes = (routes, location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    _routes = Object.assign({}, _routes, { [index]: isActive || isOpen });
  });

  return _routes;
};

const SidebarPrograms = ({ entries }) => {
  const location = useLocation();
  const [openRoutes, setOpenRoutes] = useState(() =>
    initOpenRoutes(entries, location)
  );

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );
    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const otherEntries = entries.filter(
    (entry) => entry.name !== GREEN_ROCK_NAME
  );
  const greenRockEntry = entries.filter(
    (entry) => entry.name === GREEN_ROCK_NAME
  );

  const sortedEntries = [...otherEntries, ...greenRockEntry];

  return sortedEntries.map((category, index) => (
    <React.Fragment key={index}>
      <SidebarCategory
        name={category.name}
        badgeColor={category.badgeColor}
        badgeText={category.badgeText}
        icon={category.icon}
        to={category.path}
        isOpen={openRoutes[index]}
        onClick={() => toggle(index)}
      >
        {Object.keys(category.children)
          .sort((a, b) => b - a)
          .map((key, index) => (
            <div key={index}>
              <li className="sidebar-header">{key}</li>
              {category.children[key].map((route, index) => (
                <SidebarItem key={index} name={route.name} to={route.path} />
              ))}
            </div>
          ))}
      </SidebarCategory>
    </React.Fragment>
  ));
};

export default SidebarPrograms;

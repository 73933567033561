import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { usersApi } from "../../services/userServices";
import InformationModal from "../InformationModal";
import UserBasicAttributes from "./UserBasicAttributes";
import { utils } from "../../utils/utils";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../utils/roles";
import { useAuth } from "../../providers/authProvider";
import UserAddress from "./UserAddress";

const USER_ROLE_INVESTOR = 3;
const USER_ROLE_STAKEHOLDER = 4;

const STATUS_ACTIVE = "3";
const ADVISOR_MODE = 2;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const AdvisorModalDetails = ({ advisor, onSubmit, onClose }) => {
  const [selectedInvestors, setSelectedInvestors] = useState(
    advisor.investors.map((investor) => {
      return { label: investor.name, value: investor.id };
    })
  );
  const [investorsOptions, setInvestorsOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [advisementsLoading, setAdvisementsLoading] = useState();
  const [authContext] = useAuth();

  const [loading, setLoading] = useState({});
  const [user, setUser] = useState(advisor);

  const authenticatedUser = authContext.currentUser;

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  useEffect(() => {
    setAdvisementsLoading(true);
    const hasCSRole = utils.userHasRole(authenticatedUser, CUSTOMER_SUPPORT);
    const hasSponsorRole = utils.userHasRole(authenticatedUser, SPONSOR);
    const userRole = hasCSRole
      ? "customer-support"
      : hasSponsorRole
      ? "sponsor"
      : "admin";
    Promise.all([
      usersApi.getUsers(
        {
          role: USER_ROLE_INVESTOR,
          search: search,
          page: 0,
          pageSize: search.length >= 2 ? 999999999 : 10,
        },
        userRole
      ),
      usersApi.getUsers(
        {
          role: USER_ROLE_STAKEHOLDER,
          search: search,
          page: 0,
          pageSize: search.length >= 2 ? 999999999 : 10,
        },
        userRole
      ),
    ]).then(([stakeholders, investors]) => {
      setInvestorsOptions([
        ...stakeholders.data.map((stakeholder) => {
          return { label: stakeholder.name, value: stakeholder.id };
        }),
        ...investors.data.map((investor) => {
          return { label: investor.name, value: investor.id };
        }),
      ]);
      setAdvisementsLoading(false);
    });
  }, [search, authenticatedUser]);

  const setAdvisements = (investors) => {
    setAdvisementsLoading(true);
    const removed = selectedInvestors
      .filter((investor) => !investors.find((i) => i.value === investor.value))
      .map((i) => i.value);
    const added = investors
      .filter(
        (investor) => !selectedInvestors.find((i) => i.value === investor.value)
      )
      .map((i) => i.value);
    Promise.all([
      ...removed.map((investor) =>
        usersApi.deleteAdvisement({
          investorId: investor,
          advisorId: advisor.id,
        })
      ),
      ...added.map((investor) =>
        usersApi.createAdvisement(
          { investorId: investor, advisorId: advisor.id },
          "admin"
        )
      ),
    ]).then(() => {
      setAdvisementsLoading(false);
      setSelectedInvestors(investors);
      onSubmit();
    });
  };

  const onSaveChanges = (mode) => {
    if (loading[mode]) {
      return;
    }
    setLoading({ [mode]: true });
    usersApi
      .updateUser(
        {
          ...user,
          email: user.email?.length ? user.email : null,
        },
        "admin"
      )
      .then(() => {
        setLoading({ [mode]: false });
        onSubmit();
        setUser({ ...user, changed: false });
        setInformationModal({
          isOpen: true,
          title: "Success",
          body: "User updated successfully.",
        });
      })
      .catch((err) => {
        setLoading({ [mode]: false });
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  const onAttrEdit = (field, value) =>
    setUser({ ...user, [field]: value, changed: true });

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn} className="text-body advisor-details">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <span>{advisor.name}</span>
            {advisor.address?.length || advisor.phone?.length ? (
              <>
                <br />
                <small className="text-muted">
                  {advisor.address}, {advisor.phone}
                </small>
              </>
            ) : null}
          </div>
          {advisor.status !== STATUS_ACTIVE ? (
            <div className="d-flex flex-shrink-0 align-items-center">
              <FontAwesomeIcon
                className="text-warning"
                icon={faExclamationCircle}
                fixedWidth
              />
              <small className="text-muted ml-1">user not activated</small>
            </div>
          ) : (
            <div className="d-flex flex-shrink-0 align-items-center">
              <FontAwesomeIcon
                className="text-success"
                icon={faCheckCircle}
                fixedWidth
              />
              <small className="text-muted ml-1">user activated</small>
            </div>
          )}
        </div>
      </ModalHeader>
      <ModalBody className="text-center">
        <ListGroup flush className="text-left border rounded">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
            tag="div"
          >
            Personal Info
          </ListGroupItem>
          <ListGroupItem className="pb-2 border-bottom" tag="div">
            <UserBasicAttributes
              mode={ADVISOR_MODE}
              user={user}
              loading={loading["basic"]}
              onSubmit={() => onSaveChanges("basic")}
              onAttrEdit={onAttrEdit}
            />
          </ListGroupItem>
        </ListGroup>
        <ListGroup flush className="text-left border rounded mt-2">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
            tag="div"
          >
            Adress
          </ListGroupItem>
          <ListGroupItem className="pb-2 border-bottom" tag="div">
            <UserAddress
              user={user}
              onAttrEdit={onAttrEdit}
              loading={loading["basic"]}
              onSubmit={() => onSaveChanges("basic")}
            />
          </ListGroupItem>
        </ListGroup>
        {!utils.userHasRole(authenticatedUser, CUSTOMER_SUPPORT) && (
          <ListGroup flush className="text-left border rounded mt-3">
            <ListGroupItem
              className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
              tag="div"
            >
              Investors
            </ListGroupItem>
            <ListGroupItem
              className="pb-2 border-bottom d-flex flex-column align-items-start"
              tag="div"
            >
              <Select
                placeholder={
                  <span className="text-muted">Select the investors..</span>
                }
                noOptionsMessage={() => "No investors found"}
                styles={style}
                className="col-12 px-0 border rounded my-2"
                options={investorsOptions}
                closeMenuOnSelect={true}
                components={animatedComponents}
                defaultValue={selectedInvestors}
                isMulti
                isSearchable
                inputValue={search}
                onInputChange={(value) => setSearch(value)}
                onChange={(selected) => setAdvisements(selected)}
                isLoading={advisementsLoading}
              />
            </ListGroupItem>
          </ListGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AdvisorModalDetails;

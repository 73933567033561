import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  name: "",
  fundAssetCategoryId: "",
  capital: undefined,
  mode: DEFAULT_MODE,
  categories: [],
  isModalOpen: false,
};

const FundAssetCreationModalContext = createContext(initialState);

const FundAssetCreationModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <FundAssetCreationModalContext.Provider value={stateAndDispatch}>
      {children}
    </FundAssetCreationModalContext.Provider>
  );
};

export const useFundAssetCreationModal = () =>
  useContext(FundAssetCreationModalContext);

export {
  FundAssetCreationModalContext,
  FundAssetCreationModalProvider,
  initialState,
};

import React, { useCallback, useEffect, useState } from "react";

import FundCapitalTransactionsCreationModal from "./FundCapitalTransactionsCreationModal";
import CategoryCreationModal from "../../CategoryCreationModal";
import { fundsApi } from "../../../../services/fundServices";
import { useFundCapitalTransactionsModal } from "../../../../providers/fundCapitalTransactionsModalProvider";

const DEFAULT_MODE = 1;
const CAPITAL_TRANSACTION_CATEGORY_CREATION_MODE = 2;

const FundCapitalTransactionsModal = () => {
  const [fundCapitalTransactionsModal, setFundCapitalTransactionsModal] =
    useFundCapitalTransactionsModal();
  const [loading, setLoading] = useState(true);

  const setFundCapitalTransactionsModalCb = useCallback(
    (data) => {
      setFundCapitalTransactionsModal(data);
    },
    [setFundCapitalTransactionsModal]
  );

  useEffect(() => {
    setLoading(true);
    fundsApi
      .getFundCapitalTransactionCategories({ page: 0, pageSize: 999 })
      .then((result) => {
        setFundCapitalTransactionsModalCb({ categories: result.data });
        setLoading(false);
      });
  }, [setFundCapitalTransactionsModalCb]);

  const onCapitalTransactionCategorySubmit = (data) => {
    setLoading(true);
    fundsApi.createFundCapitalTransactionCategory(data).then((result) => {
      setLoading(false);
      setFundCapitalTransactionsModal({
        categories: [...fundCapitalTransactionsModal.categories, result],
        transactionCategoryId: result.id,
        mode: DEFAULT_MODE,
      });
    });
  };

  switch (fundCapitalTransactionsModal.mode) {
    case DEFAULT_MODE:
      return <FundCapitalTransactionsCreationModal loading={loading} />;
    case CAPITAL_TRANSACTION_CATEGORY_CREATION_MODE:
      return (
        <CategoryCreationModal
          loading={loading}
          onSubmit={onCapitalTransactionCategorySubmit}
          categories={fundCapitalTransactionsModal.categories}
          onClose={() =>
            setFundCapitalTransactionsModal({ mode: DEFAULT_MODE })
          }
        />
      );
    default:
      return null;
  }
};

export default FundCapitalTransactionsModal;

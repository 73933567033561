import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

const apiPath = "/api/banners";
const generateRequestBody = (data) => {
  const form_data = new FormData();
  form_data.append("name", data.name);
  form_data.append("isActive", data.isActive);
  form_data.append("jsonContent", data.jsonContent);
  form_data.append("htmlContent", data.htmlContent);
  form_data.append("notificationEmail", data.notificationEmail);
  if (data.image) {
    form_data.append("image", data.image);
  }
  if (data.modalImage) {
    form_data.append("modalImage", data.modalImage);
  }

  return form_data;
};

export const bannersApi = {
  createBanner: (data) => {
    const requestBody = generateRequestBody(data);
    return axios(apiPath, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data: requestBody,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateBanner: (data) => {
    const requestBody = generateRequestBody(data);
    return axios(`${apiPath}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data: requestBody,
    })
      .then((result) => result.data)
      .catch((err) => {
        throw err;
      });
  },
  getBanners: async (data) => {
    const query = utils.generateQueryString(data);
    return await axios(`${apiPath}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  sendNotification: async (data) => {
    return await axios(`${apiPath}/email-notification`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => err);
  },
  deleteBanner: (data) => {
    return axios(`${apiPath}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => {
        throw err;
      });
  },
  getReport: async (data) => {
    return axios(`${apiPath}/${data.id}/reports`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
      responseType: "blob",
    })
      .then((result) => result)
      .catch((err) => {
        throw err;
      });
  },
};

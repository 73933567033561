import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

import TermsConditions from "./../components/customer/onboard/TermsConditions";
import SupportModal from "./supportModal";
import InformationModal from "./InformationModal";
import { utils } from "../utils/utils";
import { useAuth } from "../providers/authProvider";

const Footer = () => {
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [supportModal, setSupportModal] = useState({ isOpen: false });
  const [isInvestor, setIsInvestor] = useState(false);
  const [authContext] = useAuth();

  useEffect(() => {
    setIsInvestor(utils.userHasRole(authContext.currentUser, "investor"));
  }, [authContext.currentUser]);

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs={8} className="text-left">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Button
                  tag="a"
                  className="text-muted bg-transparent border-0"
                  onClick={() =>
                    setInformationModal({
                      isOpen: true,
                      title: "Terms & Conditions",
                      rawBody: true,
                      body: <TermsConditions />,
                    })
                  }
                >
                  Terms of Service
                </Button>
                {isInvestor ? (
                  <Button
                    tag="b"
                    className="text-muted bg-transparent border-0 pl-4"
                    onClick={() =>
                      setSupportModal({
                        isOpen: true,
                      })
                    }
                  >
                    Help/Support
                  </Button>
                ) : null}
              </li>
            </ul>
          </Col>
          <Col xs={4} className="text-right">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{" "}
              <Link
                to={{ pathname: "https://www.green-rock.com" }}
                className="text-muted"
                target="_blank"
              >
                Vulcan Fund Solutions
              </Link>
            </p>
          </Col>
        </Row>
        {informationModal.isOpen ? (
          <InformationModal
            rawBody={informationModal.rawBody}
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: "", body: "" })
            }
          />
        ) : supportModal.isOpen ? (
          <SupportModal onClose={() => setSupportModal({ isOpen: false })} />
        ) : null}
      </Container>
    </footer>
  );
};

export default Footer;

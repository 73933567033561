import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

const TYPE_INCOME_ID = "1";
const CREATION_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  id: null,
  date: new Date(),
  incomePeriodStart: new Date(),
  incomePeriodEnd: new Date(moment().add(1, "day").format()),
  fundTransactionCategoryId: "",
  categories: [],
  type: TYPE_INCOME_ID,
  amount: "",
  mode: CREATION_MODE,
  isModalOpen: false,
};

const FundTransactionModalContext = createContext(initialState);

const FundTransactionModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <FundTransactionModalContext.Provider value={stateAndDispatch}>
      {children}
    </FundTransactionModalContext.Provider>
  );
};

export const useFundTransactionModal = () =>
  useContext(FundTransactionModalContext);

export {
  FundTransactionModalContext,
  FundTransactionModalProvider,
  initialState,
};

import React from "react";

const TermsConditions = () => {
  return (
    <div>
      <h4>VULCAN FUNDS SOLUTIONS, LLC END USER AGREEMENT</h4>
      <p>
        By accepting this End User Agreement (this “Agreement”), you agree to be
        bound by and abide by its terms and conditions. Your use of the Vulcan
        Funds Solutions, LLC service (such service, the “Service,” and Vulcan
        Funds Solutions, LLC, “VFS”) indicates your continued acceptance of this
        Agreement. You agree that the electronic acceptance of this Agreement is
        intended to have the same force and effect as if this Agreement were
        physically signed.{" "}
        <strong>
          If you do not accept these terms and conditions, you should click the
          'decline' button and promptly destroy or delete any materials in your
          possession relating to the Service. Additionally, if you do not
          consent to the disclosure of your End User information to the
          investment advisor indicated on your respective investment
          subscription documents, please click the “opt out” button below
        </strong>
        . In this Agreement, the words "you" and "your" refer to the person
        accepting this Agreement, and "VFS®" or "VFS" refers to VFS, Inc. and
        its subsidiaries.
      </p>
      <h5>1. ACCESS TO THE VFS SERVICE</h5>
      <p>
        The Service is an Internet-based hosted platform designed to enable
        business communities to share information through a central hub. You can
        access the Service through VFS' web site, or through certain web portals
        branded by a third party. Portals are areas within the Service that have
        been specially configured for an VFS' client so that users of the
        Service authorized by the client or its designated administrators (each,
        a "Participant") can upload, manage, view, share and track electronic
        files. You have been added to one or more portals as a Participant and
        have been assigned an VFS ID and password. You also may be added to
        other VFS Services as a Participant in the future. The terms of this
        Agreement shall also apply to your use of any and all VFS Services in
        the future.
      </p>
      <p>
        The Service includes but is not limited to any images, "applets",
        photographs, animations, video, audio and text incorporated into the
        Service by VFS, the "look and feel" of the Service, and any proprietary
        software provided to you by VFS for the purpose of accessing and using
        the Service. The Service does not include any data or materials posted
        to a Service by you or any other Participant (an "End User"), such as
        the text of, or attachments to, a publication or comment (such data and
        materials collectively, "End User Data").
      </p>
      <p>
        VFS grants you a non-exclusive, non-sublicensable, revocable,
        non-transferable, limited right and license (the "License") to access
        the Service (via a web site) in order to participate in the business
        being conducted through those Services. You warrant and represent that
        you will only access and/or use those Services, End User Data, and/or
        other resources on the Service to which you have been granted authorized
        access via the Service. This End User Agreement will apply to all use of
        the Service using your VFS ID and password (and any use of the Service
        by you via a standalone software utility configured to access the
        Service with a "role" VFS ID and password).
      </p>
      <p>
        You understand and agree that the License and your VFS ID and password
        are only granted to the individual accepting this Agreement. You agree
        to take all reasonable and prudent steps not to let any other person
        learn or use the ID or password assigned to you except VFS customer
        support staff as necessary. You also agree that you will neither
        register nor use any ID for group purposes or as a role account (nor
        permit the same). VFS may block access to the Service without notice for
        any ID used for group purposes or as a role account. No service bureau
        work, multiple-user license, or time-sharing arrangement for the use of
        the Service is permitted, except as expressly authorized by VFS in
        writing.
      </p>
      <p>
        Access to the Service is provided via the Internet through the World
        Wide Web. You are required to supply all software or hardware needed by
        you to access the Service, such as a computer, an Internet connection,
        and compatible Web browser software. In addition, you may be required to
        download and/or install additional software (e.g., certain software
        plug-ins or software applications) in order to access certain data on
        the Service.
      </p>
      <p>
        Services may contain one or more hyperlinks to third-party websites and
        services. VFS and any portal sponsor(s) are not responsible for
        information gathered from, or the use of, any of these third-party
        websites and services.
      </p>
      <h5>2. OWNERSHIP OF THE VFS SERVICE</h5>
      <p>
        VFS is only providing you with the right to access and use the Service.
        VFS retains sole and exclusive ownership of and all right, title, and
        interest in and to the Service (including ownership of all trade
        secrets, trade dress, copyrights, and other intellectual property rights
        pertaining to the Service, but not including any End User Data) and to
        all modifications and enhancements of the Service, subject only to the
        rights and privileges expressly granted to you by VFS through this
        Agreement. In addition, the Service is presented with a distinctive
        "look and feel," and this "look and feel" is the proprietary property of
        VFS. VFS reserves all rights in and to the Service not expressly granted
        under this Agreement. You must not place any claims, liens, or
        encumbrances on the Service.
      </p>
      <p>
        The Service is protected by U.S. copyright and other U.S. and
        international laws and international treaty provisions. You may not use,
        copy, modify, or distribute the Service (electronically or otherwise),
        including the source code for any portion of the Service, or any copy,
        adaptation, transcription, or merged portion thereof, except as
        expressly authorized by VFS hereunder or otherwise in writing. You may
        not modify, reverse engineer, disassemble, decompile, translate, create
        derivative works from, or otherwise alter the Service, except where such
        action is necessary to develop an independent interoperable program (and
        in such circumstances such action shall only be permitted on the prior
        written consent of VFS, such consent not to be unreasonably withheld or
        delayed). You may not transfer, lease, assign, rent, or sublicense the
        rights granted to you under this Agreement, or make the Service
        available for the use of other persons through your User ID. You may not
        use any portion of the Service or any standalone utility provided for
        use with the Service for any purpose other than its intended purpose.
      </p>
      <h5>3. CONFIDENTIALITY</h5>
      <p>
        You acknowledge that the Service (including without limitation
        standalone software utilities and documentation) has been developed by
        VFS at great expenditures of time, resources, and money and are
        considered proprietary and confidential by VFS. Therefore, you undertake
        that except as needed to use the Service, you will not disclose details
        about the Service to any third party and will exercise the highest
        reasonable degree of care to safeguard the confidentiality thereof. You
        may only use or disclose any personally identifiable information about
        other End Users of the Service that you first learn through your use of
        the Service for business purposes related to the transaction or project
        being effectuated through the Service. This Confidentiality section does
        not cover your disclosure of End User Data, but your ability to disclose
        End User Data may be governed by other confidentiality obligations.
      </p>
      <p>
        VFS is committed to maintaining the confidentiality of End User Data.
        VFS will only access and process End User Data (a) upon the request of
        the VFS client for whom the Services have been provided; (b) at the
        direction of an End User granted access to that data (e.g., in
        furtherance of delivery of the Service or performance of related
        customer support functions); (c) to your investment advisor listed on
        your investment subscription documents; or (d) as otherwise required by
        applicable law. VFS also may use and disclose statistical data regarding
        the use of the Service; however, no Participant, client or particular
        transaction shall be identified in connection with such statistics.
      </p>
      <h5>4. TERMINATION; ACCESS TO SERVICE</h5>
      <p>
        Either you or VFS may terminate this Agreement, your access to one or
        more Services, or your right to use any standalone software utility at
        any time with immediate effect by providing notice of such termination
        to the VFS or the owner of the portal. (As an example without limiting
        the foregoing, if the owner of a portal closes that portal, your access
        to that portal will be terminated.) Without limiting the foregoing, VFS
        may immediately suspend your access to the Service or any utility used
        by you to access or use the Service if your use of the Service or that
        utility (a) may, in our reasonable opinion, impair the ability of other
        End Users to access or use the Service, or (b) is, in our reasonable
        opinion, in violation of any federal, state, local, or foreign laws,
        rules, regulations, and ordinances.
      </p>
      <p>
        Upon termination of this Agreement, the License granted pursuant to this
        End User Agreement will terminate, and you must immediately cease all
        access to and use of the Service through any means. Upon termination of
        this Agreement for any reason or upon discontinuance or abandonment of
        your use of the Service, you must promptly destroy all materials in your
        possession pertaining to the Service (including all copies thereof and
        any proprietary software VFS provided to you, but not including any End
        User Data which is rightfully in your possession). You agree to promptly
        provide VFS with written certification of your compliance with the terms
        of this paragraph upon VFS' request.
      </p>
      <h5>5. COMPLIANCE WITH LAWS</h5>
      <p>
        You acknowledge and agree that your access to and use of the Service,
        and/or your participation in one or more portals, may be subject to
        applicable foreign, federal, state and/or local laws, ordinances, rules,
        and regulations, including without limitation, the rules and regulations
        of foreign, federal, state, and/or local agencies, such as the Federal
        Communications Commission and/or the Securities and Exchange Commission
        (each such agency, a "Regulatory Agency"). You agree to comply with all
        laws, ordinances, rules, regulations, and/or requirements imposed by any
        government or Regulatory Agency on your use, transmission, and
        disclosure of any data or information via the Service. You also
        represent and warrant that any data, media, or other content you
        disseminate through the Service does not violate the intellectual
        property rights or other rights of any third party.
      </p>
      <h5>6. LIMITED WARRANTY</h5>
      <p>
        You acknowledge that neither VFS nor any other third party partner or
        agent of VFS involved in the provision of the Service (each such third
        party, a "Third Party Provider") can ensure that the operation of,
        performance of, and/or access to the Service will be uninterrupted or
        error-free. As your exclusive remedy for any material defect in the
        Service provided hereunder, VFS will attempt through reasonable efforts
        to correct or cure any such reproducible and documented material defect.
        VFS will not be obligated to correct, cure, or otherwise remedy any
        nonconformity or defect in the Service (a) if caused by software or
        other equipment used by you which has not been provided by VFS; (b) if
        you have made any changes whatsoever to the Service; (c) if the Service
        has been misused or damaged in any respect; or (d) if you have not
        reported to VFS the existence and nature of any such nonconformity or
        defect promptly upon discovering it.
      </p>
      <p>
        VFS does not and cannot control the flow of data to or from the Service,
        as such flow depends in large part on the performance of computer
        hardware and Internet services and software provided or controlled by
        third parties (including you), on the public Intranet infrastructure,
        and on other events beyond the reasonable control of VFS. At times,
        action or inaction of third parties, hardware/software problems with the
        public Internet infrastructure or with your computing equipment, or
        other events beyond the reasonable control of VFS can impair or disrupt
        your connection to the Service. Save as specified in the foregoing
        paragraph, VFS will not be liable for any such interruptions in your
        ability to access the Service resulting from or related to such
        services, software, or events.
      </p>
      <p>
        You acknowledge and agree that VFS shall not be responsible for the
        content of End User Data or the modification, use or publication of End
        User Data by you or any other Participant or third party (other than
        VFS' agents and subcontractors). VFS shall not be responsible for the
        accuracy, nature, content or completeness of information obtained by you
        from a portal or otherwise in connection with the Service.
      </p>
      <p>
        EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE SERVICE IS LICENSED
        TO YOU "AS-IS," AND VFS DISCLAIMS ANY AND ALL EXPRESS OR IMPLIED
        PROMISES, REPRESENTATIONS, AND WARRANTIES OF ANY KIND WITH RESPECT TO
        THE SERVICE, INCLUDING BUT NOT LIMITED TO ITS CONDITION, CONFORMITY TO
        ANY REPRESENTATION OR DESCRIPTION, ITS COMPATIBILITY WITH ALL EQUIPMENT
        AND SOFTWARE CONFIGURATIONS, THE EXISTENCE OF ANY LATENT OR PATENT
        DEFECTS, ANY NEGLIGENCE, WARRANTIES OF TITLE AGAINST INFRINGEMENT, AND
        ITS MERCHANTABILITY OR FITNESS FOR A PARTICULAR USE OR PURPOSE. SOME
        STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
        EXCLUSIONS MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL
        RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE TO
        STATE.
      </p>
      <h5>7. DATA</h5>
      <p>
        You acknowledge that the transmission of data via the Service is subject
        to the likelihood of human and machine errors, omissions, delays, and
        losses (including but not limited to viruses or inadvertent loss of data
        or damage to media) that may give rise to loss or damage. VFS will not
        be liable for any such errors, omissions, delays, or losses, except to
        the extent caused by its gross negligence or willful misconduct (and
        subject to all other terms and conditions of this Agreement).
      </p>
      <p>
        When an End User accesses data on the Service (e.g., a publication,
        comment, or attachments to a publication or comment), a copy of that
        data is copied to that user's local computer (this is called "caching"
        and is part of how web browsers work). Caching is used to improve the
        speed of the Service when you repeatedly access graphics or data during
        a session. Once pages and/or data have been cached on your local
        computer that data is beyond the control of VFS, and may be accessible
        to anyone with access to your computer. Each End User assumes all risk
        and liability associated with any data cached to that End User's local
        computer. VFS strongly recommends that your empty your web browser cache
        when you log out of an VFS session to ensure that any cached files from
        your VFS session are deleted from your computer.{" "}
      </p>
      <p>
        The Service should only be used as a conduit for information, not as
        primary information storage. As an End User, you are responsible for
        adopting reasonable measures to limit the impact of data loss (such as
        corruption or virus infection), including (a) keeping on your local
        computer backup copies of data you transmit via the Service in the event
        you have to re-transmit the data; (b) verifying the data in documents or
        other materials before transmitting them via the Service; and (c)
        verifying that documents and other information you have posted to the
        Service has been properly posted and have been permissioned to only
        those persons to whom you wish to grant access. You also understand that
        other End Users with appropriate access rights may have access to End
        User Data you transmit through the Service. You shall also run antivirus
        software on any computer you use to access the Service and shall ensure
        that documents and files transmitted through the Service have been
        scanned for viruses.
      </p>
      <h5>8. PRIVACY POLICY; EU DATA PROTECTION</h5>
      <p>
        The VFS Privacy Policy governs use of data and information provided to
        VFS (the "Privacy Policy"). By accepting this Agreement you also
        acknowledge that you have read and agree to the terms of the Privacy
        Policy. You can view the Privacy Policy by clicking here. You consent to
        the receipt of alerts from VFS regarding information posted to Services
        on which you are a Participant, and other email communications from VFS
        relating to the Service.
      </p>
      <p>
        Only in circumstances where you are a "data controller" pursuant to the
        terms of Directive 95/46/EEC and its applicable implementing legislation
        ("European Data Protection Legislation") shall the following provisions
        apply:
      </p>
      <p>
        (a) each party shall comply with the European Data Protection
        Legislation in connection with the performance of its obligations and
        the exercise of its rights under this Agreement; and
      </p>
      <p>
        (b) without prejudice to paragraph (a), with respect to the processing
        of personal data by VFS under this Agreement, VFS shall take appropriate
        technical and organizational security measures against unauthorized or
        unlawful processing of personal data supplied to it by you and against
        accidental loss or destruction of, or damage to, such personal data in
        accordance with the Seventh Data Protection Principle as set out in the
        European Data Protection Legislation (the "Seventh Principle"). VFS will
        not process any personal data under this Agreement unless, in accordance
        with the requirements of the Seventh Principle, VFS is acting on your
        express instructions, it being understood such instructions shall be
        deemed to have been given by you to VFS by virtue of this paragraph (b),
        provided that such processing relates exclusively to the provision of
        the Service.
      </p>
      <h5>9. DISCLAIMER OF LIABILITY</h5>
      <p>
        IN NO EVENT WILL VFS (INCLUDING ITS CURRENT AND FORMER SHAREHOLDERS,
        DIRECTORS, OFFICERS, EMPLOYEES AND AGENT) OR ANY THIRD PARTY PROVIDER BE
        LIABLE TO YOU IN RELATION TO THIS AGREEMENT FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING
        WITHOUT LIMITATION LOSS OF PROFITS, LOSS OF BUSINESS OPPORTUNITY, LOSS
        OF REVENUES OR SAVINGS, LOSS OF DATA OR COSTS OF RECREATING LOST DATA)
        WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, EVEN IF VFS
        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION
        UPON DAMAGES AND CLAIMS IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER
        OTHER PROVISIONS OF THIS AGREEMENT HAVE BEEN BREACHED OR HAVE BEEN HELD
        TO BE INVALID OR INEFFECTIVE.
      </p>
      <p>
        FURTHER, THE AGGREGATE LIABILITY OF VFS (INCLUDING ITS CURRENT AND
        FORMER SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS) AND ANY
        THIRD PARTY PROVIDER TO YOU IN RELATION TO THIS AGREEMENT WILL NOT IN
        ANY EVENT EXCEED THE AGGREGATE AMOUNT OF ONE THOUSAND DOLLARS
        ($1,000.00). THE FOREGOING NOTWITHSTANDING, NOTHING IN THIS AGREEMENT
        SHALL EXCLUDE OR OTHERWISE LIMIT ANY LIABILITY FOR (A) PERSONAL INJURY
        OR DEATH CAUSED DIRECTLY BY THE NEGLIGENCE OF VFS OR A THIRD PARTY; OR
        (B) FRAUDULENT MISREPRESENTATION; OR (C) WILLFUL MISCONDUCT. THE
        FOREGOING LIMITATIONS ARE A FUNDAMENTAL PART OF THE BASIS OF THE BARGAIN
        HEREUNDER.
      </p>
      <p>
        Without limiting the foregoing, in no event will any third party
        involved with or assisting in the marketing and/or development of the
        Service have any responsibility or liability for any claims or damages
        whatsoever in any way connected to the Service or their use, whether in
        tort or otherwise. The provisions of this Section 9 will operate for the
        benefit of and may be enforced by any such third party.
      </p>
      <h5>10. INDEMNIFICATION RELATING TO POSTED DATA</h5>
      <p>
        If you post End User Data to a Service for other Participants to view,
        you will hold harmless and indemnify VFS from and against any and all
        damages, losses, costs and expenses (including reasonable attorneys'
        fees) incurred by VFS in connection with any action or proceeding
        brought by a third party (excluding subcontractors and agents of VFS)
        that is based on any material violation by you of any applicable law,
        regulation, third party right or third party agreement governing the
        posting or use of such End User Data.
      </p>
      <h5>11. REMEDY</h5>
      <p>
        You acknowledge that your breach of any of the provisions of this
        Agreement may cause immediate and irreparable harm to VFS for which VFS
        may not have an adequate remedy in money or damages. VFS will therefore
        be entitled to obtain an injunction against such breach from any court
        of competent jurisdiction immediately upon request and will be entitled
        to recover from you the costs incurred in seeking such an injunction.
        VFS' right to obtain injunctive relief will not limit its right to seek
        or obtain further remedies.
      </p>
      <h5>12. JURISDICTION</h5>
      <p>
        This Agreement is governed by, and construed in accordance with, the
        laws of the State of Alabama, United States of America, without regard
        to conflict of law rules. The parties hereto (a) consent to the
        exclusive jurisdiction of the courts of the State of Alabama or the
        federal courts in Jefferson County, Alabama, and (b) consent that any
        process or notice or motion or other application to the court or judge
        thereof may be served within or without the State of Alabama by
        registered or certified mail or internationally-recognized courier
        service, or by personal service, provided a reasonable time for
        appearance is allowed.
      </p>
      <h5>13. ASSIGNMENT</h5>
      <p>
        You may not assign, novate or otherwise transfer any rights or
        obligations under this Agreement without VFS' prior written consent. Any
        attempted or purported assignment, delegation or other transfer by you
        without such consent shall be void.
      </p>
      <h5>14. SURVIVAL</h5>
      <p>
        All provisions of this Agreement which expressly or by implication
        continue to govern the parties' rights and obligations after termination
        of use of the Service shall survive notwithstanding completion of any
        transaction or project in connection with which the Service is used or
        deletion of any portal to which you are given access.
      </p>
      <h5>15. MISCELLANEOUS</h5>
      <p>
        Each party acknowledges and agrees that you may use the Service in your
        capacity as an employee, officer, director, member (in the case of a
        limited liability company) or partner of a company, partnership or other
        organization (an "Employer"). If your Employer has a written agreement
        with VFS in place for the use of the Service, the terms of such written
        agreement will prevail over any inconsistent terms of this Agreement
        relating to the use of the Service by you on Employer's behalf. Except
        as otherwise specified in a written agreement with an Employer, this
        Agreement is the complete and exclusive statement of the agreement
        between you and VFS and supersedes any and all other proposals or
        agreements, written or oral, and any other communications between you
        and VFS relating to your use of the Service (and you shall have no right
        of action (except in the case of fraud) against VFS in connection with
        any such proposals, agreements or communications). If any provision of
        this Agreement is held to be unenforceable for any reason, such
        provision will be reformed only to the extent necessary to make it
        enforceable, and such holding will not impair the validity, legality, or
        enforceability of the remaining provisions. No waiver by VFS of any
        breach of any term or condition of this Agreement will constitute a
        waiver of, or consent to, any subsequent breach of the same or any other
        term or condition of this Agreement. Except as explicitly set forth
        herein, no other act, document, usage, custom, or course of dealing
        shall be deemed to amend or modify this Agreement. Notices pursuant to
        this Agreement shall reference this Agreement and shall be provided (a)
        if to VFS, by personal delivery, registered mail return receipt
        requested, or internationally-recognized courier service to Vulcan Fund
        Solutions, LLC, 2015 3rd Avenue North, Birmingham, Alabama 35216, Attn:
        General Counsel; or (b) if to you, by email to the email address
        provided as your User ID. Notice shall be deemed effective upon
        delivery.
      </p>
      <h5>16. CHANGES TO END USER AGREEMENT</h5>
      <p>
        VFS will have the right to modify this Agreement from time to time. VFS
        will notify you of any such material modification by posting a notice on
        the Service that VFS has so modified the Agreement. You understand and
        agree that your continued use of the Service following the posting of
        such notice indicates your acceptance of any such modifications, which
        will become a part of this Agreement. We will not post notification of
        non-material changes.
      </p>
      <h5>
        BY ACCEPTING THIS AGREEMENT AND CONTINUING TO USE THE SERVICE, YOU
        ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT AND AGREE
        TO BE BOUND BY ITS TERMS AND CONDITIONS.
      </h5>
    </div>
  );
};

export default TermsConditions;

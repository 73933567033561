import React, { useEffect, useState, useCallback, useRef } from "react";

import { Container, Row, Button } from "reactstrap";

import Header from "../../../components/Header";
import { programsApi } from "../../../services/programServices";
import Loader from "../../../components/Loader";
import { useParams } from "react-router-dom";
import InformationModal from "../../../components/InformationModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { usePrograms } from "../../../providers/programsProvider";
import EmailEditor from "react-email-editor";
import { awsApi } from "../../../services/awsServices";

const OPTIONS = {
  tools: {
    button: {
      enabled: false,
    },
    html: {
      enabled: true,
    },
    menu: {
      enabled: false,
    },
  },
};

const Dashboard = () => {
  const params = useParams();
  const [programsContext, setProgramsContext] = usePrograms();

  const emailEditorRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const setProgramsContextCb = useCallback(
    (data) => setProgramsContext(data),
    [setProgramsContext]
  );

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    setLoading(true);
    programsApi.getAllPrograms({ id: params.programId }).then((program) => {
      setProgramsContextCb({ program });
      setLoading(false);
    });
  }, [params.programId, setProgramsContextCb, programsContext.refresh]);

  const onSave = () => {
    try {
      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        setConfirmationModal({
          isOpen: true,
          onSubmit: async () => {
            setConfirmationModal(initConfirmationModal);
            await programsApi.updateProgram({
              id: programsContext.program.id,
              landingPageDesign: JSON.stringify(design),
              landingPageHtml: html,
            });
            setInformationModal({
              isOpen: true,
              title: "Save Program Dashboard",
              body: "Program Dashboard saved successfully.",
            });
          },
          onClose: () => setConfirmationModal(initConfirmationModal),
          title: `Save Program Dashboard`,
          body: `<span>You confirm you want to save Program Dashboard changes?</span>`,
        });
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: "Oops! Something went wrong with your request.",
      });
    }
  };

  const onReady = (unlayer) => {
    setEnableSaveButton(false);
    unlayer.setBodyValues({
      contentWidth: "700px",
    });
    if (programsContext.program.landingPageDesign) {
      const design = JSON.parse(programsContext.program.landingPageDesign);
      unlayer.loadDesign(design);
    }

    // register event to detect when an image is added to EmailEditor component
    unlayer.registerCallback("image", async function (file, done) {
      const image = file.attachments[0];
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          // upload image
          const response = await awsApi.uploadLandingPageImage({
            base64Image: reader.result.split(",")[1],
            imageName: image.name,
            type: image.type,
          });
          done({ progress: 100, url: response.url });
        } catch (error) {
          setInformationModal({
            isOpen: true,
            title: "Image Error",
            body: error.message || `There was an error uploading the image.`,
          });
        }
      };

      reader.readAsDataURL(image);
    });
  };

  return loading || !programsContext.program ? (
    <Loader />
  ) : programsContext.program ? (
    <div>
      <Container fluid className="mt-n4">
        <Header>
          <h2>
            {programsContext.program?.name
              ? `${programsContext.program?.name}  - `
              : ""}
            Program Dashboard
          </h2>
          <Row
            className="mx-0"
            style={{
              minHeight: "650px",
            }}
          >
            <EmailEditor
              ref={emailEditorRef}
              onReady={onReady}
              options={OPTIONS}
            />
          </Row>
          <div className="mt-3">
            <Button
              className="rounded"
              color="primary"
              size="sm"
              disabled={enableSaveButton}
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </Header>
      </Container>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  ) : null;
};

export default Dashboard;

import React, { useReducer, createContext, useContext } from "react";

const INIT_PAGE = 1;
const ACTIONS = {
  SORT: "SORT",
  SEARCH: "SEARCH",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
};

let reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    default:
      return { ...state, ...data };
  }
};

const initialState = {
  sizePerPage: "All",
  page: INIT_PAGE,
  search: "",
  sortBy: "name",
  direction: "asc",
};

const AdvisorsContext = createContext(initialState);

const AdvisorsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <AdvisorsContext.Provider value={stateAndDispatch}>
      {children}
    </AdvisorsContext.Provider>
  );
};

export const useAdvisors = () => useContext(AdvisorsContext);

export { AdvisorsContext, AdvisorsProvider, ACTIONS };

import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../Loader";

const CategoryCreationModal = ({ categories, onClose, onSubmit, loading }) => {
  const [name, setName] = useState("");
  const [nameExists, setNameExists] = useState(false);

  const onNameChange = (event) => {
    const name = event.currentTarget.value;
    setNameExists(categories.find((c) => c.name === name) !== undefined);
    setName(name);
  };

  const onDismiss = () => {
    setName("");
    setNameExists(false);
  };

  const doSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Create New Category</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left">
                Category Name
              </Label>
              <Col sm={8}>
                <Input
                  maxLength="50"
                  placeholder="Enter the name.."
                  onChange={onNameChange}
                  value={name}
                />
              </Col>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              isOpen={nameExists}
              toggle={onDismiss}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>This name is already in use</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose()}>
                Cancel
              </Button>{" "}
              <Button
                disabled={nameExists || !name || !name.length}
                color={"primary"}
                type="submit"
              >
                Create
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CategoryCreationModal;

import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const fundsApi = {
  getFunds: (data, role) => {
    const query = utils.generateQueryString(data);
    return axios(
      data.id
        ? `/api/funds/${role === "ADMIN" ? "all/" : ""}${data.id}`
        : `/api/funds?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getFundReturns: (data) => {
    return axios(`/api/funds/${data.id}/returns?year=${data.year}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getFundQuartersData: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/funds/${data.id}/quartersData?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createFund: (data) =>
    axios("/api/funds", {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  updateFund: (data) =>
    axios(`/api/funds/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFund: ({ id }) =>
    axios(`/api/funds/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  //FUND QUARTERS
  getFundQuarters: (data) => {
    const query = utils.generateQueryString(data);
    return axios(
      data.id
        ? `/api/funds/quarters/${data.id}`
        : `/api/funds/quarters?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => err);
  },
  getAllFundQuarters: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/funds/all/quarters?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  closeFundQuarter: (data) => {
    return axios(`/api/funds/quarters/${data.id}/close`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  distributeQuarterFunds: (data) => {
    return axios(`/api/funds/quarters/${data.id}/distribute`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  repenFundQuarter: (data) => {
    return axios(`/api/funds/quarters/${data.id}/reopen`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //FUND MONTHS
  getFundMonths: (data) => {
    const query = utils.generateQueryString(data);
    return axios(
      data.id ? `/api/funds/months/${data.id}` : `/api/funds/months?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => err);
  },
  getAllFundMonths: (data) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/funds/all/months?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  closeFundMonth: (data) => {
    return axios(`/api/funds/months/${data.id}/close`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  distributeMonthFunds: (data) => {
    return axios(`/api/funds/months/${data.id}/distribute`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  repenFundMonth: (data) => {
    return axios(`/api/funds/months/${data.id}/reopen`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //FUND INVESTMENTS
  createFundInvestment: (data) =>
    axios(`/api/funds/${data.fundId}/investments`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  updateFundInvestment: (data) =>
    axios(`/api/funds/${data.fundId}/investments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data: { amount: data.amount },
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundInvestment: (data) =>
    axios(`/api/funds/${data.fundId}/investments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  //FUND CAPITAL TRANSACTIONS
  getFundCapitalTransactions: (data = {}) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/funds/${data.fundId}/capital/transactions?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createFundCapitalTransaction: (data) =>
    axios(`/api/funds/${data.fundId}/capital/transactions`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundCapitalTransaction: (data) =>
    axios(`/api/funds/${data.fundId}/capital/transactions/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  getFundCapitalTransactionCategories: () =>
    axios(`/api/funds/capital/transactions/categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  createFundCapitalTransactionCategory: (data) =>
    axios(`/api/funds/capital/transactions/categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundCapitalTransactionCategory: (data) =>
    axios(`/api/funds/capital/transactions/categories/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  //FUND TRANSACTIONS
  createFundTransaction: (data) =>
    axios(`/api/funds/${data.fundId}/transactions`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  updateFundTransaction: (data) =>
    axios(`/api/funds/${data.fundId}/transactions/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundTransaction: (data) =>
    axios(`/api/funds/${data.fundId}/transactions/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  createFundTransactionCategory: (data) =>
    axios(`/api/funds/transactions/categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundTransactionCategory: (data) =>
    axios(`/api/funds/transactions/categories/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  getFundTransactionCategories: (data = {}) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/funds/transactions/categories?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  //FUND ASSETS
  getFundAsset: (data) =>
    axios(`/api/funds/${data.fundId}/assets/${data.assetId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  createFundAsset: (data) =>
    axios(`/api/funds/${data.fundId}/assets`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  updateFundAsset: (data) =>
    axios(`/api/funds/${data.fundId}/assets/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundAsset: (data) =>
    axios(`/api/funds/${data.fundId}/assets/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  createFundAssetCategory: (data) =>
    axios(`/api/funds/assets/categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundAssetCategory: (data) =>
    axios(`/api/funds/assets/categories/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  getFundAssetCategories: (data = {}) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/funds/assets/categories?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getFundAssetTransactionCategories: (data = {}) => {
    const query = utils.generateQueryString(data);
    return axios(`/api/funds/assets/transactions/categories?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createFundAssetTransaction: (data) =>
    axios(`/api/funds/${data.fundId}/assets/${data.assetId}/transactions`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundAssetTransaction: (data) =>
    axios(
      `/api/funds/${data.fundId}/assets/${data.assetId}/transactions/${data.transactionId}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "delete",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  createFundAssetTransactionCategory: (data) =>
    axios(`/api/funds/assets/transactions/categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
  deleteFundAssetTransactionCategory: (data) =>
    axios(`/api/funds/assets/transactions/categories/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
};

import React, { useState, useEffect, useCallback } from "react";

import { programsApi } from "../../../services/programServices";
import Loader from "../../../components/Loader";
import { useDistributions } from "../../../providers/distributionsProvider";
import { usePrograms } from "../../../providers/programsProvider";
import { fundsApi } from "../../../services/fundServices";
import MonthDistributionsTable from "../../../components/admin/MonthDistributionsTable";
import QuarterDistributionsTable from "../../../components/admin/QuarterDistributionsTable";
import InvestorDistributionsTable from "../../../components/admin/InvestorDistributionsTable";
import { useParams } from "react-router-dom";

const FUND_PERIOD_STATUS_CLOSED = 2;

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;

const Distributions = () => {
  const params = useParams();

  const { programId } = params;

  const [programsContext, setProgramsContext] = usePrograms();

  const [fund, setFund] = useState(
    programsContext.program?.fundInvestment?.fund
  );

  const [loading, setLoading] = useState(true);

  const [, setDistributionsContext] = useDistributions();

  const [fundPeriods, setFundPeriods] = useState([]);

  const setProgramsContextCb = useCallback(
    (data) => setProgramsContext(data),
    [setProgramsContext]
  );

  const setDistributionsContextCb = useCallback(
    (data) => setDistributionsContext(data),
    [setDistributionsContext]
  );

  useEffect(() => {
    setLoading(true);
    programsApi.getAllPrograms({ id: programId }).then((program) => {
      setProgramsContextCb({ program });
      setFund(program.programFundInvestments[0]?.fundFundInvestment);
      setDistributionsContextCb({ programId: program.id });
      setLoading(false);
    });
  }, [programId, setProgramsContextCb, setDistributionsContextCb]);

  useEffect(() => {
    if (fund) {
      setLoading(true);
      if (fund.isPrivateEquity) {
        fundsApi
          .getAllFundMonths({
            fundId: fund?.id,
            status: FUND_PERIOD_STATUS_CLOSED,
          })
          .then((result) => {
            const fundMonths = result.data;
            setFundPeriods(fundMonths);
            setDistributionsContextCb({
              fundMonthId: fundMonths.length ? fundMonths[0].id : null,
            });
            setLoading(false);
          });
      } else {
        fundsApi
          .getAllFundQuarters({
            fundId: fund?.id,
            status: FUND_PERIOD_STATUS_CLOSED,
          })
          .then((result) => {
            const fundQuarters = result.data;
            setFundPeriods(fundQuarters);
            setDistributionsContextCb({
              fundQuarterId: fundQuarters.length ? fundQuarters[0].id : null,
            });
            setLoading(false);
          });
      }
    }
  }, [fund, setDistributionsContextCb]);

  if (loading) {
    return <Loader />;
  } else if (
    parseInt(programsContext.program?.programTypeId) === TYPE_HEDGE_FUND
  ) {
    return (
      <MonthDistributionsTable
        enabledPeriodSelector={true}
        fundPeriods={fundPeriods}
        name={programsContext.program?.name}
        parentType={"programs"}
        parentId={programsContext.program?.id}
      />
    );
  } else if (
    parseInt(programsContext.program?.programTypeId) === TYPE_REAL_ESTATE ||
    parseInt(programsContext.program?.programTypeId) === TYPE_PRIVATE_EQUITY
  ) {
    return (
      <InvestorDistributionsTable
        name={programsContext.program?.name}
        parentType={"programs"}
        parentId={programsContext.program?.id}
      />
    );
  } else {
    return (
      <QuarterDistributionsTable
        enabledPeriodSelector={true}
        fundPeriods={fundPeriods}
        name={programsContext.program?.name}
        parentType={"programs"}
        parentId={programsContext.program?.id}
      />
    );
  }
};

export default Distributions;

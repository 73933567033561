import React, { memo, useCallback } from "react";

import { usePrograms } from "../../../providers/programsProvider";
import { programsApi } from "../../../services/programServices";
import { PROGRAM_INVESTMENT_TRANSACTION_TYPES } from "../../../utils/constants";
import Transaction from "../Transaction";

const DEFAULT_UNIT_VALUE = 1000;

const InvestmentTransaction = ({
  investment = {},
  transaction = {},
  setInformationModal,
}) => {
  const [programsContext, setProgramContext] = usePrograms();

  const handleDeleteTransaction = useCallback(async () => {
    const amount =
      transaction.amount *
      (transaction.type === PROGRAM_INVESTMENT_TRANSACTION_TYPES.INCREASE
        ? 1
        : -1);
    const units = amount / DEFAULT_UNIT_VALUE;

    if (investment.capital - amount < 0) {
      return setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: "The resulting balance can't be negative.",
      });
    }

    try {
      await programsApi.deleteInvestmentTransaction({
        id: transaction.id,
        investmentId: investment.id,
        programId: investment.programId,
      });

      const program = { ...programsContext.program };

      program.capital -= amount;
      program.units -= units;

      const ctxInvestment = program.programInvestments.find(
        (inv) => investment.id === inv.id
      );
      ctxInvestment.capital -= amount;
      ctxInvestment.units -= units;

      const transactionIndex = ctxInvestment.transactions.findIndex(
        (tran) => transaction.id === tran.id
      );
      ctxInvestment.transactions.splice(transactionIndex, 1);

      setProgramContext({
        program,
        refresh: !programsContext.refresh,
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Oops, there was an error with your request",
        body: err?.response?.data[0]?.msg || "Please try again",
      });
    }
  }, [
    investment,
    programsContext.refresh,
    programsContext.program,
    setProgramContext,
    transaction,
    setInformationModal,
  ]);

  return (
    <Transaction
      type={transaction.type}
      label={transaction?.category?.name || "No Category Set"}
      date={transaction.date}
      amount={transaction.amount}
      onDelete={handleDeleteTransaction}
    />
  );
};

export default memo(InvestmentTransaction);

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
  CustomInput,
} from "reactstrap";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import { useAuth } from "../../../providers/authProvider";
import { authApi } from "../../../services/authServices";
import { utils } from "../../../utils/utils";
import InputMask from "react-input-mask";

const TWO_FACTOR_INVALID = "TWO_FACTOR_INVALID";
const TWO_FACTOR_CODE_SENT_AGAIN = "TWO_FACTOR_CODE_SENT_AGAIN";

const CURRENT_PHONE = "1";
const OTHER_PHONE = "2";

const SMSFactor = () => {
  const [authContext, setAuthContext] = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(CURRENT_PHONE);
  const [phone, setPhone] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onDismiss = () => {
    setError(false);
    setInfo(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    authApi
      .sendSMSCode({
        username: authContext.credentials.email,
        password: authContext.credentials.pwd,
        code2FA: authContext.credentials.code2FA,
        phone:
          selectedPhone === CURRENT_PHONE
            ? authContext.currentUser.phone
            : phone,
      })
      .then((result) => {
        setLoading(false);
        return result
          ? setAuthContext({
              currentUser: { ...result },
              advisements: result.investors,
              sms: true,
            })
          : null;
      })
      .catch((err) => {
        if (
          err?.response?.data?.status &&
          err.response.data.status === TWO_FACTOR_INVALID
        ) {
          setError(true);
        } else {
          setInformationModal({
            isOpen: true,
            title: "Two Factor Authentication",
            body: "There was an error sending the SMS to the given number.",
          });
        }
        setLoading(false);
      });
    return false;
  };

  const onResend2FA = (e) => {
    e.preventDefault();

    setLoading(true);
    authApi
      .login({
        username: authContext.credentials.email,
        password: authContext.credentials.pwd,
        resend: true,
      })
      .then((result) => {
        setLoading(false);
        if (result.status === TWO_FACTOR_CODE_SENT_AGAIN) {
          setInfo(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.status === TWO_FACTOR_INVALID);
      });
  };

  const onPhoneChange = (value) => setPhone(value);

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Two Factor Authentication</h1>
        <p className="lead">Please enter your phone number.</p>
      </div>
      <Card>
        <CardBody className="p-3">
          <div className="m-sm-4">
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Form onSubmit={onSubmit}>
                <div className="d-flex justify-content-center">
                  <div className="user-name rounded-circle bg-light img-thumbnail text-muted d-flex align-items-center justify-content-center">
                    <span>
                      {utils.nameInitials(authContext.currentUser.name)}
                    </span>
                  </div>
                </div>
                {authContext.currentUser.phone ? (
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <CustomInput
                      required={true}
                      type="select"
                      id="2FA"
                      name="2FA"
                      onChange={(e) => setSelectedPhone(e.currentTarget.value)}
                      value={selectedPhone}
                    >
                      <option value={CURRENT_PHONE}>
                        {authContext.currentUser.phone}
                      </option>
                      <option value={OTHER_PHONE}>Other</option>
                    </CustomInput>
                  </FormGroup>
                ) : null}
                {!authContext.currentUser.phone ||
                selectedPhone === OTHER_PHONE ? (
                  <FormGroup>
                    <Label>
                      {selectedPhone === OTHER_PHONE ? "Other " : ""}Phone
                      Number
                    </Label>
                    <InputMask
                      mask="(999) 999-9999"
                      value={phone || ""}
                      type="text"
                      onChange={(event) =>
                        onPhoneChange(event.currentTarget.value)
                      }
                      name="phone"
                      id="phone"
                      placeholder="Enter thee phone number"
                    >
                      {(inputProps) => (
                        <Input {...inputProps} type="text" required={true} />
                      )}
                    </InputMask>
                    <small>
                      Didn't receive code,{" "}
                      <span
                        className="text-primary cursor-pointer"
                        onClick={onResend2FA}
                      >
                        send again
                      </span>
                    </small>
                  </FormGroup>
                ) : null}
                <UncontrolledAlert
                  isOpen={error}
                  toggle={onDismiss}
                  color="warning"
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <strong>Hey!</strong>
                    <span className="ml-2">
                      It seems the code entered is not correct.
                    </span>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert
                  isOpen={info}
                  toggle={onDismiss}
                  color="info"
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <strong>Hey!</strong>
                    <span className="ml-2">
                      The 2FA code was sent, please check your phone.
                    </span>
                  </div>
                </UncontrolledAlert>
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={() =>
                      setAuthContext({
                        credentials: null,
                        currentUser: null,
                        advisements: null,
                      })
                    }
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" type="submit">
                    Send
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default SMSFactor;

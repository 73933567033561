import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const awsApi = {
  signDocuments: (data, role) => {
    return axios(`/api/aws/${role}/sign`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  putDocumentsToS3: (data) => {
    return axios(data.url, {
      method: "put",
      data: data.file,
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then(() => {
        return { name: data.file.name, url: data.fileUrl };
      })
      .catch((err) => handleError(err));
  },
  uploadLandingPageImage: (data) => {
    return axios(`/api/aws/program/landing-page-image`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => {
        if (err.response) {
          if (err.response.data && err.response.data.message) {
            throw err.response.data;
          }
          throw err;
        }
      });
  },
  uploadBannerImage: (data) => {
    return axios(`/api/aws/banner/image`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => {
        if (err.response) {
          if (err.response.data && err.response.data.message) {
            throw err.response.data;
          }
          throw err;
        }
      });
  },
};

import React, { useReducer, createContext, useContext } from "react";
import config from "../config/config";
import socketIOClient from "socket.io-client";

const ON_GENERATION_SIGNUP_SUCCESS_ACTION = "generationSignupSuccess";
const ON_UPLOAD_SIGNUP_SUCCESS_ACTION = "uploadSignupSuccess";
const ON_STATEMENT_GENERATED_ACTION = "statementGenerated";
const ON_STATEMENT_UPLOADED_ACTION = "statementUploaded";
const ON_ALL_GENERATED_ACTION = "onAllGenerated";
const ON_ALL_UPLOADED_ACTION = "onAllUploaded";
const ON_ABORT_ALL_ACTION = "onAbortAll";
const ON_ABORT_ACTION = "onAbort";
const ON_FINISH_ACTION = "onFinish";

const ON_PROGRAMS_SELECTION_STEP_ACTION = "onProgramsSelectionStepAction";
const ON_ALL_PROGRAM_SELECTED_ACTION = "onAllProgramSelectedAction";
const ON_PROGRESS_STEP_ACTION = "onProgressStepAction";
const ON_GENERATE_STEP_ACTION = "onGenerateStepAction";
const ON_LETTER_STEP_ACTION = "onLetterStepAction";
const ON_RESERVES_STEP_ACTION = "onReservesStepAction";

const ON_ACTIVATE_ACTION = "onActivateAction";
const ON_SET_LETTER_ACTION = "onSetLetterAction";
const ON_DEACTIVATE_ACTION = "onDeactivateAction";
const ON_SET_AUDIT_RESERVE_ACTION = "onSetAuditReserveAction";
const ON_PROGRAM_SELECTED_ACTION = "onProgramSelectedAction";
const ON_PROGRAM_SEND_ACTION = "onProgramSendAction";

const PROGRAMS_SELECTION_STEP = 1;
const RESERVE_STEP = 2;
const LETTER_STEP = 3;
const GENERATION_STEP = 4;
const PROGRESS_STEP = 5;

const PROGRAM_INITIAL_MODE = 1;
const PROGRAMS_GENERATION_MODE = 5;
const UPLOAD_MODE = 8;
const SUCCESS_MODE = 9;

const initialState = {
  active: false,
  year: "",
  quarter: null,
  step: PROGRAMS_SELECTION_STEP,
  programs: {},
  letter: null,
  generationSocket: null,
  uploadSocket: null,
};

let reducer = (state, data) => {
  let programs;
  let programData;
  switch (data.action) {
    case ON_DEACTIVATE_ACTION:
      return {
        ...initialState,
        active: false,
      };
    case ON_ACTIVATE_ACTION:
      return {
        ...initialState,
        active: true,
        quarter: data.payload.quarter,
        year: data.payload.year,
      };
    case ON_PROGRAMS_SELECTION_STEP_ACTION:
      return {
        ...state,
        step: PROGRAMS_SELECTION_STEP,
      };
    case ON_ALL_PROGRAM_SELECTED_ACTION:
      programData = {
        auditReserve: 0,
        previewFile: null,
        mode: PROGRAM_INITIAL_MODE,
        total: 0,
        remaining: 0,
        statements: [],
      };
      programs = data.payload.checked
        ? data.payload.programs.reduce(
            (p, c) => ({
              ...p,
              [c.id]: {
                ...programData,
                program: {
                  id: c.id,
                  name: c.name,
                },
              },
            }),
            {}
          )
        : {};
      return {
        ...state,
        programs,
      };
    case ON_PROGRAM_SELECTED_ACTION:
      if (!data.payload.checked) {
        const newState = { ...state };
        delete newState.programs[data.payload.program.id];
        return { ...newState };
      }
      programData = {
        auditReserve: 0,
        previewFile: null,
        mode: PROGRAM_INITIAL_MODE,
        total: 0,
        remaining: 0,
        statements: [],
        program: {
          id: data.payload.program.id,
          name: data.payload.program.name,
        },
      };
      return {
        ...state,
        programs: {
          ...state.programs,
          [data.payload.program.id]: programData,
        },
      };
    case ON_PROGRAM_SEND_ACTION:
      return {
        ...state,
        programs: {
          ...state.programs,
          [data.payload]: {
            ...state.programs[data.payload],
            mode: UPLOAD_MODE,
            remaining: 0,
            total: 0,
            generated: false,
          },
        },
      };
    case ON_RESERVES_STEP_ACTION:
      return {
        ...state,
        step: RESERVE_STEP,
      };
    case ON_SET_AUDIT_RESERVE_ACTION:
      return {
        ...state,
        programs: {
          ...state.programs,
          [data.payload.programId]: {
            ...state.programs[data.payload.programId],
            auditReserve: data.payload.value,
          },
        },
      };
    case ON_LETTER_STEP_ACTION:
      return {
        ...state,
        step: LETTER_STEP,
      };
    case ON_SET_LETTER_ACTION:
      return {
        ...state,
        letter: data.payload,
      };
    case ON_GENERATE_STEP_ACTION:
      return {
        ...state,
        step: GENERATION_STEP,
      };
    case ON_PROGRESS_STEP_ACTION:
      return {
        ...state,
        step: PROGRESS_STEP,
        programs: Object.keys(state.programs).reduce((p, c) => {
          p[c] = {
            ...state.programs[c],
            mode: PROGRAMS_GENERATION_MODE,
          };
          return p;
        }, {}),
      };
    case ON_GENERATION_SIGNUP_SUCCESS_ACTION:
      return {
        ...state,
        generationSocket: data.payload,
      };
    case ON_UPLOAD_SIGNUP_SUCCESS_ACTION:
      return {
        ...state,
        uploadSocket: data.payload,
      };
    case ON_STATEMENT_GENERATED_ACTION:
      if (!state.programs[data.payload.programId]) {
        return { ...state };
      }
      return {
        ...state,
        programs: {
          ...state.programs,
          [data.payload.programId]: {
            ...state.programs[data.payload.programId],
            ...data.payload,
            generated: data.payload.remaining === 0,
          },
        },
      };
    case ON_STATEMENT_UPLOADED_ACTION:
      if (!state.programs[data.payload.programId]) {
        return { ...state };
      }
      return {
        ...state,
        programs: {
          ...state.programs,
          [data.payload.programId]: {
            ...state.programs[data.payload.programId],
            ...data.payload,
            uploaded: data.payload.remaining === 0,
          },
        },
      };
    case ON_ALL_GENERATED_ACTION:
      if (state.generationSocket) {
        state.generationSocket.disconnect();
      }
      return { ...state, generationSocket: null };
    case ON_ALL_UPLOADED_ACTION:
      if (state.uploadSocket) {
        state.uploadSocket.disconnect();
      }
      return { ...state, uploadSocket: null };
    case ON_ABORT_ALL_ACTION:
      if (state.generationSocket) {
        state.generationSocket.disconnect();
      }
      if (state.uploadSocket) {
        state.uploadSocket.disconnect();
      }
      return { ...initialState };
    case ON_ABORT_ACTION:
      programs = { ...state.programs };
      delete programs[data.payload];
      if (!Object.values(programs)?.length) {
        //if aborted the last
        if (state.generationSocket) {
          state.generationSocket.disconnect();
        }
        if (state.uploadSocket) {
          state.uploadSocket.disconnect();
        }
        return { ...initialState };
      }
      return { ...state, programs };
    case ON_FINISH_ACTION:
      programs = {
        ...state.programs,
        [data.payload]: {
          ...state.programs[data.payload],
          mode: SUCCESS_MODE,
        },
      };
      return { ...state, programs };
    default: {
      return { ...state, ...data };
    }
  }
};

const StatementsFlowContext = createContext(initialState);

const StatementsFlowProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onStatementsGeneration = (socketToken, callback) => {
    if (state.socket) {
      return callback();
    }

    const socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("generationSignup", socketToken);

    socket.on(ON_GENERATION_SIGNUP_SUCCESS_ACTION, () => {
      dispatch({
        action: ON_GENERATION_SIGNUP_SUCCESS_ACTION,
        payload: socket,
      });
      callback();
    });

    socket.on(ON_STATEMENT_GENERATED_ACTION, (data) => {
      dispatch({ action: ON_STATEMENT_GENERATED_ACTION, payload: data });
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  const onStatementsUpload = (socketToken, callback) => {
    if (state.socket) {
      return callback();
    }

    const socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("uploadSignup", socketToken);

    socket.on(ON_UPLOAD_SIGNUP_SUCCESS_ACTION, () => {
      dispatch({ action: ON_UPLOAD_SIGNUP_SUCCESS_ACTION, payload: socket });
      callback();
    });

    socket.on(ON_STATEMENT_UPLOADED_ACTION, (data) => {
      dispatch({ action: ON_STATEMENT_UPLOADED_ACTION, payload: data });
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  return (
    <StatementsFlowContext.Provider
      value={[state, dispatch, onStatementsGeneration, onStatementsUpload]}
    >
      {children}
    </StatementsFlowContext.Provider>
  );
};

export const useStatementsFlow = () => useContext(StatementsFlowContext);

export { StatementsFlowContext, StatementsFlowProvider, initialState };

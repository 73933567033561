import React from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

const DocumentModalDetails = ({ document, onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn} className="text-body"></ModalHeader>
      <ModalBody className="text-center"></ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => onClose(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DocumentModalDetails;

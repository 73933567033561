import React, { useReducer, createContext, useContext } from "react";

const NAME_STEP = 1;
const NO_SELECTION = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  active: false,
  name: "",
  programTypeId: null,
  year: new Date().getFullYear(),
  sponsor: NO_SELECTION,
  step: NAME_STEP,
  stakeholders: [],
  createdOnDate: new Date(),
  closedOnDate: new Date(),
};

const ProgramCreationFlowContext = createContext(initialState);

const ProgramCreationFlowProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ProgramCreationFlowContext.Provider value={stateAndDispatch}>
      {children}
    </ProgramCreationFlowContext.Provider>
  );
};

export const useProgramCreationFlow = () =>
  useContext(ProgramCreationFlowContext);

export {
  ProgramCreationFlowContext,
  ProgramCreationFlowProvider,
  initialState,
};

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  UncontrolledAlert,
  Input,
  Row,
} from "reactstrap";
import { usersApi } from "../../../services/userServices";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const STATUS_INACTIVE = "1";

const CreateAdvisorModal = ({ onSubmit, onClose }) => {
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [foundByEmail, setFoundByEmail] = useState(false);
  const [foundByName, setFoundByName] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });

  useEffect(() => {
    if (name?.length > 3 && !foundByEmail) {
      usersApi.getUsers({ name }, "investor").then((result) => {
        if (result?.count) {
          const advisor = result.data[0];
          setFoundByName(true);
          setEmail(advisor.email);
          setAlertMessage(
            "An user exists for the entered name, so the email cannot be overwritten."
          );
          setShowAlert(true);
          setId(advisor.id);
        } else {
          setFoundByName(false);
          setId();
        }
      });
    }
  }, [name, foundByEmail]);

  useEffect(() => {
    if (email?.length > 3 && !foundByName) {
      usersApi.getUsers({ email }, "investor").then((result) => {
        if (result?.count) {
          const advisor = result.data[0];
          setFoundByEmail(true);
          setName(advisor.name);
          setAlertMessage(
            "An user exists for the entered email, so the name cannot be overwritten."
          );
          setShowAlert(true);
          setId(advisor.id);
        } else {
          setFoundByEmail(false);
          setId();
        }
      });
    }
  }, [email, foundByName]);

  const doSubmit = (event) => {
    event.preventDefault();
    let promise;
    setLoading(true);
    if (id) {
      promise = usersApi.createAdvisement(
        {
          advisorId: id,
        },
        "investor"
      );
    } else {
      promise = usersApi.createUser({
        name,
        email,
        isAdvisor: true,
        status: STATUS_INACTIVE,
      });
    }
    promise
      .then(() => {
        setLoading(false);
        onSubmit();
      })
      .catch((err) => {
        if (err?.response?.data) {
          setAlertMessage(err?.response?.data[0]?.msg);
          setShowAlert(true);
          setLoading(false);
        }
      });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() => setInformationModal({ isOpen: false, body: "" })}
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Add Advisor</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Name
                </Label>
                <Col sm={8}>
                  <Input
                    disabled={foundByEmail}
                    required={true}
                    type="text"
                    maxLength="50"
                    placeholder="Enter the name.."
                    onChange={(event) => setName(event.currentTarget.value)}
                    value={name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-sm-left">
                  Email
                </Label>
                <Col sm={8}>
                  <Input
                    disabled={foundByName}
                    required={true}
                    type="email"
                    maxLength="50"
                    placeholder="Enter the email.."
                    onChange={(event) => setEmail(event.currentTarget.value)}
                    value={email}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              isOpen={showAlert}
              toggle={() => setShowAlert(false)}
              color="info"
            >
              <div className="alert-icon d-flex align-items-center">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left d-flex align-items-center">
                <span>{alertMessage}</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>{" "}
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateAdvisorModal;

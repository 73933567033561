import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
} from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import Loader from "../../../../Loader";

const MAX_VALUE = 999999999;

const ExpenseCreationModal = ({ onClose, onSubmit, loading }) => {
  const [amount, setAmount] = useState();

  const onAmountChange = (value) => {
    if (!value || value < MAX_VALUE) {
      setAmount(value);
    }
  };

  const doSubmit = (e) => {
    e.preventDefault();
    onSubmit(amount);
    onClose();
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Create New Expense</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left">
                Amount
              </Label>
              <Col sm={8}>
                <CurrencyInput
                  intlConfig={{ locale: "en-US", currency: "USD" }}
                  required={true}
                  allowNegativeValue={false}
                  maxLength={20}
                  className="form-control"
                  placeholder="Enter the amount.."
                  value={amount}
                  onValueChange={onAmountChange}
                />
              </Col>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose()}>
                Cancel
              </Button>{" "}
              <Button color={"primary"} type="submit">
                Create
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ExpenseCreationModal;

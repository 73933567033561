import React, { useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
} from "reactstrap";

import Loader from "../../Loader";
import { useAuth } from "../../../providers/authProvider";
import { usersApi } from "../../../services/userServices";
import InformationModal from "../../InformationModal";
import { utils } from "../../../utils/utils";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const states = require("../../../assets/states.json");

const PersonalInfo = () => {
  const [authContext, setAuthContext] = useAuth();
  const [user, setUser] = useState({ ...authContext.currentUser });
  const [loading, setLoading] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSaveChanges = (event) => {
    event.preventDefault();
    setLoading(true);

    const userData = { ...user };
    const updateUserData = {
      id: userData.id,
      name: userData.name,
      email: userData.email,
      state: userData.state,
      city: userData.city,
      zipcode: userData.zipcode,
      address: userData.address,
      phone: userData.phone,
    };

    usersApi
      .updateUser(updateUserData, "admin")
      .then((result) => {
        setLoading(false);
        setUser({ ...user, ...updateUserData });
        setAuthContext({ currentUser: { ...user, ...updateUserData } });
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "Information updated successfully",
          onClose: () => {},
        });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  return (
    <Card className="col-5 box-shadow-none tab-card">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Personal info
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSaveChanges}>
          <Row form>
            <Col md={8}>
              <FormGroup>
                <Label for="name">Full Name</Label>
                <Input
                  maxLength={60}
                  required={true}
                  type="text"
                  onChange={(event) =>
                    setUser({ ...user, name: event.currentTarget.value })
                  }
                  name="text"
                  id="name"
                  placeholder="Full Name"
                  value={user.name || ""}
                />
              </FormGroup>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="user-name rounded-circle bg-light img-thumbnail text-muted d-flex align-items-center justify-content-center">
                <span>{utils.nameInitials(user.name)}</span>
              </div>
            </Col>
          </Row>
          <Row form className="mx-0">
            <FormGroup className="col-12 px-0">
              <Label for="email">Email</Label>
              <Input
                maxLength={60}
                required={true}
                onChange={(event) =>
                  setUser({ ...user, email: event.currentTarget.value })
                }
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                value={user.email || ""}
              />
            </FormGroup>
          </Row>
          <Row form className="mx-0">
            <FormGroup className="col-12 px-0">
              <Label for="address">Address</Label>
              <Input
                maxLength={60}
                required={true}
                value={user.address || ""}
                type="text"
                onChange={(event) =>
                  setUser({ ...user, address: event.currentTarget.value })
                }
                name="address"
                id="address"
                placeholder="Address"
              />
            </FormGroup>
          </Row>
          <Row form className="mx-0">
            <Col md={4} className="pl-0">
              <FormGroup className="col-12 px-0">
                <Label for="address">State</Label>
                <CustomInput
                  required={true}
                  type="select"
                  id="stateCustomSelect"
                  name="customSelect"
                  onChange={(event) =>
                    setUser({ ...user, state: event.currentTarget.value })
                  }
                  value={user.state || ""}
                >
                  <option value="">Select...</option>
                  {states.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="col-12 px-0">
                <Label for="city">City</Label>
                <Input
                  maxLength={60}
                  required={true}
                  value={user.city || ""}
                  type="text"
                  onChange={(event) =>
                    setUser({ ...user, city: event.currentTarget.value })
                  }
                  name="city"
                  id="city"
                  placeholder="City"
                />
              </FormGroup>
            </Col>
            <Col md={4} className="pr-0">
              <FormGroup className="col-12 px-0">
                <Label for="zipcode">Zip Code</Label>
                <Input
                  maxLength={50}
                  required={true}
                  value={user.zipcode || ""}
                  type="text"
                  onChange={(event) =>
                    setUser({ ...user, zipcode: event.currentTarget.value })
                  }
                  name="zipcode"
                  id="zipcode"
                  placeholder="Zip Code"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form className="mx-0">
            <FormGroup className="col-12 px-0">
              <Label for="phone">Phone</Label>
              <PhoneInput
                placeholder="Phone"
                name="phone"
                id="phone"
                international
                defaultCountry="US"
                country="US"
                countryCallingCodeEditable={false}
                value={user.phone || ""}
                rules={{ required: true }}
                onChange={(number) => {
                  if (number) {
                    setUser({ ...user, phone: number });
                  }
                }}
              />
            </FormGroup>
          </Row>
          {loading ? (
            <Loader align="start" size="sm" />
          ) : (
            <Button type="submit" color="success">
              Save Changes
            </Button>
          )}
        </Form>
        {informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              setInformationModal({ isOpen: false, title: "", body: "" })
            }
          />
        ) : null}
      </CardBody>
    </Card>
  );
};

export default PersonalInfo;

import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { Badge } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarItem = ({ name, badgeColor, badgeText, icon, to, action }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const getSidebarItemClass = (path) =>
    location.pathname === path ? "active" : "";

  return (
    <li
      className={"sidebar-item " + getSidebarItemClass(to)}
      onClick={() => (action ? action() : navigate(to))}
    >
      <NavLink
        to="#"
        className={({ isActive }) =>
          `sidebar-link text-truncate ${isActive ? "active" : "none"}`
        }
      >
        {icon ? (
          <React.Fragment>
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className="align-middle mx-2 sidebar-icon"
            />{" "}
            <span className="align-middle">{name}</span>
          </React.Fragment>
        ) : (
          name
        )}{" "}
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} pill className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );
};

export default SidebarItem;

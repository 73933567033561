import React from "react";
import { Row } from "reactstrap";
import { bannersApi } from "../../../services/bannerServices";
import BannerContent from "./BannerContent";

const BannerView = ({ bannerData, selectBanner = () => {} }) => {
  const settings = JSON.parse(bannerData.jsonContent);
  const content = bannerData.htmlContent.replace(
    `<style type="text/css">`,
    `<style type="text/css">
    .u-row {
        margin: 0 !important;
      }`
  );

  const onBannerClick = () => {
    if (bannerData.modalImage) {
      selectBanner(bannerData);
      bannersApi.sendNotification(bannerData);
    }
  };

  return (
    <Row
      className="m-0 mb-1"
      style={{
        backgroundColor: settings.body.values.backgroundColor,
        height: 75,
      }}
      onClick={onBannerClick}
    >
      {bannerData.image ? (
        <img
          style={{
            height: "100%",
            maxHeight: 75,
          }}
          src={bannerData.image}
          alt={`Banner logo`}
        />
      ) : null}
      <div
        className="p-0"
        style={{
          flex: 1,
        }}
      >
        <BannerContent htmlContent={content} />
      </div>
    </Row>
  );
};

export default BannerView;

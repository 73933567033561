import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  Row,
  InputGroup,
  Input,
  Button,
  Badge,
  CustomInput,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { useDocuments } from "../../../providers/documentsProvider";
import { documentsApi } from "../../../services/documentServices";
import Loader from "../../../components/Loader";
import DocumentModalDetails from "../../../components/admin/DocumentDetailsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useDocumentsUploadFlow,
  initialState,
} from "../../../providers/documentUploadFlowProvider";
import {
  faPlus,
  faFileDownload,
  faTrashAlt,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import DocumentUploadFlow from "../../../components/admin/DocumentUploadFlow";
import DocumentPreviewModal from "./review/DocumentPreviewModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { awsApi } from "../../../services/awsServices";
import config from "../../../config/config";
import moment from "moment";
import { programsApi } from "../../../services/programServices";
import { useAuth } from "../../../providers/authProvider";
import { utils } from "../../../utils/utils";
import { SPONSOR } from "../../../utils/roles";
import download from "downloadjs";
import CustomCheckbox from "../../../components/CustomCheckbox";
import AdvanceTableWrapper from "../../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../../components/admin/advanceTable/AdvanceTablePagination";

const FILE_TYPE = "pdf";
const MAX_PAGE_SIZE = 100;

const DOCUMENT_TYPE_TAX_K1 = 2;
const DOCUMENT_TYPE_PROGRAM = 3;
const DOCUMENT_TYPE_CLOSE_PROGRAM = 4;
const DOCUMENT_TYPE_TAX_8886 = 5;

const LIMIT_YEAR = 2018;

let years = [];
let year = moment().get("year");

while (year >= LIMIT_YEAR) {
  years.push(year);
  year = moment().subtract(years.length, "year").get("year");
}

const TABLE_COLUMNS = [
  {
    accessor: "name",
    Header: "Name",
    width: "30%",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { name } = rowData.row.original;
      return name || "";
    },
  },
  {
    accessor: "user.name",
    Header: "Investor",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { user } = rowData.row.original;
      return user?.name || "";
    },
  },
  {
    accessor: "year",
    Header: "Year",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { year, program } = rowData.row.original;
      return year || program?.year || "-";
    },
  },
  {
    accessor: "program.name",
    Header: "Program",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { program } = rowData.row.original;
      return program?.name || "";
    },
  },
  {
    accessor: "documentType.name",
    Header: "Type",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { documentType } = rowData.row.original;
      return `${documentType?.name} Document`;
    },
  },
];

const Documents = () => {
  const [documentsContext, setDocumentsContext] = useDocuments();
  const [documentsUploadFlow, setDocumentsUploadFlow] = useDocumentsUploadFlow({
    ...initialState,
  });
  const [authContext] = useAuth();

  const [checked, setChecked] = useState({});
  const [documents, setDocuments] = useState({});
  const [programs, setPrograms] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState();
  const [documentsLoading, setDocumentsLoading] = useState({});
  const [previewModal, setPreviewModal] = useState({});

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const onPreview = (fileName, id) => {
    setDocumentsLoading({ ...documentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        "admin"
      )
      .then((result) => {
        setDocumentsLoading({ ...documentsLoading, [id]: false });
        setPreviewModal({
          isOpen: true,
          file: result?.pop().signedRequest,
        });
      });
  };

  const onDownload = (fileName, id) => {
    setDocumentsLoading({ ...documentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        "admin"
      )
      .then((result) => {
        setDocumentsLoading({ ...documentsLoading, [id]: false });
        download(result?.pop().signedRequest);
      });
  };

  const getLink = (document) => (
    <div className="d-flex align-items-center">
      <span
        className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
        onClick={() => onDownload(document.fileName, document.id)}
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="text-primary" icon={faFileDownload} />
      </span>
      <Badge
        onClick={() => onPreview(document.fileName, document.id)}
        className="cursor-pointer"
        color={"info"}
      >
        Preview
      </Badge>
    </div>
  );

  const onBulkDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        documentsApi
          .bulkDeleteDocument(
            Object.keys(checked)
              .filter((item) => checked[item])
              .join(",")
          )
          .then(() => {
            setConfirmationModal(initConfirmationModal);
            setChecked({});
            setRefresh(!refresh);
          });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Documents",
      body: `<span>Do you confirm you want to delete the selected documents?</span>`,
    });
  };

  const onDelete = (document) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        documentsApi.deleteDocument({ id: document.id }).then(() => {
          setConfirmationModal(initConfirmationModal);
          setChecked({});
          setRefresh(!refresh);
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Document",
      body: `<span>Do you confirm you want to delete?</span>`,
    });
  };

  const onSort = useCallback(
    ([data]) => {
      if (data && data.id) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          documentsContext.sortBy === sortBy?.id &&
          documentsContext.direction === direction
        ) {
          return;
        }
        setDocumentsContext({ sortBy, direction });
      } else {
        setDocumentsContext({
          sortBy: null,
          direction: null,
        });
      }
    },
    [documentsContext.direction, documentsContext.sortBy, setDocumentsContext]
  );

  const trashIcon = (document) => (
    <FontAwesomeIcon
      onClick={() => onDelete(document)}
      icon={faTrashAlt}
      className="cursor-pointer text-danger"
    />
  );

  useEffect(() => {
    setLoading(true);
    const hasSponsorRole = utils.userHasRole(authContext.currentUser, SPONSOR);
    programsApi
      .getAllPrograms(
        hasSponsorRole
          ? { pageSize: 999, userId: authContext.currentUser.id }
          : { pageSize: 999 }
      )
      .then((result) => {
        setPrograms(result?.data);
        setLoading(false);
      });
  }, [authContext.currentUser]);

  useEffect(() => {
    setLoading(true);
    documentsApi
      .getAllDocuments({
        programId: documentsContext.programId,
        documentTypeId: documentsContext.documentType,
        year: documentsContext.year,
        userId: documentsContext.userId,
        search: documentsContext.search,
        page: documentsContext.page - 1,
        pageSize: getSizePerPage(documentsContext.sizePerPage),
        sortBy: documentsContext.sortBy,
        direction: documentsContext.direction,
      })
      .then((result) => {
        setDocuments(result);
        setLoading(false);
      });
  }, [
    documentsContext.programId,
    documentsContext.documentType,
    documentsContext.year,
    documentsContext.userId,
    documentsContext.search,
    documentsContext.page,
    documentsContext.sizePerPage,
    documentsContext.sortBy,
    documentsContext.direction,
    refresh,
  ]);

  const allChecked =
    documents?.data &&
    Object.keys(checked).filter((item) => checked[item]).length ===
      documents?.data?.length;

  const someChecked =
    documents?.data && Object.keys(checked).find((item) => checked[item]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <div className="p-0 d-flex flex-column align-items-start">
            <span>Documents</span>
          </div>
          <div className="p-0 d-flex align-items-center col-6 px-0 justify-content-end">
            <CustomInput
              id="yearSelect"
              type="select"
              name="yearSelect"
              onChange={(event) => {
                setChecked({});
                setDocumentsContext({ year: event.currentTarget.value });
              }}
              value={documentsContext.year}
            >
              <option value={""}>Any Year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </CustomInput>
            <CustomInput
              className="ml-4"
              id="programSelect"
              type="select"
              name="programSelect"
              onChange={(event) => {
                setChecked({});
                setDocumentsContext({ programId: event.currentTarget.value });
              }}
              value={documentsContext.programId || ""}
            >
              <option value={""}>Any Program</option>
              {(programs || []).map((program, i) => (
                <option key={i} value={program.id}>
                  {program.name}
                </option>
              ))}
            </CustomInput>
            <CustomInput
              className="ml-4"
              id="documentTypeSelect"
              type="select"
              name="documentTypeSelect"
              onChange={(event) => {
                setChecked({});
                setDocumentsContext({
                  documentType: event.currentTarget.value,
                });
              }}
              value={documentsContext.documentType || ""}
            >
              <option value="">Any Type</option>
              <option value={DOCUMENT_TYPE_TAX_K1}>Tax K-1</option>
              <option value={DOCUMENT_TYPE_TAX_8886}>Tax Form 8886</option>
              <option value={DOCUMENT_TYPE_PROGRAM}>Program Document</option>
              <option value={DOCUMENT_TYPE_CLOSE_PROGRAM}>
                Close Investment Document
              </option>
            </CustomInput>
            <Button
              className="flex-shrink-0 ml-4"
              color="primary"
              onClick={() => {
                setChecked({});
                setDocumentsUploadFlow({ ...initialState, active: true });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Upload Documents</span>
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3 col-4 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={documentsContext.search}
                    onChange={(evt) => {
                      setChecked({});
                      setDocumentsContext({
                        search: evt.target.value,
                        page: 1,
                      });
                    }}
                    className="border-0"
                    placeholder="Search for.."
                  />
                </InputGroup>
                <div className="d-flex align-items-center">
                  {someChecked ? (
                    <Button
                      size="sm"
                      className="rounded mr-2"
                      color="danger"
                      onClick={onBulkDelete}
                    >
                      Delete Selected
                    </Button>
                  ) : null}
                  <small className="text-muted mr-2">
                    Showing {documents?.count} documents
                  </small>
                  <Button
                    size="sm"
                    className="mr-2 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => {
                      setChecked({});
                      setRefresh(!refresh);
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (documents?.data || []).length ? (
                <AdvanceTableWrapper
                  columns={[
                    {
                      accessor: "id",
                      Header: (
                        <div className="min-width-50 d-flex justify-content-start">
                          <CustomCheckbox
                            checked={allChecked}
                            onClick={() =>
                              setChecked(
                                documents?.data?.reduce((p, c) => {
                                  p[c.id] = !allChecked;
                                  return p;
                                }, {})
                              )
                            }
                          />
                        </div>
                      ),
                      headerProps: { className: "text-truncate" },
                      cellProps: {
                        className: "text-left",
                      },
                      width: 50,
                      disableSortBy: true,
                      Cell: (rowData) => {
                        const { id } = rowData.row.original;
                        return (
                          <div className="min-width-50 d-flex justify-content-start">
                            <CustomCheckbox
                              checked={checked[id]}
                              onClick={() =>
                                setChecked({
                                  ...checked,
                                  [id]: !checked[id],
                                })
                              }
                            />
                          </div>
                        );
                      },
                    },
                    ...TABLE_COLUMNS,
                    {
                      accessor: "url",
                      Header: "File",
                      width: 160,
                      headerProps: { className: "text-truncate" },
                      disableSortBy: true,
                      Cell: (rowData) => {
                        const document = rowData.row.original;
                        return documentsLoading[document.id] ? (
                          <Loader size="sm" />
                        ) : (
                          getLink(document)
                        );
                      },
                    },
                    {
                      accessor: "user",
                      Header: "",
                      width: 80,
                      headerProps: { className: "text-truncate" },
                      cellProps: {
                        className: "text-right",
                      },
                      disableSortBy: true,
                      Cell: (rowData) => {
                        const statement = rowData.row.original;
                        return trashIcon(statement);
                      },
                    },
                  ]}
                  data={documents?.data || []}
                  sortable
                  onSort={onSort}
                  pageSize={getSizePerPage(documentsContext.sizePerPage)}
                  defaultSort={{
                    sortBy: documentsContext.sortBy,
                    direction: documentsContext.direction,
                  }}
                >
                  <Card className="d-flex">
                    <CardBody className="overflow-auto min-height-300p">
                      {documents?.data?.length ? (
                        <AdvanceTable
                          table
                          isLoading={loading}
                          headerClassName="small"
                          tableProps={{
                            striped: false,
                            className: "mb-0",
                          }}
                        />
                      ) : (
                        <div className="text-center">No results</div>
                      )}
                    </CardBody>
                    <CardFooter>
                      <AdvanceTablePagination
                        totalCount={documents?.count}
                        pageCount={documents?.totalPages}
                        currentPage={documentsContext.page - 1}
                        onPageChange={(page) => setDocumentsContext({ page })}
                        pageSize={getSizePerPage(documentsContext.sizePerPage)}
                        onPageSizeChange={(sizePerPage) =>
                          setDocumentsContext({ page: 1, sizePerPage })
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                      />
                    </CardFooter>
                  </Card>
                </AdvanceTableWrapper>
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {documentsUploadFlow.active ? (
        <DocumentUploadFlow
          onSubmit={() => setDocumentsContext({ ...documentsContext })}
        />
      ) : null}
      {selectedDocument ? (
        <DocumentModalDetails
          document={selectedDocument}
          onClose={() => setSelectedDocument(false)}
        />
      ) : null}
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={() => setPreviewModal({ isOpen: false, file: null })}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Documents;

import React, { useEffect, useState } from "react";

import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import Header from "../components/Header";
import HeaderTitle from "../components/HeaderTitle";
import PersonalInfo from "../components/Personal";
import Security from "../components/Security";
import Advisors from "../components/customer/account/Advisors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAstronaut,
  faUserLock,
  faUserFriends,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { useAccount } from "../providers/accountProvider";
import { useAuth } from "../providers/authProvider";
import Investors from "../components/advisor/Investors";
import BankPayment from "../components/customer/account/BankPayment";
import { usersApi } from "../services/userServices";
import Loader from "../components/Loader";
import { utils } from "../utils/utils";

const PERSONAL_TAB = "1";
const ADVISORS_TAB = "2";
const SECURITY_TAB = "3";
const BANK_TAB = "4";

const Account = () => {
  const [authContext] = useAuth();
  const [accountContext, setAccountContext] = useAccount();
  const [loading, setLoading] = useState();

  const { cookieRole, user } = utils.getSessionData(authContext.currentUser);

  useEffect(() => {
    setLoading(true);
    usersApi.getUsers({ id: user.id }, cookieRole).then((user) => {
      setAccountContext({ user });
      setLoading(false);
    });
  }, [cookieRole, user.id, setAccountContext]);

  return (
    <Container fluid className="my-account">
      <Header>
        <HeaderTitle>My Account</HeaderTitle>
      </Header>
      <Row className="tab tab-vertical account-tabs">
        <Col className="flex-grow-0 px-0">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${
                  accountContext.tab === PERSONAL_TAB
                    ? "active text-primary bg-white"
                    : "text-muted"
                } cursor-pointer`}
                onClick={() => setAccountContext({ tab: PERSONAL_TAB })}
              >
                <FontAwesomeIcon icon={faUserAstronaut} />
              </NavLink>
            </NavItem>
            {utils.userHasRole(user, "advisor") ? <Investors /> : null}
            {utils.userHasRole(user, "investor") ||
            utils.userHasRole(user, "stakeholder") ||
            utils.userHasRole(user, "sponsor") ? (
              <NavItem>
                <NavLink
                  className={`${
                    accountContext.tab === ADVISORS_TAB
                      ? "active text-primary bg-white"
                      : "text-muted"
                  } cursor-pointer`}
                  onClick={() => setAccountContext({ tab: ADVISORS_TAB })}
                >
                  <FontAwesomeIcon icon={faUserFriends} />
                </NavLink>
              </NavItem>
            ) : null}
            <NavItem>
              <NavLink
                className={`${
                  accountContext.tab === SECURITY_TAB
                    ? "active text-primary bg-white"
                    : "text-muted"
                } cursor-pointer`}
                onClick={() => setAccountContext({ tab: SECURITY_TAB })}
              >
                <FontAwesomeIcon icon={faUserLock} />
              </NavLink>
            </NavItem>
            {!utils.userHasRole(user, "sponsor") ? (
              <NavItem>
                <NavLink
                  className={`${
                    accountContext.tab === BANK_TAB
                      ? "active text-primary bg-white"
                      : "text-muted"
                  } cursor-pointer`}
                  onClick={() => setAccountContext({ tab: BANK_TAB })}
                >
                  <FontAwesomeIcon icon={faMoneyBill} />
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </Col>
        <Col className="flex-grow-1 px-0">
          <TabContent activeTab={accountContext.tab}>
            {loading || !accountContext.user ? (
              <Loader size="sm" />
            ) : (
              <>
                <TabPane tabId={PERSONAL_TAB}>
                  <PersonalInfo />
                </TabPane>
                <TabPane tabId={ADVISORS_TAB}>
                  {utils.userHasRole(user, "advisor") ? <Investors /> : null}
                  {utils.userHasRole(user, "investor") ||
                  utils.userHasRole(user, "stakeholder") ||
                  utils.userHasRole(user, "sponsor") ? (
                    <Advisors />
                  ) : null}
                </TabPane>
                <TabPane tabId={SECURITY_TAB}>
                  <Security />
                </TabPane>
                <TabPane tabId={BANK_TAB}>
                  <BankPayment />
                </TabPane>
              </>
            )}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
};

export default Account;

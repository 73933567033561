import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Button,
  CardTitle,
  Col,
} from "reactstrap";

import { fundsApi } from "../../services/fundServices";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import InvestmentAllocation from "../../components/admin/fundDetails/InvestmentAllocation";
import FundAssetsTable from "../../components/customer/fund/FundAssetsTable";
import ReturnsTable from "../../components/admin/fundDetails/ReturnsTable";
import { programsApi } from "../../services/programServices";
import SFQuarterlyReturnsChart from "../../components/customer/SFQuarterlyReturnsChart";
import { utils } from "../../utils/utils";
import { useParams } from "react-router-dom";

const TABLE_ROWS = 4;
const PAGE_SIZE = 999;

const Fund = () => {
  const params = useParams();

  const [fund, setFund] = useState();
  const [loading, setLoading] = useState(false);
  const [letterLoading, setLetterLoading] = useState(true);
  const [expandedLetter, setExpandedLetter] = useState(false);
  const [programStatement, setProgramStatement] = useState();

  const [quartersLoading, setQuartersLoading] = useState(false);
  const [fundQuarters, setFundQuarters] = useState([]);
  const [quartersData, setQuartersData] = useState([]);

  useEffect(() => {
    setLoading(true);
    fundsApi.getFunds({ id: params.fundId }).then((result) => {
      setLoading(false);
      setFund(result);
    });
  }, [setFund, params.fundId]);

  useEffect(() => {
    setLetterLoading(true);
    programsApi
      .getProgramStatements({
        fundId: params.fundId,
        pageSize: 1,
        sortBy: "createdAt",
        direction: "DESC",
      })
      .then((result) => {
        if (result.data.length > 0) {
          setProgramStatement(result.data[0]);
        } else {
          setProgramStatement(null);
        }
        setLetterLoading(false);
      });
  }, [params.fundId]);

  useEffect(() => {
    if (fund && !fund.isPrivateEquity) {
      setQuartersLoading(true);
      fundsApi
        .getFundQuarters({ fundId: params.fundId, pageSize: PAGE_SIZE })
        .then((result) => {
          setQuartersLoading(false);
          setFundQuarters(result.data);
          setQuartersData(
            utils.buildQuartersArray(result.data, TABLE_ROWS).reverse()
          );
        });
    }
  }, [params.fundId, fund]);

  return loading || !fundQuarters ? (
    <Loader />
  ) : fund ? (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <Row className="p-0 align-items-baseline">
            <span>{`${fund.name} ${fund.year}`}</span>
          </Row>
        </HeaderTitle>
      </Header>
      {fund && !fund.isPrivateEquity ? (
        <Row className="mx-0 mb-4">
          <Card className="mb-0 col-12 px-0">
            {quartersLoading ? (
              <div className="my-3">
                <Loader size="sm" />
              </div>
            ) : (
              <ReturnsTable fundQuarters={fundQuarters} />
            )}
          </Card>
        </Row>
      ) : null}
      <Row className="mx-0 mt-5 flex-grow-1 px-0">
        <Card className="flex-grow-1 mb-0">
          <CardHeader className="font-weight-bold d-flex justify-content-between">
            <span className="text-underline">Letter From Our CEO</span>
            <Button
              className="rounded"
              hidden={!programStatement?.letter}
              size="sm"
              onClick={() => setExpandedLetter(!expandedLetter)}
            >
              {expandedLetter ? "See Less" : "See More"}
            </Button>
          </CardHeader>
          <CardBody>
            {letterLoading ? (
              <Loader size="sm" />
            ) : programStatement?.letter ? (
              <div
                className={`overflow-hidden d-flex flex-column col-12 ${
                  expandedLetter ? "" : "max-height-100"
                }`}
              >
                <div
                  className="justify-self-center ceo-letter text-left"
                  dangerouslySetInnerHTML={{ __html: programStatement.letter }}
                ></div>
              </div>
            ) : (
              <span className="text-black-50">
                No statements have been sent.
              </span>
            )}
          </CardBody>
        </Card>
      </Row>
      <Row className="mx-0 mt-5 justify-content-between mb-4">
        <Col className="col-6 bg-transparent pl-0 d-flex">
          <InvestmentAllocation assets={fund.assets} legendPosition={"left"} />
        </Col>
        {fund && !fund.isPrivateEquity ? (
          <Col className="col-6 bg-transparent pr-0 d-flex">
            {quartersLoading ? (
              <Card className="mb-0 flex-grow-1 p-2 d-flex align-items-center justify-content-center">
                <Loader size="sm" />
              </Card>
            ) : (
              <SFQuarterlyReturnsChart quartersData={quartersData} />
            )}
          </Col>
        ) : null}
      </Row>
      {fund.assets.length ? (
        <Row className="mx-0 mt-5 mb-4">
          <Card className="mb-0 flex-grow-1 justify-content-center align-items-center min-vh-50">
            <CardHeader>
              <CardTitle tag="h5" className="mb-0 text-center">
                Assets Totals
              </CardTitle>
            </CardHeader>
            <CardBody className="d-flex col-12 align-items-center">
              <FundAssetsTable fund={fund} fundQuarters={fundQuarters} />
            </CardBody>
          </Card>
        </Row>
      ) : null}
    </Container>
  ) : null;
};

export default Fund;

import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const PERSONAL_TAB = "1";

const initialState = {
  tab: PERSONAL_TAB,
};

const AccountContext = createContext(initialState);

const AccountProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <AccountContext.Provider value={stateAndDispatch}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => useContext(AccountContext);

export { AccountContext, AccountProvider, initialState };

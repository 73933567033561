import React, { useState, useEffect, useCallback } from "react";

import { fundsApi } from "../../../services/fundServices";
import Loader from "../../../components/Loader";
import { useDistributions } from "../../../providers/distributionsProvider";
import { useFundContext } from "../../../providers/fundsProvider";
import QuarterDistributionsTable from "../../../components/admin/QuarterDistributionsTable";
import MonthDistributionsTable from "../../../components/admin/MonthDistributionsTable";
import { useParams } from "react-router-dom";

const Distributions = () => {
  const params = useParams();
  const [fundsContext] = useFundContext();

  const { fundQuarterId, fundMonthId } = params;

  const [loading, setLoading] = useState(false);
  const [fundPeriod, setFundPeriod] = useState();

  const [, setDistributionsContext] = useDistributions();

  const setDistributionsContextCb = useCallback(
    (data) => setDistributionsContext(data),
    [setDistributionsContext]
  );

  useEffect(() => {
    setLoading(true);
    if (fundQuarterId) {
      fundsApi.getFundQuarters({ id: fundQuarterId }).then((result) => {
        setLoading(false);
        setFundPeriod(result);
        setDistributionsContextCb({
          fundMonthId: null,
          fundQuarterId: result?.id || null,
        });
      });
    }
    if (fundMonthId) {
      fundsApi.getFundMonths({ id: fundMonthId }).then((result) => {
        setLoading(false);
        setFundPeriod(result);
        setDistributionsContextCb({
          fundQuarterId: null,
          fundMonthId: result?.id || null,
        });
      });
    }
  }, [fundMonthId, fundQuarterId, setDistributionsContextCb]);

  return loading ? (
    <Loader />
  ) : fundQuarterId ? (
    <QuarterDistributionsTable
      enabledPeriodSelector={false}
      name={`Q${fundPeriod?.quarter}-${fundPeriod?.year}`}
      parentType={`funds`}
      parentId={fundsContext.fund?.id || fundPeriod?.fundId}
    />
  ) : (
    <MonthDistributionsTable
      enabledPeriodSelector={false}
      name={`M${fundPeriod?.month}-${fundPeriod?.year}`}
      parentType={`funds`}
      parentId={fundsContext.fund?.id || fundPeriod?.fundId}
    />
  );
};

export default Distributions;

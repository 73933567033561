import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  CustomInput,
} from "reactstrap";

import Loader from "../../Loader";
import InformationModal from "../../InformationModal";
import { settingsApi } from "../../../services/settingServices";

const Global = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState({});
  const [globalLoading, setGlobalLoading] = useState(true);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setGlobalLoading(true);
    settingsApi.getSettings({}).then((result) => {
      setGlobalLoading(false);
      setSettings(result);
    });
  }, []);

  const onSettingChange = (setting, value) => {
    setting.enabled = value;
    setLoading({ [setting.id]: true });
    settingsApi.updateSettings(setting).then(() => {
      setLoading({ [setting.id]: false });
      settings.splice(
        settings.findIndex((s) => s.id === setting.id),
        1,
        setting
      );
      setSettings([...settings]);
      setInformationModal({
        isOpen: true,
        title: "Alright!",
        body: `${setting.label} ${value ? "enabled" : "disabled"} successfully`,
      });
    });
  };

  return (
    <Card className="col-5 box-shadow-none tab-card">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Global Settings
        </CardTitle>
      </CardHeader>
      <CardBody>
        {globalLoading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            {settings.map((setting, index) => (
              <FormGroup key={index} className="col-12 px-0">
                {loading[setting.id] ? (
                  <Loader align="start" size="sm" />
                ) : (
                  <CustomInput
                    defaultChecked={setting.enabled}
                    disabled={setting.label.includes("Two Factor")}
                    onClick={(event) =>
                      onSettingChange(setting, event.target.checked)
                    }
                    type="switch"
                    id={setting.setting}
                    name={setting.setting}
                    label={setting.label}
                  />
                )}
              </FormGroup>
            ))}
          </Form>
        )}
      </CardBody>
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Card>
  );
};

export default Global;

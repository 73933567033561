import React from "react";
import { Container, Col } from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import HeaderSubtitle from "../../components/HeaderSubtitle";
import { useAuth } from "../../providers/authProvider";
import AlternativeInvestmentsOverview from "../../components/customer/AlternativeInvestmentsOverview";
import { utils } from "../../utils/utils";
import InvestmentsOverview from "../../components/customer/InvestmentsOverview";
import BannersViewList from "../admin/banners/BannersViewList";
import { ADVISOR, PRINTER, INVESTOR } from "../../utils/roles";

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;

const Landing = () => {
  const [authContext] = useAuth();
  const user = authContext.currentUser;

  const isAdvisor =
    authContext.currentUser?.fakeLogin &&
    utils.userHasRole(authContext.currentUser.sessionOwner, ADVISOR) &&
    authContext.currentUser.id !== authContext.currentUser.sessionOwner.id;

  const userInvestments = authContext.currentUser?.investments || [];
  const userOwnerships = authContext.currentUser?.ownerships || [];

  let sponsors = [];
  if (!utils.userHasRole(authContext.currentUser.sessionOwner, PRINTER)) {
    sponsors = utils
      .sidebarSponsors([...userInvestments, ...userOwnerships])
      .sort((x, y) => (y.name.toLowerCase() < x.name.toLowerCase() ? 1 : -1));
  }

  const sidebarLogo = sponsors.length === 1 ? sponsors[0].webLogo : null;
  const isInvestor = utils.userHasRole(authContext.currentUser, INVESTOR);

  return (
    <Container>
      <Col>
        <Header>
          {isInvestor ? <BannersViewList /> : null}
          <HeaderTitle>
            {user.isNewUser ? "Welcome" : "Welcome back"}, {user?.name}!
          </HeaderTitle>
          <HeaderSubtitle>
            {isAdvisor
              ? `${user?.name}’s Investments`
              : "My Investment Overview"}
          </HeaderSubtitle>
        </Header>
      </Col>
      <Col className="d-flex col-12 flex-column flex-grow-1">
        <InvestmentsOverview
          title={"Private Equity"}
          types={[TYPE_PRIVATE_EQUITY, TYPE_HEDGE_FUND]}
        />
        <InvestmentsOverview title={"Real Estate"} types={[TYPE_REAL_ESTATE]} />
        <AlternativeInvestmentsOverview />
        {sidebarLogo ? <Col className="d-flex flex-column align-items-center my-5 p-0">
        <img
            style={{
              opacity: 0.15,
            }}
            className="my-5"
            src={sidebarLogo}
            alt={`Sidebar logo`}
            
          />
        </Col> : null}
      </Col>
    </Container>
  );
};

export default Landing;

import React, { useEffect, useState, useCallback } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { programsApi } from "../../../services/programServices";
import {
  faPlus,
  faUpload,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../components/Loader";
import {
  useProgramCreationFlow,
  initialState,
} from "../../../providers/programCreationFlowProvider";
import ProgramCreationModal from "../../../components/admin/ProgramCreationModal";
import { useLocation, useNavigate } from "react-router-dom";
import { usePrograms } from "../../../providers/programsProvider";
import { utils } from "../../../utils/utils";
import { useAuth } from "../../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../utils/roles";
import { useProgramStatements } from "../../../providers/programStatementsProvider";
import { useProgramCloseDocuments } from "../../../providers/programCloseDocumentsProvider";
import { useStatementsFlow } from "../../../providers/statementsFlowProvider";
import StatementsGenerationFlow from "./StatementsGenerationFlow";
import { fundsApi } from "../../../services/fundServices";
import InformationModal from "../../../components/InformationModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ProgramEmailModal from "../../../components/admin/programDetails/ProgramEmailModal";

const FUND_QUARTER_STATUS_CLOSED = 2;
const ON_ACTIVATE_ACTION = "onActivateAction";

const ALTERNATIVE_INVESTMENTS_TAB = "ALTERNATIVE_INVESTMENTS_TAB";
const HEDGE_FUNDS_TAB = "HEDGE_FUNDS_TAB";
const REAL_ESTATE_TAB = "REAL_ESTATE_TAB";
const PRIVATE_EQUITY_TAB = "PRIVATE_EQUITY_TAB";

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;
const TYPE_ALTERNATIVE = 4;

const TAB_TYPE_MAPPING = {
  [ALTERNATIVE_INVESTMENTS_TAB]: TYPE_ALTERNATIVE,
  [HEDGE_FUNDS_TAB]: TYPE_HEDGE_FUND,
  [REAL_ESTATE_TAB]: TYPE_REAL_ESTATE,
  [PRIVATE_EQUITY_TAB]: TYPE_PRIVATE_EQUITY,
};

const Programs = () => {
  const location = useLocation();
  const [programsContext, setProgramsContext] = usePrograms();
  const [, setProgramCreationFlow] = useProgramCreationFlow();
  const [programStatements] = useProgramStatements();
  const [programCloseDocuments] = useProgramCloseDocuments();
  const [statementsFlow, setStatementsFlow] = useStatementsFlow();
  const [authContext] = useAuth();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const programTypeId = parseInt(params.get("programTypeId"));
  const [programEmails, setProgramEmails] = useState({ isActive: false });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(ALTERNATIVE_INVESTMENTS_TAB);

  const user = authContext.currentUser;

  const setProgramsContextCb = useCallback(
    (data) => setProgramsContext(data),
    [setProgramsContext]
  );

  const setProgramCreationFlowCb = useCallback(
    (data) => setProgramCreationFlow(data),
    [setProgramCreationFlow]
  );

  useEffect(() => {
    setLoading(true);
    programsApi
      .getAllPrograms({
        page: programsContext.page - 1,
        pageSize: programsContext.sizePerPage,
        programTypeId,
      })
      .then((result) => {
        setProgramsContextCb({ programs: result.data });
        setProgramCreationFlowCb({ programTypeId });
        setLoading(false);
      });
  }, [
    programTypeId,
    programsContext.page,
    programsContext.sizePerPage,
    setProgramsContextCb,
    setProgramCreationFlowCb,
    user,
  ]);

  const years = programsContext.programs.reduce(
    (p, c) => (p.indexOf(c.year) > -1 ? p : [c.year, ...p]),
    []
  );
  const isStatementInProgress =
    utils.isSingleProgramStatementsGenerationInProgress(programStatements);
  const isCloseDocumentInProgress =
    utils.isSingleProgramCloseDocumentsGenerationInProgress(
      programCloseDocuments
    );

  const onProgramEmail = (year) => {
    const yearsProgram = programsContext.programs.filter(
      (p) => p.year === year
    );
    return setProgramEmails({ programs: yearsProgram, isActive: true });
  };

  const onSendStatements = (year) => {
    const yearsProgram = programsContext.programs.filter(
      (p) => p.year === year && p.programTypeId === TAB_TYPE_MAPPING[tab]
    );
    const unpublishedProgram = yearsProgram.find(
      (program) => !program.published
    );
    if (unpublishedProgram) {
      return setInformationModal({
        isOpen: true,
        title: "Heads Up!",
        body: `The Program ${unpublishedProgram.name} is not published yet.`,
      });
    }

    const uninvestedProgram = yearsProgram.find(
      (program) => !program.programInvestments?.length
    );
    if (uninvestedProgram) {
      return setInformationModal({
        isOpen: true,
        title: "Heads Up!",
        body: `The Program ${uninvestedProgram.name} has no investments yet.`,
      });
    }

    setLoading(true);
    fundsApi
      .getAllFundQuarters({
        fundYear: year,
        status: FUND_QUARTER_STATUS_CLOSED,
        pageSize: 1,
      })
      .then((closedFundQuarter) => {
        setLoading(false);
        if (!closedFundQuarter?.count) {
          return setInformationModal({
            isOpen: true,
            title: "Heads Up!",
            body: `The Strategic Fund ${year} doesn't have any closed quarter yet.`,
          });
        }
        const currentQ = closedFundQuarter.data[0];
        if (!currentQ.fundsDistributed) {
          return setInformationModal({
            isOpen: true,
            title: "Heads Up!",
            rawBody: true,
            body: `<div class="text-center">Distibutions have not been created for <strong class="text-underline">Q${currentQ.quarter}-${currentQ.year}</strong></div>`,
          });
        }
        const alreadySent = yearsProgram.filter((program) =>
          currentQ.programStatements.find(
            (pStatements) => pStatements.programId === program.id
          )
        );
        if (alreadySent?.length) {
          return setConfirmationModal({
            isOpen: true,
            confirmColor: "danger",
            onSubmit: () => {
              setStatementsFlow({
                action: ON_ACTIVATE_ACTION,
                payload: {
                  quarter: currentQ,
                  year,
                },
              });
              setConfirmationModal(initConfirmationModal);
            },
            onClose: () => setConfirmationModal(initConfirmationModal),
            title: `Heads Up!`,
            body: `
            <p class="text-warning">
              Please note that an statement has already been sent for 
              <strong class="text-underline">Q${currentQ.quarter}-${
              currentQ.year
            }</strong> 
              for the programs:<br/><br/>
              <strong>${alreadySent.map((p) => p.name).join(", ")}</strong> 
            </p>
          <span>You confirm you want to continue and send it again?</span>`,
          });
        }
        setStatementsFlow({
          action: ON_ACTIVATE_ACTION,
          payload: {
            quarter: currentQ,
            year,
          },
        });
      });
  };

  return statementsFlow.active ? (
    <StatementsGenerationFlow />
  ) : (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <span>Programs</span>
          {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
            !utils.userHasRole(user, SPONSOR) && (
              <Button
                color="primary"
                onClick={() =>
                  setProgramCreationFlow({ ...initialState, active: true })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="ml-2">Create New Program</span>
              </Button>
            )}
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          {loading ? (
            <Loader />
          ) : (
            <Col>
              <Row className="mx-0 border-bottom">
                <Nav
                  tabs
                  className="d-flex justify-content-around col-12 px-0 border-0 funds-tabs"
                >
                  <NavItem
                    className={`${
                      tab === ALTERNATIVE_INVESTMENTS_TAB
                        ? "active font-weight-bold bg-white"
                        : "text-muted"
                    } cursor-pointer flex-grow-1 text-center py-1`}
                    onClick={() => setTab(ALTERNATIVE_INVESTMENTS_TAB)}
                  >
                    <NavLink to="#">Alternative Investments</NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      tab === HEDGE_FUNDS_TAB
                        ? "active font-weight-bold bg-white"
                        : "text-muted"
                    } cursor-pointer flex-grow-1 text-center py-1`}
                    onClick={() => setTab(HEDGE_FUNDS_TAB)}
                  >
                    <NavLink to="#">Hedge Funds</NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      tab === REAL_ESTATE_TAB
                        ? "active font-weight-bold bg-white"
                        : "text-muted"
                    } cursor-pointer flex-grow-1 text-center py-1`}
                    onClick={() => setTab(REAL_ESTATE_TAB)}
                  >
                    <NavLink to="#">Real Estate</NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      tab === PRIVATE_EQUITY_TAB
                        ? "active font-weight-bold bg-white"
                        : "text-muted"
                    } cursor-pointer flex-grow-1 text-center py-1`}
                    onClick={() => setTab(PRIVATE_EQUITY_TAB)}
                  >
                    <NavLink to="#">Private Equity</NavLink>
                  </NavItem>
                </Nav>
              </Row>
              <Row className="mx-0">
                <TabContent activeTab={tab} className="col-12 px-0">
                  <TabPane tabId={ALTERNATIVE_INVESTMENTS_TAB}>
                    {years.length ? (
                      years
                        .sort((x, y) => y - x)
                        .map((year, i) => (
                          <Card key={i}>
                            <CardHeader>
                              <CardTitle
                                tag="h5"
                                className="mb-0 d-flex justify-content-between align-items-middle"
                              >
                                <span>{year}</span>
                                <div className="d-flex my-2">
                                  <Button
                                    color="info"
                                    className="mr-2"
                                    onClick={() => onProgramEmail(year)}
                                  >
                                    <FontAwesomeIcon icon={faMailBulk} />
                                    <span className="ml-2">Email All</span>
                                  </Button>
                                  <Button
                                    color="secondary"
                                    onClick={() => onSendStatements(year)}
                                    disabled={
                                      isStatementInProgress ||
                                      isCloseDocumentInProgress
                                    }
                                  >
                                    <FontAwesomeIcon icon={faUpload} />
                                    <span className="ml-2">
                                      Send Statements
                                    </span>
                                  </Button>
                                </div>
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <ListGroup flush>
                                {programsContext.programs.filter(
                                  (p) =>
                                    p.year === year &&
                                    parseInt(p.programTypeId) ===
                                      TYPE_ALTERNATIVE
                                ).length ? (
                                  programsContext.programs
                                    .filter(
                                      (p) =>
                                        p.year === year &&
                                        parseInt(p.programTypeId) ===
                                          TYPE_ALTERNATIVE
                                    )
                                    .map((program, i) => (
                                      <ListGroupItem
                                        onClick={() =>
                                          navigate(
                                            `/back/programs/${program.id}`
                                          )
                                        }
                                        key={i}
                                        action
                                        className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                                        tag="div"
                                      >
                                        <span>{program.name}</span>
                                        <span>
                                          {utils.formatCurrency(
                                            program.capital
                                          )}
                                        </span>
                                      </ListGroupItem>
                                    ))
                                ) : (
                                  <ListGroupItem
                                    className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                                    tag="div"
                                  >
                                    No Programs
                                  </ListGroupItem>
                                )}
                              </ListGroup>
                            </CardBody>
                          </Card>
                        ))
                    ) : (
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5" className="mb-0 text-center">
                            No Programs
                          </CardTitle>
                        </CardHeader>
                      </Card>
                    )}
                  </TabPane>
                  <TabPane tabId={HEDGE_FUNDS_TAB}>
                    {years.length ? (
                      years
                        .sort((x, y) => y - x)
                        .map((year, i) => (
                          <Card key={i}>
                            <CardHeader>
                              <CardTitle
                                tag="h5"
                                className="mb-0 d-flex justify-content-between align-items-middle"
                              >
                                <span>{year}</span>
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <ListGroup flush>
                                {!programsContext.programs.filter(
                                  (p) =>
                                    p.year === year &&
                                    parseInt(p.programTypeId) ===
                                      TYPE_HEDGE_FUND
                                ).length ? (
                                  <ListGroupItem
                                    className="pb-2 border-bottom d-flex justify-content-between"
                                    tag="div"
                                  >
                                    No Programs
                                  </ListGroupItem>
                                ) : null}
                                {programsContext.programs
                                  .filter(
                                    (p) =>
                                      p.year === year &&
                                      parseInt(p.programTypeId) ===
                                        TYPE_HEDGE_FUND
                                  )
                                  .map((program, i) => (
                                    <ListGroupItem
                                      onClick={() =>
                                        navigate(`/back/programs/${program.id}`)
                                      }
                                      key={i}
                                      action
                                      className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                                      tag="div"
                                    >
                                      <span>{program.name}</span>
                                      <span>
                                        {utils.formatCurrency(program.capital)}
                                      </span>
                                    </ListGroupItem>
                                  ))}
                              </ListGroup>
                            </CardBody>
                          </Card>
                        ))
                    ) : (
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5" className="mb-0 text-center">
                            No Programs
                          </CardTitle>
                        </CardHeader>
                      </Card>
                    )}
                  </TabPane>
                  <TabPane tabId={REAL_ESTATE_TAB}>
                    {years.length ? (
                      years
                        .sort((x, y) => y - x)
                        .map((year, i) => (
                          <Card key={i}>
                            <CardHeader>
                              <CardTitle
                                tag="h5"
                                className="mb-0 d-flex justify-content-between align-items-middle"
                              >
                                <span>{year}</span>
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <ListGroup flush>
                                {!programsContext.programs.filter(
                                  (p) =>
                                    p.year === year &&
                                    parseInt(p.programTypeId) ===
                                      TYPE_REAL_ESTATE
                                ).length ? (
                                  <ListGroupItem
                                    className="pb-2 border-bottom d-flex justify-content-between"
                                    tag="div"
                                  >
                                    No Programs
                                  </ListGroupItem>
                                ) : null}
                                {programsContext.programs
                                  .filter(
                                    (p) =>
                                      p.year === year &&
                                      parseInt(p.programTypeId) ===
                                        TYPE_REAL_ESTATE
                                  )
                                  .map((program, i) => (
                                    <ListGroupItem
                                      onClick={() =>
                                        navigate(`/back/programs/${program.id}`)
                                      }
                                      key={i}
                                      action
                                      className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                                      tag="div"
                                    >
                                      <span>{program.name}</span>
                                      <span>
                                        {utils.formatCurrency(program.capital)}
                                      </span>
                                    </ListGroupItem>
                                  ))}
                              </ListGroup>
                            </CardBody>
                          </Card>
                        ))
                    ) : (
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5" className="mb-0 text-center">
                            No Programs
                          </CardTitle>
                        </CardHeader>
                      </Card>
                    )}
                  </TabPane>
                  <TabPane tabId={PRIVATE_EQUITY_TAB}>
                    {years.length ? (
                      years
                        .sort((x, y) => y - x)
                        .map((year, i) => (
                          <Card key={i}>
                            <CardHeader>
                              <CardTitle
                                tag="h5"
                                className="mb-0 d-flex justify-content-between align-items-middle"
                              >
                                <span>{year}</span>
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <ListGroup flush>
                                {!programsContext.programs.filter(
                                  (p) =>
                                    p.year === year &&
                                    parseInt(p.programTypeId) ===
                                      TYPE_PRIVATE_EQUITY
                                ).length ? (
                                  <ListGroupItem
                                    className="pb-2 border-bottom d-flex justify-content-between"
                                    tag="div"
                                  >
                                    No Programs
                                  </ListGroupItem>
                                ) : null}
                                {programsContext.programs
                                  .filter(
                                    (p) =>
                                      p.year === year &&
                                      parseInt(p.programTypeId) ===
                                        TYPE_PRIVATE_EQUITY
                                  )
                                  .map((program, i) => (
                                    <ListGroupItem
                                      onClick={() =>
                                        navigate(`/back/programs/${program.id}`)
                                      }
                                      key={i}
                                      action
                                      className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                                      tag="div"
                                    >
                                      <span>{program.name}</span>
                                      <span>
                                        {utils.formatCurrency(program.capital)}
                                      </span>
                                    </ListGroupItem>
                                  ))}
                              </ListGroup>
                            </CardBody>
                          </Card>
                        ))
                    ) : (
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5" className="mb-0 text-center">
                            No Programs
                          </CardTitle>
                        </CardHeader>
                      </Card>
                    )}
                  </TabPane>
                </TabContent>
              </Row>
            </Col>
          )}
        </Col>
      </Row>
      <ProgramCreationModal />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {programEmails.isActive ? (
        <ProgramEmailModal
          program={programEmails.programs}
          onClose={() => setProgramEmails({ isActive: false })}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Programs;

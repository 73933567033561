import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import PrinterLayout from "../layouts/Printer";
import CustomerLayout from "../layouts/Customer";
import AdvisorLayout from "../layouts/Advisor";
import AdminLayout from "../layouts/Admin";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import {
  authRoutes,
  adminRoutes,
  advisorRoutes,
  customerRoutes,
  surveyRoutes,
  printerRoutes,
} from ".";

import Page500 from "../pages/auth/Page500";
import OnBoarding from "../components/customer/onboard/OnBoarding";

const CustomRoutes = () => {
  return (
    <Router>
      <Routes>
        {surveyRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            element={<route.component />}
          />
        ))}
        {authRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            element={
              <AuthLayout>
                <route.component />
              </AuthLayout>
            }
          />
        ))}
        {adminRoutes().flatMap((route, index) =>
          route.children
            ? route.children.map((child, index) => (
                <Route
                  key={index}
                  path={child.path}
                  exact
                  element={
                    <AdminLayout>
                      <route.component />
                    </AdminLayout>
                  }
                />
              ))
            : [
                <Route
                  key={index}
                  path={route.path}
                  exact
                  element={
                    <AdminLayout>
                      <route.component />
                    </AdminLayout>
                  }
                />,
              ]
        )}
        {customerRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            element={
              <CustomerLayout>
                <route.component />
              </CustomerLayout>
            }
          />
        ))}
        {advisorRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            element={
              <AdvisorLayout>
                <route.component />
              </AdvisorLayout>
            }
          />
        ))}
        {printerRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            element={
              <PrinterLayout>
                <route.component />
              </PrinterLayout>
            }
          />
        ))}
        <Route path={"/"} element={<Navigate to="/portal" />} />
        <Route
          path={"/error/500"}
          exact
          element={
            <AuthLayout>
              <Page500 />
            </AuthLayout>
          }
        />
        <Route
          path={"/error/404"}
          exact
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
        <Route path={"/onboard/:token"} exact element={<OnBoarding />} />
        <Route
          path={"*"}
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default CustomRoutes;

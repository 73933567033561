import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderSubtitle from "../../../../components/HeaderSubtitle";
import { Navigate } from "react-router-dom";
import { useDocumentsUploadFlow } from "../../../../providers/documentUploadFlowProvider";
import UploadReview from "./UploadReview";
import MatchReview from "./MatchReview";
import Upload from "./Upload";

const REVIEW_STEP = 2;
const MATCH_STEP = 3;
const FINAL_STEP = 4;

const STEPS = [
  { id: REVIEW_STEP, text: "Review Documents" },
  { id: MATCH_STEP, text: "Match Review" },
  { id: FINAL_STEP, text: "Upload" },
];

const getCircleColor = (current, step) => {
  if (current < step) {
    return "light";
  }
  if (current === step) {
    return "primary";
  }
  return "success";
};

const textStyle = (current, step) =>
  current === step ? "font-weight-bold" : "text-muted";

const FlowProgress = () => {
  const [documentsUploadFlow] = useDocumentsUploadFlow();
  return (
    <Col className="d-flex flex-column align-items-center col-8 progress-container p-0 mt-4">
      <Row className="progress-line"></Row>
      <Row className="justify-content-between steps-container">
        {STEPS.map((step, i) => (
          <span
            key={i}
            className={`text-${getCircleColor(
              documentsUploadFlow.step,
              step.id
            )}`}
          >
            <FontAwesomeIcon icon={faCircle} />
          </span>
        ))}
      </Row>
      <Row className="justify-content-between steps-container">
        {STEPS.map((step, i) => (
          <span
            key={i}
            className={textStyle(documentsUploadFlow.step, step.id)}
          >
            {step.text}
          </span>
        ))}
      </Row>
    </Col>
  );
};

const getComponent = (step) => {
  switch (step) {
    case REVIEW_STEP:
      return UploadReview;
    case MATCH_STEP:
      return MatchReview;
    case FINAL_STEP:
      return Upload;
    default:
      return UploadReview;
  }
};

const DocumentsReview = () => {
  const [documentsUploadFlow] = useDocumentsUploadFlow();
  if (documentsUploadFlow.statements) {
    STEPS[0].text = "Review Statements";
  }
  const Component = getComponent(documentsUploadFlow.step);
  return documentsUploadFlow.step < REVIEW_STEP ? (
    <Navigate to="/back/documents" />
  ) : (
    <Container fluid>
      <Header>
        <HeaderTitle>
          {documentsUploadFlow.statements ? "Statements" : "Documents"} Upload
        </HeaderTitle>
        <HeaderSubtitle>Review</HeaderSubtitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="div" className="mb-0">
                <FlowProgress />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Component />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DocumentsReview;

import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Input,
  Form,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { fundsApi } from "../../../../../services/fundServices";
import Loader from "../../../../Loader";
import { useFundContext } from "../../../../../providers/fundsProvider";
import ConfirmationModal from "../../../../ConfirmationModal";
import ExpenseCreationModal from "./ExpenseCreationModal";
import { programsApi } from "../../../../../services/programServices";
import CurrencyInput from "react-currency-input-field";
import { utils } from "../../../../../utils/utils";

const MAX_VALUE = 999999999;

const FundQuarterCloseModal = ({ onSubmitted, onClose }) => {
  const [fundContext] = useFundContext();
  const selectedFundQuarter = fundContext.selectedFundQuarter;

  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [propCoFees, setPropCoFees] = useState({});
  const [expenses, setExpenses] = useState({});
  const [incomeFee, setIncomeFee] = useState(selectedFundQuarter.incomeFee);
  const [annualFee, setAnnualFee] = useState(selectedFundQuarter.annualFee);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const [expenseCreationModal, setExpenseCreationModal] = useState({
    isOpen: false,
    programId: null,
  });

  useEffect(() => {
    setLoading(true);
    const ids = fundContext.fund.fundFundInvestments
      .filter((fi) => fi.programFundInvestment)
      .map((fi) => fi.programFundInvestment.id)
      .join(",");
    programsApi
      .getAllPrograms({
        page: 0,
        pageSize: 999999999,
        ids,
      })
      .then((programs) => {
        const programsData = programs.data;
        setLoading(false);
        setPrograms(programsData);
        setPropCoFees(
          programsData.reduce((p, c) => {
            const programPropCoFee = (
              selectedFundQuarter.propCoFees || []
            ).find((fee) => fee.programId === c.id);
            p[c.id] = {
              programId: c.id,
              flatFee: programPropCoFee?.flatFee || 0,
              percentFee: programPropCoFee?.percentFee || 0,
            };
            return p;
          }, {})
        );
        setExpenses(
          programsData.reduce((p, c) => {
            const programExpenses = (
              selectedFundQuarter.programExpenses || []
            ).filter((pe) => pe.programId === c.id);
            p[c.id] = programExpenses || [];
            return p;
          }, {})
        );
      });
  }, [
    selectedFundQuarter.propCoFees,
    selectedFundQuarter.programExpenses,
    fundContext.fund.fundFundInvestments,
  ]);

  const onSubmit = () => {
    const propCoFeesValues = Object.keys(propCoFees).map((programId) => {
      return {
        programId,
        ...propCoFees[programId],
      };
    });
    const expensesValues = Object.values(expenses).flatMap((e) => e);

    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setLoading(true);
        fundsApi
          .closeFundQuarter({
            incomeFee,
            annualFee,
            propCoFees: propCoFeesValues,
            expenses: expensesValues,
            id: selectedFundQuarter.id,
          })
          .then(() => {
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            onSubmitted();
          });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Close Q${selectedFundQuarter.quarter}-${selectedFundQuarter.year}`,
      body: `<p class="text-underline">You selected to close Q${selectedFundQuarter.quarter} of ${selectedFundQuarter.year}.</p>
          <span class="font-weight-bold">You confirm you want to continue?</span>`,
    });
  };

  const onAddExpense = (amount) => {
    const fundQuarterId = selectedFundQuarter.id;
    const programId = expenseCreationModal.programId;

    const exp = expenses[programId];
    exp.push({
      fundQuarterId,
      programId,
      amount,
    });
    setExpenses({
      ...expenses,
      [programId]: exp,
    });
  };

  const onDeleteExpense = (programId, index) => {
    const exp = expenses[programId];
    exp.splice(index, 1);
    setExpenses({
      ...expenses,
      [programId]: exp,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : expenseCreationModal.isOpen ? (
    <ExpenseCreationModal
      onClose={() =>
        setExpenseCreationModal({
          isOpen: false,
          programId: null,
        })
      }
      onSubmit={onAddExpense}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        Close Q{selectedFundQuarter.quarter}-{selectedFundQuarter.year}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Management Fee %
                </Label>
                <Col sm={7}>
                  <Input
                    required={true}
                    type="number"
                    max={100}
                    min={0}
                    step={0.01}
                    placeholder="Enter a percentage.."
                    onChange={(event) =>
                      setIncomeFee(event.currentTarget.value)
                    }
                    value={incomeFee === 0 ? "" : incomeFee}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Annual Management Fee %
                </Label>
                <Col sm={7}>
                  <Input
                    required={true}
                    type="number"
                    max={100}
                    min={0}
                    step={0.01}
                    placeholder="Enter a percentage.."
                    onChange={(event) =>
                      setAnnualFee(event.currentTarget.value)
                    }
                    value={annualFee === 0 ? "" : annualFee}
                  />
                </Col>
              </FormGroup>
              <ListGroup flush className="text-left border rounded mb-2">
                <ListGroupItem
                  className="text-center border-bottom font-weight-bold text-body list-group-item"
                  tag="div"
                >
                  <span>PropCo Fees</span>
                </ListGroupItem>
                {programs.map((program) => (
                  <div key={program.id}>
                    <ListGroupItem
                      className="text-center border-bottom text-body list-group-item bg-light"
                      tag="div"
                    >
                      <span>{program.name}</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="border-bottom d-flex align-items-center justify-content-between flex-column"
                      tag="div"
                    >
                      <FormGroup row className="col-12">
                        <Label
                          sm={5}
                          className="text-sm-left d-flex flex-column"
                        >
                          Flat Fee
                        </Label>
                        <Col sm={7}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            required={true}
                            allowNegativeValue={false}
                            maxLength={20}
                            className="form-control"
                            placeholder="Enter the amount.."
                            value={propCoFees[program.id]?.flatFee || ""}
                            onValueChange={(value) => {
                              if (!value || value < MAX_VALUE) {
                                setPropCoFees({
                                  ...propCoFees,
                                  [program.id]: {
                                    ...(propCoFees[program.id] || {}),
                                    flatFee: value,
                                  },
                                });
                              }
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="col-12">
                        <Label
                          sm={5}
                          className="text-sm-left d-flex flex-column"
                        >
                          % Fee
                        </Label>
                        <Col sm={7}>
                          <Input
                            required={true}
                            type="number"
                            max={100}
                            min={0}
                            step={0.01}
                            placeholder="Enter a value.."
                            value={propCoFees[program.id]?.percentFee || ""}
                            onChange={(event) =>
                              setPropCoFees({
                                ...propCoFees,
                                [program.id]: {
                                  ...(propCoFees[program.id] || {}),
                                  percentFee: event.currentTarget.value,
                                },
                              })
                            }
                          />
                        </Col>
                      </FormGroup>
                      <ListGroup className="col-12 px-0 text-left border rounded">
                        <ListGroupItem
                          className="pb-2 border-bottom text-body bg-light d-flex align-items-center justify-content-between"
                          tag="div"
                        >
                          <div className="col-3"></div>
                          <div className="col-6 text-center">Expenses</div>
                          <div className="col-3 text-right">
                            <Button
                              color="info"
                              size="sm"
                              className="rounded"
                              onClick={() =>
                                setExpenseCreationModal({
                                  isOpen: true,
                                  programId: program.id,
                                })
                              }
                            >
                              Add
                            </Button>
                          </div>
                        </ListGroupItem>
                        {expenses[program.id]?.length ? (
                          expenses[program.id].map((expense, index) => {
                            return (
                              <ListGroupItem
                                className="pb-2 border-bottom"
                                tag="div"
                              >
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  key={index}
                                >
                                  <span>
                                    {utils.formatCurrency(expense.amount)}
                                  </span>
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      onDeleteExpense(program.id, index)
                                    }
                                    icon={faTrashAlt}
                                    className="cursor-pointer text-danger"
                                    size="sm"
                                  />
                                </div>
                              </ListGroupItem>
                            );
                          })
                        ) : (
                          <ListGroupItem
                            className="pb-2 border-bottom"
                            tag="div"
                          >
                            No expenses added
                          </ListGroupItem>
                        )}
                      </ListGroup>
                    </ListGroupItem>
                  </div>
                ))}
                {!programs.length ? (
                  <ListGroupItem
                    className="text-center border-bottom text-body"
                    tag="div"
                  >
                    <span>No Programs</span>
                  </ListGroupItem>
                ) : null}
              </ListGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button color={"primary"} type={"submit"}>
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundQuarterCloseModal;

import {
  faArrowDown,
  faArrowUp,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";

import { ListGroupItem } from "reactstrap";
import { utils } from "../../utils/utils";
import Loader from "../Loader";
import { PROGRAM_INVESTMENT_TRANSACTION_TYPES } from "../../utils/constants";

const DEFAULT_UNIT_VALUE = 1000;

const Transaction = ({
  loading,
  type,
  label,
  date,
  amount,
  onDelete = () => {},
}) => (
  <ListGroupItem
    className="pb-2 border-bottom d-flex align-items-center justify-content-between"
    tag="div"
  >
    <div>
      <FontAwesomeIcon
        className={
          type === PROGRAM_INVESTMENT_TRANSACTION_TYPES.INCREASE
            ? "text-success"
            : "text-danger"
        }
        icon={
          type === PROGRAM_INVESTMENT_TRANSACTION_TYPES.INCREASE
            ? faArrowUp
            : faArrowDown
        }
        fixedWidth
      />
      <span className="ml-2 mr-2">{utils.formatDate(date)}</span>
      <span>
        •<span className="ml-2">{label}</span>
      </span>
    </div>
    <div className="d-flex align-items-center">
      <span className="mr-2">{`${utils.formatCurrency(amount)} / ${
        amount / DEFAULT_UNIT_VALUE
      } units`}</span>
      {loading ? (
        <div className="min-width-50">
          <Loader size="sm" align="end" />
        </div>
      ) : (
        <FontAwesomeIcon
          onClick={onDelete}
          className="text-danger cursor-pointer"
          icon={faTrashAlt}
          fixedWidth
        />
      )}
    </div>
  </ListGroupItem>
);

export default memo(Transaction);

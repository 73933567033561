import React from "react";

import { Button, ListGroupItem, Card } from "reactstrap";
import { utils } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useFundContext } from "../../../providers/fundsProvider";
import {
  useFundAssetDetailModal,
  initialState,
} from "../../../providers/fundAssetDetailModalProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../utils/roles";
import { useAuth } from "../../../providers/authProvider";

const TYPE_OUTCOME = {
  id: "2",
};

const FundAsset = ({ asset }) => {
  const [, setFundAssetDetailModal] = useFundAssetDetailModal();
  const [fundContext] = useFundContext();
  const [authContext] = useAuth();

  const fund = fundContext.fund;
  const user = authContext.currentUser;

  const getAssetCurrentCapital = (asset) =>
    asset.capital +
    (asset.transactions || []).reduce(
      (p, c) => p + (c.type === TYPE_OUTCOME.id ? -c.amount : c.amount),
      0
    );

  const totalAssetsCapital = fund.assets.reduce(
    (p, c) => p + getAssetCurrentCapital(c),
    0
  );

  return (
    <div className="col-3 pl-0 py-1">
      <Card className="text-center mb-0">
        <ListGroupItem
          className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
          tag="div"
        >
          {asset.name}
        </ListGroupItem>
        <ListGroupItem className="pb-2 border-bottom" tag="div">
          {utils.formatCurrency(getAssetCurrentCapital(asset))}
        </ListGroupItem>
        <ListGroupItem className="pb-2 border-bottom" tag="div">
          {(
            (getAssetCurrentCapital(asset) /
              (fund.balance + (totalAssetsCapital || 1))) *
            100
          ).toFixed(2)}
          %
        </ListGroupItem>
        {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
          !utils.userHasRole(user, SPONSOR) && (
            <ListGroupItem className="pb-2 border-bottom" tag="div">
              <Button
                color={"info"}
                onClick={() =>
                  setFundAssetDetailModal({
                    ...initialState,
                    fundId: asset.fundId,
                    assetId: asset.id,
                    assetCapital: asset.capital,
                    isModalOpen: true,
                    transactionCategoryId: asset.fundAssetCategoryId,
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="ml-2">See More</span>
              </Button>
            </ListGroupItem>
          )}
      </Card>
    </div>
  );
};

export default FundAsset;

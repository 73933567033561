import React, { useState } from "react";

import {
  Button,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Badge,
} from "reactstrap";
import { useDocumentsUploadFlow } from "../../../../providers/documentUploadFlowProvider";
import {
  faFilePdf,
  faTrashAlt,
  faTrashRestoreAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentPreviewModal from "./DocumentPreviewModal";

const REVIEW_STEP = 2;
const FINAL_STEP = 4;

const MatchReview = () => {
  const [documentsUploadFlow, setDocumentsUploadFlow] =
    useDocumentsUploadFlow();
  const programs = Object.keys(documentsUploadFlow.documents);
  const [previewModal, setPreviewModal] = useState({});

  const getDocuments = () =>
    Object.keys(documentsUploadFlow.documents).reduce(
      (p, c) => [
        ...p,
        ...documentsUploadFlow.documents[c].reduce(
          (p, c) => (c.removed ? [...p] : [...p, c]),
          []
        ),
      ],
      []
    );

  const removeDocument = (document) => {
    document.removed = true;
    setDocumentsUploadFlow({ documents: { ...documentsUploadFlow.documents } });
  };

  const restoreDocument = (document) => {
    document.removed = false;
    setDocumentsUploadFlow({ documents: { ...documentsUploadFlow.documents } });
  };

  const onPreview = (file) => {
    setPreviewModal({
      isOpen: true,
      file,
    });
  };

  return (
    <>
      <CardHeader className="py-0 h4 my-4 text-underline body-color text-center col-12">
        Document Matches
      </CardHeader>
      <ListGroup>
        {programs.map((program, index) => (
          <div key={index}>
            <h4 className="mt-2 text-center">{program}</h4>
            {documentsUploadFlow.documents[program].map((document, index) => (
              <ListGroupItem
                key={index}
                className="d-flex justify-content-between p-0 border-0 my-2"
              >
                <span className="col-3 px-0 py-3 text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
                  {document.investor.name}
                </span>
                <Row className="mx-0 col-6 px-0 d-flex justify-content-center align-items-center">
                  <Row className="mx-0 match-line"></Row>
                  {document.removed ? (
                    <Button
                      className="d-flex z-index-2"
                      color={"success"}
                      onClick={() => restoreDocument(document)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashRestoreAlt}
                        fixedWidth
                        className="text-white"
                      />
                    </Button>
                  ) : (
                    <Button
                      className="d-flex z-index-2"
                      color={"danger"}
                      onClick={() => removeDocument(document)}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        fixedWidth
                        className="text-white"
                      />
                    </Button>
                  )}
                </Row>
                <div className="col-3 p-3 text-center border rounded bg-lighter justify-content-between d-flex">
                  <div className="d-flex align-items-center text-truncate">
                    <FontAwesomeIcon icon={faFilePdf} />
                    <span className="ml-2 text-truncate">
                      {document.filename || document.fileName}
                    </span>
                  </div>
                  <Badge
                    onClick={() => onPreview(document.file)}
                    className="ml-2 cursor-pointer mt-1"
                    color={"info"}
                  >
                    Preview
                  </Badge>
                </div>
              </ListGroupItem>
            ))}
          </div>
        ))}
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() => setDocumentsUploadFlow({ step: REVIEW_STEP })}
        >
          Back
        </Button>{" "}
        <Button
          disabled={!getDocuments().length}
          className="ml-2"
          color={"primary"}
          onClick={() => setDocumentsUploadFlow({ step: FINAL_STEP })}
        >
          Confirm & Upload
        </Button>
      </Row>
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={() => setPreviewModal({ isOpen: false, file: null })}
        />
      ) : null}
    </>
  );
};

export default MatchReview;

import React from "react";

import { Bar } from "react-chartjs-2";
import { utils } from "../../../utils/utils";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import moment from "moment";

const TYPE_INCREASE = "1";

const monthNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const programIdByName = [];

const getData = (investments) =>
  monthNumbers.map((month) =>
    investments
      .filter(
        (investment) =>
          moment(investment.date).format("MMMM") ===
          moment().set("month", month).format("MMMM")
      )
      .reduce((p, c) => p + c.amount * (c.type === TYPE_INCREASE ? 1 : -1), 0)
  );

const getProgramsData = (investments) =>
  investments.reduce((p, c) => {
    if (!p[c.programFundInvestment.name]) {
      p[c.programFundInvestment.name] = [];
    }
    p[c.programFundInvestment.name] = [...p[c.programFundInvestment.name], c];
    programIdByName[c.programFundInvestment.name] = c.programFundInvestment.id;
    return p;
  }, {});

const getChartData = (investments, chartColors) => {
  const data = getProgramsData(investments);
  const months = monthNumbers.map((month) =>
    moment().set("month", month).format("MMMM")
  );

  return {
    labels: months,
    datasets: Object.keys(data)
      .map((programName, index) => ({
        type: "bar",
        label: programName,
        data: getData(data[programName]),
        backgroundColor: chartColors[index],
        fill: false,
        datalabels: {
          display: false,
        },
      }))
      .sort((a, b) => a.data - b.data),
  };
};

const getChartOptions = () => {
  return {
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
    },
    responsive: true,
    tooltips: {
      mode: "label",
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data["datasets"][tooltipItem.datasetIndex];
          const value = dataset["data"][tooltipItem["index"]];
          return utils.formatCurrency(value || 0);
        },
      },
    },
    plugins: {
      colorschemes: {
        scheme: "tableau.Classic20",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `$${tooltipItem.formattedValue}`,
        },
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        ticks: {
          callback: (label) => utils.formatCurrency(label),
        },
      },
    },
  };
};

const InvestmentTimeline = ({ investments, chartColors }) => (
  <Card className="mb-0 flex-grow-1 p-2">
    <CardHeader>
      <CardTitle tag="h5" className="mb-0 text-center">
        Investment Timeline
      </CardTitle>
    </CardHeader>
    <CardBody className="d-flex col-12 align-items-center ">
      <Bar
        height={400}
        data={getChartData(investments, chartColors)}
        options={getChartOptions()}
      />
    </CardBody>
  </Card>
);

export default InvestmentTimeline;

export const USER_ROLES = Object.freeze({
  ADVISOR: 2,
  INVESTOR: 3,
  STAKEHOLDER: 4,
});

// Investment Transaction Type
export const PROGRAM_INVESTMENT_TRANSACTION_TYPES = Object.freeze({
  INCREASE: "1",
  DECREASE: "2",
});
export const PROGRAM_INVESTMENT_TRANSACTION_TYPES_ASSERT = Object.freeze(
  Object.values(PROGRAM_INVESTMENT_TRANSACTION_TYPES).reduce(
    (accum, value) => ({ ...accum, [value]: true }),
    {}
  )
);
export const PROGRAM_INVESTMENT_TRANSACTION_TYPES_ENUM = Object.freeze(
  Object.values(PROGRAM_INVESTMENT_TRANSACTION_TYPES)
);

export const DEFAULT_RICH_TEXT_EDITOR_MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons

    [{ list: "ordered" }, { list: "bullet" }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme

    ["clean"], // remove formatting button
  ],
};

import React, { useState } from "react";
import { usersApi } from "../services/userServices";
import { useAuth } from "../providers/authProvider";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  FormGroup,
  Row,
  Col,
  Form,
} from "reactstrap";
import Loader from "./Loader";
import brand from "../assets/img/brand.png";

const InformationModal = ({ onClose }) => {
  const [authContext] = useAuth();
  const [supportEmailData, setSupportEmailData] = useState({
    subject: "",
    content: "",
  });
  const userName = authContext.currentUser.name;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [supportModal, setSupportModal] = useState(true);
  const email = authContext.currentUser.email;

  const closeModal = (event) => {
    event.preventDefault();
    setSupportEmailData({ subject: "", content: "" });
    onClose();
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={closeModal}>
      &times;
    </Button>
  );

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await usersApi.sendSupportEmail({ ...supportEmailData, email, userName });
    setLoading(false);
    setSupportEmailData({ subject: "", content: "" });
    setSupportModal(false);
    setConfirmationModal(true);
    setSupportModal(true);
  };

  return (
    <Modal isOpen={supportModal} onClosed={closeModal} size="md">
      <Form onSubmit={onSubmit}>
        <ModalHeader close={closeBtn}>
          {!confirmationModal ? "Help/Support" : "Alright!"}
        </ModalHeader>
        {!confirmationModal ? (
          <ModalBody>
            <Row>
              <Col className="pr-0 col-8">
                <h3>Contact Vulcan Fund Solutions</h3>
                <p className="mb-0 mt-3">
                  2014 3rd Ave N, Birmingham, AL 35302
                </p>
                <p>
                  <b>Email:</b> support@vulcanfundsolutions.com
                </p>
              </Col>
              <Col className="p-0 col-4">
                <img
                  src={brand}
                  className="rounded p-0 col-12 position-absolute top-0"
                  alt={"Vulcan Fund"}
                />
              </Col>
            </Row>
            <p className="mb-4 pt-3">
              For any general inquiries, please fill in the following contact
              form:
            </p>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    disabled={true}
                    type="text"
                    maxLength="50"
                    placeholder="Name *"
                    value={userName}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    disabled={true}
                    type="email"
                    maxLength="50"
                    placeholder="Email *"
                    value={email}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Input
                    type="text"
                    maxLength="50"
                    placeholder="Subject"
                    onChange={(event) =>
                      setSupportEmailData({
                        ...supportEmailData,
                        subject: event.currentTarget.value,
                      })
                    }
                    value={supportEmailData.subject}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="h-100">
                  <Input
                    className="h-100"
                    type="textarea"
                    maxLength="200"
                    placeholder="Message"
                    onChange={(event) =>
                      setSupportEmailData({
                        ...supportEmailData,
                        content: event.currentTarget.value,
                      })
                    }
                    value={supportEmailData.content}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        ) : (
          <ModalBody className="text-center text-success">
            Email sent successfully!
          </ModalBody>
        )}
        <ModalFooter className="d-flex justify-content-between align-items-center">
          <Button color={"secondary"} onClick={closeModal}>
            Close
          </Button>
          {!confirmationModal ? (
            loading ? (
              <div className="min-width-50">
                <Loader size="sm" />
              </div>
            ) : (
              <Button
                color={"primary"}
                disabled={
                  !supportEmailData.subject && !supportEmailData.content
                }
                type="submit"
              >
                Send
              </Button>
            )
          ) : null}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default InformationModal;

import React from "react";
import Routes from "./routes/Routes";
import { AuthProvider } from "./providers/authProvider";
import { InvestorsProvider } from "./providers/investorsProvider";
import { ProgramCreationFlowProvider } from "./providers/programCreationFlowProvider";
import { ProgramsProvider } from "./providers/programsProvider";
import { OnBoardingFlowProvider } from "./providers/onBoardingFlowProvider";
import { FundAssetDetailModalProvider } from "./providers/fundAssetDetailModalProvider";
import { FundTransactionModalProvider } from "./providers/fundTransactionModalProvider";
import { FundAssetCreationModalProvider } from "./providers/fundAssetCreationModalProvider";
import { FundsProvider } from "./providers/fundsProvider";
import { DocumentsProvider } from "./providers/documentsProvider";
import { DocumentsUploadFlowProvider } from "./providers/documentUploadFlowProvider";
import { ProgramEmailProvider } from "./providers/programEmailProvider";
import { CategoriesProvider } from "./providers/categoriesProvider";
import { SponsorsProvider } from "./providers/sponsorsProvider";
import { UsersProvider } from "./providers/usersProvider";
import { DistributionsProvider } from "./providers/distributionsProvider";
import { AccountProvider } from "./providers/accountProvider";
import { ProgramStatementsProvider } from "./providers/programStatementsProvider";
import { SettingsProvider } from "./providers/settingsProvider";
import { StatementsProvider } from "./providers/statementsProvider";
import { ActivitiesProvider } from "./providers/activitiesProvider";
import { UserEmailProvider } from "./providers/userEmailProvider";
import { EmailProvider } from "./providers/emailProvider";
import { AdvisorsProvider } from "./providers/advisorsProvider";
import { FundCapitalTransactionsModalProvider } from "./providers/fundCapitalTransactionsModalProvider";
import { ProgramCloseDocumentsProvider } from "./providers/programCloseDocumentsProvider";
import { StakeholdersProvider } from "./providers/stakeholdersProvider";
import { StatementsFlowProvider } from "./providers/statementsFlowProvider";
import { EditInvestmentModalProvider } from "./providers/editInvestmentModalProvider";
import { EditProgramStatusModalProvider } from "./providers/editProgramStatusModalProvider";

class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
  }

  render() {
    return (
      <AuthProvider>
        <InvestorsProvider>
          <AdvisorsProvider>
            <UsersProvider>
              <SponsorsProvider>
                <FundsProvider>
                  <ProgramsProvider>
                    <DocumentsProvider>
                      <CategoriesProvider>
                        <ProgramEmailProvider>
                          <EmailProvider>
                            <UserEmailProvider>
                              <ProgramCreationFlowProvider>
                                <DocumentsUploadFlowProvider>
                                  <OnBoardingFlowProvider>
                                    <FundAssetDetailModalProvider>
                                      <FundTransactionModalProvider>
                                        <FundAssetCreationModalProvider>
                                          <FundAssetDetailModalProvider>
                                            <DistributionsProvider>
                                              <AccountProvider>
                                                <ProgramStatementsProvider>
                                                  <SettingsProvider>
                                                    <StatementsProvider>
                                                      <ActivitiesProvider>
                                                        <FundCapitalTransactionsModalProvider>
                                                          <ProgramCloseDocumentsProvider>
                                                            <StakeholdersProvider>
                                                              <StatementsFlowProvider>
                                                                <EditInvestmentModalProvider>
                                                                  <EditProgramStatusModalProvider>
                                                                    <Routes />
                                                                  </EditProgramStatusModalProvider>
                                                                </EditInvestmentModalProvider>
                                                              </StatementsFlowProvider>
                                                            </StakeholdersProvider>
                                                          </ProgramCloseDocumentsProvider>
                                                        </FundCapitalTransactionsModalProvider>
                                                      </ActivitiesProvider>
                                                    </StatementsProvider>
                                                  </SettingsProvider>
                                                </ProgramStatementsProvider>
                                              </AccountProvider>
                                            </DistributionsProvider>
                                          </FundAssetDetailModalProvider>
                                        </FundAssetCreationModalProvider>
                                      </FundTransactionModalProvider>
                                    </FundAssetDetailModalProvider>
                                  </OnBoardingFlowProvider>
                                </DocumentsUploadFlowProvider>
                              </ProgramCreationFlowProvider>
                            </UserEmailProvider>
                          </EmailProvider>
                        </ProgramEmailProvider>
                      </CategoriesProvider>
                    </DocumentsProvider>
                  </ProgramsProvider>
                </FundsProvider>
              </SponsorsProvider>
            </UsersProvider>
          </AdvisorsProvider>
        </InvestorsProvider>
      </AuthProvider>
    );
  }
}

export default App;

import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = { sizePerPage: "All", page: 1, search: "" };

const SponsorsContext = createContext(initialState);

const SponsorsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <SponsorsContext.Provider value={stateAndDispatch}>
      {children}
    </SponsorsContext.Provider>
  );
};

export const useSponsors = () => useContext(SponsorsContext);

export { SponsorsContext, SponsorsProvider };

import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { fundsApi } from "../../services/fundServices";
import { useCategories } from "../../providers/categoriesProvider";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";

const FA_CATEGORIES_TAB = 1;
const FAT_CATEGORIES_TAB = 2;
const MAX_PAGE_SIZE = 100;

const Categories = () => {
  const [categoriesContext, setCategoriesContext] = useCategories();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });
  const [loading, setLoading] = useState(true);
  const [fundAssetCategories, setFundAssetCategories] = useState(true);
  const [fundAssetTransactionCategories, setFundAssetTransactionCategories] =
    useState(true);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const onDelete = (category, type) => {
    if (category.elements.length) {
      return setInformationModal({
        isOpen: true,
        body: `This category can't be deleted because is in use.`,
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        const { key, setCategories, deleteCategory, getCategories } = (() => {
          switch (type) {
            case FA_CATEGORIES_TAB:
              return {
                key: "fundAssetCategories",
                setCategories: setFundAssetCategories,
                deleteCategory: fundsApi.deleteFundAssetCategory,
                getCategories: fundsApi.getFundAssetCategories,
              };
            case FAT_CATEGORIES_TAB:
              return {
                key: "fundAssetTransactionCategory",
                setCategories: setFundAssetTransactionCategories,
                deleteCategory: fundsApi.deleteFundAssetTransactionCategory,
                getCategories: fundsApi.getFundAssetTransactionCategories,
              };
            default:
              return {};
          }
        })();
        deleteCategory(category).then(() =>
          getCategories({
            page: categoriesContext[key].page - 1,
            pageSize: getSizePerPage(categoriesContext[key].sizePerPage),
          }).then((result) => {
            setConfirmationModal(initConfirmationModal);
            setCategories(result);
          })
        );
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Category",
      body: `<span>Do you confirm you want to delete ${category.name}?</span>`,
    });
  };

  const trashIcon = (category, type) => (
    <FontAwesomeIcon
      icon={faTrashAlt}
      onClick={() => onDelete(category, type)}
      className="cursor-pointer text-danger"
    />
  );

  useEffect(() => {
    setLoading(true);
    fundsApi
      .getFundAssetCategories({
        page: categoriesContext.fundAssetCategories.page - 1,
        pageSize: getSizePerPage(
          categoriesContext.fundAssetCategories.sizePerPage
        ),
      })
      .then((result) => {
        setFundAssetCategories(result);
        setLoading(false);
      });
  }, [categoriesContext.fundAssetCategories]);

  useEffect(() => {
    setLoading(true);
    fundsApi
      .getFundAssetTransactionCategories({
        page: categoriesContext.fundAssetTransactionCategories.page - 1,
        pageSize: getSizePerPage(
          categoriesContext.fundAssetTransactionCategories.sizePerPage
        ),
      })
      .then((result) => {
        setFundAssetTransactionCategories(result);
        setLoading(false);
      });
  }, [categoriesContext.fundAssetTransactionCategories]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <span>Categories</span>
        </HeaderTitle>
      </Header>
      <Row className="mx-0">
        <Nav
          tabs
          className="d-flex justify-content-around col-12 px-0 border-0 categories-tabs"
        >
          <NavItem
            className={`${
              categoriesContext.tab === FA_CATEGORIES_TAB
                ? "active font-weight-bold bg-white"
                : "text-muted"
            } cursor-pointer flex-grow-1 text-center py-1`}
            onClick={() => setCategoriesContext({ tab: FA_CATEGORIES_TAB })}
          >
            <NavLink to="#">Fund Assets Categories</NavLink>
          </NavItem>
          <NavItem
            className={`${
              categoriesContext.tab === FAT_CATEGORIES_TAB
                ? "active font-weight-bold bg-white"
                : "text-muted"
            } cursor-pointer flex-grow-1 text-center py-1`}
            onClick={() => setCategoriesContext({ tab: FAT_CATEGORIES_TAB })}
          >
            <NavLink to="#">Fund Assets Transactions Categories</NavLink>
          </NavItem>
        </Nav>
      </Row>
      <Row className="mx-0">
        <TabContent
          activeTab={categoriesContext.tab}
          className="col-12 px-0 categories-tabs-content"
        >
          <TabPane tabId={FA_CATEGORIES_TAB}>
            <Card>
              <CardBody>
                {loading ? (
                  <Loader />
                ) : fundAssetCategories.data?.length ? (
                  <AdvanceTableWrapper
                    columns={[
                      {
                        accessor: "name",
                        Header: "Name",
                        headerProps: { className: "text-truncate" },
                        disableSortBy: true,
                      },
                      {
                        accessor: "id",
                        Header: "",
                        width: 80,
                        headerProps: { className: "text-right text-truncate" },
                        cellProps: {
                          className: "text-right",
                        },
                        disableSortBy: true,
                        Cell: (rowData) => {
                          const category = rowData.row.original;
                          return category.default
                            ? null
                            : trashIcon(category, FA_CATEGORIES_TAB);
                        },
                      },
                    ]}
                    data={fundAssetCategories.data || []}
                    pageSize={getSizePerPage(
                      categoriesContext.fundAssetCategories.sizePerPage
                    )}
                  >
                    <Card className="d-flex">
                      <CardBody className="overflow-auto min-height-300p">
                        {fundAssetCategories.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={fundAssetCategories?.count}
                          pageCount={fundAssetCategories?.totalPages}
                          currentPage={fundAssetCategories.page}
                          onPageChange={(page) =>
                            setCategoriesContext({
                              fundAssetCategories: {
                                ...categoriesContext.fundAssetCategories,
                                page,
                              },
                            })
                          }
                          pageSize={getSizePerPage(
                            categoriesContext.fundAssetCategories.sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setCategoriesContext({
                              fundAssetCategories: {
                                ...categoriesContext.fundAssetCategories,
                                page: 1,
                                sizePerPage,
                              },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : (
                  <div className="text-center">No results</div>
                )}
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId={FAT_CATEGORIES_TAB}>
            <Card>
              <CardBody>
                {loading ? (
                  <Loader />
                ) : fundAssetTransactionCategories.data?.length ? (
                  <AdvanceTableWrapper
                    columns={[
                      {
                        accessor: "name",
                        Header: "Name",
                        headerProps: { className: "text-truncate" },
                        disableSortBy: true,
                      },
                      {
                        accessor: "id",
                        Header: "",
                        width: 80,
                        headerProps: { className: "text-right text-truncate" },
                        cellProps: {
                          className: "text-right",
                        },
                        disableSortBy: true,
                        Cell: (rowData) => {
                          const category = rowData.row.original;
                          return category.default
                            ? null
                            : trashIcon(category, FAT_CATEGORIES_TAB);
                        },
                      },
                    ]}
                    data={fundAssetTransactionCategories.data || []}
                    pageSize={getSizePerPage(
                      categoriesContext.fundAssetTransactionCategories
                        .sizePerPage
                    )}
                  >
                    <Card className="d-flex">
                      <CardBody className="overflow-auto min-height-300p">
                        {fundAssetTransactionCategories.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={fundAssetTransactionCategories?.count}
                          pageCount={fundAssetTransactionCategories?.totalPages}
                          currentPage={fundAssetTransactionCategories.page}
                          onPageChange={(page) =>
                            setCategoriesContext({
                              fundAssetTransactionCategories: {
                                ...categoriesContext.fundAssetTransactionCategories,
                                page,
                              },
                            })
                          }
                          pageSize={getSizePerPage(
                            categoriesContext.fundAssetTransactionCategories
                              .sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setCategoriesContext({
                              fundAssetTransactionCategories: {
                                ...categoriesContext.fundAssetTransactionCategories,
                                page: 1,
                                sizePerPage,
                              },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : (
                  <div className="text-center">No results</div>
                )}
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Row>
      {informationModal?.isOpen ? (
        <InformationModal
          title={"Heads Up!"}
          body={informationModal.body}
          onClose={() => setInformationModal({ isOpen: false, body: "" })}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Categories;

import React, { useRef, useState } from "react";
import { Button, Form, UncontrolledAlert, Row } from "reactstrap";
import { faBell, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStatementsFlow } from "../../../../providers/statementsFlowProvider";
import { programsApi } from "../../../../services/programServices";
import Loader from "../../../Loader";
import DocumentPreviewModal from "../../../../pages/admin/documents/review/DocumentPreviewModal";
import RichTextEditor from "../../../RichTextEditor";

const ON_RESERVES_STEP_ACTION = "onReservesStepAction";
const ON_GENERATE_STEP_ACTION = "onGenerateStepAction";
const ON_SET_LETTER_ACTION = "onSetLetterAction";

const CeoLetter = () => {
  const [statementsFlow, setStatementsFlow] = useStatementsFlow();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewModal, setPreviewModal] = useState({});

  const letterRef = useRef();

  const onSubmit = (event) => {
    event.preventDefault();
    if (statementsFlow.letter?.length / 1000 / 1000 > 1) {
      return setError(true);
    }
    setStatementsFlow({
      action: ON_GENERATE_STEP_ACTION,
    });
  };

  const onGeneratePreview = () => {
    setLoading(true);
    const programPreview = Object.values(statementsFlow.programs)[0];
    programsApi
      .generateStatementPreview({
        id: programPreview.program.id,
        letter: statementsFlow.letter,
        auditReserveAccruedInterest: programPreview.auditReserve,
      })
      .then((result) => {
        const data = new Blob([result], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setPreviewModal({
          isOpen: true,
          file: URL,
        });
        setLoading(false);
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      className="flex-grow-1 col-12 height-100 d-flex flex-column justify-content-between"
    >
      <Row className="flex-grow-1">
        <div className="col-12 d-flex flex-column">
          <h5>Compose CEO Letter to include in the Statements:</h5>
          <UncontrolledAlert className="my-2" isOpen={error} color="warning">
            <div className="alert-icon">
              <FontAwesomeIcon icon={faBell} fixedWidth />
            </div>
            <div className="alert-message text-left">
              <span>
                The size of the letter is too big. Please make sure all the
                content together don't exceed 1 MB.
              </span>
            </div>
          </UncontrolledAlert>
          <div className="border rounded flex-grow-1">
            <RichTextEditor
              value={statementsFlow.letter}
              onChange={(letter) =>
                setStatementsFlow({
                  action: ON_SET_LETTER_ACTION,
                  payload: letter,
                })
              }
              name="letter"
              id="letter"
              ref={letterRef}
              required
            />
          </div>
        </div>
      </Row>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() =>
            setStatementsFlow({
              action: ON_RESERVES_STEP_ACTION,
            })
          }
        >
          Back
        </Button>
        {loading ? (
          <div className="d-flex justify-content-center flex-column">
            <Loader size="sm" />
            <small className="mt-1">Generating Preview...</small>
          </div>
        ) : (
          <Button color="success" onClick={() => onGeneratePreview()}>
            <FontAwesomeIcon icon={faUpload} />
            <span className="ml-2">Generate Preview</span>
          </Button>
        )}
        <Button className="ml-2" color={"primary"} type={"submit"}>
          Next
        </Button>
      </Row>
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={() => setPreviewModal({ isOpen: false, file: null })}
        />
      ) : null}
    </Form>
  );
};

export default CeoLetter;

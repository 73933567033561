import React, { memo, useCallback, useState } from "react";

import { Button, Row, Form, ListGroupItem, Col, FormGroup, Label, } from "reactstrap";
import { useEditInvestmentModal } from "../../../providers/editInvestmentModalProvider";

import { usePrograms } from "../../../providers/programsProvider";
import { programsApi } from "../../../services/programServices";

import { PROGRAM_INVESTMENT_TRANSACTION_TYPES } from "../../../utils/constants";

import BaseInput from "../../Forms/BaseInput";
import DateInput from "../../Forms/DateInput";

const CATEGORY_CREATION_MODE = 2;
const DEFAULT_UNIT_VALUE = 1000;

const initialTransactionState = {
  amount: "",
  date: new Date(),
  type: "",
  category: "",
};

const CreateInvestmentTransaction = ({
  investment = {},
  setInformationModal,
}) => {
  const [programsContext, setProgramContext] = usePrograms();
  const [editInvestmentModal, setEditInvestmentModal] =
    useEditInvestmentModal();

  const [transaction, setTransaction] = useState(initialTransactionState);

  const handleCreateTransaction = useCallback(
    async (event) => {
      event.preventDefault();

      const amount =
        transaction.amount *
        (transaction.type === PROGRAM_INVESTMENT_TRANSACTION_TYPES.INCREASE
          ? 1
          : -1);

      if (investment.capital + amount < 0) {
        return setInformationModal({
          isOpen: true,
          title: "Oops!",
          body: "The resulting balance can't be negative.",
        });
      }

      try {
        const createdTransaction =
          await programsApi.createInvestmentTransaction({
            ...transaction,
            investmentId: investment.id,
            programId: investment.programId,
          });

        const program = { ...programsContext.program };
        program.capital += amount;
        program.units += amount / DEFAULT_UNIT_VALUE;

        const programInvestment =
          program.programInvestments[
            program.programInvestments.findIndex(
              (inv) => inv.id === investment.id
            )
          ];

        programInvestment.capital += amount;
        programInvestment.units += amount / DEFAULT_UNIT_VALUE;
        programInvestment.transactions.push(createdTransaction);

        setProgramContext({
          program,
          refresh: !programsContext.refresh,
        });

        setTransaction(initialTransactionState);
      } catch (err) {
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body: err?.response?.data[0]?.msg || "Please try again",
        });
      }
    },
    [
      transaction,
      investment,
      programsContext,
      setProgramContext,
      setInformationModal,
    ]
  );

  return (
    <>
      <ListGroupItem
        className="pb-2 font-weight-bold border-bottom-0"
        tag="div"
      >
        Add Transaction
      </ListGroupItem>
      <ListGroupItem>
        <Form onSubmit={handleCreateTransaction}>
          <BaseInput
            label="Amount"
            type="currency"
            placeholder="Amount"
            value={transaction.amount}
            onChange={(value) => {
              setTransaction({
                ...transaction,
                amount: value,
              });
            }}
          />
          <BaseInput
            disabled={true}
            label="Units"
            type="number"
            placeholder="Units"
            value={transaction.amount / DEFAULT_UNIT_VALUE}
          />
          <FormGroup row className="mt-3">
            <Label sm={5} className="text-sm-left">
              <span>Date</span>
              <span className="ml-2 text-danger">*</span>
            </Label>
            <Col
              sm={7}
              className="d-flex flex-column align-items-baseline justify-content-center"
            >
              <DateInput
                value={transaction.date}
                onChange={(date) =>
                  setTransaction({
                    ...transaction,
                    date,
                  })
                }
              />
            </Col>
          </FormGroup>
          <BaseInput
            label="Type"
            placeholder="Type"
            type="select"
            options={[
              { value: "", label: "Select Type" },
              {
                value: PROGRAM_INVESTMENT_TRANSACTION_TYPES.INCREASE,
                label: "Increase",
              },
              {
                value: PROGRAM_INVESTMENT_TRANSACTION_TYPES.DECREASE,
                label: "Decrease",
              },
            ]}
            value={transaction.type}
            onChange={(event) =>
              setTransaction({
                ...transaction,
                type: event.currentTarget.value,
              })
            }
          />
          <BaseInput
            label="Category"
            placeholder="Category"
            type="select"
            options={[
              { value: "", label: "Select Category" },
              ...editInvestmentModal.categories.map((cat) => ({
                value: cat.id,
                label: cat.name,
              })),
            ]}
            value={transaction.category}
            onChange={(event) =>
              setTransaction({
                ...transaction,
                category: parseInt(event.currentTarget.value, 10),
              })
            }
          />
          <Row className="d-flex justify-content-end">
            <Col xs={12} className="d-flex justify-content-between">
              <Button
                className="rounded"
                size="sm"
                type="button"
                color="info"
                onClick={() =>
                  setEditInvestmentModal({
                    ...editInvestmentModal,
                    mode: CATEGORY_CREATION_MODE,
                  })
                }
              >
                New Category
              </Button>
              <Button
                className="rounded"
                size="sm"
                type="submit"
                color="primary"
              >
                Add Transaction
              </Button>
            </Col>
          </Row>
        </Form>
      </ListGroupItem>
    </>
  );
};

export default memo(CreateInvestmentTransaction);

export const emailsUtils = {
  surveyTemplate: (programName) => `
    <p>Dear Member,</p>
    <p>Below is a link to a ballot containing a vote to approve or not approve an amendment to the uses of all assets maintained by ${programName}. As our private placement documents have evolved over the years, so has the language regarding the management of the funds held by each partnership. </p>
    <p>The purpose of this vote is to create consistency and continuity across all programs. The vote is also intended to streamline the uses of these funds to allow the Manager to promptly take actions that are necessary to defend the integrity of the partnerships or are believed to be in the best interest of the partnerships.</p>
    <p>The amendment will provide Green Rock Management (the Manager) with flexibility to use the company’s cash reserves for additional purposes that will benefit the company and its Members. While there is not an immediate need for these funds, we are conducting this vote to ensure that the company is able to act quickly to address any future needs. In particular, the Manager anticipates that over the next several years it may be necessary to utilize up to 25% of the company’s reserves to help fund additional lobbying, industry advocacy, or any unforeseen offensive or defensive action necessary to maintain the integrity of the partnership. The Manager will continue to regularly communicate with all Members regarding any such uses of the reserves, and will continue in our commitment to providing advanced notice of any additional utilization of the company’s reserves to all Members in order to ensure that all Members have transparency in monitoring the company’s operations.</p>
    <p>Green Rock and its principals have spent considerable amounts towards funding these efforts (over $1.5 million collectively), but we believe that additional funds may be needed in the future to support these activities.  We also remain committed to ensuring that each partnership remains in solid financial health with sufficient capitalization to address all projected future cash needs.  We ask you to support these efforts to defend the integrity of the company and its activities, and ask that you vote to APPROVE the amendment.</p>
    <p>As always, we are thankful for the trust you place in us and will always continue to put the interest of our investor partners at the forefront of everything we do. If you have any questions, please do not hesitate to contact me directly.</p>
    <p><a href="{{surveyLink}}">CLICK HERE FOR BALLOT</a><br/><br/></p>
    <p>Sincerely,<br/><br/>
    Chris Devine<br/>
    Chief Executive Officer<br/>
    Green Rock Management, LLC</p>`,
  publishingProgramInvestor: (sponsorName) => `
    <div style="width: 100%; text-align: center">
        <img style="width: 350px" src="https://www.vulcanfundsolutions.com/static/media/brand.387adfec.png"></img>
    </div>
    <h1>Hello {{userName}},</h1>
    <p>Thank you for your trust in ${sponsorName}. We would like to invite you to join the ${sponsorName} Investor Portal which is managed by Vulcan Fund Solutions. The Investor Portal is a custom-designed, world-class, web-based portal to provide you online access to your investment portfolio.</p>
    <p>Please click on the link below to setup your account.<p>
    <p><a href="{{activationLink}}"><strong>SETUP ACCOUNT</strong></a><p>
    <p>If you have any problems with the link above, please copy and paste this link into your browser: <strong>{{activationLink}}</strong><p>
    <p>Should you have any questions please contact the Vulcan Fund Solutions team at <a href="mailto:support@vulcanfundsolutions.com">support@vulcanfundsolutions.com</a>.</p>
    `,
  publishingProgramAdvisor: () => `
    <div style="width: 100%; text-align: center">
        <img style="width: 350px" src="https://www.vulcanfundsolutions.com/static/media/brand.387adfec.png"></img>
    </div>
    <h1>Hello {{userName}},</h1>
    <p>You’ve been added by as an Advisor through the Vulcan Fund Solutions Investor Portal.</p>
    <p>Please click on the link below to setup your account.<p>
    <p><a href="{{activationLink}}"><strong>SETUP ACCOUNT</strong></a><p>
    <p>If you have any problems with the link above, please copy and paste this link into your browser: <strong>{{activationLink}}</strong><p>
    <p>Should you have any questions please contact the Vulcan Fund Solutions team at <a href="mailto:support@vulcanfundsolutions.com">support@vulcanfundsolutions.com</a>.</p>
    `,
};

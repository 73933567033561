import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  year: new Date().getFullYear(),
  funds: [],
  fund: null,
  selectedFundQuarter: null,
  fundQuarters: [],
  selectedFundMonth: null,
  fundMonths: [],
};

const FundsContext = createContext(initialState);

const FundsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <FundsContext.Provider value={stateAndDispatch}>
      {children}
    </FundsContext.Provider>
  );
};

export const useFundContext = () => useContext(FundsContext);

export { FundsContext, FundsProvider, initialState };

import React, { useState, useEffect } from "react";

import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";

import Loader from "../Loader";
import { usersApi } from "../../services/userServices";

const Investors = () => {
  const [advisements, setAdvisements] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    usersApi.getInvestors().then((result) => {
      setAdvisements(
        result?.sort((firstItem, secondItem) =>
          firstItem.investor?.name > secondItem.investor?.name ? 1 : -1
        )
      );
      setLoading(false);
    });
  }, []);

  return (
    <Card className="col-12 min-vh-25">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          Investors
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Loader size="sm" />
        ) : advisements?.length ? (
          <>
            <Table className="border table-striped mb-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                {advisements.map((advisement, i) => (
                  <tr key={i}>
                    <td className="max-width-100 text-truncate">
                      {advisement.investor.name}
                    </td>
                    <td className="max-width-100 text-truncate">
                      {advisement.investor.email}
                    </td>
                    <td className="max-width-100 text-truncate">
                      {advisement.investor.address || "-"}
                    </td>
                    <td className="max-width-100 text-truncate">
                      {advisement.investor.phone || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <div className="text-center">You have no investors</div>
        )}
      </CardBody>
    </Card>
  );
};

export default Investors;

import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  CustomInput,
  ListGroup,
  ListGroupItem,
  UncontrolledAlert,
  Row,
} from "reactstrap";
import { fundsApi } from "../../../../services/fundServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faArrowUp,
  faArrowDown,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../../../utils/utils";
import DateInput from "../../../Forms/DateInput";
import Loader from "../../../Loader";
import CurrencyInput from "react-currency-input-field";
import { useFundContext } from "../../../../providers/fundsProvider";

import { useFundCapitalTransactionsModal } from "../../../../providers/fundCapitalTransactionsModalProvider";
import InformationModal from "../../../InformationModal";

const MAX_VALUE = 999999999;

const TRANSACTION_CATEGORY_CREATION_MODE = 2;

const TYPE_INCOME = {
  id: 1,
  name: "Increase",
};

const TYPE_OUTCOME = {
  id: 2,
  name: "Decrease",
};

const TYPES = [TYPE_INCOME, TYPE_OUTCOME];

const FundCapitalTransactionsCreationModal = ({ loading: startLoading }) => {
  const [fundCapitalTransactionsModal, setFundCapitalTransactionsModal] =
    useFundCapitalTransactionsModal();
  const [fundContext, setFundContext] = useFundContext();
  const fund = fundContext.fund;

  const [errorCapital, setErrorCapital] = useState(false);

  const [loading, setLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSubmitTransaction = () => {
    setLoading(true);
    const capitalTransactionObj = {
      fundId: fund.id,
      type: parseInt(fundCapitalTransactionsModal.type),
      date: utils.formatDate(fundCapitalTransactionsModal.date, "YYYY-MM-DD"),
      amount: fundCapitalTransactionsModal.amount,
      fundCapitalTransactionCategoryId: parseInt(
        fundCapitalTransactionsModal.transactionCategoryId
      ),
    };
    fundsApi
      .createFundCapitalTransaction(capitalTransactionObj)
      .then((result) => {
        setFundCapitalTransactionsModal({
          amount: "",
          type: "",
          date: new Date(),
          transactionCategoryId: "",
        });
        let fundCapitalTransactions = [
          ...fund.fundCapitalTransactions,
          {
            ...result,
            fundCapitalTransactionCategory:
              fundCapitalTransactionsModal.categories.find(
                (c) => c.id === result.fundCapitalTransactionCategoryId
              ),
          },
        ];
        const balance =
          fund.balance +
          parseFloat(capitalTransactionObj.amount) *
            (capitalTransactionObj.type === TYPE_OUTCOME.id ? -1 : 1);
        setFundContext({ fund: { ...fund, balance, fundCapitalTransactions } });
        setLoading(false);
      });
  };

  const onTransactionDelete = (transaction) => {
    const balance =
      fund.balance -
      parseFloat(transaction.amount) *
        (parseInt(transaction.type) === TYPE_OUTCOME.id ? -1 : 1);
    if (balance < 0) {
      return setInformationModal({
        isOpen: true,
        title: "Oops!",
        body: (
          <>
            <p>Invalid action.</p>
            <span>
              By deleting this transaction the fund would have a negative
              balance.
            </span>
          </>
        ),
      });
    }
    setLoading(true);
    fundsApi
      .deleteFundCapitalTransaction({
        fundId: fund.id,
        id: transaction.id,
      })
      .then(() => {
        let fundCapitalTransactions = [...fund.fundCapitalTransactions];
        fundCapitalTransactions.splice(
          fundCapitalTransactions.findIndex((a) => a.id === transaction.id),
          1
        );
        setFundContext({ fund: { ...fund, balance, fundCapitalTransactions } });
        setLoading(false);
      });
  };

  const onCapitalChange = (value) => {
    setErrorCapital(
      parseInt(fundCapitalTransactionsModal.type) === TYPE_OUTCOME.id &&
        fund.balance < value
    );
    setFundCapitalTransactionsModal({ amount: value });
  };

  const onTypeChange = (type) => {
    setErrorCapital(
      parseInt(type) === TYPE_OUTCOME.id &&
        fund.balance < fundCapitalTransactionsModal.amount
    );
    setFundCapitalTransactionsModal({ type });
  };

  const onDismiss = () => {
    if (errorCapital) {
      setFundCapitalTransactionsModal({ amount: "" });
    }
    setErrorCapital(false);
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setFundCapitalTransactionsModal({ isModalOpen: false })}
    >
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      rawBody={true}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>
        Edit Strategic Fund {fund.year}
      </ModalHeader>
      <ModalBody className="text-center">
        {loading || startLoading ? (
          <Loader size={"sm"} />
        ) : (
          <>
            <FormGroup
              row
              className="mx-0 mb-1 d-flex align-items-center justify-content-between col-12 form-group"
            >
              <Label sm={4} className="text-sm-left col-sm-4 px-0">
                Current Capital
              </Label>
              <Col sm={8} className="font-weight-bold text-right px-0">
                {utils.formatCurrency(fund.balance)}
              </Col>
            </FormGroup>
            <Col className="d-flex flex-column align-items-start border rounded px-0">
              <ListGroup flush className="col-12 p-0">
                <ListGroupItem
                  className="text-underline py-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                  tag="div"
                >
                  Capital Transactions
                </ListGroupItem>
                {fund.fundCapitalTransactions?.map((transaction, index) => (
                  <ListGroupItem
                    key={index}
                    className="p-2 border-bottom d-flex justify-content-between"
                    tag="div"
                  >
                    <div>
                      {parseInt(transaction.type) === TYPE_INCOME.id ? (
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          fixedWidth
                          className="mr-2 text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          fixedWidth
                          className="mr-2 text-danger"
                        />
                      )}
                      <span>{utils.formatDate(transaction.date)}</span>
                      <span className="mx-2">·</span>
                      <span>
                        {transaction.fundCapitalTransactionCategory?.name}
                      </span>
                    </div>
                    <div>
                      <span>{utils.formatCurrency(transaction.amount)}</span>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        fixedWidth
                        className="ml-2 text-danger cursor-pointer"
                        onClick={() => onTransactionDelete(transaction)}
                      />
                    </div>
                  </ListGroupItem>
                ))}
                {!fund.fundCapitalTransactions?.length ? (
                  <ListGroupItem
                    className="py-2 d-flex justify-content-between"
                    tag="div"
                  >
                    <span>No transactions to show</span>
                  </ListGroupItem>
                ) : null}
              </ListGroup>
              <Form
                className="col-12 p-3 text-left"
                onSubmit={onSubmitTransaction}
              >
                <span className="font-weight-bold text-underline">
                  Add Transaction
                </span>
                <FormGroup row className="mt-3">
                  <Label sm={4} className="text-sm-left">
                    Amount
                  </Label>
                  <Col sm={8}>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      required={true}
                      allowNegativeValue={false}
                      maxLength={20}
                      className="form-control"
                      placeholder="Enter the amount.."
                      value={fundCapitalTransactionsModal.amount}
                      onValueChange={(value) => {
                        if (!value || value < MAX_VALUE) {
                          onCapitalChange(value);
                        }
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="mt-3">
                  <Label sm={4} className="text-sm-left">
                    Date
                  </Label>
                  <Col sm={8}>
                    <DateInput
                      label="Date"
                      value={fundCapitalTransactionsModal.date}
                      onChange={(date) =>
                        setFundCapitalTransactionsModal({ date })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={4} className="text-sm-left">
                    Type
                  </Label>
                  <Col sm={8}>
                    <CustomInput
                      required={true}
                      id="typeSelect"
                      type="select"
                      name="typeSelect"
                      onChange={(event) =>
                        onTypeChange(event.currentTarget.value)
                      }
                      value={fundCapitalTransactionsModal.type}
                    >
                      <option value="">Select...</option>
                      {TYPES.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </CustomInput>
                  </Col>
                </FormGroup>
                <FormGroup row className="mb-0">
                  <Label sm={4} className="text-sm-left">
                    Category
                  </Label>
                  <Col sm={8}>
                    <CustomInput
                      required={true}
                      id="categorySelect"
                      type="select"
                      name="categorySelect"
                      onChange={(event) =>
                        setFundCapitalTransactionsModal({
                          transactionCategoryId: event.currentTarget.value,
                        })
                      }
                      value={fundCapitalTransactionsModal.transactionCategoryId}
                    >
                      <option value="">Select...</option>
                      {fundCapitalTransactionsModal.categories.map(
                        (category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        )
                      )}
                    </CustomInput>
                    <div className="d-flex justify-content-between">
                      <Button
                        color={"info"}
                        size={"sm"}
                        className="mt-2 rounded"
                        onClick={() =>
                          setFundCapitalTransactionsModal({
                            mode: TRANSACTION_CATEGORY_CREATION_MODE,
                          })
                        }
                      >
                        New Category
                      </Button>
                      <Button
                        disabled={errorCapital}
                        color={"primary"}
                        className="mt-2 ml-2 rounded"
                        size={"sm"}
                        type="submit"
                      >
                        Save Transaction
                      </Button>
                    </div>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Col>
          <UncontrolledAlert
            toggle={onDismiss}
            isOpen={errorCapital}
            color="warning"
          >
            <div className="alert-icon">
              <FontAwesomeIcon icon={faBell} fixedWidth />
            </div>
            <div className="alert-message text-left">
              <span>{`Transaction amount can't be higher than the Fund current capital of ${utils.formatCurrency(
                fund.balance
              )}`}</span>
            </div>
          </UncontrolledAlert>
          <Row className="justify-content-between">
            <Button
              color={"secondary"}
              onClick={() =>
                setFundCapitalTransactionsModal({ isModalOpen: false })
              }
            >
              Close
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default FundCapitalTransactionsCreationModal;

import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderSubtitle from "../../../../components/HeaderSubtitle";
import { useProgramCreationFlow } from "../../../../providers/programCreationFlowProvider";
import ProgramInvestorsReview from "./ProgramInvestorsReview";
import ProgramAdvisorsReview from "./ProgramAdvisorsReview";
import ProgramMatchReview from "./ProgramMatchReview";
import ProgramFinalReview from "./ProgramFinalReview";
import { Navigate } from "react-router-dom";
import ProgramStakeholders from "./ProgramStakeholders";

const INVESTORS_REVIEW_STEP = 5;
const ADVISORS_REVIEW_STEP = 6;
const MATCH_REVIEW_STEP = 7;
const STAKEHOLDERS_STEP = 8;
const FINAL_REVIEW_STEP = 9;

const STEPS = [
  { id: INVESTORS_REVIEW_STEP, text: "Review Investors" },
  { id: ADVISORS_REVIEW_STEP, text: "Review Advisors" },
  { id: MATCH_REVIEW_STEP, text: "Match Review" },
  { id: STAKEHOLDERS_STEP, text: "PropCo" },
  { id: FINAL_REVIEW_STEP, text: "Final Review" },
];

const getCircleColor = (current, step) => {
  if (current < step) {
    return "light";
  }
  if (current === step) {
    return "primary";
  }
  return "success";
};

const textStyle = (current, step) =>
  current === step ? "font-weight-bold" : "text-muted";

const FlowProgress = () => {
  const [programCreationFlow] = useProgramCreationFlow();
  return (
    <Col className="d-flex flex-column align-items-center col-10 progress-container p-0 mt-4">
      <Row className="progress-line mx-0"></Row>
      <Row className="justify-content-between steps-container mx-0">
        {STEPS.map((step, i) => (
          <span
            key={i}
            className={`text-${getCircleColor(
              programCreationFlow.step,
              step.id
            )}`}
          >
            <FontAwesomeIcon icon={faCircle} />
          </span>
        ))}
      </Row>
      <Row className="justify-content-between steps-container">
        {STEPS.map((step, i) => (
          <span
            key={i}
            className={textStyle(programCreationFlow.step, step.id)}
          >
            {step.text}
          </span>
        ))}
      </Row>
    </Col>
  );
};

const getComponent = (step) => {
  switch (step) {
    case INVESTORS_REVIEW_STEP:
      return ProgramInvestorsReview;
    case ADVISORS_REVIEW_STEP:
      return ProgramAdvisorsReview;
    case MATCH_REVIEW_STEP:
      return ProgramMatchReview;
    case STAKEHOLDERS_STEP:
      return ProgramStakeholders;
    case FINAL_REVIEW_STEP:
      return ProgramFinalReview;
    default:
      return ProgramInvestorsReview;
  }
};

const ProgramReview = () => {
  const [programCreationFlow] = useProgramCreationFlow();

  const Component = getComponent(programCreationFlow.step);

  return programCreationFlow.step < INVESTORS_REVIEW_STEP ? (
    <Navigate to="/back/programs" />
  ) : (
    <Container fluid>
      <Header>
        <HeaderTitle>Program Creation</HeaderTitle>
        <HeaderSubtitle>Review</HeaderSubtitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="div" className="mb-0">
                <FlowProgress />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Component />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProgramReview;

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";
import { authApi } from "../../../services/authServices";
import { utils } from "../../../utils/utils";

const TWO_FACTOR_INVALID = "TWO_FACTOR_INVALID";
const TWO_FACTOR_CODE_SENT_AGAIN = "TWO_FACTOR_CODE_SENT_AGAIN";

const TwoFactorAuthentication = () => {
  const [authContext, setAuthContext] = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);

  const onDismiss = () => {
    setError(false);
    setInfo(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    authApi
      .login({
        username: authContext.credentials.email,
        password: authContext.credentials.pwd,
        code2FA: authContext.credentials.code2FA,
      })
      .then((result) => {
        setLoading(false);
        return result
          ? setAuthContext({
              credentials: {},
              currentUser: { ...result },
              advisements: result.investors,
            })
          : null;
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.status === TWO_FACTOR_INVALID);
      });

    return false;
  };

  const onResend2FA = (e) => {
    e.preventDefault();

    setLoading(true);
    authApi
      .login({
        username: authContext.credentials.email,
        password: authContext.credentials.pwd,
        resend: true,
      })
      .then((result) => {
        setLoading(false);
        if (result.status === TWO_FACTOR_CODE_SENT_AGAIN) {
          setInfo(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.status === TWO_FACTOR_INVALID);
      });
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Two Factor Authentication</h1>
        <p className="lead">
          Please enter the code sent to your{" "}
          {authContext.sms ? "phone" : "email"}.
        </p>
      </div>
      <Card>
        <CardBody className="p-3">
          <div className="m-sm-4">
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Form onSubmit={onSubmit}>
                <div className="d-flex justify-content-center">
                  <div className="user-name rounded-circle bg-light img-thumbnail text-muted d-flex align-items-center justify-content-center">
                    <span>
                      {utils.nameInitials(authContext.currentUser.name)}
                    </span>
                  </div>
                </div>
                <FormGroup>
                  <Label>Code</Label>
                  <Input
                    required={true}
                    maxLength="6"
                    bsSize="lg"
                    type="password"
                    name="code"
                    placeholder="Enter the code"
                    onChange={(event) =>
                      setAuthContext({
                        credentials: {
                          ...authContext.credentials,
                          code2FA: event.currentTarget.value,
                        },
                      })
                    }
                  />
                  <small>
                    Didn't receive code,{" "}
                    <span
                      className="text-primary cursor-pointer"
                      onClick={onResend2FA}
                    >
                      send again
                    </span>
                  </small>
                </FormGroup>
                <UncontrolledAlert
                  isOpen={error}
                  toggle={onDismiss}
                  color="warning"
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <strong>Hey!</strong>
                    <span className="ml-2">
                      It seems the code entered is not correct.
                    </span>
                  </div>
                </UncontrolledAlert>
                <UncontrolledAlert
                  isOpen={info}
                  toggle={onDismiss}
                  color="info"
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message text-left">
                    <strong>Hey!</strong>
                    <span className="ml-2">
                      The 2FA code was sent, please check your{" "}
                      {authContext.sms ? "phone" : "email"}.
                    </span>
                  </div>
                </UncontrolledAlert>
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={() =>
                      setAuthContext({
                        credentials: null,
                        currentUser: null,
                        advisements: null,
                      })
                    }
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" type="submit">
                    Send
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TwoFactorAuthentication;

import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  investmentId: null,
  mode: DEFAULT_MODE,
  categories: [],
  isModalOpen: false,
};

const EditInvestmentModalContext = createContext(initialState);

const EditInvestmentModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <EditInvestmentModalContext.Provider value={stateAndDispatch}>
      {children}
    </EditInvestmentModalContext.Provider>
  );
};

export const useEditInvestmentModal = () =>
  useContext(EditInvestmentModalContext);

export {
  EditInvestmentModalContext,
  EditInvestmentModalProvider,
  initialState,
};

import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Container,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";

import { useStatementsFlow } from "../../../../providers/statementsFlowProvider";
import Header from "../../../Header";
import HeaderTitle from "../../../HeaderTitle";
import HeaderSubtitle from "../../../HeaderSubtitle";
import ProgramStatementsProgress from "../../ProgramStatementsProgress";
import ProgramPreview from "./ProgramPreview";
import { programsApi } from "../../../../services/programServices";
import { useAuth } from "../../../../providers/authProvider";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import InformationModal from "../../../../components/InformationModal";
import {
  emailInitialState,
  useEmailContext,
} from "../../../../providers/emailProvider";
import EmailModal from "../../EmailModal";

const ON_ALL_GENERATED_ACTION = "onAllGenerated";
const ON_ALL_UPLOADED_ACTION = "onAllUploaded";
const ON_ABORT_ALL_ACTION = "onAbortAll";
const ON_ABORT_ACTION = "onAbort";
const ON_FINISH_ACTION = "onFinish";
const ON_PROGRAM_SEND_ACTION = "onProgramSendAction";
const ON_DEACTIVATE_ACTION = "onDeactivateAction";

const Progress = () => {
  const [authContext] = useAuth();
  const [statementsFlow, setStatementsFlow, , onStatementsUpload] =
    useStatementsFlow();
  const [preview, setPreview] = useState();
  const [allGenerated, setAllGenerated] = useState();
  const [allUploaded, setAllUploaded] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [emailContext, setEmailContext] = useEmailContext();
  const [emailModal, setEmailModal] = useState(emailInitialState);

  const setStatementsFlowCb = useCallback(
    (data) => setStatementsFlow(data),
    [setStatementsFlow]
  );

  useEffect(() => {
    const generated = Object.values(statementsFlow.programs).filter(
      (program) => program.generated
    );
    if (generated.length === Object.keys(statementsFlow.programs).length) {
      setAllGenerated(true);
      setStatementsFlowCb({
        action: ON_ALL_GENERATED_ACTION,
      });
    } else {
      setAllGenerated(false);
    }
    const uploaded = Object.values(statementsFlow.programs).filter(
      (program) => program.uploaded
    );
    if (
      Object.keys(statementsFlow.programs).length &&
      uploaded.length === Object.keys(statementsFlow.programs).length
    ) {
      setAllUploaded(true);
      setStatementsFlowCb({
        action: ON_ALL_UPLOADED_ACTION,
      });
    } else {
      setAllUploaded(false);
    }
  }, [statementsFlow.programs, setStatementsFlowCb]);

  const onSend = () => {
    setEmailModal(false);
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setStatementsFlow({
          action: ON_PROGRAM_SEND_ACTION,
          payload: emailContext.program.id,
        });
        onStatementsUpload(authContext.currentUser.socketToken, () => {
          programsApi.sendStatements({
            socketToken: authContext.currentUser.socketToken,
            letter: statementsFlow.letter,
            auditReserveAccruedInterest:
              statementsFlow.programs[emailContext.program.id].auditReserve,
            fundQuarterId: statementsFlow.quarter.id,
            id: emailContext.program.id,
            statements:
              statementsFlow.programs[emailContext.program.id].statements,
            subject: emailContext.subject,
            replyTo: emailContext.replyTo,
            content: emailContext.content,
          });
          setConfirmationModal(initConfirmationModal);
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Send Q${statementsFlow.quarter.quarter}-${statementsFlow.quarter.year} Program Statements`,
      body: `<span>You confirm you want to continue to upload and send the statements by email?</span>`,
    });
  };

  const onNext = (program) => {
    const defaultSubject = `${program.name} Quarterly Statement`;
    if (emailContext?.subject !== defaultSubject) {
      setEmailContext({
        program,
        name: program.name,
        subject: defaultSubject,
        content: `
            <p>Dear Member,</p>
            <p>Attached is a copy of the quarterly statement related to your investment in ${program.name}. You will receive a copy of this statement along with your distribution in the mail over next couple weeks.</p>
            <p>Please feel free to contact us if you have any questions.</p>
            <p>Sincerely,<br/>
            <strong>Vulcan Fund Solutions Team</strong><br/>
            2015 3rd Avenue North <br/>
            Birmingham, AL 35203</p>
          `,
      });
    }
    setEmailModal({
      isOpen: true,
    });
  };

  return (
    <Container
      fluid
      className="flex-grow-1 col-12 height-100 d-flex flex-column"
    >
      <Header>
        <HeaderTitle className="mb-0">
          {statementsFlow.year} Programs
        </HeaderTitle>
        <HeaderSubtitle className="d-flex justify-content-between align-items-center">
          Q{statementsFlow.quarter.quarter}-{statementsFlow.quarter.year}
          {!allGenerated || !allUploaded ? (
            <Button
              size="sm"
              className="rounded"
              onClick={() =>
                setStatementsFlow({
                  action: ON_ABORT_ALL_ACTION,
                })
              }
            >
              Abort All
            </Button>
          ) : null}
        </HeaderSubtitle>
      </Header>
      <Card>
        <CardBody>
          <ListGroup flush className="text-left border rounded">
            {Object.values(statementsFlow.programs).map((programData) => (
              <ListGroupItem
                key={programData.program.id}
                className="pb-2 border-bottom text-body d-flex justify-content-between align-items-center"
                tag="div"
              >
                <span>{programData.program.name}</span>
                <div className="col-6">
                  <ProgramStatementsProgress
                    progressData={programData}
                    onAbort={() => {
                      setStatementsFlow({
                        action: ON_ABORT_ACTION,
                        payload: programData.program.id,
                      });
                    }}
                    onPreview={() => setPreview(programData.program.id)}
                    onSend={() => onNext(programData.program)}
                    onFinish={() => {
                      setStatementsFlow({
                        action: ON_FINISH_ACTION,
                        payload: programData.program.id,
                      });
                    }}
                  />
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Card>
      {preview ? (
        <ProgramPreview programId={preview} onClose={() => setPreview()} />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {allUploaded ? (
        <InformationModal
          title={"Generate & send statements"}
          body={"Statements generated & sent successfully!"}
          onClose={() =>
            setStatementsFlow({
              action: ON_DEACTIVATE_ACTION,
            })
          }
        />
      ) : null}
      {emailModal.isOpen ? (
        <EmailModal
          onClose={() => {
            setEmailModal(emailInitialState);
          }}
          onSubmit={onSend}
          cancelBtnText="Back"
          sendBtnText="Upload & Send"
        />
      ) : null}
    </Container>
  );
};

export default Progress;

import React, { useState } from "react";

import { Card, CardBody, CardHeader, CardTitle, CustomInput } from "reactstrap";

import { useAuth } from "../providers/authProvider";
import { usersApi } from "../services/userServices";
import InformationModal from "./InformationModal";
import UserBasicAttributes from "./admin/UserBasicAttributes";
import UserAddress from "./admin/UserAddress";
import { utils } from "../utils/utils";
import { emailRegex } from "../utils/regex";

const PersonalInfo = () => {
  const [authContext, setAuthContext] = useAuth();
  const [localData, setLocalData] = useState({ ...authContext.currentUser });
  const [mainAddress, setMainAddress] = useState(
    localData.mainAddress || "primary"
  );

  const [loading, setLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const { cookieRole } = utils.getSessionData(authContext.currentUser);

  const onSaveChanges = () => {
    const userData = { ...localData };

    if (userData.notificationEmails) {
      const notificationEmails = userData.notificationEmails.split(",");
      if (
        !notificationEmails.filter((email) =>
          emailRegex.test(email.trim().toLowerCase())
        ).length ||
        notificationEmails.some(
          (email) => !emailRegex.test(email.trim().toLowerCase())
        )
      ) {
        return setInformationModal({
          isOpen: true,
          title: "Oops!",
          body: "One or more notification email addresses are not valid.",
        });
      }
    }

    setLoading(true);

    const updateUserData = {
      id: userData.id,
      name: userData.name,
      email: userData.email,
      notificationEmails: userData.notificationEmails,
      state: userData.state,
      city: userData.city,
      zipcode: userData.zipcode,
      address: userData.address,
      phone: userData.phone,
      secondaryAddress: userData.secondaryAddress,
      secondaryState: userData.secondaryState,
      secondaryCity: userData.secondaryCity,
      secondaryZipcode: userData.secondaryZipcode,
      mainAddress,
    };

    usersApi
      .updateUser(updateUserData, cookieRole)
      .then(() => {
        setLoading(false);
        const updatedUser = { ...localData, ...updateUserData };
        setAuthContext({
          currentUser: { ...authContext.currentUser, ...updatedUser },
        });
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "Information updated successfully",
        });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  const onAttrEdit = (field, value) =>
    setLocalData({ ...localData, [field]: value });

  return (
    <>
      <Card className="col-5 box-shadow-none tab-card">
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Personal info
          </CardTitle>
        </CardHeader>
        <CardBody>
          {localData ? (
            <UserBasicAttributes
              user={localData}
              loading={loading}
              onSubmit={onSaveChanges}
              onAttrEdit={onAttrEdit}
            />
          ) : null}
        </CardBody>
      </Card>
      <Card className="col-5 box-shadow-none tab-card">
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Address 1
            <CustomInput
              checked={mainAddress === "primary"}
              onChange={() => setMainAddress("primary")}
              type="switch"
              id="firstAddress"
              name="firstAddress"
              label={"Primary"}
              className="cursor-pointer d-flex align-items-center font-weight-bold text-primary float-right"
            />
          </CardTitle>
        </CardHeader>
        <CardBody>
          {localData ? (
            <UserAddress
              user={localData}
              loading={loading}
              onSubmit={onSaveChanges}
              onAttrEdit={onAttrEdit}
            />
          ) : null}
        </CardBody>
      </Card>
      <Card className="col-5 box-shadow-none tab-card">
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Address 2
            <CustomInput
              checked={mainAddress === "secondary"}
              onChange={() => setMainAddress("secondary")}
              type="switch"
              id="secondAddress"
              name="secondAddress"
              label={"Primary"}
              className="cursor-pointer d-flex align-items-center font-weight-bold text-primary float-right"
            />
          </CardTitle>
        </CardHeader>
        <CardBody>
          {localData ? (
            <UserAddress
              user={localData}
              loading={loading}
              onSubmit={onSaveChanges}
              onAttrEdit={onAttrEdit}
              isSecondary
            />
          ) : null}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default PersonalInfo;

import React, { useState, useEffect } from "react";
import BannerView from "./BannerView";
import { bannersApi } from "../../../services/bannerServices";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const BannersViewList = () => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState();

  const modalDialogStyles = {
    maxWidth: "90%",
    maxHeight: "90vh",
  };

  const modalContentStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const imageStyles = {
    maxWidth: "100%",
    maxHeight: "80vh",
    width: "auto",
    height: "auto",
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await bannersApi.getBanners({
        isActive: true,
        pageSize: 100,
      });
      setBanners(
        response?.data?.map((banner) => (
          <BannerView bannerData={banner} selectBanner={setSelectedBanner} />
        ))
      );
    };
    fetchData();
  }, []);

  const onClose = () => setSelectedBanner(false);
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return banners.length ? (
    <div className="mb-5">
      {banners}
      {selectedBanner ? (
        <Modal isOpen={true} onClosed={onClose} style={modalDialogStyles}>
          <ModalHeader close={closeBtn}>
            <h4 className="d-flex m-0 flex-column">{selectedBanner.name}</h4>
          </ModalHeader>
          <ModalBody style={modalContentStyles}>
            <img
              alt={"Banner information"}
              className="w-100"
              src={selectedBanner.modalImage}
              style={imageStyles}
            />
          </ModalBody>
          <ModalFooter className="align-items-end">
            <Button color={"secondary"} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </div>
  ) : null;
};

export default BannersViewList;

import React from "react";

import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
} from "reactstrap";
import Loader from "../Loader";

const states = require("../../assets/states.json");

const UserAddress = ({
  loading,
  foundByEmail,
  foundByName,
  foundBySSNumber,
  user,
  onSubmit,
  onAttrEdit,
  isSecondary,
  hideButton,
}) => {
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <Row form className="mx-0">
        <FormGroup className="col-12 px-0">
          <Label for="address">Address</Label>
          <Input
            disabled={
              user.address?.length &&
              (foundByName || foundByEmail || foundBySSNumber)
            }
            maxLength={60}
            value={user[isSecondary ? "secondaryAddress" : "address"] || ""}
            type="text"
            onChange={(event) =>
              onAttrEdit(
                isSecondary ? "secondaryAddress" : "address",
                event.currentTarget.value
              )
            }
            name="address"
            id="address"
            placeholder="Address"
          />
        </FormGroup>
      </Row>
      <Row form className="mx-0">
        <Col md={4}>
          <FormGroup className="col-12 px-0">
            <Label for="city">City</Label>
            <Input
              disabled={
                user.city?.length &&
                (foundByName || foundByEmail || foundBySSNumber)
              }
              maxLength={60}
              value={user[isSecondary ? "secondaryCity" : "city"] || ""}
              type="text"
              onChange={(event) =>
                onAttrEdit(
                  isSecondary ? "secondaryCity" : "city",
                  event.currentTarget.value
                )
              }
              name="city"
              id="city"
              placeholder="City"
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="col-12 px-0">
            <Label for="address">State</Label>
            <CustomInput
              disabled={
                user.state?.length &&
                (foundByName || foundByEmail || foundBySSNumber)
              }
              type="select"
              id="stateCustomSelect"
              name="customSelect"
              onChange={(event) =>
                onAttrEdit(
                  isSecondary ? "secondaryState" : "state",
                  event.currentTarget.value
                )
              }
              value={user[isSecondary ? "secondaryState" : "state"] || ""}
            >
              <option value="">Select...</option>
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="col-12 px-0">
            <Label for="zipcode">Zip Code</Label>
            <Input
              disabled={
                user.zipcode?.length &&
                (foundByName || foundByEmail || foundBySSNumber)
              }
              maxLength={50}
              value={user[isSecondary ? "secondaryZipcode" : "zipcode"] || ""}
              type="text"
              onChange={(event) =>
                onAttrEdit(
                  isSecondary ? "secondaryZipcode" : "zipcode",
                  event.currentTarget.value
                )
              }
              name="zipcode"
              id="zipcode"
              placeholder="Zip Code"
            />
          </FormGroup>
        </Col>
      </Row>
      {!hideButton ? (
        loading ? (
          <Loader align="start" size="sm" />
        ) : (
          <Button
            size="sm"
            className="mt-1 rounded float-left"
            type="submit"
            color="primary"
          >
            Save Changes
          </Button>
        )
      ) : null}
    </Form>
  );
};

export default UserAddress;

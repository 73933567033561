import React from "react";
import ReactQuill from "react-quill";
import { DEFAULT_RICH_TEXT_EDITOR_MODULES } from "../utils/constants";

const RichTextEditor = ({
  value,
  onChange = () => {},
  placeholder = "Type something...",
  ...props
}) => (
  <ReactQuill
    placeholder={placeholder}
    modules={DEFAULT_RICH_TEXT_EDITOR_MODULES}
    value={value}
    onChange={onChange}
    {...props}
  />
);

export default RichTextEditor;

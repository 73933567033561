import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  Badge,
  InputGroup,
  UncontrolledAlert,
} from "reactstrap";
import { surveyApi } from "../../../../../services/surveyServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faMinus,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import RichTextEditor from "../../../../RichTextEditor";

const QUESTION_TYPES = {
  "Multiple Choice": 1,
  "Short text": 2,
};

const AddNewSurveyModal = ({ isOpen, onSubmit, onClose, program }) => {
  const initQuestion = {
    content: "",
    questionTypeId: null,
    options: null,
  };

  const inputOptionRef = useRef();
  const questionRef = useRef();
  const questionTypeRef = useRef();
  const addQuestionFormRef = useRef();
  const addSurveyFormRef = useRef();

  const [question, setQuestion] = useState(initQuestion);

  const [addNewQuestion, setAddNewQuestion] = useState(false);

  const [questionTypes, setQuestionTypes] = useState([]);

  const [surveyName, setSurveyName] = useState("");

  const [questions, setQuestions] = useState([]);

  const [selectedQuestionType, setSelectedQuestionType] = useState();

  const [options, setOptions] = useState();

  const [option, setOption] = useState("");

  const [limited, setLimited] = useState(false);

  const [noQuestionsError, setNoQuestionsError] = useState(false);

  const [noVoteNameError, setNoVoteNameError] = useState(false);

  const isMultipleChoice =
    selectedQuestionType === QUESTION_TYPES["Multiple Choice"];

  useEffect(() => {
    if (!questionTypes.length) {
      surveyApi.getQuestionTypes().then((questionTypes) => {
        setQuestionTypes(questionTypes);
      });
    }
  }, [option, questionTypes.length]);

  useEffect(() => {
    if (options?.length < 10 && limited) {
      setLimited(false);
    }
  }, [options, limited]);

  const onSurveyNameChange = (e) => {
    setSurveyName(e.currentTarget.value);
  };

  const onQuestionChange = (content) => {
    setQuestion({
      ...question,
      content,
    });
  };

  const onOptionChange = (e) => {
    setOption(e.currentTarget.value);
  };

  const onQuestionTypeChange = (e) => {
    const questionTypeId = parseInt(e.currentTarget.value);
    setSelectedQuestionType(questionTypeId);
    setQuestion({
      ...question,
      questionTypeId,
    });
  };

  const onSendSurvey = (e) => {
    e.preventDefault();
    if (!surveyName) {
      return setNoVoteNameError(true);
    }
    setNoVoteNameError(false);

    if (!questions.length) {
      return setNoQuestionsError(true);
    }
    setNoQuestionsError(false);

    onSubmit(surveyName, questions);
  };

  const addQuestion = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!question.content) {
      questionRef.current.focus();
      return;
    }

    if (!selectedQuestionType) {
      questionTypeRef.current.focus();
      return;
    }

    if (isMultipleChoice) {
      if (!options) {
        inputOptionRef.current.focus();
        return;
      }
      question.options = JSON.stringify(
        options.map((opt, idx) => ({ label: opt, value: idx + 1 }))
      );
      setLimited(false);
    }

    setNoQuestionsError(false);

    let questionsObj = questions.length ? questions : [];
    questionsObj.push(question);
    setQuestions(questionsObj);
    setAddNewQuestion(false);
    setQuestion(initQuestion);
    setOptions(null);
    setSelectedQuestionType(null);
  };

  const onDeleteQuestion = (i) => {
    const filtered = questions.filter((_, idx) => i !== idx);
    setQuestions(filtered);
  };

  const isOptionDuplicated = (newOption) => {
    if (options) {
      const data = options.filter((_option) => _option === newOption);
      return data.length > 0;
    }
    return false;
  };

  const onAddOption = (e) => {
    e.preventDefault();
    if (option && !isOptionDuplicated(option)) {
      let optionsObj = options ? options : [];
      optionsObj.push(option);
      if (optionsObj.length === 10) {
        setLimited(true);
      }
      setOptions(optionsObj);
      setOption("");
    }
  };

  const onDeleteOption = (i) => {
    const filtered = options.filter((_, idx) => i !== idx);
    setOptions(filtered);
  };

  const onCancel = () => {
    setAddNewQuestion(false);
    setQuestion(initQuestion);
    setOptions(null);
    setSelectedQuestionType(null);
  };

  const submitForm = (formRef) => {
    formRef.current.dispatchEvent(new Event("submit"));
  };

  const submitAddQuestionForm = () => {
    submitForm(addQuestionFormRef);
  };

  const submitAddSurveyForm = () => {
    submitForm(addSurveyFormRef);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn} className="d-flex justify-content-between">
        Votes for program {program.name}
      </ModalHeader>
      <ModalBody className="text-center">
        <Form onSubmit={onSendSurvey} innerRef={addSurveyFormRef}>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              Vote Name
            </Label>
            <Col sm={8}>
              <Input
                required
                maxLength="50"
                placeholder="Enter vote name.."
                onChange={onSurveyNameChange}
                value={surveyName}
              />
            </Col>
          </FormGroup>
        </Form>
        <div className="border rounded">
          <Col className="px-0 border-bottom">
            <div className="py-1 bg-light d-flex justify-content-center align-items-center">
              <span>{addNewQuestion ? "New" : "Add"} Question</span>
              <Button
                size="sm"
                color="primary"
                className="mx-2 rounded"
                onClick={() => setAddNewQuestion(!addNewQuestion)}
              >
                <FontAwesomeIcon
                  icon={addNewQuestion ? faMinus : faPlus}
                  fixedWidth
                />
              </Button>
            </div>
            {addNewQuestion && (
              <div className="m-3 mt-1">
                <Form onSubmit={addQuestion} innerRef={addQuestionFormRef}>
                  <FormGroup row>
                    <Label sm={12} for="question" className="text-sm-left">
                      Question
                    </Label>
                    <Col className="border rounded px-0 mx-2 flex-grow-1">
                      <RichTextEditor
                        value={question.content}
                        onChange={onQuestionChange}
                        name="question"
                        id="question"
                        ref={questionRef}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} for="questionType" className="text-sm-left">
                      Question type
                    </Label>
                    <Col sm={8}>
                      <Input
                        required
                        type="select"
                        name="questionType"
                        id="questionType"
                        onChange={onQuestionTypeChange}
                        innerRef={questionTypeRef}
                      >
                        <option value="">Select type</option>
                        {questionTypes.map(({ name, id }, i) => (
                          <option key={i} value={id}>
                            {name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                </Form>
                <div>
                  {isMultipleChoice && (
                    <Form onSubmit={onAddOption}>
                      <Col className="my-2">
                        <div className="d-flex align-items-center">
                          <InputGroup className="my-2 pr-2">
                            <Input
                              required
                              placeholder="Enter an option"
                              type="text"
                              onChange={onOptionChange}
                              value={option}
                              disabled={limited}
                              innerRef={inputOptionRef}
                            />
                          </InputGroup>
                          <Button outline color="primary" type="submit">
                            Add
                          </Button>
                        </div>
                        <ListGroup className="my-2">
                          {options &&
                            options.map((option, i) => (
                              <ListGroupItem
                                className="d-flex justify-content-between"
                                key={i}
                              >
                                <div>
                                  <p className="text-break word-wrap m-0">
                                    {option}
                                  </p>
                                </div>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    fixedWidth
                                    color="red"
                                    className="cursor-pointer"
                                    onClick={() => onDeleteOption(i)}
                                  />
                                </div>
                              </ListGroupItem>
                            ))}
                        </ListGroup>
                      </Col>
                      <hr />
                    </Form>
                  )}
                </div>
                <Row className="m-0 d-flex justify-content-between">
                  <Button outline onClick={onCancel} className="mr-2">
                    Cancel
                  </Button>
                  <Button
                    outline
                    color="primary"
                    onClick={submitAddQuestionForm}
                  >
                    Add question
                  </Button>
                </Row>
              </div>
            )}
          </Col>
          <ListGroup className="m-2">
            {questions?.length ? (
              questions.map((q, i) => (
                <ListGroupItem
                  className="d-flex justify-content-between"
                  key={i}
                >
                  <div>
                    <p
                      className="mb-n3 word-break-all"
                      dangerouslySetInnerHTML={{ __html: q.content }}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Badge
                      color="dark"
                      className="d-flex align-items-center mr-2"
                    >
                      {
                        questionTypes.find(
                          (type) => type.id === q.questionTypeId
                        )?.name
                      }
                    </Badge>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      fixedWidth
                      color="red"
                      className="cursor-pointer"
                      onClick={() => onDeleteQuestion(i)}
                    />
                  </div>
                </ListGroupItem>
              ))
            ) : (
              <span className="text-muted">No questions have been added.</span>
            )}
          </ListGroup>
        </div>
        <UncontrolledAlert
          toggle={() => setNoQuestionsError(false)}
          isOpen={noQuestionsError}
          color="warning"
          className="mt-3 mb-0"
        >
          <div className="alert-icon">
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className="alert-message text-left">
            <span>The vote must have at least one question</span>
          </div>
        </UncontrolledAlert>
        <UncontrolledAlert
          toggle={() => setNoVoteNameError(false)}
          isOpen={noVoteNameError}
          color="warning"
          className="mt-3 mb-0"
        >
          <div className="alert-icon">
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className="alert-message text-left">
            <span>Enter vote name</span>
          </div>
        </UncontrolledAlert>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={onClose}>
              Back
            </Button>
            <Button
              disabled={false}
              color="primary"
              onClick={submitAddSurveyForm}
            >
              Send Vote
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewSurveyModal;

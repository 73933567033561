import React, { useState } from "react";
import Header from "../../components/Header";
import { Container, Row, Button } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import BannerCreation from "./banners/BannerCreation";
import BannersList from "./banners/BannersList";
import { utils } from "../../utils/utils";

const MarketingBanner = () => {
  const [informationModal, setInformationModal] = useState(utils.initModal);
  const [confirmationModal, setConfirmationModal] = useState(utils.initModal);
  const [createBanner, setCreateBanner] = useState(false);

  return (
    <div>
      <Container fluid>
        <Header>
          <Row className="m-0 justify-content-end">
            {!createBanner ? (
              <Button
                className="rounded"
                color="primary"
                onClick={() => setCreateBanner(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="ml-2">Create New Banner</span>
              </Button>
            ) : (
              <div className="m-3"></div>
            )}
          </Row>
        </Header>
        <Row className="m-0">
          {createBanner ? (
            <BannerCreation
              createBanner={setCreateBanner}
              data={createBanner}
              setInformationModal={setInformationModal}
              setConfirmationModal={setConfirmationModal}
            />
          ) : (
            <BannersList
              bannerCreationButton={setCreateBanner}
              setInformationModal={setInformationModal}
              setConfirmationModal={setConfirmationModal}
            />
          )}
        </Row>
      </Container>
      {informationModal.isOpen ? (
        <InformationModal {...informationModal} />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default MarketingBanner;

import React, { useCallback, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import HeaderSubtitle from "../../../components/HeaderSubtitle";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStatementsFlow } from "../../../providers/statementsFlowProvider";
import { Navigate } from "react-router-dom";
import ProgramSelection from "../../../components/admin/programDetails/statementsFlow/ProgramSelection";
import ReserveBalances from "../../../components/admin/programDetails/statementsFlow/ReserveBalances";
import { usePrograms } from "../../../providers/programsProvider";
import Button from "reactstrap/lib/Button";
import CeoLetter from "../../../components/admin/programDetails/statementsFlow/CeoLetter";
import Generation from "../../../components/admin/programDetails/statementsFlow/Generation";
import Progress from "../../../components/admin/programDetails/statementsFlow/Progress";

const INITIAL_MODE = 1;
const PROGRAMS_SELECTION_STEP = 1;
const RESERVE_STEP = 2;
const LETTER_STEP = 3;
const GENERATION_STEP = 4;
const PROGRESS_STEP = 5;

const ON_DEACTIVATE_ACTION = "onDeactivateAction";

const STEPS = [
  { id: PROGRAMS_SELECTION_STEP, text: "Program Selection" },
  { id: RESERVE_STEP, text: "Audit Reserve" },
  { id: LETTER_STEP, text: "CEO Letter" },
  { id: GENERATION_STEP, text: "Generate Statements" },
];

const getCircleColor = (current, step) => {
  if (current < step) {
    return "light";
  }
  if (current === step) {
    return "primary";
  }
  return "success";
};

const textStyle = (current, step) =>
  current === step ? "font-weight-bold" : "text-muted";

const FlowProgress = () => {
  const [statementsFlow] = useStatementsFlow();

  return (
    <Col className="d-flex flex-column align-items-center col-10 progress-container p-0 mt-4">
      <Row className="progress-line mx-0"></Row>
      <Row className="justify-content-between steps-container mx-0">
        {STEPS.map((step, i) => (
          <span
            key={i}
            className={`text-${getCircleColor(statementsFlow.step, step.id)}`}
          >
            <FontAwesomeIcon icon={faCircle} />
          </span>
        ))}
      </Row>
      <Row className="justify-content-between steps-container">
        {STEPS.map((step, i) => (
          <span key={i} className={textStyle(statementsFlow.step, step.id)}>
            {step.text}
          </span>
        ))}
      </Row>
    </Col>
  );
};

const getComponent = (step) => {
  switch (step) {
    case PROGRAMS_SELECTION_STEP:
      return ProgramSelection;
    case RESERVE_STEP:
      return ReserveBalances;
    case LETTER_STEP:
      return CeoLetter;
    case GENERATION_STEP:
      return Generation;
    default:
      return ProgramSelection;
  }
};

const StatementsGenerationFlow = () => {
  const [programsContext] = usePrograms();
  const [statementsFlow, setStatementsFlow] = useStatementsFlow();

  const setStatementsFlowCb = useCallback(
    (data) => setStatementsFlow(data),
    [setStatementsFlow]
  );

  useEffect(() => {
    const yearsProgram = programsContext.programs.filter(
      (p) => p.year === statementsFlow.year
    );

    const programData = {
      auditReserve: 0,
      previewFile: null,
      mode: INITIAL_MODE,
      total: 0,
      remaining: 0,
      statements: [],
    };

    const programs = yearsProgram.reduce((p, c) => {
      p[c.id] = {
        ...programData,
        program: { id: c.id, name: c.name },
      };
      return p;
    }, {});

    setStatementsFlowCb({ programs });
  }, [programsContext.programs, statementsFlow.year, setStatementsFlowCb]);

  const Component = getComponent(statementsFlow.step);

  if (statementsFlow.step < PROGRAMS_SELECTION_STEP) {
    return <Navigate to="/back/programs" />;
  }

  if (statementsFlow.step === PROGRESS_STEP) {
    return <Progress />;
  }

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Header>
        <HeaderTitle>{statementsFlow.year} Program Statements</HeaderTitle>
        <HeaderSubtitle>
          <Button
            size="sm"
            className="rounded"
            onClick={() => {
              setStatementsFlow({ action: ON_DEACTIVATE_ACTION });
            }}
          >
            Cancel
          </Button>
        </HeaderSubtitle>
      </Header>
      <Row className="flex-grow-1">
        <Card className="flex-grow-1">
          <CardHeader>
            <CardTitle tag="div" className="mb-0">
              <FlowProgress />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Component />
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default StatementsGenerationFlow;

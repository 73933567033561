import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faTag,
} from "@fortawesome/free-solid-svg-icons";

import { Table, Card } from "reactstrap";
import { utils } from "../../../../utils/utils";
import { useFundTransactionModal } from "../../../../providers/fundTransactionModalProvider";
import { useFundContext } from "../../../../providers/fundsProvider";

const TYPE_INCOME_ID = "1";
const TYPE_EXPENSE_ID = "2";
const TYPE_INCOME_DND_ID_ID = "3";

const STATUS_OPEN = "1";
const DETAIL_MODE = 4;

const TYPE_QUARTER = "2";

const TransactionsTable = ({ period, type }) => {
  const [, setFundContext] = useFundContext();
  const [, setFundTransactionModal] = useFundTransactionModal();

  return period ? (
    <Card className="flex-grow-1">
      <Table
        striped
        className={`my-0 text-custom-dark text-center ${
          period.status === STATUS_OPEN ? "table-hover" : ""
        }`}
      >
        <thead>
          <tr>
            <th></th>
            {type === TYPE_QUARTER ? (
              <th>Date</th>
            ) : (
              <>
                <th>Income Start Date</th>
                <th>Income End Date</th>
              </>
            )}
            <th>Fund Asset</th>
            <th>Category</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody className="font-weight-normal">
          {period.transactions?.map((transaction, index) => (
            <tr
              className="cursor-pointer"
              key={index}
              onClick={() => {
                setFundContext({
                  [type === TYPE_QUARTER
                    ? "selectedFundQuarter"
                    : "selectedFundMonth"]: period,
                });
                setFundTransactionModal({
                  ...transaction,
                  isModalOpen: true,
                  mode: DETAIL_MODE,
                });
              }}
            >
              <td>
                {transaction.type === TYPE_INCOME_ID && (
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    fixedWidth
                    className="mr-2 text-success"
                  />
                )}
                {transaction.type === TYPE_EXPENSE_ID && (
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    fixedWidth
                    className="mr-2 text-danger"
                  />
                )}
                {transaction.type === TYPE_INCOME_DND_ID_ID && (
                  <FontAwesomeIcon icon={faTag} fixedWidth className="mr-2" />
                )}
              </td>
              {type === TYPE_QUARTER ? (
                <td>{utils.formatDate(transaction.date)}</td>
              ) : (
                <>
                  <td>{utils.formatDate(transaction.incomePeriodStart)}</td>
                  <td>{utils.formatDate(transaction.incomePeriodEnd)}</td>
                </>
              )}
              <td>{transaction.asset?.name || "-"}</td>
              <td>{transaction.category?.name || "-"}</td>
              <td>{utils.formatCurrency(transaction.amount)}</td>
            </tr>
          ))}
          {!period.transactions?.length ? (
            <tr>
              <td colSpan="6">No transactions</td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </Card>
  ) : null;
};

export default TransactionsTable;

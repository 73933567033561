import React, { useEffect, useState, useCallback } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  Row,
  InputGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { ACTIONS, useAdvisors } from "../../providers/advisorsProvider";
import {
  faEllipsisV,
  faMailBulk,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usersApi } from "../../services/userServices";
import Loader from "../../components/Loader";
import AdvisorModalDetails from "../../components/admin/AdvisorDetailsModal";
import { authApi } from "../../services/authServices";
import { useAuth } from "../../providers/authProvider";
import InformationModal from "../../components/InformationModal";
import {
  useUserEmail,
  initialState as userEmailInitialState,
} from "../../providers/userEmailProvider";
import UserEmailModal from "../../components/admin/UserEmailModal";
import { utils } from "../../utils/utils";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../utils/roles";
import Badge from "reactstrap/lib/Badge";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";

const states = require("../../assets/states.json");

const USER_STATUS_ACTIVE = "3";
const USER_ROLE_ADVISOR = 2;

const MAX_PAGE_SIZE = 100;

const Advisors = () => {
  const [authContext, setAuthContext] = useAuth();
  const [advisorsContext, setAdvisorsContext] = useAdvisors();
  const [refresh, setRefresh] = useState();
  const [advisors, setAdvisors] = useState({});
  const [selectedAdvisor, setSelectedAdvisor] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(true);
  const [loading, setLoading] = useState({});
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [userEmailContext, setUserEmailContext] = useUserEmail();

  const user = authContext.currentUser;

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const sendEmail = (user) => {
    setUserEmailContext({ ...userEmailInitialState, user, isActive: true });
  };

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          advisorsContext.sortBy === sortBy?.id &&
          advisorsContext.direction === direction
        ) {
          return;
        }
        setAdvisorsContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setAdvisorsContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [advisorsContext.direction, advisorsContext.sortBy, setAdvisorsContext]
  );

  const actions = (advisor, loading) => {
    return loading[advisor.id] ? (
      <div className="min-width-50">
        <Loader size="sm" align="end" />
      </div>
    ) : (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle color="white">
          <FontAwesomeIcon className="text-primary" icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => setSelectedAdvisor(advisor)}>
            See Details
          </DropdownItem>
          <DropdownItem onClick={() => resetPassword(advisor)}>
            Password Reset
          </DropdownItem>
          <DropdownItem onClick={() => loginAs(advisor)}>
            See Advisor Portal
          </DropdownItem>
          <DropdownItem onClick={() => sendEmail(advisor)}>
            Send Email
          </DropdownItem>
          <DropdownItem onClick={() => resendInvite(advisor)}>
            Resend Invite
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const COLUMNS = [
    {
      accessor: "name",
      Header: "Name",
      headerProps: { className: "text-truncate" },
    },
    {
      accessor: "email",
      Header: "Email",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return email || "-";
      },
    },
    {
      accessor: "state",
      Header: "State",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { state } = rowData.row.original;
        return states.find((item) => item.abbreviation === state)?.name || "-";
      },
    },
    {
      accessor: "city",
      Header: "City",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { city } = rowData.row.original;
        return city || "-";
      },
    },
    {
      accessor: "zipcode",
      Header: "Zip",
      width: 80,
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { zipcode } = rowData.row.original;
        return zipcode || "-";
      },
    },
    {
      accessor: "address",
      Header: "Address",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { address } = rowData.row.original;
        return address || "-";
      },
    },
    {
      accessor: "phone",
      Header: "Phone",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { phone } = rowData.row.original;
        return phone || "-";
      },
    },
    {
      accessor: "lastActivity",
      Header: "Activity",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { lastActivity } = rowData.row.original;
        return lastActivity ? (
          utils.formatDate(lastActivity, "MM/DD/YYYY HH:mm")
        ) : (
          <Badge color={"warning"}>No Activity</Badge>
        );
      },
    },
    {
      accessor: "id",
      Header: "",
      width: 15,
      headerProps: { className: "text-right text-truncate" },
      cellProps: {
        className: "text-right p-0",
      },
      disableSortBy: true,
      Cell: (rowData) => actions(rowData.row.original, loading),
    },
  ];

  const sendEmailAll = () => {
    setUserEmailContext({
      ...userEmailInitialState,
      user: advisors.data,
      isActive: true,
      type: "Advisor",
    });
  };

  useEffect(() => {
    const hasCSRole = utils.userHasRole(user, CUSTOMER_SUPPORT);
    const hasSponsorRole = utils.userHasRole(user, SPONSOR);
    setGeneralLoading(true);
    usersApi
      .getUsers(
        {
          role: USER_ROLE_ADVISOR,
          search: advisorsContext.search,
          page: advisorsContext.page - 1,
          pageSize: getSizePerPage(advisorsContext.sizePerPage),
          sortBy: advisorsContext.sortBy,
          direction: advisorsContext.direction,
        },
        hasCSRole ? "customer-support" : hasSponsorRole ? "sponsor" : "admin"
      )
      .then((result) => {
        setAdvisors(result);
        setGeneralLoading(false);
      });
  }, [
    refresh,
    advisorsContext.search,
    advisorsContext.page,
    advisorsContext.sizePerPage,
    advisorsContext.sortBy,
    advisorsContext.direction,
    user,
  ]);

  const loginAs = (advisor) => {
    setLoading({ [advisor.id]: true });
    const hasCSRole = utils.userHasRole(user, CUSTOMER_SUPPORT);
    const hasSponsorRole = utils.userHasRole(user, SPONSOR);
    authApi
      .loginAs(
        { userId: advisor.id },
        hasCSRole ? "customer-support" : hasSponsorRole ? "sponsor" : "admin"
      )
      .then((result) => {
        setAuthContext({ currentUser: { ...result } });
        setLoading({ [advisor.id]: false });
        window.open(`${window.location.origin}/investor-selector`, "_blank");
      });
  };

  const resendInvite = (advisor) => {
    if (advisor.status === USER_STATUS_ACTIVE) {
      return setInformationModal({
        isOpen: true,
        title: "Heads up!",
        body: "User is already active.",
      });
    }
    setLoading({ [advisor.id]: true });
    usersApi
      .resendInvite({ id: advisor.id })
      .then(() => {
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "Invite resent successfully.",
        });
        setLoading({ [advisor.id]: false });
      })
      .catch((err) => {
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body: "There was an error sending email.",
        });
        setLoading({ [advisor.id]: false });
      });
  };

  const resetPassword = (advisor) => {
    if (advisor.status !== USER_STATUS_ACTIVE) {
      return setInformationModal({
        isOpen: true,
        title: "Heads up!",
        body: "User is not active.",
      });
    }
    setLoading({ [advisor.id]: true });
    usersApi.resetPassword({ email: advisor.email }).then(() => {
      setInformationModal({
        isOpen: true,
        title: "Alright!",
        body: "Reset email sent successfully.",
      });
      setLoading({ [advisor.id]: false });
    });
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-inline-block">Advisors</HeaderTitle>
        <Button color="info" className="m-2 float-right" onClick={sendEmailAll}>
          <FontAwesomeIcon icon={faMailBulk} />
          <span className="ml-2">Email All</span>
        </Button>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3 col-4 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={advisorsContext.search}
                    onChange={(evt) =>
                      setAdvisorsContext({ search: evt.target.value, page: 1 })
                    }
                    className="border-0"
                    placeholder="Search for.."
                  />
                </InputGroup>
                <div className="d-flex align-items-center">
                  <small className="text-muted mr-2">
                    Showing {advisors?.count} advisors
                  </small>
                  <Button
                    size="sm"
                    className="mr-2 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {generalLoading ? (
                <Loader />
              ) : advisors?.data?.length ? (
                <AdvanceTableWrapper
                  columns={COLUMNS}
                  data={advisors?.data || []}
                  sortable
                  onSort={onSort}
                  pageSize={getSizePerPage(advisorsContext.sizePerPage)}
                  defaultSort={{
                    sortBy: advisorsContext.sortBy,
                    direction: advisorsContext.direction,
                  }}
                >
                  <Card className="d-flex">
                    <CardBody className="overflow-auto min-height-300p">
                      {advisors?.data?.length ? (
                        <AdvanceTable
                          table
                          isLoading={generalLoading}
                          headerClassName="small"
                          tableProps={{
                            striped: false,
                            className: "mb-0",
                          }}
                        />
                      ) : (
                        <div className="text-center">No results</div>
                      )}
                    </CardBody>
                    <CardFooter>
                      <AdvanceTablePagination
                        totalCount={advisors?.count}
                        pageCount={advisors?.totalPages}
                        currentPage={advisorsContext.page - 1}
                        onPageChange={(page) => setAdvisorsContext({ page })}
                        pageSize={getSizePerPage(advisorsContext.sizePerPage)}
                        onPageSizeChange={(sizePerPage) =>
                          setAdvisorsContext({
                            action: ACTIONS.PAGE_SIZE_CHANGE,
                            payload: { sizePerPage },
                          })
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                      />
                    </CardFooter>
                  </Card>
                </AdvanceTableWrapper>
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {userEmailContext.isActive ? (
        <UserEmailModal
          onClose={() => setUserEmailContext({ isActive: false })}
        />
      ) : null}
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {selectedAdvisor ? (
        <AdvisorModalDetails
          advisor={selectedAdvisor}
          onSubmit={() =>
            setAdvisorsContext({ refresh: !advisorsContext.refresh })
          }
          onClose={() => setSelectedAdvisor(false)}
        />
      ) : null}
    </Container>
  );
};

export default Advisors;

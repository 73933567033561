import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  ModalHeader,
} from "reactstrap";
import { surveyApi } from "../../../../services/surveyServices";
import ConfirmationModal from "../../../ConfirmationModal";
import InformationModal from "../../../InformationModal";
import EmailModal from "../../EmailModal";
import {
  useEmailContext,
  emailInitialState,
} from "../../../../providers/emailProvider";
import { emailsUtils } from "../../../../utils/emails";

const SurveyDetailsModal = ({
  isOpen,
  surveyDetails,
  onClose,
  onDeleteSurvey,
}) => {
  const [emailContext, setEmailContext] = useEmailContext();
  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [emailModal, setEmailModal] = useState(emailInitialState);

  const onResendVote = (user) => {
    setConfirmationModal(initConfirmationModal);
    const defaultSubject = `Reminder - ${surveyDetails?.programName} - Member Vote`;
    if (emailContext?.subject !== defaultSubject) {
      setEmailContext({
        name: user.name,
        subject: defaultSubject,
        content: emailsUtils.surveyTemplate(surveyDetails?.programName),
      });
    }
    setEmailModal({
      isOpen: true,
      cancelBtnText: "Back",
      onSubmit: async (emailContext) => {
        try {
          const result = await surveyApi.resendEmail({
            ...emailContext,
            surveyId: surveyDetails.id,
            userId: user.id,
          });
          setEmailModal(emailInitialState);
          setEmailContext(emailInitialState);
          setInformationModal({
            isOpen: true,
            title: "Resend Email",
            body: result
              ? "Vote email was resent successfully."
              : "There was an error resending the vote email.",
          });
        } catch (error) {}
      },
      onClose: () => {
        setEmailModal(emailInitialState);
        setConfirmationModal(initConfirmationModal);
      },
    });
  };

  const onResendAll = () => {
    setConfirmationModal(initConfirmationModal);
    const defaultSubject = `Reminder - ${surveyDetails?.programName} - Member Vote`;
    if (emailContext?.subject !== defaultSubject) {
      setEmailContext({
        name: surveyDetails?.programName,
        subject: defaultSubject,
        content: emailsUtils.surveyTemplate(surveyDetails?.programName),
      });
    }
    setEmailModal({
      isOpen: true,
      cancelBtnText: "Back",
      onSubmit: async (emailContext) => {
        try {
          const result = await surveyApi.resendAll({
            surveyId: surveyDetails.id,
            subject: emailContext.subject,
            replyTo: emailContext.replyTo,
            content: emailContext.content,
          });
          setEmailModal(emailInitialState);
          setEmailContext(emailInitialState);
          setInformationModal({
            isOpen: true,
            title: "Resend All Emails",
            body: result
              ? "Vote email were resent successfully."
              : "There was an error resending the vote email.",
          });
        } catch (error) {}
      },
      onClose: () => {
        setEmailModal(emailInitialState);
        setConfirmationModal(initConfirmationModal);
      },
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : emailModal.isOpen ? (
    <EmailModal {...emailModal} />
  ) : informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={isOpen}>
      <ModalHeader close={closeBtn}></ModalHeader>
      <ModalBody>
        <h5 className="mb-0 d-flex justify-content-between align-items-start">
          <span className="col-4"></span>
          <span className="col-4 text-center">Questions</span>
          <div className="ml-2 col-4 text-right">
            <Button
              color={"secondary"}
              onClick={onResendAll}
              size="sm"
              className="rounded"
            >
              Resend All
            </Button>
          </div>
        </h5>
        {surveyDetails.questions.length &&
          surveyDetails.questions.map((question) => (
            <ListGroup key={question.id} className="mt-3">
              <ListGroupItem
                className="bg-lighter text-break word-wrap question-content"
                dangerouslySetInnerHTML={{ __html: question.content }}
              />
              {surveyDetails.userSurveyTokens.map((userSurvey) => {
                const answer = question.answers.find(
                  (a) => a.userId === userSurvey.user.id
                );
                return (
                  <ListGroupItem
                    key={userSurvey.user.id}
                    className="pb-2 border-bottom d-flex justify-content-between text-break word-wrap"
                  >
                    <span className="text-break word-wrap">
                      {userSurvey.user.name}
                    </span>
                    {answer ? (
                      <span className="text-break word-wrap">
                        {question.options && question.options.length
                          ? question.options.find(
                              (option) =>
                                parseInt(option.value) ===
                                parseInt(answer.content)
                            )?.label || "NA"
                          : answer.content}
                      </span>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <span className="text-break word-wrap">No Answer</span>
                        <Button
                          color={"primary"}
                          onClick={() => onResendVote(userSurvey.user)}
                          size="sm"
                          className="ml-2 rounded"
                        >
                          Resend Vote
                        </Button>
                      </div>
                    )}
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          ))}
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={onClose}>
              Back
            </Button>
            <Button
              color={"danger"}
              onClick={() => onDeleteSurvey(surveyDetails.id)}
            >
              Delete Vote
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default SurveyDetailsModal;

import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { useFundAssetDetailModal } from "../../../../providers/fundAssetDetailModalProvider";
import { fundsApi } from "../../../../services/fundServices";
import Loader from "../../../Loader";
import { useFundContext } from "../../../../providers/fundsProvider";

const DEFAULT_MODE = 1;

const FundAssetDeletionConfirmationModal = () => {
  const [loading, setLoading] = useState(false);

  const [fundContext, setFundContext] = useFundContext();
  const fund = fundContext.fund;

  const [fundAssetModal, setFundAssetDetailModal] = useFundAssetDetailModal();

  const onDelete = () => {
    setLoading(true);
    fundsApi
      .deleteFundAsset({
        fundId: fundAssetModal.fundId,
        id: fundAssetModal.assetId,
      })
      .then(() => {
        fund.assets.splice(
          fund.assets.findIndex((a) => a.id === fundAssetModal.assetId),
          1
        );
        const balance = fund.balance + parseFloat(fundAssetModal.assetCapital);
        setFundContext({ fund: { ...fund, balance } });
        setFundAssetDetailModal({ isModalOpen: false });
        setLoading(false);
      });
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setFundAssetDetailModal({ isModalOpen: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal
      isOpen={true}
      onClosed={() => setFundAssetDetailModal({ isModalOpen: false })}
    >
      <ModalHeader close={closeBtn}>
        Delete Fund Asset {fundAssetModal.assetName}
      </ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <span>
            Are you sure you want to delete the asset {fundAssetModal.assetName}
            ?
          </span>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color={"secondary"}
          onClick={() => setFundAssetDetailModal({ mode: DEFAULT_MODE })}
        >
          Cancel
        </Button>{" "}
        <Button color={"danger"} onClick={onDelete}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FundAssetDeletionConfirmationModal;

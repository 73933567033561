import {
  faTrashAlt,
  faTrashRestoreAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Button, CardHeader, ListGroup, ListGroupItem, Row } from "reactstrap";
import { useProgramCreationFlow } from "../../../../providers/programCreationFlowProvider";

const ADVISORS_REVIEW_STEP = 6;
const STAKEHOLDERS_STEP = 8;

const ProgramMatchReview = () => {
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();

  const removeRelationship = (index) => {
    let program = programCreationFlow.program;
    program.investors[index].advisor.removed = true;
    setProgramCreationFlow({ program });
  };

  const restoreRelationship = (index) => {
    let program = programCreationFlow.program;
    program.investors[index].advisor.removed = false;
    setProgramCreationFlow({ program });
  };

  return (
    <>
      <CardHeader className="py-0 h5 mt-4 text-underline body-color text-center col-12">
        Relationship Review
      </CardHeader>
      <Row className="mx-0 justify-content-between small text-muted">
        <span>Investor</span>
        <span>Advisor</span>
      </Row>
      <ListGroup>
        {programCreationFlow.program.investors.map((investor, i) => (
          <ListGroupItem
            key={i}
            className="d-flex justify-content-between p-0 border-0 my-2"
          >
            <span className="col-3 px-0 py-3 text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              {investor.name}
            </span>
            <Row className="mx-0 col-6 px-0 d-flex align-items-center justify-content-center">
              <Row className="mx-0 match-line"></Row>
              {investor.advisor?.removed ? (
                <Button
                  className="d-flex z-index-2"
                  color={"success"}
                  onClick={() => restoreRelationship(i)}
                >
                  <FontAwesomeIcon
                    icon={faTrashRestoreAlt}
                    fixedWidth
                    className="text-white"
                  />
                </Button>
              ) : (
                <Button
                  className="d-flex z-index-2"
                  color={"danger"}
                  onClick={() => removeRelationship(i)}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    fixedWidth
                    className="text-white"
                  />
                </Button>
              )}
            </Row>
            <span className="col-3 px-0 py-3 text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              {investor.advisor ? investor.advisor.name : "No Advisor"}
            </span>
          </ListGroupItem>
        ))}
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() => setProgramCreationFlow({ step: ADVISORS_REVIEW_STEP })}
        >
          Back
        </Button>{" "}
        <Button
          className="ml-2"
          color={"primary"}
          onClick={() => setProgramCreationFlow({ step: STAKEHOLDERS_STEP })}
        >
          Next
        </Button>
      </Row>
    </>
  );
};

export default ProgramMatchReview;

import 'chart.js/auto';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";

import { v4 as uuidv4 } from "uuid";

import "./assets/scss/_app.scss";
import "react-day-picker/dist/style.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

import { BarElement, LinearScale, CategoryScale, Chart } from "chart.js";
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);

const uniqueIdentifier = uuidv4();

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: "https://c252b3ebeefe4d19b19117ad709cb027@o912544.ingest.sentry.io/5950466",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [window.location.origin],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || "local",
  "web-version": process.env.REACT_APP_VERSION || "undefined",
});

root.render(
  <React.StrictMode>
    <App version={uniqueIdentifier} />
  </React.StrictMode>
);

import React, { useCallback, useState } from "react";

import { usePrograms } from "../../../../providers/programsProvider";
import { utils } from "../../../../utils/utils";

import {
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";
import { programsApi } from "../../../../services/programServices";
import AddProgramActorModal from "../../../../components/admin/AddProgramActorModal";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { USER_ROLES } from "../../../../utils/constants";
import Loader from "../../../../components/Loader";
import InformationModal from "../../../../components/InformationModal";
import EditInvestment from "../../../../components/admin/editInvestmentModal/EditInvestment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../../utils/roles";
import moment from "moment";
import { useEditInvestmentModal } from "../../../../providers/editInvestmentModalProvider";

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;

const Investors = () => {
  const [programsContext, setProgramContext] = usePrograms();
  const [editInvestmentModal, setEditInvestmentModal] =
    useEditInvestmentModal();
  const [authContext] = useAuth();

  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const user = authContext.currentUser;

  const canEdit =
    !utils.userHasRole(user, CUSTOMER_SUPPORT) &&
    !utils.userHasRole(user, SPONSOR) &&
    (parseInt(programsContext.program.programTypeId) === TYPE_HEDGE_FUND ||
      parseInt(programsContext.program.programTypeId) === TYPE_REAL_ESTATE ||
      parseInt(programsContext.program.programTypeId) === TYPE_PRIVATE_EQUITY);

  const filteredResults = programsContext.program.programInvestments
    .filter((pi) =>
      search && search.length
        ? pi.investor.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        : true
    )
    .sort((x, y) =>
      x.investor.name.toLowerCase() > y.investor.name.toLowerCase() ? 1 : -1
    );

  const handleCreate = useCallback(
    (investment) => {
      setLoading(true);
      setCreateModalOpen(false);
      programsApi
        .createInvestment({
          ...investment,
          programId: programsContext.program.id,
        })
        .then((inv) => {
          const program = programsContext.program;
          program.programInvestments = [...program.programInvestments, inv];
          program.capital += inv.capital;
          setProgramContext({
            ...programsContext,
            refresh: !programsContext.refresh,
          });
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.data?.length) {
            setLoading(false);
            return setInformationModal({
              isOpen: true,
              title: "Add Stakeholder",
              body: err.response?.data[0].msg,
              onClose: () => {
                setInformationModal({ isOpen: false, title: "", body: "" });
                setCreateModalOpen(true);
              },
            });
          }
        });
      setCreateModalOpen(false);
    },
    [setProgramContext, programsContext]
  );

  const onEdit = (investmentId) =>
    setEditInvestmentModal({
      ...editInvestmentModal,
      isModalOpen: true,
      investmentId,
    });

  const getProgramTotalInvestment = () => {
    const totalUnits = `${programsContext.program.units.toFixed(2)} units`;
    const totalCapital = utils.formatCurrency(programsContext.program.capital);
    const investmentText = [totalUnits, totalCapital].join(" / ");
    return investmentText;
  };

  return (
    <Card>
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
          <span className="font-weight-bold">Investors</span>
          <div className="d-flex flex-grow-1 justify-content-end align-items-center">
            <InputGroup className="mr-3 col-4 px-0" size="m">
              <Input
                maxLength="50"
                value={search}
                onChange={(evt) => setSearch(evt.target.value)}
                className="border-0"
                placeholder="Search for.."
              />
            </InputGroup>
            {canEdit && (
              <span>
                <Button
                  size="sm"
                  className="rounded"
                  color="primary"
                  onClick={() => {
                    setCreateModalOpen(true);
                  }}
                >
                  <span>Add</span>
                </Button>
              </span>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <ListGroup flush>
            <ListGroupItem
              className="border-bottom d-flex justify-content-between"
              tag="div"
            >
              <span className="small text-muted">{`Name / Last Activity ${
                parseInt(programsContext.program.programTypeId) ===
                TYPE_HEDGE_FUND
                  ? "/ Effective Date"
                  : ""
              }`}</span>
              <span className="small text-muted">Units / Amount</span>
            </ListGroupItem>
            {filteredResults.map((programInvestment, i) => {
              const investmentUnits = `${programInvestment.units.toFixed(
                2
              )} units`;
              const investmentCapital = utils.formatCurrency(
                programInvestment.capital
              );
              const investmentText = [investmentUnits, investmentCapital].join(
                " / "
              );
              return (
                <ListGroupItem
                  key={i}
                  className="border-bottom d-flex justify-content-between row"
                  tag="div"
                >
                  <div className="text-truncate col-6">
                    <span>
                      {programInvestment.investor.name}
                      {programInvestment.investor.name !==
                        programInvestment.investedAs &&
                      programInvestment.investedAs ? (
                        <small className="ml-1 text-muted">{`as ${programInvestment.investedAs}`}</small>
                      ) : (
                        ""
                      )}
                      <small className="ml-1 text-muted">
                        {`- ${
                          programInvestment.investor.lastActivity
                            ? utils.formatDate(
                                programInvestment.investor.lastActivity,
                                "MM/DD/YYYY HH:mm"
                              )
                            : "No Activity"
                        }`}
                      </small>
                      {parseInt(programsContext.program.programTypeId) ===
                      TYPE_HEDGE_FUND ? (
                        <small className="ml-1 text-muted">{`- ${moment(
                          programInvestment.dateEffective ||
                            programInvestment.createdAt
                        ).format("YYYY-MM-DD")}`}</small>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="col-6 text-right d-flex justify-content-end">
                    <span className="text-truncate">{investmentText}</span>
                    {canEdit && (
                      <>
                        <div className="min-width-50 text-right">
                          <FontAwesomeIcon
                            onClick={() => onEdit(programInvestment.id)}
                            className="text-primary cursor-pointer"
                            icon={faPen}
                            fixedWidth
                          />
                        </div>
                      </>
                    )}
                  </div>
                </ListGroupItem>
              );
            })}
            {!filteredResults.length ? (
              <ListGroupItem
                className="border-bottom d-flex justify-content-center"
                tag="div"
              >
                <span>No Results</span>
              </ListGroupItem>
            ) : null}
            <ListGroupItem
              className="font-weight-bold border-bottom d-flex justify-content-between"
              tag="div"
            >
              <span>Totals</span>
              <span
                className={
                  parseInt(programsContext.program.programTypeId) ===
                  TYPE_HEDGE_FUND
                    ? "mr-5"
                    : ""
                }
              >
                {getProgramTotalInvestment()}
              </span>
            </ListGroupItem>
          </ListGroup>
        )}
      </CardBody>
      {canEdit && createModalOpen ? (
        <AddProgramActorModal
          title="Add investor"
          onClose={() => setCreateModalOpen(false)}
          actorType={USER_ROLES.INVESTOR}
          onSubmit={handleCreate}
        />
      ) : null}
      {canEdit && editInvestmentModal.isModalOpen ? <EditInvestment /> : null}
      {canEdit && informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Card>
  );
};

export default Investors;

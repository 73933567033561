import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { usePrograms } from "../../../../providers/programsProvider";
import { utils } from "../../../../utils/utils";

import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";

const TYPE_INCREASE = "1";
const TYPE_HEDGE_FUND = 3;

const PieHoldings = () => {
  const [programsContext] = usePrograms();
  const { program } = programsContext;

  const [groupedFundInvestments, setGroupedFundInvestments] = useState();

  useEffect(() => {
    if (parseInt(program.programTypeId) === TYPE_HEDGE_FUND) {
      setGroupedFundInvestments(
        program.programFundInvestments.reduce((accum, fundInvestment) => {
          if (!accum[fundInvestment.fundId]) {
            accum[fundInvestment.fundId] = [];
          }
          accum[fundInvestment.fundId].push(fundInvestment);
          return accum;
        }, {})
      );
    }
  }, [program.programTypeId, program.programFundInvestments]);

  const getData = () => {
    const data = [];
    const labels = [];
    if (program.programTypeId !== TYPE_HEDGE_FUND) {
      program.programFundInvestments?.forEach((fundInvestment) => {
        data.push(fundInvestment.amount);
        labels.push(
          `${fundInvestment.fundFundInvestment.name} · ${fundInvestment.fundFundInvestment.year}`
        );
      });
    } else if (groupedFundInvestments) {
      Object.keys(groupedFundInvestments)?.forEach((fundId) => {
        data.push(
          groupedFundInvestments[fundId].reduce(
            (accum, fundInvestment) =>
              accum +
              fundInvestment.amount *
                (fundInvestment.type === TYPE_INCREASE ? 1 : -1),
            0
          )
        );
        const fund = groupedFundInvestments[fundId][0].fundFundInvestment;
        labels.push(`${fund.name} · ${fund.year}`);
      });
    }
    return { data, labels };
  };

  const getChart = () => {
    const { data, labels } = getData();
    return {
      data: {
        labels,
        datasets: [
          {
            fill: true,
            data,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          colorschemes: {
            scheme: "tableau.Classic20",
          },
          datalabels: {
            formatter: (value) => utils.formatCurrency(value),
            display: true,
            color: "white",
            font: {
              weight: "bold",
              familiy: "Rubik",
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => `$${tooltipItem.formattedValue}`,
            },
          },
        },
      },
    };
  };

  const chart = getChart();

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0 text-center">
          Holdings
        </CardTitle>
      </CardHeader>
      <CardBody className="col-12">
        <Pie height={230} data={chart.data} options={chart.options} />
      </CardBody>
    </Card>
  );
};

export default PieHoldings;

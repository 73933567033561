import React from "react";
import DateInput from "../../Forms/DateInput";

const DateRangePicker = ({ fromDate, toDate, onChange }) => {
  return (
    <div className="d-flex align-items-center justify-content-end flex-grow-1 flex-shrink-0 col-5">
      <div className="d-flex align-items-center justify-content-between">
        <span className="font-size-85 font-weight-normal">From</span>
        <div className="border-0 form-control p-0 ml-2">
          <DateInput
            value={fromDate}
            onChange={(date) => onChange("fromDate", date)}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between ml-4">
        <span className="font-size-85 font-weight-normal">To</span>
        <div className="border-0 form-control p-0 ml-2">
          <DateInput
            className="ml-2"
            value={toDate}
            onChange={(date) => onChange("toDate", date)}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;

import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Col,
  Input,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import { useUserEmail } from "../../providers/userEmailProvider";
import { usersApi } from "../../services/userServices";

import Loader from "../Loader";

import { utils } from "../../utils/utils";
import { settingsApi } from "../../services/settingServices";
import RichTextEditor from "../RichTextEditor";
import InformationModal from "../InformationModal";

const DEFAULT_MODE = 1;
const PREVIEW_MODE = 2;
const SUCCESS_MODE = 3;

const DefaultMode = ({ onClose }) => {
  const [userEmailContext, setUserEmailContext] = useUserEmail();
  const sendEmailAll = Array.isArray(userEmailContext.user);
  const investorName = sendEmailAll
    ? `All ${userEmailContext.type}s`
    : userEmailContext.user.name;

  const onSubmit = (event) => {
    event.preventDefault();
    setUserEmailContext({ mode: PREVIEW_MODE });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <Form onSubmit={onSubmit}>
        <ModalHeader close={closeBtn}>Send email to {investorName}</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Subject</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Col sm={8}>
              <Input
                required
                type="text"
                maxLength="50"
                placeholder="Enter the subject.."
                onChange={(event) =>
                  setUserEmailContext({ subject: event.currentTarget.value })
                }
                value={userEmailContext.subject}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} className="text-sm-left">
              <span>Reply To</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Col sm={8}>
              <Input
                required={!sendEmailAll}
                type="email"
                maxLength="50"
                placeholder="Enter the reply email.."
                onChange={(event) =>
                  setUserEmailContext({ replyTo: event.currentTarget.value })
                }
                value={userEmailContext.replyTo}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} className="text-sm">
              You can use the variable {"{{userName}}"} to be replaced by the
              name of the target user
            </Label>
          </FormGroup>
          <RichTextEditor
            value={userEmailContext.content}
            onChange={(content) => setUserEmailContext({ content })}
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button
            color={"primary"}
            disabled={
              !userEmailContext.subject?.length ||
              !userEmailContext.content?.length ||
              (sendEmailAll ? false : !userEmailContext.replyTo?.length)
            }
            type="submit"
          >
            Next
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const PreviewMode = () => {
  const [loading, setLoading] = useState(false);
  const [userEmailContext, setUserEmailContext] = useUserEmail();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const sendEmailAll = Array.isArray(userEmailContext.user);

  const emailContent = userEmailContext.content.replaceAll(
    "{{userName}}",
    userEmailContext.user.name
  );

  const onSendTestEmail = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const email = { ...userEmailContext };
    try {
      await settingsApi.sendTestEmail({ ...email, to: email.testEmailTo });
      setInformationModal({
        isOpen: true,
        title: "Email Preview",
        body: "Test email sent successfully.",
        onClose: () => {
          setInformationModal({
            isOpen: false,
            title: "",
            body: "",
            onClose: null,
          });
          setUserEmailContext({ testEmailTo: null });
        },
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Oops, there was an error with your request",
        body: err?.response?.data[0]?.msg || "Please try again",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSend = async () => {
    setLoading(true);
    const email = { ...userEmailContext };
    try {
      await (sendEmailAll ? usersApi.sendEmailAll : usersApi.sendEmail)({
        id: userEmailContext.user.id,
        ...email,
      });
      setUserEmailContext({ mode: SUCCESS_MODE });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Oops, there was an error with your request",
        body: err?.response?.data[0]?.msg || "Please try again",
      });
    } finally {
      setLoading(false);
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader>Email Preview</ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <div
            className="mb-n3 word-break-all"
            dangerouslySetInnerHTML={{ __html: emailContent }}
          ></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color={"secondary"}
          onClick={() => setUserEmailContext({ mode: DEFAULT_MODE })}
        >
          Back
        </Button>{" "}
        <Form onSubmit={onSendTestEmail} className="d-flex">
          <InputGroup className="container-input-button col-12 px-0">
            <InputGroupAddon addonType="prepend">
              <Button
                disabled={!utils.emailIsValid(userEmailContext.testEmailTo)}
                type="submit"
                color={"info"}
              >
                Send Test Email
              </Button>
            </InputGroupAddon>
            <Input
              required={true}
              type="email"
              placeholder="Test email to.."
              onChange={(event) =>
                setUserEmailContext({ testEmailTo: event.currentTarget.value })
              }
              value={userEmailContext.testEmailTo}
            />
          </InputGroup>
        </Form>
        <Button
          color={"primary"}
          disabled={!!userEmailContext.testEmailTo}
          onClick={onSend}
        >
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const SuccessMode = ({ onClose }) => {
  return (
    <Modal isOpen={true}>
      <ModalHeader>Alright!</ModalHeader>
      <ModalBody className="text-center">
        <span className="text-success">Email sent successfully!</span>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"primary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Content = ({ onClose }) => {
  const [userEmailContext] = useUserEmail();
  switch (userEmailContext.mode) {
    case DEFAULT_MODE:
      return <DefaultMode onClose={onClose} />;
    case PREVIEW_MODE:
      return <PreviewMode />;
    case SUCCESS_MODE:
      return <SuccessMode onClose={onClose} />;
    default:
      return <DefaultMode onClose={onClose} />;
  }
};

const UserEmailModal = ({ onClose }) => {
  return <Content onClose={onClose} />;
};

export default UserEmailModal;

import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import { programsApi } from "../../services/programServices";
import HeaderSubtitle from "../../components/HeaderSubtitle";
import MyInvestment from "../../components/customer/program/MyInvestment";
import MyReturns from "../../components/customer/program/MyReturns";
import FundInvestments from "../../components/customer/program/FundInvestments";
import Documents from "../../components/customer/Documents";
import { useParams } from "react-router-dom";

const TYPE_ALTERNATIVE = 4;

const Program = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [program, setProgram] = useState();
  const [letterLoading, setLetterLoading] = useState(true);
  const [expandedLetter, setExpandedLetter] = useState(false);
  const [programStatement, setProgramStatement] = useState();

  useEffect(() => {
    setLoading(true);
    programsApi.getPrograms({ id: params.programId }).then((program) => {
      setProgram(program);
      setLoading(false);
    });
  }, [params.programId]);

  useEffect(() => {
    setLetterLoading(true);
    programsApi
      .getProgramStatements({
        programId: params.programId,
        pageSize: 1,
        sortBy: "createdAt",
        direction: "DESC",
      })
      .then((result) => {
        if (result.data.length > 0) {
          setProgramStatement(result.data[0]);
        } else {
          setProgramStatement(null);
        }
        setLetterLoading(false);
      });
  }, [params.programId]);

  return loading || !program ? (
    <Loader />
  ) : (
    <Container fluid className="d-flex flex-row">
      <Col className="col-9">
        <Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-center flex-column pr-4 text-truncate">
            <HeaderTitle className="m-0 text-truncate">
              {program.name}
            </HeaderTitle>
            <HeaderSubtitle className="m-0">
              {program.year} Program
            </HeaderSubtitle>
          </div>
          {program.sponsor?.webUrl ? (
            <div
              className="sponsor-image-program-detail rounded width-200"
              style={{ backgroundImage: `url(${program.sponsor.webUrl})` }}
              alt={program.sponsor.name}
            ></div>
          ) : null}
        </Header>
        <Row className="mx-0 flex-grow-1">
          <MyInvestment program={program} />
          {program.programTypeId === TYPE_ALTERNATIVE ? (
            !program.programFundInvestments?.length ? (
              <Card className="box-shadow-none mb-0 col-12 mt-5">
                <CardHeader>
                  <CardTitle className="my-0 d-flex justify-content-center">
                    <span>The Program has no Investments Yet</span>
                  </CardTitle>
                </CardHeader>
              </Card>
            ) : (
              <>
                <MyReturns program={program} />
                <Row className="mx-0 mt-5 flex-grow-1 col-12 px-0">
                  <Card className="flex-grow-1 mb-0">
                    <CardHeader className="font-weight-bold d-flex justify-content-between">
                      <span className="text-underline">
                        Letter From Our CEO
                      </span>
                      <Button
                        className="rounded"
                        hidden={!programStatement?.letter}
                        size="sm"
                        onClick={() => setExpandedLetter(!expandedLetter)}
                      >
                        {expandedLetter ? "See Less" : "See More"}
                      </Button>
                    </CardHeader>
                    <CardBody>
                      {letterLoading ? (
                        <Loader size="sm" />
                      ) : programStatement?.letter ? (
                        <div
                          className={`overflow-hidden d-flex flex-column col-12 ${
                            expandedLetter ? "" : "max-height-100"
                          }`}
                        >
                          <div
                            className="justify-self-center ceo-letter text-left"
                            dangerouslySetInnerHTML={{
                              __html: programStatement.letter,
                            }}
                          ></div>
                        </div>
                      ) : (
                        <span className="text-black-50">
                          No statements have been sent.
                        </span>
                      )}
                    </CardBody>
                  </Card>
                </Row>
                <FundInvestments program={program} />
              </>
            )
          ) : null}
          {program.landingPageHtml ? (
            <Row className="m-0 mt-5">
              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: program.landingPageHtml }}
              ></div>
            </Row>
          ) : null}
        </Row>
      </Col>
      <Documents programId={program.id} />
    </Container>
  );
};

export default Program;

import React from "react";

import { ListGroup, Col, ListGroupItem, Row, Button } from "reactstrap";
import { useStatementsFlow } from "../../../../providers/statementsFlowProvider";
import CurrencyInput from "react-currency-input-field";

const MAX_VALUE = 999999999;

const ON_LETTER_STEP_ACTION = "onLetterStepAction";
const ON_PROGRAMS_SELECTION_STEP_ACTION = "onProgramsSelectionStepAction";

const ON_SET_AUDIT_RESERVE_ACTION = "onSetAuditReserveAction";

const ReserveBalances = () => {
  const [statementsFlow, setStatementsFlow] = useStatementsFlow();

  return (
    <Col className="flex-grow-1 col-12 height-100 d-flex flex-column justify-content-between">
      <ListGroup flush className="text-left border rounded">
        {Object.values(statementsFlow.programs).map(
          ({ program, auditReserve }) => (
            <ListGroupItem
              key={program.id}
              className="pb-2 border-bottom text-body d-flex justify-content-between align-items-center"
              tag="div"
            >
              <span>{program.name}</span>
              <div>
                <CurrencyInput
                  intlConfig={{ locale: "en-US", currency: "USD" }}
                  required={true}
                  allowNegativeValue={false}
                  maxLength={20}
                  className="form-control"
                  placeholder="Enter the amount.."
                  value={auditReserve || 0}
                  onValueChange={(value) => {
                    if (!value || value < MAX_VALUE) {
                      setStatementsFlow({
                        action: ON_SET_AUDIT_RESERVE_ACTION,
                        payload: { value, programId: program.id },
                      });
                    }
                  }}
                />
              </div>
            </ListGroupItem>
          )
        )}
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() =>
            setStatementsFlow({
              action: ON_PROGRAMS_SELECTION_STEP_ACTION,
            })
          }
        >
          Back
        </Button>{" "}
        <Button
          className="ml-2"
          color={"primary"}
          onClick={() =>
            setStatementsFlow({
              action: ON_LETTER_STEP_ACTION,
            })
          }
        >
          Next
        </Button>
      </Row>
    </Col>
  );
};

export default ReserveBalances;

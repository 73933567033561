import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  CustomInput,
} from "reactstrap";
import Loader from "../../Loader";
import { useEditProgramStatusModal } from "../../../providers/editProgramStatusModalProvider";
import { usePrograms } from "../../../providers/programsProvider";
import { programsApi } from "../../../services/programServices";
import InformationModal from "../../InformationModal";

const STATUS_CREATION_MODE = 2;

const EditProgramStatusModal = () => {
  const [programsContext, setProgramsContext] = usePrograms();
  const [editProgramStatusModal, setEditProgramStatusModal] =
    useEditProgramStatusModal();

  const [loading, setLoading] = useState();

  const [status, setStatus] = useState(
    editProgramStatusModal.createdStatus ||
      programsContext.program.programStatusId
  );

  const onClose = () => setEditProgramStatusModal({ isModalOpen: false });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    await programsApi.updateProgram({
      ...programsContext.program,
      programStatusId: status,
    });
    setLoading(false);
    return setInformationModal({
      isOpen: true,
      title: `Edit Program ${programsContext.program.name} Status`,
      body: "Program status updated successfully.",
      onClose: () => {
        setEditProgramStatusModal({ isModalOpen: false });
        setProgramsContext({
          refresh: !programsContext.refresh,
        });
      },
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn} className="d-flex justify-content-between">
        Edit Program {programsContext.program.name} Status
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <FormGroup row className="mb-0">
              <Label
                sm={4}
                className="text-sm-left d-flex flex-column align-items-start"
              >
                <span>Status</span>
                <Button
                  color="warning"
                  className="rounded mt-2"
                  size="sm"
                  onClick={() =>
                    setEditProgramStatusModal({ mode: STATUS_CREATION_MODE })
                  }
                >
                  Add New Status
                </Button>
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  type="select"
                  id="statusSelect"
                  name="statusSelect"
                  onChange={(e) => setStatus(e.currentTarget.value)}
                  value={status || ""}
                >
                  <option value="">Select a status..</option>
                  {editProgramStatusModal.statuses.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button color={"primary"} type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditProgramStatusModal;

import React from "react";
import classNames from "classnames";

import {
  faSignOutAlt,
  faHome,
  faHandshake,
  faFileAlt,
  faUserAlt,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../providers/authProvider";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import SidebarPrograms from "./SidebarPrograms";
import { authApi } from "../../../services/authServices";
import { USER_ROLE_SPONSOR, utils } from "../../../utils/utils";
import { PRINTER } from "../../../utils/roles";
import { useNavigate } from "react-router-dom";

const GREEN_ROCK_NAME = "Green Rock";

const Sidebar = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();

  const userInvestments = authContext.currentUser?.investments || [];
  const userOwnerships = authContext.currentUser?.ownerships || [];

  let sponsors = [];
  let funds = [];
  if (!utils.userHasRole(authContext.currentUser.sessionOwner, PRINTER)) {
    sponsors = utils
      .sidebarSponsors([...userInvestments, ...userOwnerships])
      .filter((sponsor) => {
        if (
          utils.userHasRole(authContext.currentUser.sessionOwner, "sponsor")
        ) {
          const sponsorRole = authContext.currentUser.sessionOwner.roles.find(
            (r) => r.id === USER_ROLE_SPONSOR
          );
          const sponsorId = sponsorRole.userRole.sponsorId;
          return sponsor.id === sponsorId;
        }
        return true;
      })
      .sort((x, y) => (y.name.toLowerCase() < x.name.toLowerCase() ? 1 : -1));
    funds = utils
      .sidebarFunds([...userInvestments, ...userOwnerships])
      .sort((x, y) => y.year - x.year);
  }

  const isAdvisor =
    utils.userHasRole(authContext.currentUser.sessionOwner, "advisor") ||
    authContext.currentUser.id === authContext.currentUser.sessionOwner?.id;

  const logout = () => {
    authApi.logout("investor");
    setAuthContext({ currentUser: null, advisements: null });
    navigate("/auth/sign-in");
  };

  const sidebarLogo = sponsors.length === 1 ? sponsors[0].webLogo : null;

  const greenrockSponsor = sponsors.find(
    (entry) => entry.name === GREEN_ROCK_NAME
  );

  if (greenrockSponsor && funds.length) {
    if (!greenrockSponsor.children) {
      greenrockSponsor.children = {};
    }
    funds.forEach((fund) => {
      const entry = greenrockSponsor.children["Strategic Funds"];
      const exists = entry?.find((e) => e.name === fund.year);
      if (!exists) {
        greenrockSponsor.children["Strategic Funds"] = [
          ...(greenrockSponsor.children["Strategic Funds"] || []),
          {
            id: fund.id,
            path: `/portal/funds/${fund.id}`,
            name: fund.year,
          },
        ];
      }
    });
  }

  return (
    <nav className={classNames("sidebar")}>
      <div className="sidebar-content">
        {sidebarLogo ? (
          <div className="px-4 pt-3">
            <div
              className="sponsor-image-program-detail rounded w-100"
              style={{
                backgroundImage: `url(${sidebarLogo})`,
                minHeight: 100,
              }}
              alt="Sponsor"
            />
          </div>
        ) : null}
        <SideBarUser />
        <ul className="sidebar-nav">
          <SidebarItem name="Dashboard" to="/portal" icon={faHome} />
          {sponsors.length ? (
            <>
              <SidebarPrograms entries={sponsors} />
            </>
          ) : (
            <SidebarItem
              className="cursor-default"
              name="No Investments"
              icon={faHandshake}
            />
          )}
          <SidebarItem
            name="Documents"
            icon={faFileAlt}
            to={"/portal/documents"}
          />
          {isAdvisor ? null : (
            <SidebarItem
              name="My Account"
              icon={faUserAlt}
              to={"/portal/account"}
            />
          )}
          <SidebarItem name="Logout" icon={faSignOutAlt} action={logout} />
          {isAdvisor ? (
            <div className="bg-lighter border-top border-bottom py-1 my-2">
              <>
                <li className="sidebar-header">
                  {authContext.currentUser.sessionOwner?.name}
                </li>
                <SidebarItem
                  name="Investor Selector"
                  classes="bg-transparent"
                  icon={faUsersCog}
                  action={() => (window.location.href = "/investor-selector")}
                />
                <SidebarItem
                  name="My Account"
                  classes="bg-transparent"
                  icon={faUserAlt}
                  to={"/portal/account"}
                />
              </>
            </div>
          ) : null}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;

import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Badge,
  CustomInput,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import UserBasicAttributes from "./UserBasicAttributes";
import { usersApi } from "../../services/userServices";
import InformationModal from "../InformationModal";
import UserBankAttributes from "./UserBankAttributes";
import ConfirmationModal from "../ConfirmationModal";
import { utils } from "../../utils/utils";
import { useAuth } from "../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../utils/roles";
import { emailRegex } from "../../utils/regex";
import UserAddress from "./UserAddress";

const STATUS_ACTIVE = "3";

const InvestorModalDetails = ({ investor, onClose, onSubmit }) => {
  const [loading, setLoading] = useState({});
  const [user, setUser] = useState(investor);
  const [mainAddress, setMainAddress] = useState(user.mainAddress || "primary");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [authContext] = useAuth();

  const authenticatedUser = authContext.currentUser;

  const onSaveChanges = (mode) => {
    if (loading[mode]) {
      return;
    }

    if (user.notificationEmails) {
      const notificationEmails = user.notificationEmails.split(",");
      if (
        !notificationEmails.filter((email) =>
          emailRegex.test(email.trim().toLowerCase())
        ).length ||
        notificationEmails.some(
          (email) => !emailRegex.test(email.trim().toLowerCase())
        )
      ) {
        return setInformationModal({
          isOpen: true,
          title: "Oops!",
          body: "One or more notification email addresses are not valid.",
        });
      }
    }

    setLoading({ [mode]: true });
    usersApi
      .updateUser(
        {
          ...user,
          mainAddress,
          email: user.email?.length ? user.email : null,
        },
        "admin"
      )
      .then(() => {
        setLoading({ [mode]: false });
        onSubmit(user);
        setUser({ ...user, changed: false, ssNumberChanged: false });
        setInformationModal({
          isOpen: true,
          title: "Success",
          body: "User updated successfully.",
        });
      })
      .catch((err) => {
        setLoading({ [mode]: false });
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  const onAttrEdit = (field, value) =>
    setUser({
      ...user,
      [field]: value,
      changed: true,
      ssNumberChanged: field === "ssNumber" ? true : user.ssNumberChanged,
    });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn} className="text-body investor-details">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span>{investor.name}</span>
            {investor.address?.length || investor.phone?.length ? (
              <>
                <br />
                <small className="text-muted">
                  {investor.address}, {investor.phone}
                </small>
              </>
            ) : null}
          </div>
          {investor.status !== STATUS_ACTIVE ? (
            <div className="d-flex flex-column align-items-center">
              <FontAwesomeIcon
                className="text-warning"
                icon={faExclamationCircle}
                fixedWidth
              />
              <small className="text-muted">user not activated</small>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <FontAwesomeIcon
                className="text-success"
                icon={faCheckCircle}
                fixedWidth
              />
              <small className="text-muted">user activated</small>
            </div>
          )}
        </div>
      </ModalHeader>
      <ModalBody className="text-center">
        <ListGroup flush className="text-left border rounded">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
            tag="div"
          >
            Personal Info
          </ListGroupItem>
          <ListGroupItem className="pb-2 border-bottom" tag="div">
            <UserBasicAttributes
              includeSafeData
              user={user}
              loading={loading["basic"]}
              onSubmit={() => {
                if (user.ssNumberChanged) {
                  return setConfirmationModal({
                    isOpen: true,
                    onSubmit: () => {
                      setConfirmationModal(initConfirmationModal);
                      onSaveChanges("basic");
                    },
                    onClose: () => setConfirmationModal(initConfirmationModal),
                    title: `Update Investor's Data`,
                    body: `<span>You confirm you want to change the Investor SSN?</span>`,
                  });
                }
                onSaveChanges("basic");
              }}
              onAttrEdit={onAttrEdit}
            />
          </ListGroupItem>
        </ListGroup>
        <ListGroup flush className="text-left border rounded mt-3">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
            tag="div"
          >
            Address 1
            <CustomInput
              checked={mainAddress === "primary"}
              onChange={() => setMainAddress("primary")}
              type="switch"
              id="firstAddress"
              name="firstAddress"
              label={"Primary"}
              className="cursor-pointer d-flex align-items-center font-weight-bold text-primary float-right"
            />
          </ListGroupItem>
          <ListGroupItem className="pb-2 border-bottom" tag="div">
            <UserAddress
              includeSafeData
              user={user}
              loading={loading["basic"]}
              onSubmit={() => {
                if (user.ssNumberChanged) {
                  return setConfirmationModal({
                    isOpen: true,
                    onSubmit: () => {
                      setConfirmationModal(initConfirmationModal);
                      onSaveChanges("basic");
                    },
                    onClose: () => setConfirmationModal(initConfirmationModal),
                    title: `Update Investor's Primary Address`,
                    body: `<span>You confirm you want to change the Investor Primary Address?</span>`,
                  });
                }
                onSaveChanges("basic");
              }}
              onAttrEdit={onAttrEdit}
            />
          </ListGroupItem>
        </ListGroup>
        <ListGroup flush className="text-left border rounded mt-3">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
            tag="div"
          >
            Address 2
            <CustomInput
              checked={mainAddress === "secondary"}
              onChange={() => setMainAddress("secondary")}
              type="switch"
              id="secondAddress"
              name="secondAddress"
              label={"Primary"}
              className="cursor-pointer d-flex align-items-center font-weight-bold text-primary float-right"
            />
          </ListGroupItem>
          <ListGroupItem className="pb-2 border-bottom" tag="div">
            <UserAddress
              includeSafeData
              user={user}
              loading={loading["basic"]}
              isSecondary
              onSubmit={() => {
                if (user.ssNumberChanged) {
                  return setConfirmationModal({
                    isOpen: true,
                    onSubmit: () => {
                      setConfirmationModal(initConfirmationModal);
                      onSaveChanges("basic");
                    },
                    onClose: () => setConfirmationModal(initConfirmationModal),
                    title: `Update Investor's Secondary Address`,
                    body: `<span>You confirm you want to change the Investor Secondary Address?</span>`,
                  });
                }
                onSaveChanges("basic");
              }}
              onAttrEdit={onAttrEdit}
            />
          </ListGroupItem>
        </ListGroup>
        {!utils.userHasRole(authenticatedUser, CUSTOMER_SUPPORT) && (
          <>
            {!utils.userHasRole(authenticatedUser, SPONSOR) && (
              <ListGroup flush className="text-left border rounded mt-3">
                <ListGroupItem
                  className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                  tag="div"
                >
                  Bank Account Information
                </ListGroupItem>
                <ListGroupItem className="pb-2 border-bottom" tag="div">
                  <UserBankAttributes
                    user={user}
                    loading={loading["bank"]}
                    onSubmit={() => onSaveChanges("bank")}
                    onAttrEdit={onAttrEdit}
                  />
                </ListGroupItem>
              </ListGroup>
            )}
            {utils.userHasRole(investor, "investor") ? (
              <ListGroup flush className="text-left border rounded mt-3">
                <ListGroupItem
                  className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                  tag="div"
                >
                  Program Investments
                </ListGroupItem>
                {investor.investments.map((investment, index) => (
                  <ListGroupItem
                    key={index}
                    className="pb-2 border-bottom d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <div>
                      {investment.name}
                      <small className="text-muted">
                        , {investment.programInvestment.units} units
                      </small>
                    </div>
                    <Badge color={"info"}>{investment.year}</Badge>
                  </ListGroupItem>
                ))}
                {!investor.investments.length ? (
                  <ListGroupItem className="pb-2 border-bottom" tag="div">
                    No Investments
                  </ListGroupItem>
                ) : null}
              </ListGroup>
            ) : null}
            {utils.userHasRole(investor, "stakeholder") ? (
              <ListGroup flush className="text-left border rounded mt-3">
                <ListGroupItem
                  className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                  tag="div"
                >
                  Program Ownerships
                </ListGroupItem>
                {investor.ownerships.map((ownership, index) => (
                  <ListGroupItem
                    key={index}
                    className="pb-2 border-bottom d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <div>
                      {ownership.name}
                      <small className="text-muted">
                        , {ownership.programStakeholder.percentage}%
                      </small>
                    </div>
                    <Badge color={"info"}>{ownership.year}</Badge>
                  </ListGroupItem>
                ))}
                {!investor.ownerships.length ? (
                  <ListGroupItem className="pb-2 border-bottom" tag="div">
                    No Investments
                  </ListGroupItem>
                ) : null}
              </ListGroup>
            ) : null}
            <ListGroup flush className="text-left border rounded mt-3">
              <ListGroupItem
                className="pb-2 border-bottom font-weight-bold text-body list-group-item bg-light"
                tag="div"
              >
                Advisors
              </ListGroupItem>
              {investor.advisors.map((advisor, index) => (
                <ListGroupItem
                  key={index}
                  className="pb-2 border-bottom"
                  tag="div"
                >
                  {advisor.name}
                </ListGroupItem>
              ))}
              {!investor.advisors.length ? (
                <ListGroupItem className="pb-2 border-bottom" tag="div">
                  No Advisors
                </ListGroupItem>
              ) : null}
            </ListGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InvestorModalDetails;

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { Card, Table, UncontrolledTooltip } from "reactstrap";

import { useAuth } from "../../providers/authProvider";
import { fundsApi } from "../../services/fundServices";
import { utils } from "../../utils/utils";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const FUND_QUARTER_STATUS_CLOSED = 2;
const TYPE_ALTERNATIVE = 4;

const getAlternativeFundInvestments = (programs) => {
  const fundInvestments = programs
    .filter((p) => p.programTypeId === TYPE_ALTERNATIVE)
    .reduce((funds, program) => {
      const programInvestment = program.programInvestment;
      const programStakeholder = program.programStakeholder;
      const fund = program.programFundInvestments[0]?.fundFundInvestment;
      const programFundInvestmentAmount =
        program.programFundInvestments[0]?.amount || 0;
      if (!fund) {
        return funds;
      }
      const programUnits = programInvestment ? programInvestment.units : 0;
      const programOwnership = programStakeholder
        ? programStakeholder.percentage
        : 0;
      const investorProgramOwnership = programStakeholder
        ? programStakeholder.percentage / 100
        : program.units
        ? programUnits / program.units
        : 0;
      funds[fund.id] = {
        programs: [
          ...(funds[fund.id]?.programs || []),
          {
            id: program.id,
            sponsorId: program.sponsorId,
            name: program.name,
            units: programUnits,
            ownership: programOwnership,
            amount: programStakeholder
              ? (programStakeholder.percentage / 100) * program.capital
              : programInvestment.capital,
            allocation: investorProgramOwnership * programFundInvestmentAmount,
          },
        ],
        fund: {
          id: fund.id,
          name: fund.name,
          year: fund.year,
          capital: fund.fundFundInvestments.reduce((p, c) => p + c.amount, 0),
          units: (funds[fund.id]?.fund.units || 0) + programUnits,
          amount:
            (funds[fund.id]?.fund.amount || 0) +
            (programInvestment ? programInvestment.capital : 0),
          allocation:
            (funds[fund.id]?.fund.allocation || 0) +
            investorProgramOwnership * programFundInvestmentAmount,
        },
      };
      return funds;
    }, {});

  return Promise.all(
    Object.keys(fundInvestments).map((fundId) =>
      fundsApi.getFundQuarters({
        fundId,
        status: FUND_QUARTER_STATUS_CLOSED,
        pageSize: 1,
      })
    )
  ).then((fundLastQuarters) => {
    fundLastQuarters.forEach((result) => {
      if (result?.count) {
        const fundLastQuarter = result.data[0];
        Object.assign(fundInvestments[fundLastQuarter.fundId].fund, {
          projectedAnnualizedReturn: fundLastQuarter.projectedAnnualizedReturn,
          returnPercentage: fundLastQuarter.returnPercentage,
        });
      }
    });
    return Object.values(fundInvestments);
  });
};

const AlternativeInvestmentsOverview = () => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState(true);
  const [fundInvestments, setFundInvestments] = useState();
  const user = authContext.currentUser;

  useEffect(() => {
    getAlternativeFundInvestments([
      ...user.investments,
      ...user.ownerships,
    ]).then((fundInvestments) => {
      setFundInvestments(fundInvestments);
      setLoading(false);
    });
  }, [user.investments, user.ownerships]);

  const getTotalAllocation = (fundInvestments) =>
    fundInvestments.reduce((p, c) => p + c.fund.allocation, 0);

  const getWeightedAvgReturn = (fundInvestments) => {
    const totalAmount = fundInvestments.reduce((p, c) => p + c.fund.amount, 0);
    const total = fundInvestments.reduce(
      (p, c) => p + c.fund.amount * (c.fund.returnPercentage || 0),
      0
    );
    return totalAmount > 0 ? total / totalAmount : 0;
  };

  const FundInvestmentRow = ({ fundInvestment }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <>
        <tr
          onClick={() => setExpanded(!expanded)}
          className="font-weight-bold bg-lighter cursor-pointer"
        >
          <td>
            <FontAwesomeIcon
              className="font-size-75 align-baseline text-muted font-weight-normal mr-2"
              icon={expanded ? faChevronDown : faChevronRight}
              fixedWidth
            />
          </td>
          <td>
            <Link to={`/portal/funds/${fundInvestment.fund.id}`}>
              {fundInvestment.fund.name} {fundInvestment.fund.year}
            </Link>
          </td>
          {utils.userHasRole(user, "investor") ? (
            <td>
              {fundInvestment.fund.units
                ? fundInvestment.fund.units.toFixed(2)
                : "-"}
            </td>
          ) : null}
          {utils.userHasRole(user, "stakeholder") ? (
            <td>
              {fundInvestment.programs.find((program) => program.ownership)
                ? `${(
                    (fundInvestment.fund.allocation /
                      fundInvestment.fund.capital) *
                    100
                  ).toFixed(3)}%`
                : "-"}
            </td>
          ) : null}
          {utils.userHasRole(user, "investor") ? (
            <td>
              {fundInvestment.programs.find((program) => program.units)
                ? utils.formatCurrency(fundInvestment.fund.amount)
                : "-"}
            </td>
          ) : null}
          <td>{utils.formatCurrency(fundInvestment.fund.allocation)}</td>
          <td>{utils.formatCurrency(fundInvestment.fund.capital)}</td>
          <td>{(fundInvestment.fund.returnPercentage || 0).toFixed(3)}%</td>
          <td>
            {(fundInvestment.fund.projectedAnnualizedReturn || 0).toFixed(3)}%
          </td>
        </tr>
        {fundInvestment.programs.map((program, index) => (
          <tr hidden={!expanded} key={index}>
            <td></td>
            <td>
              <Link
                to={`/portal/sponsor/${program.sponsorId}/program/${program.id}`}
              >
                {program.name}
              </Link>
            </td>
            {utils.userHasRole(user, "investor") ? (
              <td>{program.units ? program.units.toFixed(2) : "-"}</td>
            ) : null}
            {utils.userHasRole(user, "stakeholder") ? (
              <td>
                {program.ownership ? `${program.ownership.toFixed(3)}%` : "-"}
              </td>
            ) : null}
            {utils.userHasRole(user, "investor") ? (
              <td>
                {program.units ? utils.formatCurrency(program.amount) : "-"}
              </td>
            ) : null}
            <td>{utils.formatCurrency(program.allocation)}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        ))}
      </>
    );
  };

  return loading || !fundInvestments ? (
    <Loader />
  ) : !fundInvestments.length ? null : (
    <Card className=" w-100">
      <Table className="my-0 text-custom-dark text-center">
        <thead>
          <tr className="text-muted font-size-75">
            <th
              className="text-left"
              colSpan={
                utils.userHasRole(user, "investor") &&
                utils.userHasRole(user, "stakeholder")
                  ? 5
                  : utils.userHasRole(user, "investor")
                  ? 4
                  : 3
              }
            >
              <span className="text-custom-red font-size-1-2">
                Alternative Investments
              </span>
            </th>
            <th className="align-middle">Total Allocation</th>
            <th></th>
            <th className="align-middle">Weighted Avg Return</th>
            <th></th>
          </tr>
          <tr className="font-weight-bold bg-lighter">
            <th
              colSpan={
                utils.userHasRole(user, "investor") &&
                utils.userHasRole(user, "stakeholder")
                  ? 5
                  : utils.userHasRole(user, "investor")
                  ? 4
                  : 3
              }
            >
              Summary
            </th>
            <th>{utils.formatCurrency(getTotalAllocation(fundInvestments))}</th>
            <th></th>
            <th>{getWeightedAvgReturn(fundInvestments).toFixed(3)}%</th>
            <th></th>
          </tr>
          <tr className="text-muted font-size-75">
            <th className="align-middle"></th>
            <th className="align-middle">Fund Name</th>
            {utils.userHasRole(user, "investor") ? (
              <th className="align-middle">Units</th>
            ) : null}
            {utils.userHasRole(user, "stakeholder") ? (
              <th className="align-middle">Ownership</th>
            ) : null}
            {utils.userHasRole(user, "investor") ? (
              <th className="align-middle">My Investment</th>
            ) : null}
            <th className="align-middle">
              <span id="allocationToSf">Allocation to Strategic Fund</span>
              <UncontrolledTooltip placement={"top"} target={"allocationToSf"}>
                Additional Investment in Strategic Fund
              </UncontrolledTooltip>
            </th>
            <th className="align-middle">Total Strategic Fund Capital</th>
            <th className="align-middle">Last Quarter Return</th>
            <th className="align-middle">
              Annualized Return Based on Last Quarter
            </th>
          </tr>
        </thead>
        <tbody>
          {fundInvestments
            .sort((a, b) => b.fund.year - a.fund.year)
            .map((fundInvestment) => (
              <FundInvestmentRow
                fundInvestment={fundInvestment}
                key={`fund-${fundInvestment.fund.id}`}
              />
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default AlternativeInvestmentsOverview;

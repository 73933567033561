import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const ADMINS_TAB = "1";

const initialState = {
  adminUsers: { sizePerPage: "All", page: 1 },
  advisorUsers: { sizePerPage: "All", page: 1 },
  investorUsers: { sizePerPage: "All", page: 1 },
  sponsorUsers: { sizePerPage: "All", page: 1 },
  printerUsers: { sizePerPage: "All", page: 1 },
  tab: ADMINS_TAB,
};

const UsersContext = createContext(initialState);

const UsersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <UsersContext.Provider value={stateAndDispatch}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);

export { UsersContext, UsersProvider, initialState };

import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  isActive: false,
  mode: DEFAULT_MODE,
  content: "",
  subject: "",
  replyTo: "",
  attachment: {},
  result: {},
};

const ProgramEmailContext = createContext(initialState);

const ProgramEmailProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ProgramEmailContext.Provider value={stateAndDispatch}>
      {children}
    </ProgramEmailContext.Provider>
  );
};

export const useProgramEmail = () => useContext(ProgramEmailContext);

export { ProgramEmailContext, ProgramEmailProvider, initialState };

import React, { useEffect, useState } from "react";

import { Card, CardBody, CardFooter, Col, Container, Row } from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { useSponsors } from "../../providers/sponsorsProvider";
import Loader from "../../components/Loader";
import { sponsorsApi } from "../../services/sponsorServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformationModal from "../../components/InformationModal";
import { faTrashAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../components/ConfirmationModal";
import SponsorModal from "../../components/admin/SponsorModal";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";

const MAX_PAGE_SIZE = 100;

const Sponsors = () => {
  const [sponsorsContext, setSponsorsContext] = useSponsors();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sponsorLoading, setSponsorLoading] = useState({});

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const [sponsorModal, setSponsorModal] = useState({});

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const onSubmit = (updatedSponsor) => {
    setSponsorModal({ sponsor: null, isOpen: false });
    const sponsorObj = {
      id: sponsorModal.sponsor.id,
      name: updatedSponsor.name,
      logo: updatedSponsor.logo,
      ceoAttribute: updatedSponsor.ceo,
      physicalAddress: updatedSponsor.physicalAddress,
      webAddress: updatedSponsor.webAddress,
      phoneNumber: updatedSponsor.phoneNumber,
      supportEmail: updatedSponsor.supportEmail,
      statementLogo: updatedSponsor.statementLogo,
      webLogo: updatedSponsor.webLogo,
      icon: updatedSponsor.icon,
      statementHeader: updatedSponsor.statementHeader,
      statementBackground: updatedSponsor.statementBackground,
      color: updatedSponsor.color,
    };
    setSponsorLoading({ [sponsorObj.id]: true });
    sponsorsApi.updateSponsor(sponsorObj).then((result) => {
      setInformationModal({
        isOpen: true,
        title: "Update Sponsor",
        body: "Sponsor updated successfully",
        onClose: () => {
          setInformationModal({ isOpen: false });
          setSponsorLoading({ [sponsorObj.id]: false });
          sponsors.data.splice(
            sponsors.data.findIndex((s) => s.id === sponsorObj.id),
            1,
            { ...result, id: sponsorObj.id, programs: updatedSponsor.programs }
          );
          setSponsors({ ...sponsors });
        },
      });
    });
  };

  const onDelete = (sponsor) => {
    if (sponsor.programs?.length) {
      return setInformationModal({
        isOpen: true,
        body: `This sponsor can't be deleted because has programs associated, delete them first.`,
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        sponsorsApi.deleteSponsor(sponsor).then(() => {
          sponsorsApi
            .getSponsors({
              page: sponsorsContext.page - 1,
              pageSize: getSizePerPage(sponsorsContext.sizePerPage),
            })
            .then((result) => {
              setSponsors(result);
              setConfirmationModal(initConfirmationModal);
            });
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Sponsor",
      body: `<span>Do you confirm you want to delete ${sponsor.name}?</span>`,
    });
  };

  const actionIcons = (sponsor) =>
    sponsorLoading[sponsor.id] ? (
      <Loader size="sm" align="end" />
    ) : (
      <div>
        <FontAwesomeIcon
          icon={faPen}
          onClick={() =>
            setSponsorModal({ sponsor: { ...sponsor }, isOpen: true })
          }
          className="mx-2 cursor-pointer text-primary"
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          onClick={() => onDelete(sponsor)}
          className="mx-2 cursor-pointer text-danger"
        />
      </div>
    );

  useEffect(() => {
    setLoading(true);
    sponsorsApi
      .getSponsors({
        page: sponsorsContext.page - 1,
        pageSize: getSizePerPage(sponsorsContext.sizePerPage),
      })
      .then((result) => {
        setSponsors(result);
        setLoading(false);
      });
  }, [sponsorsContext]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Sponsors</HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {loading ? (
                <Loader />
              ) : sponsors.data.length ? (
                <AdvanceTableWrapper
                  columns={[
                    {
                      accessor: "name",
                      Header: "Name",
                      headerProps: { className: "text-truncate" },
                      disableSortBy: true,
                      Cell: (rowData) => {
                        const { name } = rowData.row.original;
                        return (
                          <div className="d-flex flex-column">
                            <span>{name}</span>
                            <span>{`${
                              window.location.origin
                            }/auth/sign-in/${name
                              .replaceAll(" ", "_")
                              .toLowerCase()}`}</span>
                          </div>
                        );
                      },
                    },
                    {
                      accessor: "programs",
                      Header: "Programs",
                      headerProps: { className: "text-truncate" },
                      disableSortBy: true,
                      Cell: (rowData) => {
                        const { programs } = rowData.row.original;
                        return programs.length
                          ? programs.reduce(
                              (p, c) =>
                                p?.length ? `${p}, ${c.name}` : c.name,
                              ""
                            )
                          : "-";
                      },
                    },
                    {
                      accessor: "id",
                      Header: "",
                      width: 60,
                      headerProps: {
                        className: "text-truncate",
                      },
                      cellProps: {
                        className: "text-right",
                      },
                      disableSortBy: true,
                      Cell: (rowData) => {
                        const sponsor = rowData.row.original;
                        return actionIcons(sponsor);
                      },
                    },
                  ]}
                  data={sponsors.data || []}
                  pageSize={getSizePerPage(sponsorsContext.sizePerPage)}
                >
                  <Card className="d-flex">
                    <CardBody className="overflow-auto min-height-300p">
                      {sponsors.data?.length ? (
                        <AdvanceTable
                          table
                          isLoading={loading}
                          headerClassName="small"
                          tableProps={{
                            striped: false,
                            className: "mb-0",
                          }}
                        />
                      ) : (
                        <div className="text-center">No results</div>
                      )}
                    </CardBody>
                    <CardFooter>
                      <AdvanceTablePagination
                        totalCount={sponsors?.count}
                        pageCount={sponsors?.totalPages}
                        currentPage={sponsorsContext.page - 1}
                        onPageChange={(page) => setSponsorsContext({ page })}
                        pageSize={getSizePerPage(sponsorsContext.sizePerPage)}
                        onPageSizeChange={(sizePerPage) =>
                          setSponsorsContext({ page: 1, sizePerPage })
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                      />
                    </CardFooter>
                  </Card>
                </AdvanceTableWrapper>
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal?.isOpen ? (
        <InformationModal
          title={"Heads Up!"}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : sponsorModal.isOpen ? (
        <SponsorModal
          sponsor={sponsorModal.sponsor}
          onSubmit={onSubmit}
          onClose={() => setSponsorModal({ sponsor: null, isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default Sponsors;

import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const PERSONAL_TAB = "1";

const initialState = {
  tab: PERSONAL_TAB,
};

const SettingsContext = createContext(initialState);

const SettingsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <SettingsContext.Provider value={stateAndDispatch}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);

export { SettingsContext, SettingsProvider, initialState };

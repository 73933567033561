import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  UncontrolledAlert,
  Input,
  Row,
  FormText,
} from "reactstrap";
import { sponsorsApi } from "../../services/sponsorServices";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import { SketchPicker } from "react-color";

const MAX_LOGO_FILE_SIZE = 1000000;
const MAX_FILE_SIZE = 5000000;
const MAX_PAGE_SIZE = 999;
const DEFAULT_IMAGE_WIDTH = 1260;
const DEFAULT_IMAGE_HEIGHT = 360;
const STATEMENT_LOGO_WIDTH = 1280;
const STATEMENT_LOGO_HEIGHT = 200;

const SponsorModal = ({ sponsor, onSubmit, onClose }) => {
  const [name, setName] = useState(sponsor?.name || "");
  const webLogoRef = useRef();
  const iconRef = useRef();
  const statementLogoRef = useRef();
  const statementHeaderRef = useRef();
  const statementBackgroundRef = useRef();

  const [statementLogo, setStatementLogo] = useState(
    sponsor?.statementLogo || ""
  );
  const [statementLogoSrc, setStatementLogoSrc] = useState();

  const [webLogo, setWebLogo] = useState(sponsor?.webLogo || "");
  const [webLogoSrc, setWebLogoSrc] = useState();

  const [icon, setIcon] = useState(sponsor?.icon || "");
  const [iconSrc, setIconSrc] = useState();

  const [statementHeader, setStatementHeader] = useState(
    sponsor?.statementHeader || ""
  );
  const [statementHeaderSrc, setStatementHeaderSrc] = useState();

  const [statementBackground, setStatementBackground] = useState(
    sponsor?.statementBackground || ""
  );
  const [statementBackgroundSrc, setStatementBackgroundSrc] = useState();

  const [color, setColor] = useState(sponsor?.color || "#000");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [ceo, setCeo] = useState(sponsor?.ceoAttribute || "");
  const [physicalAddress, setPhysicalAddress] = useState(
    sponsor?.physicalAddress || ""
  );
  const [webAddress, setWebAddress] = useState(sponsor?.webAddress || "");
  const [phoneNumber, setPhoneNumber] = useState(sponsor?.phoneNumber || "");
  const [supportEmail, setSupportEmail] = useState(sponsor?.supportEmail || "");
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });

  useEffect(() => {
    setLoading(true);
    sponsorsApi.getSponsors({ pageSize: MAX_PAGE_SIZE }).then((result) => {
      setSponsors(result.data);
      setLoading(false);
    });
  }, []);

  const [nameExists, setNameExists] = useState(false);

  const onImageChange = (event, type) => {
    const file = event ? event.currentTarget.files[0] : null;
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // validate image size/width/height according the image type to load
        let message;
        switch (type) {
          case "statementLogo":
            if (file.size > MAX_LOGO_FILE_SIZE) {
              message = "The file size exceeds the maximum size of 1mb.";
            } else if (width !== STATEMENT_LOGO_WIDTH) {
              message = `The image width doesn't meet ${STATEMENT_LOGO_WIDTH}px.`;
            } else if (height !== STATEMENT_LOGO_HEIGHT) {
              message = `The image height doesn't meet ${STATEMENT_LOGO_HEIGHT}px.`;
            }
            break;

          case "statementHeader":
          case "statementBackground":
            if (file.size > MAX_FILE_SIZE) {
              message = "The file size exceeds the maximum size of 5mb.";
            } else if (width !== DEFAULT_IMAGE_WIDTH) {
              message = `The image width doesn't meet ${DEFAULT_IMAGE_WIDTH}px.`;
            } else if (height !== DEFAULT_IMAGE_HEIGHT) {
              message = `The The image height doesn't meet ${DEFAULT_IMAGE_HEIGHT}px.`;
            }
            break;

          default:
            if (file.size > MAX_LOGO_FILE_SIZE) {
              message = "The file size exceeds the maximum size of 1mb.";
            }
            break;
        }

        // notify if there was a problem with the image size/width/height
        if (message) {
          return setInformationModal({
            isOpen: true,
            title: "Heads Up!",
            body: message,
          });
        }

        switch (type) {
          case "webLogo":
            if (file) {
              setWebLogoSrc(URL.createObjectURL(file));
            } else {
              webLogoRef.current.value = "";
              setWebLogoSrc();
            }
            setWebLogo(file);
            break;
          case "icon":
            if (file) {
              setIconSrc(URL.createObjectURL(file));
            } else {
              iconRef.current.value = "";
              setIconSrc();
            }
            setIcon(file);
            break;
          case "statementLogo":
            if (file) {
              setStatementLogoSrc(URL.createObjectURL(file));
            } else {
              statementLogoRef.current.value = "";
              setStatementLogoSrc();
            }
            setStatementLogo(file);
            break;
          case "statementHeader":
            if (file) {
              setStatementHeaderSrc(URL.createObjectURL(file));
            } else {
              statementHeaderRef.current.value = "";
              setStatementHeaderSrc();
            }
            setStatementHeader(file);
            break;
          case "statementBackground":
            if (file) {
              setStatementBackgroundSrc(URL.createObjectURL(file));
            } else {
              statementBackgroundRef.current.value = "";
              setStatementBackgroundSrc(null);
            }
            setStatementBackground(file);
            break;
          default:
            break;
        }
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onNameChange = (event) => {
    const name = event.currentTarget.value;
    setNameExists(
      sponsors.find((sponsor) => sponsor.name === name) !== undefined
    );
    setName(name);
  };

  const onDismiss = () => {
    setName("");
    setNameExists(false);
  };

  const doSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit({
      name,
      physicalAddress,
      webAddress,
      phoneNumber,
      supportEmail,
      ceo,
      statementLogo,
      webLogo,
      icon,
      statementHeader,
      statementBackground,
      color,
      programs: sponsor?.programs,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() => setInformationModal({ isOpen: false, body: "" })}
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>
        {sponsor ? "Edit" : "Create New"} Sponsor
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Sponsor Name
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter the name.."
                    onChange={onNameChange}
                    value={name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  CEO
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    placeholder="Enter the CEO name.."
                    onChange={(event) => setCeo(event.currentTarget.value)}
                    value={ceo}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Physical Address
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    placeholder="Enter the physical address.."
                    onChange={(event) =>
                      setPhysicalAddress(event.currentTarget.value)
                    }
                    value={physicalAddress}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Web Address
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    placeholder="Enter the web address.."
                    onChange={(event) =>
                      setWebAddress(event.currentTarget.value)
                    }
                    value={webAddress}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Phone Number
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    placeholder="Enter the phone number.."
                    onChange={(event) =>
                      setPhoneNumber(event.currentTarget.value)
                    }
                    value={phoneNumber}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Support Email
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    type={"email"}
                    placeholder="Enter the support email.."
                    onChange={(event) =>
                      setSupportEmail(event.currentTarget.value)
                    }
                    value={supportEmail}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-1">
                <Label sm={4} className="text-sm-left">
                  Brand Color
                </Label>
                <Col sm={1}>
                  <div
                    className="color-picker"
                    onClick={() => setDisplayColorPicker(!displayColorPicker)}
                  >
                    <div
                      className="color-box"
                      style={{
                        background: `${color}`,
                      }}
                    />
                  </div>
                  {displayColorPicker ? (
                    <div className="position-absolute z-index-2">
                      <div
                        className="color-selector"
                        onClick={() => setDisplayColorPicker(false)}
                      />
                      <SketchPicker
                        color={color}
                        onChangeComplete={(color) => {
                          setColor(color.hex);
                        }}
                      />
                    </div>
                  ) : null}
                </Col>
              </FormGroup>
              <FormGroup row className="mb-1">
                <Label sm={4} className="text-sm-left">
                  Web Logo
                </Label>
                <Col sm={8}>
                  <Input
                    className="text-truncate"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(event) => onImageChange(event, "webLogo")}
                    innerRef={webLogoRef}
                  />
                  {webLogoSrc || webLogo ? (
                    <div className="text-left mt-2">
                      <div
                        className="d-inline-block sponsor-image-program-detail rounded max-width-100  col-3"
                        style={{
                          backgroundImage: `url(${webLogoSrc || webLogo})`,
                        }}
                        alt="web logo"
                      ></div>
                      <Button
                        className="close float-none"
                        color="none"
                        onClick={() => {
                          webLogoRef.current.value = "";
                          setWebLogo(null);
                          setWebLogoSrc(null);
                        }}
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        &times;
                      </Button>
                    </div>
                  ) : (
                    <FormText className="text-left" color="muted">
                      Select a file
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row className="mb-1">
                <Label sm={4} className="text-sm-left">
                  Sidebar Logo
                </Label>
                <Col sm={8}>
                  <Input
                    className="text-truncate"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(event) => onImageChange(event, "icon")}
                    innerRef={iconRef}
                  />
                  {iconSrc || icon ? (
                    <div className="text-left mt-2">
                      <div
                        className="d-inline-block sponsor-image-program-detail rounded max-width-100  col-3"
                        style={{
                          backgroundImage: `url(${iconSrc || icon})`,
                        }}
                        alt="icon"
                      ></div>
                      <Button
                        className="close float-none"
                        color="none"
                        onClick={() => {
                          iconRef.current.value = "";
                          setIcon(null);
                          setIconSrc(null);
                        }}
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        &times;
                      </Button>
                    </div>
                  ) : (
                    <FormText className="text-left" color="muted">
                      Select a file
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row className="mb-1">
                <Label sm={4} className="text-sm-left">
                  Statement Logo
                </Label>
                <Col sm={8}>
                  <Input
                    className="text-truncate"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(event) => onImageChange(event, "statementLogo")}
                    innerRef={statementLogoRef}
                  />
                  {statementLogoSrc || statementLogo ? (
                    <div className="text-left mt-2">
                      <div
                        className="d-inline-block sponsor-image-program-detail rounded max-width-100  col-3"
                        style={{
                          backgroundImage: `url(${
                            statementLogoSrc || statementLogo
                          })`,
                        }}
                        alt="statement logo"
                      ></div>
                      <Button
                        className="close float-none"
                        color="none"
                        onClick={() => {
                          statementLogoRef.current.value = "";
                          setStatementLogo(null);
                          setStatementLogoSrc(null);
                        }}
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        &times;
                      </Button>
                      <FormText className="text-left" color="muted">
                        Select a 1280x200 image
                      </FormText>
                    </div>
                  ) : (
                    <FormText className="text-left" color="muted">
                      Select a 1280x200 image
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row className="mb-1">
                <Label sm={4} className="text-sm-left">
                  Statement Header
                </Label>
                <Col sm={8}>
                  <Input
                    className="text-truncate"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(event) =>
                      onImageChange(event, "statementHeader")
                    }
                    innerRef={statementHeaderRef}
                  />
                  {statementHeaderSrc || statementHeader ? (
                    <div className="text-left mt-2">
                      <div
                        className="d-inline-block sponsor-image-program-detail rounded max-width-100  col-3"
                        style={{
                          backgroundImage: `url(${
                            statementHeaderSrc || statementHeader
                          })`,
                        }}
                        alt="statement logo"
                      ></div>
                      <Button
                        className="close float-none"
                        color="none"
                        onClick={() => {
                          statementHeaderRef.current.value = "";
                          setStatementHeader(null);
                          setStatementHeaderSrc(null);
                        }}
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        &times;
                      </Button>
                      <FormText className="text-left" color="muted">
                        Select a 1260x360 image
                      </FormText>
                    </div>
                  ) : (
                    <FormText className="text-left" color="muted">
                      Select a 1260x360 image
                    </FormText>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row className="mb-1">
                <Label sm={4} className="text-sm-left">
                  Statement Background
                </Label>
                <Col sm={8}>
                  <Input
                    className="text-truncate"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(event) =>
                      onImageChange(event, "statementBackground")
                    }
                    innerRef={statementBackgroundRef}
                  />
                  {statementBackgroundSrc || statementBackground ? (
                    <div className="text-left mt-2">
                      <div
                        className="d-inline-block sponsor-image-program-detail rounded max-width-100  col-3"
                        style={{
                          backgroundImage: `url(${
                            statementBackgroundSrc || statementBackground
                          })`,
                        }}
                        alt="web logo"
                      ></div>
                      <Button
                        className="close float-none"
                        color="none"
                        onClick={() => {
                          statementBackgroundRef.current.value = "";
                          setStatementBackground(null);
                          setStatementBackgroundSrc(null);
                        }}
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        &times;
                      </Button>
                      <FormText className="text-left" color="muted">
                        Select a 1260x360 image
                      </FormText>
                    </div>
                  ) : (
                    <FormText className="text-left" color="muted">
                      Select a 1260x1320 image
                    </FormText>
                  )}
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              isOpen={nameExists}
              toggle={onDismiss}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>This name is already in use</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>{" "}
              <Button disabled={nameExists} color={"primary"} type="submit">
                {sponsor ? "Save" : "Create"}
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SponsorModal;

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import HeaderSubtitle from "../../components/HeaderSubtitle";
import ProgramStatements from "./ProgramStatements";
import { programsApi } from "../../services/programServices";
import moment from "moment";
import Loader from "../../components/Loader";

const ReadyToPrint = () => {
  const [programs, setPrograms] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    getPrograms();
  }, []);

  const getPrograms = async () => {
    setLoading(true);
    const programs = await programsApi.getAllPrograms({});
    setPrograms(programs.data);
    setLoading(false);
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Ready to Print</HeaderTitle>
        <HeaderSubtitle>{`${moment().get("year")} Q${moment().get(
          "Q"
        )} Statements`}</HeaderSubtitle>
      </Header>
      <Row>
        {loading ? (
          <Loader />
        ) : (
          <Col className="d-flex col-12 flex-column">
            {programs &&
              programs.map((p, i) => <ProgramStatements program={p} key={i} />)}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ReadyToPrint;

import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  sizePerPage: "All",
  page: 1,
  search: "",
  sortBy: "name",
  direction: "asc",
  investorId: null,
  programId: null,
};

const DocumentsContext = createContext(initialState);

const DocumentsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <DocumentsContext.Provider value={stateAndDispatch}>
      {children}
    </DocumentsContext.Provider>
  );
};

export const useDocuments = () => useContext(DocumentsContext);

export { DocumentsContext, DocumentsProvider, initialState };

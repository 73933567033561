import React, { useState } from "react";

import { ListGroup, Col, ListGroupItem, Row, Button } from "reactstrap";
import { useStatementsFlow } from "../../../../providers/statementsFlowProvider";
import { usePrograms } from "../../../../providers/programsProvider";
import CustomCheckbox from "../../../CustomCheckbox";
import InformationModal from "../../../InformationModal";

const ON_RESERVES_STEP_ACTION = "onReservesStepAction";
const ON_DEACTIVATE_ACTION = "onDeactivateAction";
const ON_PROGRAM_SELECTED_ACTION = "onProgramSelectedAction";
const ON_ALL_PROGRAM_SELECTED_ACTION = "onAllProgramSelectedAction";

const ProgramSelection = () => {
  const [programsContext] = usePrograms();
  const [statementsFlow, setStatementsFlow] = useStatementsFlow();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const yearsProgram = programsContext.programs.filter(
    (p) => p.year === statementsFlow.year
  );

  const onChange = (program, checked) => {
    return setStatementsFlow({
      action: ON_PROGRAM_SELECTED_ACTION,
      payload: { checked, program },
    });
  };

  const allChecked =
    Object.keys(statementsFlow.programs).filter(
      (key) => statementsFlow.programs[key]
    ).length === yearsProgram.length;

  const onAllCheckedChange = (allChecked) => {
    return setStatementsFlow({
      action: ON_ALL_PROGRAM_SELECTED_ACTION,
      payload: { checked: !allChecked, programs: yearsProgram },
    });
  };

  return (
    <Col className="flex-grow-1 col-12 height-100 d-flex flex-column justify-content-between">
      <ListGroup flush className="text-left border rounded">
        <ListGroupItem
          className="pb-2 border-bottom text-body d-flex justify-content-between align-items-center bg-light"
          tag="div"
        >
          <span>Program</span>
          <CustomCheckbox
            label={"All"}
            checked={allChecked}
            onClick={() => onAllCheckedChange(allChecked)}
          />
        </ListGroupItem>
        {yearsProgram.map((program) => (
          <ListGroupItem
            key={program.id}
            className="pb-2 border-bottom text-body d-flex justify-content-between align-items-center"
            tag="div"
          >
            {program.name}
            <CustomCheckbox
              checked={statementsFlow.programs[program.id] || false}
              onClick={() =>
                onChange(program, !statementsFlow.programs[program.id])
              }
            />
          </ListGroupItem>
        ))}
      </ListGroup>
      <Row className="mx-0 justify-content-between mt-4">
        <Button
          color={"secondary"}
          onClick={() => {
            setStatementsFlow({ action: ON_DEACTIVATE_ACTION });
          }}
        >
          Cancel
        </Button>{" "}
        <Button
          className="ml-2"
          color={"primary"}
          onClick={() => {
            if (!Object.keys(statementsFlow.programs).length) {
              return setInformationModal({
                isOpen: true,
                title: "Send Statements",
                body: "Please select at least one program.",
              });
            }
            setStatementsFlow({
              action: ON_RESERVES_STEP_ACTION,
            });
          }}
        >
          Next
        </Button>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          rawBody={informationModal.rawBody}
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Col>
  );
};

export default ProgramSelection;

import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  Row,
  InputGroup,
  Input,
  Badge,
  CustomInput,
  Button,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import DocumentModalDetails from "../../components/admin/DocumentDetailsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faPlus,
  faSync,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DocumentPreviewModal from "./documents/review/DocumentPreviewModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { awsApi } from "../../services/awsServices";
import config from "../../config/config";
import { useStatements } from "../../providers/statementsProvider";
import { programsApi } from "../../services/programServices";
import { fundsApi } from "../../services/fundServices";
import download from "downloadjs";
import { utils } from "../../utils/utils";
import { useAuth } from "../../providers/authProvider";
import { SPONSOR } from "../../utils/roles";
import moment from "moment";
import {
  initialState,
  useDocumentsUploadFlow,
} from "../../providers/documentUploadFlowProvider";
import DocumentUploadFlow from "../../components/admin/DocumentUploadFlow";
import CustomCheckbox from "../../components/CustomCheckbox";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";

const FILE_TYPE = "pdf";

const ROLE_ADMIN = "1";
const ADMIN = "admin";

const MAX_PAGE_SIZE = 100;

const LIMIT_YEAR = 2018;
const INVESTOR_STATEMENTS_UPLOAD_STEP = 7;

let years = [];
let year = moment().get("year");

while (year >= LIMIT_YEAR) {
  years.push(year);
  year = moment().subtract(years.length, "year").get("year");
}

const quarters = ["4", "3", "2", "1"];

const Statements = () => {
  const [authContext] = useAuth();

  const [statementsContext, setStatementsContext] = useStatements();
  const [documentsUploadFlow, setDocumentsUploadFlow] = useDocumentsUploadFlow({
    ...initialState,
  });

  const [checked, setChecked] = useState({});
  const [refresh, setRefresh] = useState();
  const [statements, setStatements] = useState({});
  const [programs, setPrograms] = useState([]);

  const [loading, setLoading] = useState(true);
  const [statmentsLoading, setStatementsLoading] = useState({});

  const [selectedDocument, setSelectedDocument] = useState(false);
  const [previewModal, setPreviewModal] = useState({});

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const allChecked =
    statements?.data &&
    Object.keys(checked).filter((item) => checked[item]).length ===
      statements?.data?.length;

  const someChecked =
    statements?.data && Object.keys(checked).find((item) => checked[item]);

  const TABLE_COLUMNS = [
    {
      accessor: "id",
      Header: (
        <div className="min-width-50 d-flex justify-content-start">
          <CustomCheckbox
            checked={allChecked}
            onClick={() =>
              setChecked(
                statements?.data?.reduce((p, c) => {
                  p[c.id] = !allChecked;
                  return p;
                }, {})
              )
            }
          />
        </div>
      ),
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-left",
      },
      width: 50,
      disableSortBy: true,
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="min-width-50 d-flex justify-content-start">
            <CustomCheckbox
              checked={checked[id]}
              onClick={() =>
                setChecked({
                  ...checked,
                  [id]: !checked[id],
                })
              }
            />
          </div>
        );
      },
    },
    {
      accessor: "fileName",
      Header: "Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const statement = rowData.row.original;
        const { fileName } = statement;
        return (
          <div className="d-flex align-items-center">
            <span>{fileName}</span>
            {statement.manual ? (
              <Badge color="warning" className="ml-2">
                Manual
              </Badge>
            ) : null}
          </div>
        );
      },
    },
    {
      accessor: "user.name",
      Header: "Investor",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      Cell: (rowData) => {
        const { user } = rowData.row.original;
        return user.name;
      },
    },
    {
      accessor:
        statementsContext.type === "REGULAR"
          ? "programStatement.fundQuarter"
          : "quarter",
      Header: "Fiscal Quarter",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      Cell: (rowData) => {
        const statement = rowData.row.original;
        const { programStatement, quarter, year } = statement;
        return statementsContext.type === "REGULAR"
          ? `Q${programStatement.fundQuarter?.quarter}-${programStatement.fundQuarter?.year}`
          : `Q${quarter}-${year}`;
      },
    },
    {
      accessor:
        statementsContext.type === "REGULAR"
          ? "programStatement.program.name"
          : "program.name",
      Header: "Program",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      Cell: (rowData) => {
        const statement = rowData.row.original;
        const { programStatement, program } = statement;
        return programStatement?.program?.name || program?.name;
      },
    },
    {
      accessor: "url",
      Header: "File",
      width: 160,
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      Cell: (rowData) => {
        const statement = rowData.row.original;
        return statmentsLoading[statement.id] ? (
          <Loader size="sm" />
        ) : (
          getLink(statement)
        );
      },
    },
    {
      accessor: "user",
      Header: "",
      width: 80,
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-right",
      },
      disableSortBy: true,
      Cell: (rowData) => {
        const statement = rowData.row.original;
        return trashIcon(statement);
      },
    },
  ];

  const onSort = useCallback(
    ([data]) => {
      if (data && data.id) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          statementsContext.sortBy === sortBy?.id &&
          statementsContext.direction === direction
        ) {
          return;
        }
        setStatementsContext({ sortBy, direction });
      } else {
        setStatementsContext({
          sortBy: null,
          direction: null,
        });
      }
    },
    [
      statementsContext.direction,
      statementsContext.sortBy,
      setStatementsContext,
    ]
  );

  const onPreview = (fileName, id) => {
    setStatementsLoading({ ...statmentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        ADMIN
      )
      .then((result) => {
        setStatementsLoading({ ...statmentsLoading, [id]: false });
        setPreviewModal({
          isOpen: true,
          file: result?.pop().signedRequest,
        });
      });
  };

  const onDownload = (fileName, id) => {
    setStatementsLoading({ ...statmentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        ADMIN
      )
      .then((result) => {
        setStatementsLoading({ ...statmentsLoading, [id]: false });
        download(result?.pop().signedRequest);
      });
  };

  const getLink = (statement) => (
    <div className="d-flex align-items-center">
      <span
        className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
        onClick={() => onDownload(statement.fileName, statement.id)}
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="text-primary" icon={faFileDownload} />
      </span>
      <Badge
        onClick={() => onPreview(statement.fileName, statement.id)}
        className="cursor-pointer"
        color={"info"}
      >
        Preview
      </Badge>
    </div>
  );

  const onBulkDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        if (statementsContext.type === "REGULAR") {
          programsApi
            .bulkDeleteStatement(
              Object.keys(checked)
                .filter((item) => checked[item])
                .join(",")
            )
            .then(() => {
              setConfirmationModal(initConfirmationModal);
              setRefresh(!refresh);
            });
        } else {
          programsApi
            .bulkDeleteManualStatement(
              Object.keys(checked)
                .filter((item) => checked[item])
                .join(",")
            )
            .then(() => {
              setConfirmationModal(initConfirmationModal);
              setRefresh(!refresh);
            });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Documents",
      body: `<span>Do you confirm you want to delete the selected documents?</span>`,
    });
  };

  const onDelete = (statement) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        if (statementsContext.type === "REGULAR") {
          programsApi.deleteStatement({ id: statement.id }).then(() => {
            setConfirmationModal(initConfirmationModal);
            setRefresh(!refresh);
          });
        } else {
          programsApi.deleteManualStatement({ id: statement.id }).then(() => {
            setConfirmationModal(initConfirmationModal);
            setRefresh(!refresh);
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Document",
      body: `<span>Do you confirm you want to delete?</span>`,
    });
  };

  const trashIcon = (statement) => (
    <FontAwesomeIcon
      onClick={() => onDelete(statement)}
      icon={faTrashAlt}
      className="cursor-pointer text-danger"
    />
  );

  useEffect(() => {
    setLoading(true);
    const hasSponsorRole = utils.userHasRole(authContext.currentUser, SPONSOR);
    programsApi
      .getAllPrograms(
        hasSponsorRole
          ? { pageSize: 999, userId: authContext.currentUser.id }
          : { pageSize: 999 }
      )
      .then((result) => {
        setPrograms(result.data);
        setLoading(false);
      });
    fundsApi.getAllFundQuarters({}).then((result) => {
      if (result.data.length) {
        const distributed = result.data.filter((f) => f.fundsDistributed);
        if (distributed.length) {
          setStatementsContext({
            year: distributed[0].year,
            quarter: distributed[0].quarter,
          });
        }
      }
    });
  }, [authContext.currentUser, setStatementsContext]);

  useEffect(() => {
    setLoading(true);
    if (statementsContext.type === "MANUAL") {
      programsApi
        .getAllManualStatements(
          {
            programId: statementsContext.programId,
            year: statementsContext.year,
            quarter: statementsContext.quarter,
            userId: statementsContext.userId,
            search: statementsContext.search,
            page: statementsContext.page - 1,
            pageSize: getSizePerPage(statementsContext.sizePerPage),
            sortBy: statementsContext.sortBy,
            direction: statementsContext.direction,
          },
          ROLE_ADMIN
        )
        .then((result) => {
          setStatements(result);
          setLoading(false);
        });
    } else {
      programsApi
        .getAllStatements(
          {
            programId: statementsContext.programId,
            year: statementsContext.year,
            quarter: statementsContext.quarter,
            userId: statementsContext.userId,
            search: statementsContext.search,
            page: statementsContext.page - 1,
            pageSize: getSizePerPage(statementsContext.sizePerPage),
            sortBy: statementsContext.sortBy,
            direction: statementsContext.direction,
          },
          ROLE_ADMIN
        )
        .then((result) => {
          setStatements(result);
          setLoading(false);
        });
    }
  }, [
    statementsContext.type,
    statementsContext.programId,
    statementsContext.year,
    statementsContext.quarter,
    statementsContext.userId,
    statementsContext.search,
    statementsContext.sortBy,
    statementsContext.direction,
    statementsContext.sizePerPage,
    statementsContext.page,
    refresh,
  ]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <div className="p-0 d-flex flex-column align-items-start">
            <span>Statements</span>
          </div>
          <div className="p-0 d-flex align-items-center col-6 px-0 justify-content-end">
            <CustomInput
              id="typeSelect"
              type="select"
              name="typeSelect"
              onChange={(event) => {
                setChecked({});
                setStatementsContext({ type: event.currentTarget.value });
              }}
              value={statementsContext.type}
            >
              <option value={"REGULAR"}>Regular</option>
              <option value={"MANUAL"}>Manual</option>
            </CustomInput>
            <CustomInput
              className="ml-4"
              id="quarterSelect"
              type="select"
              name="quarterSelect"
              onChange={(event) => {
                setChecked({});
                setStatementsContext({
                  quarter: event.currentTarget.value,
                  page: 1,
                  search: "",
                });
              }}
              value={statementsContext.quarter}
            >
              <option value={""}>Any Quarter</option>
              {quarters?.map((quarter) => (
                <option key={quarter} value={quarter}>
                  {`Q${quarter}`}
                </option>
              ))}
            </CustomInput>
            <CustomInput
              className="ml-4"
              id="yearSelect"
              type="select"
              name="yearSelect"
              onChange={(event) => {
                setChecked({});
                setStatementsContext({ year: event.currentTarget.value });
              }}
              value={statementsContext.year}
            >
              <option value={""}>Any Year</option>
              {years?.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </CustomInput>
            <CustomInput
              className="ml-4"
              id="programSelect"
              type="select"
              name="programSelect"
              onChange={(event) => {
                setChecked({});
                setStatementsContext({ programId: event.currentTarget.value });
              }}
              value={statementsContext.programId || ""}
            >
              <option value={""}>Any Program</option>
              {(programs || []).map((program, i) => (
                <option key={i} value={program.id}>
                  {program.name}
                </option>
              ))}
            </CustomInput>
            <Button
              className="flex-shrink-0 ml-4"
              color="primary"
              onClick={() => {
                setChecked({});
                setDocumentsUploadFlow({
                  ...initialState,
                  active: true,
                  step: INVESTOR_STATEMENTS_UPLOAD_STEP,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Upload Statements</span>
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="col-4 px-0 mr-3" size="m">
                  <Input
                    maxLength="50"
                    value={statementsContext.search}
                    onChange={(evt) => {
                      setChecked({});
                      setStatementsContext({
                        search: evt.target.value,
                        page: 1,
                      });
                    }}
                    className="border-0"
                    placeholder="Search for.."
                  />
                </InputGroup>
                <div className="d-flex align-items-center">
                  {someChecked ? (
                    <Button
                      size="sm"
                      className="rounded mr-2"
                      color="danger"
                      onClick={onBulkDelete}
                    >
                      Delete Selected
                    </Button>
                  ) : null}
                  <small className="text-muted mr-2">
                    Showing {statements?.count} statements
                  </small>
                  <Button
                    size="sm"
                    className="mr-2 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => {
                      setChecked({});
                      setRefresh(!refresh);
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : statements?.data?.length ? (
                <AdvanceTableWrapper
                  columns={TABLE_COLUMNS}
                  data={statements?.data || []}
                  sortable
                  onSort={onSort}
                  pageSize={getSizePerPage(statementsContext.sizePerPage)}
                  defaultSort={{
                    sortBy: statementsContext.sortBy,
                    direction: statementsContext.direction,
                  }}
                >
                  <Card className="d-flex">
                    <CardBody className="overflow-auto min-height-300p">
                      {statements?.data?.length ? (
                        <AdvanceTable
                          table
                          isLoading={loading}
                          headerClassName="small"
                          tableProps={{
                            striped: false,
                            className: "mb-0",
                          }}
                        />
                      ) : (
                        <div className="text-center">No results</div>
                      )}
                    </CardBody>
                    <CardFooter>
                      <AdvanceTablePagination
                        totalCount={statements?.count}
                        pageCount={statements?.totalPages}
                        currentPage={statementsContext.page - 1}
                        onPageChange={(page) => setStatementsContext({ page })}
                        pageSize={getSizePerPage(statementsContext.sizePerPage)}
                        onPageSizeChange={(sizePerPage) =>
                          setStatementsContext({ page: 1, sizePerPage })
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                      />
                    </CardFooter>
                  </Card>
                </AdvanceTableWrapper>
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {documentsUploadFlow.active ? <DocumentUploadFlow /> : null}
      {selectedDocument ? (
        <DocumentModalDetails
          document={selectedDocument}
          onClose={() => setSelectedDocument(false)}
        />
      ) : null}
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={() => setPreviewModal({ isOpen: false, file: null })}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Statements;

import React, { useEffect, useState } from "react";
import * as FlexmonsterReact from "react-flexmonster";
import CustomInput from "reactstrap/lib/CustomInput";
import Header from "../../../components/Header";
import { reportsApi } from "../../../services/reportServices";
import * as setReportTable from "./setReportTable";
import Container from "reactstrap/lib/Container";
import Col from "reactstrap/lib/Col";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";
import { programsApi } from "../../../services/programServices";
import Loader from "../../../components/Loader";
import { Button } from "reactstrap";

const customizeToolbar = (toolbar) => {
  var tabs = toolbar.getTabs(); // get all tabs from the toolbar
  toolbar.getTabs = function () {
    delete tabs[0];
    delete tabs[1];
    return tabs;
  };
};

const TABLE = {
  users: {
    api: reportsApi.usersReport,
    table: setReportTable.usersTable,
    title: "Users",
  },
  votes: {
    api: reportsApi.investorsVotesReport,
    table: setReportTable.votesTable,
    title: "Votes",
  },
  returns: {
    api: reportsApi.investorsReturnReport,
    table: setReportTable.returnTable,
    title: "Returns",
  },
  documents: {
    api: reportsApi.investorsDocumentsReport,
    table: setReportTable.documentsTable,
    title: "Documents",
  },
  statements: {
    api: reportsApi.investorsStatementsReport,
    table: setReportTable.statementsTable,
    title: "Statements",
  },
};

const Reports = () => {
  const [currentReport, setCurrentReport] = useState("");
  const [reportData, setReportData] = useState();
  const [program, setProgram] = useState();
  const [programs, setPrograms] = useState();
  const [year, setYear] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    programsApi.getAllPrograms({}).then((programs) => {
      setPrograms(programs.data);
      setLoading(false);
    });
  }, []);

  const onRunReport = () => {
    setLoading(true);
    if (currentReport) {
      const condition = {};
      if (program) {
        condition.programId = program;
      }
      if (year) {
        condition.year = year;
      }
      TABLE[currentReport].api(condition).then((data) => {
        setReportData(TABLE[currentReport].table(data));
        setLoading(false);
      });
    }
  };

  return (
    <Container fluid className="d-flex flex-column height-100">
      <Header>
        <div className="d-flex justify-content-between">
          <div className="flex-grow-1 h1 mb-0">Reports</div>
          <div className="d-flex flex-column align-items-end">
            <div className="d-flex">
              <FormGroup className="flex-shrink-0 d-flex align-items-center ml-2">
                <Label className="col-6 mb-0 text-right" for="options">
                  Report Type
                </Label>
                <CustomInput
                  className="col-6"
                  type="select"
                  name="options"
                  id="options"
                  onChange={(event) => setCurrentReport(event.target.value)}
                >
                  <option value={""}>Choose a report</option>
                  {Object.keys(TABLE).map((key) => (
                    <option value={key} key={key}>
                      {TABLE[key].title}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              {currentReport === "returns" ? (
                <>
                  <FormGroup className="flex-shrink-0 d-flex align-items-center ml-2">
                    <Label className="col-6 mb-0 text-right" for="programs">
                      Program
                    </Label>
                    <CustomInput
                      className="col-6"
                      type="select"
                      name="programs"
                      id="programs"
                      onChange={(event) => setProgram(event.target.value)}
                    >
                      <option value={""}>Any</option>
                      {programs.map((program) => (
                        <option value={program.id} key={program.id}>
                          {program.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                  <FormGroup className="flex-shrink-0 d-flex align-items-center ml-2">
                    <Label className="col-6 mb-0 text-right" for="year">
                      Year
                    </Label>
                    <CustomInput
                      className="col-6"
                      type="select"
                      name="year"
                      id="year"
                      onChange={(event) => setYear(event.target.value)}
                    >
                      <option value={""}>Any</option>
                      {Array.from({ length: 20 }, (v, i) => (
                        <option value={2010 + i} key={2010 + i}>
                          {2010 + i}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </>
              ) : null}
            </div>
            {currentReport ? (
              <Button
                size="sm"
                className="rounded float-right"
                onClick={onRunReport}
              >
                Run Report
              </Button>
            ) : null}
          </div>
        </div>
      </Header>
      <Col className="flex-grow-1 d-flex flex-column px-0">
        {currentReport ? (
          <div className="rounded border col flex-grow-1 px-0 bg-white">
            {loading ? (
              <div className="mt-2">
                <Loader />
              </div>
            ) : (
              <FlexmonsterReact.Pivot
                licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                toolbar={true}
                height="100%"
                beforetoolbarcreated={customizeToolbar}
                report={reportData}
              />
            )}
          </div>
        ) : (
          <h4 className="d-flex justify-content-center">
            Please, choose a report and press run report
          </h4>
        )}
      </Col>
    </Container>
  );
};

export default Reports;

const api_dev = "https://api.dev.vulcanfundsolutions.com/";
const api_beta = "https://api.beta.vulcanfundsolutions.com/";
const api_prod = "https://api.vulcanfundsolutions.com/";
const api_local = "http://localhost:3000/";
let apiURL, documentsBucket;

if (process.env.REACT_APP_ENV === "production") {
  apiURL = api_prod;
  documentsBucket = "greenrock-api-prod-documents";
} else if (process.env.REACT_APP_ENV === "beta") {
  apiURL = api_beta;
  documentsBucket = "greenrock-api-dev-documents";
} else if (process.env.REACT_APP_ENV === "development") {
  apiURL = api_dev;
  documentsBucket = "greenrock-api-dev-documents";
} else {
  apiURL = api_local;
  documentsBucket = "greenrock-api-dev-documents";
}

const config = { apiURL, documentsBucket };

export default config;

/*
https://vulcanfundsolutions.com
https://beta.vulcanfundsolutions.com
https://dev.vulcanfundsolutions.com
*/

import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Button,
  CustomInput,
  InputGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import Papa from "papaparse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisV,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../utils/utils";
import Loader from "../../components/Loader";
import { ACTIONS, useActivities } from "../../providers/activitiesProvider";
import { activityApi } from "../../services/activityServices";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import InformationModal from "../../components/InformationModal";
import DateRangePicker from "../../components/admin/activities/DateRangePicker";
import moment from "moment";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";

const MAX_PAGE_SIZE = 100;

const entities = [
  { name: "Sponsor", id: "sponsor" },
  { name: "Fund", id: "fund" },
  { name: "Fund Asset", id: "fundAsset" },
  { name: "Fund Asset Category", id: "fundAssetCategory" },
  { name: "Fund Asset Transaction", id: "fundAssetTransaction" },
  {
    name: "Fund Asset Transaction Category",
    id: "fundAssetTransactionCategory",
  },
  { name: "Fund Investment", id: "fundInvestment" },
  { name: "Fund Quarter", id: "fundQuarter" },
  { name: "Fund Transaction", id: "fundTransaction" },
  { name: "Program", id: "program" },
  { name: "Setting", id: "setting" },
  { name: "Statement", id: "statement" },
  { name: "User", id: "user" },
];

const actions = [
  { name: "Create", id: "create" },
  { name: "Read", id: "read" },
  { name: "Update", id: "update" },
  { name: "Delete", id: "delete" },
];

const Activities = () => {
  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });

  const [activitiesContext, setActivitiesContext] = useActivities();
  const [activities, setActivities] = useState([]);

  const [selectedActivity, setSelectedActivity] = useState();

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  useEffect(() => {
    if (
      activitiesContext.search ||
      activitiesContext.action ||
      activitiesContext.entity ||
      activitiesContext.fromDate ||
      activitiesContext.toDate
    ) {
      setLoading(true);
      activityApi
        .getActivityLogs({
          page: activitiesContext.page - 1,
          pageSize: getSizePerPage(activitiesContext.sizePerPage),
          search: activitiesContext.search,
          action: activitiesContext.action,
          entity: activitiesContext.entity,
          fromDate: activitiesContext.fromDate
            ? moment(activitiesContext.fromDate).format("YYYY-MM-DD")
            : null,
          toDate: activitiesContext.toDate
            ? moment(activitiesContext.toDate).format("YYYY-MM-DD")
            : null,
        })
        .then((result) => {
          setActivities(result);
          setLoading(false);
        });
    } else {
      setActivities({ data: [], count: 0 });
    }
  }, [activitiesContext, refresh]);

  const onExport = () => {
    if (!activities.data.length) {
      return;
    }
    const CSVdata = activities.data.map((data) => {
      const row = {
        User: data.user.name,
        Activity: data.activity,
        Action: data.action,
        Entity:
          entities.find((entity) => entity.id === data.modelName)?.name ||
          data.modelName,
      };
      if (activitiesContext.action && activitiesContext.entity) {
        Object.keys(data.data).forEach((key) => (row[key] = data.data[key]));
      }
      return row;
    });
    const CSVDownloadData = Papa.unparse(CSVdata, { delimiter: ";" });
    const filename = `activity-export-${utils.formatDate(
      {},
      "DD/MM/YYYY HH.mm.ss"
    )}`;
    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv; charset=utf-8,\ufeff" +
      encodeURIComponent(CSVDownloadData);
    hiddenElement.target = "_self";
    hiddenElement.download = `${filename}.csv`;
    hiddenElement.click();
  };

  const onSeeMore = (activity) => {
    setSelectedActivity(activity);
    setInformationModal({ isOpen: true });
  };

  const rowActions = (activity) => {
    return (
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle color="white">
          <FontAwesomeIcon className="text-primary" icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onSeeMore(activity)}>
            See More
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const COLUMNS = [
    {
      Header: "User",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      Cell: (rowData) => {
        const { user } = rowData.row.original;
        return user.name;
      },
    },
    {
      accessor: "activity",
      Header: "Activity",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
    },
    {
      accessor: "action",
      Header: "Action",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-capitalize",
      },
      disableSortBy: true,
    },
    {
      accessor: "modelName",
      Header: "Entity",
      headerProps: { className: "text-capitalize text-truncate" },
      cellProps: {
        className: "text-capitalize",
      },
      disableSortBy: true,
    },
    {
      accessor: "createdAt",
      Header: "Date & Time",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      Cell: (rowData) => {
        const { createdAt } = rowData.row.original;
        return utils.formatDate(createdAt, "MM/DD/YYYY HH:mm:ss");
      },
    },
    {
      accessor: "id",
      Header: "More",
      headerProps: { className: "text-right text-truncate" },
      cellProps: {
        className: "text-right",
      },
      disableSortBy: true,
      Cell: (rowData) => rowActions(rowData.row.original, loading),
    },
  ];

  const getActivityDetail = (data) => (
    <ListGroup flush className="border rounded border-bottom-0">
      {Object.keys(data).map((key, index) => (
        <ListGroupItem
          key={index}
          className="border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          <strong>{key}:</strong>
          <span>{data[key]}</span>
        </ListGroupItem>
      ))}
      {!Object.keys(data).length ? (
        <ListGroupItem
          className="border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          No data to show
        </ListGroupItem>
      ) : null}
    </ListGroup>
  );

  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <div className="p-0 d-flex align-items-center">
            <span>Activity</span>
          </div>
          <div className="p-0 d-flex align-items-center px-0 justify-content-end flex-grow-1">
            <DateRangePicker
              fromDate={activitiesContext.fromDate || new Date()}
              toDate={activitiesContext.toDate || new Date()}
              onChange={(edge, date) => setActivitiesContext({ [edge]: date })}
            />
            <CustomInput
              id="entitySelect"
              type="select"
              name="entitySelect"
              className="col-2 ml-2"
              onChange={(event) =>
                setActivitiesContext({ entity: event.currentTarget.value })
              }
              value={activitiesContext.entity}
            >
              <option value="">Any Entity</option>
              {entities.map((entity, index) => (
                <option key={index} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </CustomInput>
            <CustomInput
              id="actionSelect"
              type="select"
              name="actionSelect"
              className="col-2 ml-3"
              onChange={(event) =>
                setActivitiesContext({ action: event.currentTarget.value })
              }
              value={activitiesContext.action}
            >
              <option value="">Any Action</option>
              {actions.map((action, index) => (
                <option key={index} value={action.id}>
                  {action.name}
                </option>
              ))}
            </CustomInput>
            <Button
              color="secondary"
              className="ml-4 flex-shrink-0 align-items-center"
              onClick={() => onExport()}
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className="mx-2">Export CSV</span>
            </Button>
          </div>
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader className="pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3 col-4 px-0" size="m">
                  <Input
                    maxLength="50"
                    value={activitiesContext.search}
                    onChange={(evt) =>
                      setActivitiesContext({
                        search: evt.target.value,
                        page: 1,
                      })
                    }
                    className="border-0"
                    placeholder="Search for.."
                  />
                </InputGroup>
                <div className="d-flex align-items-center">
                  <small className="text-muted mr-2">
                    Showing {activities?.count} entries
                  </small>
                  <Button
                    size="sm"
                    className="mr-2 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </div>
            </CardHeader>
            {loading ? (
              <Loader />
            ) : (
              <CardBody>
                {activities?.data?.length ? (
                  <AdvanceTableWrapper
                    columns={COLUMNS}
                    data={activities?.data || []}
                    pageSize={getSizePerPage(activitiesContext.sizePerPage)}
                  >
                    <Card>
                      <CardBody className="overflow-auto min-height-300p">
                        {activities?.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={activities?.count}
                          pageCount={activities?.totalPages}
                          currentPage={activitiesContext.page - 1}
                          onPageChange={(page) =>
                            setActivitiesContext({ page })
                          }
                          pageSize={getSizePerPage(
                            activitiesContext.sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setActivitiesContext({
                              action: ACTIONS.PAGE_SIZE_CHANGE,
                              payload: { sizePerPage },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : activitiesContext.search ||
                  activitiesContext.action ||
                  activitiesContext.entity ||
                  activitiesContext.fromDate ||
                  activitiesContext.toDate ? (
                  <div className="text-center">No results</div>
                ) : (
                  <div className="text-center">
                    Apply filters to see results
                  </div>
                )}
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          rawBody={true}
          title={"Activity Detail"}
          body={getActivityDetail(selectedActivity.data)}
          onClose={() => {
            setInformationModal({ isOpen: false, body: "" });
            setSelectedActivity(null);
          }}
        />
      ) : null}
    </Container>
  );
};

export default Activities;

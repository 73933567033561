import React, { useEffect, useState, useCallback } from "react";

import {
  Col,
  Container,
  Row,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";

import Header from "../../../../components/Header";
import { programsApi } from "../../../../services/programServices";
import {
  faMailBulk,
  faUpload,
  faMoneyCheckAlt,
  faHandshake,
  faListAlt,
  faSearch,
  faEllipsisV,
  faStickyNote,
  faChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import FundInvestmentModal from "../../../../components/admin/FundInvestmentModal";
import ProgramEmailModal from "../../../../components/admin/programDetails/ProgramEmailModal";
import ProgramSurveyModal from "../../../../components/admin/programDetails/programSurvey/ProgramSurveyModal";
import {
  useProgramEmail,
  initialState,
} from "../../../../providers/programEmailProvider";
import InformationModal from "../../../../components/InformationModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { useProgramStatements } from "../../../../providers/programStatementsProvider";
import ProgramEditModal from "../../../../components/admin/programDetails/ProgramEditModal";
import { usePrograms } from "../../../../providers/programsProvider";
import { fundsApi } from "../../../../services/fundServices";
import ProgramStatementsProgress from "../../../../components/admin/ProgramStatementsProgress";
import ProgramCloseDocumentsProgress from "../../../../components/admin/ProgramCloseDocumentsProgress";
import Holdings from "./Holdings";
import Investors from "./Investors";
import PropCo from "./PropCo";
import { useProgramCloseDocuments } from "../../../../providers/programCloseDocumentsProvider";
import { useAuth } from "../../../../providers/authProvider";
import { utils } from "../../../../utils/utils";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../../utils/roles";
import HedgeFundTransactionsModal from "../../../../components/admin/hedgeFundTransactions/HedgeFundTransactionsModal";
import PieHoldings from "./PieHoldings";
import { useEditProgramStatusModal } from "../../../../providers/editProgramStatusModalProvider";
import EditProgramStatus from "../../../../components/admin/programDetails/EditProgramStatus";
import AddDistributionsModal from "../../../../components/admin/programDetails/AddDistributionsModal";
import PieMoic from "./MOIC";
import ManageCeoLetterModal from "../../../../components/ManageCeoLetterModal";

const STATEMENTS_MODAL_DEFAULT_MODE = 1;

const CLOSE_DOCUMENTS_MODAL_DEFAULT_MODE = 1;

const FUND_QUARTER_STATUS_CLOSED = 2;

const INITIAL_MODE = 1;
const PREVIEW_MODE = 6;
const SUCCESS_MODE = 9;

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;

const ProgramDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [programsContext, setProgramsContext] = usePrograms();
  const [editProgramStatusModal, setEditProgramStatusModal] =
    useEditProgramStatusModal();

  const [loading, setLoading] = useState(true);
  const [fundInvestmentModal, setFundInvestmentModal] = useState();
  const [addDistributionsModal, setAddDistributionsModal] = useState();

  const [programEmail, setProgramEmail] = useProgramEmail();

  const [manageCeoLetterModal, setManageCeoLetterModal] = useState();
  const [editProgramModal, setEditProgramModal] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [surveyModal, setSurveyModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [programStatements, setProgramStatements] = useProgramStatements();
  const [programCloseDocuments, setProgramCloseDocuments] =
    useProgramCloseDocuments();

  const setProgramsContextCb = useCallback(
    (data) => setProgramsContext(data),
    [setProgramsContext]
  );

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [authContext] = useAuth();

  const user = authContext.currentUser;

  useEffect(() => {
    setLoading(true);
    programsApi
      .getAllPrograms({
        page: programsContext.page - 1,
        pageSize: programsContext.sizePerPage,
      })
      .then((result) => {
        setProgramsContextCb({ programs: result.data });
        setLoading(false);
      });
  }, [programsContext.page, programsContext.sizePerPage, setProgramsContextCb]);

  useEffect(() => {
    setLoading(true);
    programsApi.getAllPrograms({ id: params.programId }).then((program) => {
      setProgramsContextCb({ program });
      setLoading(false);
    });
  }, [params.programId, setProgramsContextCb, programsContext.refresh]);

  const onDelete = () =>
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setLoading(true);
        programsApi
          .deleteProgram({ id: programsContext.program.id })
          .then(() => {
            setLoading(false);
            navigate("/back/programs");
          });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Program",
      body: `<p>You selected to delete the program ${programsContext.program.name}.</p>
      <p>All data associated with it will be removed except users.</p>
      <span class="font-weight-bold">You confirm you want to continue?</span>`,
      confirmColor: "danger",
    });

  const onModalClose = (refresh) => {
    setFundInvestmentModal(false);
    if (refresh) {
      setLoading(true);
      programsApi.getAllPrograms({ id: params.programId }).then((program) => {
        setProgramsContextCb({ program });
        setLoading(false);
      });
    }
  };

  const onAddDistributions = () => setAddDistributionsModal(true);

  const onUpdateCeoLetter = async () => {
    if (!programsContext.program.published) {
      return setInformationModal({
        isOpen: true,
        title: "Heads Up!",
        body: "The Program is not published yet.",
      });
    }
    const result = await programsApi.getProgramStatements({
      programId: programsContext.program.id,
      pageSize: 1,
      sortBy: "createdAt",
      direction: "DESC",
    });
    if (!result.data.length) {
      return setInformationModal({
        isOpen: true,
        title: "Heads Up!",
        body: "No statements have been sent for the program.",
      });
    } else {
      setManageCeoLetterModal(result.data[0]);
    }
  };

  const onSendStatements = () => {
    if (!programsContext.program.published) {
      return setInformationModal({
        isOpen: true,
        title: "Heads Up!",
        body: "The Program is not published yet.",
      });
    }
    if (
      parseInt(programsContext.program.programTypeId) !== TYPE_PRIVATE_EQUITY &&
      parseInt(programsContext.program.programTypeId) !== TYPE_REAL_ESTATE &&
      parseInt(programsContext.program.programTypeId) !== TYPE_HEDGE_FUND &&
      !programsContext.program.programFundInvestments[0]
    ) {
      return setInformationModal({
        isOpen: true,
        title: "Heads Up!",
        body: "The Program has no investments yet.",
      });
    }
    if (programsContext.program.programFundInvestments?.length) {
      setLoading(true);
      fundsApi
        .getAllFundQuarters({
          fundId:
            programsContext.program.programFundInvestments[0].fundFundInvestment
              .id,
          status: FUND_QUARTER_STATUS_CLOSED,
          pageSize: 1,
        })
        .then((closedFundQuarter) => {
          setLoading(false);
          if (!closedFundQuarter?.count) {
            return setInformationModal({
              isOpen: true,
              title: "Heads Up!",
              body: `The Strategic Fund ${programsContext.program.programFundInvestments[0].fundFundInvestment.year} doesn't have any closed Quarter yet.`,
            });
          }
          const currentQ = closedFundQuarter.data[0];
          if (!currentQ.fundsDistributed) {
            return setInformationModal({
              isOpen: true,
              title: "Heads Up!",
              rawBody: true,
              body: `<div class="text-center">Distibutions have not been created for <strong class="text-underline">Q${currentQ.quarter}-${currentQ.year}</strong></div>`,
            });
          }
          const alreadySent = currentQ.programStatements.find(
            (pStatements) =>
              pStatements.programId === programsContext.program.id
          );
          if (alreadySent) {
            return setConfirmationModal({
              isOpen: true,
              confirmColor: "danger",
              onSubmit: () => {
                setProgramStatements({
                  quarter: currentQ,
                  isActive: true,
                  program: programsContext.program,
                  mode: STATEMENTS_MODAL_DEFAULT_MODE,
                  letter: "",
                  auditReserveAccruedInterest: 0,
                  total: 0,
                  remaining: 0,
                  statements: [],
                });
                setConfirmationModal(initConfirmationModal);
              },
              onClose: () => setConfirmationModal(initConfirmationModal),
              title: `Heads Up!`,
              body: `<p class="text-warning">Please note that an statement has already been sent for <strong class="text-underline">Q${currentQ.quarter}-${currentQ.year}</strong></p>
          <span>You confirm you want to continue and send it again?</span>`,
            });
          }
          setProgramStatements({
            quarter: currentQ,
            isActive: true,
            program: programsContext.program,
            mode: STATEMENTS_MODAL_DEFAULT_MODE,
            letter: "",
            auditReserveAccruedInterest: 0,
            total: 0,
            remaining: 0,
            statements: [],
          });
        });
    } else {
      setProgramStatements({
        isActive: true,
        program: programsContext.program,
        mode: STATEMENTS_MODAL_DEFAULT_MODE,
        letter: "",
        auditReserveAccruedInterest: 0,
        total: 0,
        remaining: 0,
        statements: [],
      });
    }
  };

  const onProgramCloseDocument = () => {
    setProgramCloseDocuments({
      isActive: true,
      program: programsContext.program,
      mode: CLOSE_DOCUMENTS_MODAL_DEFAULT_MODE,
      total: 0,
      remaining: 0,
      closeDocuments: [],
    });
  };

  const onEditModalClose = (program) => {
    if (program) {
      setProgramsContextCb({ program });
    }
    setEditProgramModal(false);
  };

  const onDistributions = () => {
    navigate(`${window.location.pathname}/distributions`);
  };

  const onProgramStatus = () =>
    setEditProgramStatusModal({ isModalOpen: true });

  const onProgramSurvey = () => {
    setSurveyModal({ isOpen: true });
  };

  const onAddTransaction = () => setFundInvestmentModal(true);

  const onProgramEmail = () =>
    setProgramEmail({ id: programsContext.program.id, isActive: true });

  const isStatementInProgress =
    utils.isSingleProgramStatementsGenerationInProgress(programStatements);

  const isCloseDocumentInProgress =
    utils.isSingleProgramCloseDocumentsGenerationInProgress(
      programCloseDocuments
    );
  const onDashboard = () => {
    navigate(`${window.location.pathname}/dashboard`);
  };

  return loading || !programsContext.program ? (
    <Loader />
  ) : programsContext.program ? (
    <Container fluid>
      <Header>
        <div className="d-flex justify-content-between">
          <Col className="col-6 pl-0 d-flex justify-content-between">
            <Row className="d-flex align-items-start col-12">
              <div className="d-flex flex-column px-0 col-9 align-items-start">
                <div className="d-flex align-items-center col-12 px-0">
                  <h1 className="text-truncate mb-0">{`${programsContext.program.name}`}</h1>
                  {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
                    !utils.userHasRole(user, SPONSOR) && (
                      <UncontrolledDropdown className="d-inline-block ml-2">
                        <DropdownToggle color="white">
                          <FontAwesomeIcon
                            className="text-primary"
                            icon={faEllipsisV}
                          />
                        </DropdownToggle>
                        <DropdownMenu bottom={"true"} className="ml-4">
                          <DropdownItem
                            onClick={() => setEditProgramModal(true)}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem onClick={() => onDelete()}>
                            Remove
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                </div>
                {parseInt(programsContext.program.programTypeId) ===
                TYPE_HEDGE_FUND ? (
                  <Badge color={"warning"} pill className="font-size-6 mt-1">
                    Hedge Funds
                  </Badge>
                ) : parseInt(programsContext.program.programTypeId) ===
                  TYPE_PRIVATE_EQUITY ? (
                  <Badge color={"warning"} pill className="font-size-6 mt-1">
                    Private Equity
                  </Badge>
                ) : parseInt(programsContext.program.programTypeId) ===
                  TYPE_REAL_ESTATE ? (
                  <Badge color={"warning"} pill className="font-size-6 mt-1">
                    Real Estate Investment
                  </Badge>
                ) : (
                  <Badge color={"warning"} pill className="font-size-6 mt-1">
                    Alternative Investment
                  </Badge>
                )}
                {programsContext.program.published ? (
                  <Badge color={"success"} pill className="font-size-6 mt-1">
                    Published
                  </Badge>
                ) : (
                  <Badge color={"danger"} pill className="font-size-6 mt-1">
                    Not Published
                  </Badge>
                )}
                {programsContext.program.programTypeId ===
                  TYPE_PRIVATE_EQUITY ||
                programsContext.program.programTypeId === TYPE_REAL_ESTATE ? (
                  <Badge color={"secondary"} pill className="font-size-6 mt-1">
                    {programsContext.program.programStatus?.name ||
                      "Status not set"}
                  </Badge>
                ) : null}
              </div>
              {programsContext.program.sponsor?.webLogo ? (
                <div className="col-3 d-flex flex-column">
                  <div
                    className="sponsor-image-program-detail rounded max-width-200"
                    style={{
                      backgroundImage: `url(${programsContext.program.sponsor.webLogo})`,
                    }}
                    alt={programsContext.program.sponsor.name}
                  ></div>
                  <div className="text-center small mt-1">
                    {programsContext.program.sponsor.name}
                  </div>
                </div>
              ) : null}
            </Row>
          </Col>
          {!utils.userHasRole(user, CUSTOMER_SUPPORT) ? (
            <Col className="col-6 pr-0">
              {programsContext.program.programTypeId !== TYPE_PRIVATE_EQUITY &&
              programsContext.program.programTypeId !== TYPE_REAL_ESTATE ? (
                <Button
                  color="success"
                  className="m-2"
                  onClick={onAddTransaction}
                >
                  <FontAwesomeIcon icon={faHandshake} />
                  <span className="ml-2">
                    {programsContext.program.programFundInvestments[0]
                      ? "Edit"
                      : "Add"}
                    {parseInt(programsContext.program.programTypeId) ===
                    TYPE_HEDGE_FUND
                      ? " Inv. Transactions"
                      : " Transaction"}
                  </span>
                </Button>
              ) : null}
              <Button
                color="tertiary"
                className="m-2"
                onClick={onSendStatements}
                disabled={isStatementInProgress || isCloseDocumentInProgress}
              >
                <FontAwesomeIcon icon={faUpload} />
                <span className="ml-2">Send Statements</span>
              </Button>
              <Button
                color="primary"
                className="m-2"
                onClick={onUpdateCeoLetter}
              >
                <FontAwesomeIcon icon={faStickyNote} />
                <span className="ml-2">Manage CEO Letter</span>
              </Button>
              {programsContext.program.programTypeId === TYPE_PRIVATE_EQUITY ||
              programsContext.program.programTypeId === TYPE_REAL_ESTATE ? (
                <Button
                  color="success"
                  className="m-2"
                  onClick={onAddDistributions}
                >
                  <FontAwesomeIcon icon={faMoneyCheckAlt} />
                  <span className="ml-2">Add Distributions</span>
                </Button>
              ) : null}
              {programsContext.program.programTypeId === TYPE_PRIVATE_EQUITY ||
              programsContext.program.programTypeId === TYPE_REAL_ESTATE ? (
                <Button
                  color="danger"
                  className="m-2"
                  onClick={onProgramStatus}
                >
                  <FontAwesomeIcon icon={faSearch} />
                  <span className="ml-2">Program Status</span>
                </Button>
              ) : null}
              <Button
                color="secondary"
                className="m-2"
                onClick={onDistributions}
              >
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
                <span className="ml-2">Distributions</span>
              </Button>
              <Button color="info" className="m-2" onClick={onProgramEmail}>
                <FontAwesomeIcon icon={faMailBulk} />
                <span className="ml-2">Email Program</span>
              </Button>
              <Button
                color="warning"
                className="m-2"
                onClick={onProgramCloseDocument}
                disabled={isStatementInProgress || isCloseDocumentInProgress}
              >
                <FontAwesomeIcon icon={faMailBulk} />
                <span className="ml-2">Program Close Documents</span>
              </Button>
              <Button
                color="primary"
                className="m-2"
                onClick={onProgramSurvey}
                disabled={isStatementInProgress || isCloseDocumentInProgress}
              >
                <FontAwesomeIcon icon={faListAlt} />
                <span className="ml-2">Votes</span>
              </Button>
              <Button color="secondary" className="m-2" onClick={onDashboard}>
                <FontAwesomeIcon icon={faChalkboard} />
                <span className="ml-2">Program Dashboard</span>
              </Button>
            </Col>
          ) : null}
        </div>
        <div className="d-flex flex-column align-items-start">
          <span>
            {programsContext.program.programInvestments.length} Partners •
            Closed{" "}
            {moment(
              programsContext.program.closedOnDate ||
                programsContext.program.createdAt
            ).format("MM/DD/YY")}
          </span>
        </div>
      </Header>
      <Row className="mx-0 justify-content-between">
        <div className="pr-2 col-6 pl-0">
          <Investors />
        </div>
        <div className="pl-2 col-6 pr-0">
          <ProgramStatementsProgress
            progressData={programStatements}
            onAbort={() => {
              programStatements.socket.disconnect();
              setProgramStatements({
                isActive: false,
                socket: null,
                mode: INITIAL_MODE,
              });
            }}
            onPreview={() => {
              setProgramStatements({ socket: null, mode: PREVIEW_MODE });
            }}
            onFinish={() => {
              setProgramStatements({
                sisActive: false,
                ocket: null,
                mode: SUCCESS_MODE,
              });
            }}
          />
          <ProgramCloseDocumentsProgress />
          {programsContext.program.programTypeId !== TYPE_PRIVATE_EQUITY &&
          programsContext.program.programTypeId !== TYPE_REAL_ESTATE ? (
            <>
              <Holdings />
              {parseInt(programsContext.program.programTypeId) ===
                TYPE_HEDGE_FUND &&
              programsContext.program.programFundInvestments?.length ? (
                <PieHoldings />
              ) : null}
              {parseInt(programsContext.program.programTypeId) ===
              TYPE_HEDGE_FUND ? null : (
                <PropCo />
              )}
            </>
          ) : (
            <PieMoic />
          )}
        </div>
      </Row>
      {fundInvestmentModal ? (
        parseInt(programsContext.program.programTypeId) === TYPE_HEDGE_FUND ? (
          <HedgeFundTransactionsModal
            program={programsContext.program}
            onClose={onModalClose}
          />
        ) : (
          <FundInvestmentModal
            program={programsContext.program}
            onClose={onModalClose}
          />
        )
      ) : programEmail.isActive ? (
        <ProgramEmailModal
          program={programsContext.program}
          onClose={() => setProgramEmail({ isActive: false, ...initialState })}
        />
      ) : surveyModal.isOpen ? (
        <ProgramSurveyModal
          program={programsContext.program}
          onClose={() => setSurveyModal({ isOpen: false })}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : editProgramModal ? (
        <ProgramEditModal
          program={programsContext.program}
          onClose={onEditModalClose}
        />
      ) : editProgramStatusModal.isModalOpen ? (
        <EditProgramStatus />
      ) : addDistributionsModal ? (
        <AddDistributionsModal
          onClose={() => setAddDistributionsModal(false)}
        />
      ) : manageCeoLetterModal ? (
        <ManageCeoLetterModal
          programStatement={manageCeoLetterModal}
          onClose={() => setManageCeoLetterModal(false)}
        />
      ) : null}
    </Container>
  ) : null;
};

export default ProgramDetails;

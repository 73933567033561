import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { fundsApi } from "../../../services/fundServices";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../components/Loader";
import FundCreationModal from "../../../components/admin/FundCreationModal";
import { utils } from "../../../utils/utils";
import { useAuth } from "../../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../utils/roles";
import { sponsorsApi } from "../../../services/sponsorServices";
import { useNavigate } from "react-router-dom";

const SF_TAB = "SF_TAB";
const PE_TAB = "PE_TAB";

const Funds = () => {
  const navigate = useNavigate();

  const [funds, setFunds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(SF_TAB);
  const [modalOpen, setModalOpen] = useState(false);
  const [authContext] = useAuth();

  const user = authContext.currentUser;

  const onModalClose = (refresh) => {
    setModalOpen(false);
    if (refresh) {
      refreshDataCb();
    }
  };

  const refreshDataCb = useCallback(() => {
    if (utils.userHasRole(authContext.currentUser, SPONSOR)) {
      setLoading(true);
      sponsorsApi
        .getSponsors({ userId: authContext.currentUser.id })
        .then((response) => {
          setLoading(false);
          const [sponsor] = response.data;
          getFunds({ sponsorId: sponsor.id });
        });
    } else {
      getFunds();
    }
  }, [authContext.currentUser]);

  const getFunds = (query = {}) => {
    setLoading(true);
    fundsApi.getFunds(query).then((result) => {
      setFunds(result.data.sort((x, y) => y.year - x.year));
      setLoading(false);
    });
  };

  useEffect(() => {
    refreshDataCb();
  }, [refreshDataCb]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <span>Funds</span>
          {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
            !utils.userHasRole(user, SPONSOR) && (
              <Button color="primary" onClick={() => setModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} />
                <span className="ml-2">Create New Fund</span>
              </Button>
            )}
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          {loading ? (
            <Loader />
          ) : funds.length ? (
            <Col>
              <Row className="mx-0 border-bottom">
                <Nav
                  tabs
                  className="d-flex justify-content-around col-12 px-0 border-0 funds-tabs"
                >
                  <NavItem
                    className={`${
                      tab === SF_TAB
                        ? "active font-weight-bold bg-white"
                        : "text-muted"
                    } cursor-pointer flex-grow-1 text-center py-1`}
                    onClick={() => setTab(SF_TAB)}
                  >
                    <NavLink to="#">Strategic Funds</NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      tab === PE_TAB
                        ? "active font-weight-bold bg-white"
                        : "text-muted"
                    } cursor-pointer flex-grow-1 text-center py-1`}
                    onClick={() => setTab(PE_TAB)}
                  >
                    <NavLink to="#">Hedge Fund Investments</NavLink>
                  </NavItem>
                </Nav>
              </Row>
              <Row className="mx-0">
                <TabContent activeTab={tab} className="col-12 px-0">
                  <TabPane tabId={SF_TAB}>
                    <Card>
                      <CardBody>
                        <ListGroup flush>
                          {!funds?.filter((f) => !f.isPrivateEquity)?.length ? (
                            <ListGroupItem
                              className="pb-2 border-bottom d-flex justify-content-between text-center"
                              tag="div"
                            >
                              No funds to show
                            </ListGroupItem>
                          ) : null}
                          {funds
                            .filter((f) => !f.isPrivateEquity)
                            .map((fund, i) => (
                              <ListGroupItem
                                onClick={() =>
                                  navigate(`/back/funds/${fund.id}`)
                                }
                                key={i}
                                action
                                className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                                tag="div"
                              >
                                <div>{`${fund.name} ${fund.year}`}</div>
                                <span>
                                  {utils.formatCurrency(fund.balance)}
                                </span>
                              </ListGroupItem>
                            ))}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId={PE_TAB}>
                    <Card>
                      <CardBody>
                        <ListGroup flush>
                          {!funds?.filter((f) => f.isPrivateEquity)?.length ? (
                            <ListGroupItem
                              className="pb-2 border-bottom d-flex justify-content-between text-center"
                              tag="div"
                            >
                              No funds to show
                            </ListGroupItem>
                          ) : null}
                          {funds
                            .filter((f) => f.isPrivateEquity)
                            .map((fund, i) => (
                              <ListGroupItem
                                onClick={() =>
                                  navigate(`/back/funds/${fund.id}`)
                                }
                                key={i}
                                action
                                className="cursor-pointer pb-2 border-bottom d-flex justify-content-between"
                                tag="div"
                              >
                                <div>{`${fund.name} ${fund.year}`}</div>
                                <span>
                                  {utils.formatCurrency(fund.balance)}
                                </span>
                              </ListGroupItem>
                            ))}
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              </Row>
            </Col>
          ) : (
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0 text-center">
                  No Funds
                </CardTitle>
              </CardHeader>
            </Card>
          )}
        </Col>
      </Row>
      {modalOpen ? (
        <FundCreationModal funds={funds} onClose={onModalClose} />
      ) : null}
    </Container>
  );
};

export default Funds;

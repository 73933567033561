import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
  Form,
  UncontrolledAlert,
  CustomInput,
} from "reactstrap";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFundAssetCreationModal } from "../../../../providers/fundAssetCreationModalProvider";
import { fundsApi } from "../../../../services/fundServices";
import CurrencyInput from "react-currency-input-field";
import { useFundContext } from "../../../../providers/fundsProvider";
import Loader from "../../../Loader";
import { utils } from "../../../../utils/utils";

const CATEGORY_CREATION_MODE = 2;
const MAX_VALUE = 999999999;

const FundAssetCreationModal = () => {
  const [fundAssetCreationModal, setFundAssetCreationModal] =
    useFundAssetCreationModal();
  const [errorExists, setErrorExists] = useState(false);
  const [errorCapital, setErrorCapital] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setFundContext] = useFundContext();
  const unallocatedFundCapital = fundAssetCreationModal.fund.balance;

  const onDismiss = () => {
    if (errorExists) {
      setFundAssetCreationModal({ name: "" });
    }
    if (errorCapital) {
      setFundAssetCreationModal({ capital: "" });
    }
    setErrorExists(false);
    setErrorCapital(false);
  };

  const onNameChange = (event) => {
    setErrorExists(
      fundAssetCreationModal.fund.assets.find(
        (fa) => fa.name === event.currentTarget.value
      ) !== undefined
    );
    setFundAssetCreationModal({ name: event.currentTarget.value });
  };

  const onCapitalChange = (value) => {
    setErrorCapital(unallocatedFundCapital < value);
    if (!value || value <= MAX_VALUE) {
      setFundAssetCreationModal({ capital: value });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    fundsApi
      .createFundAsset({
        name: fundAssetCreationModal.name,
        capital: fundAssetCreationModal.capital,
        fundId: fundAssetCreationModal.fund.id,
        fundAssetCategoryId: fundAssetCreationModal.fundAssetCategoryId,
      })
      .then(() => {
        setLoading(true);
        fundsApi
          .getFunds({ id: fundAssetCreationModal.fund.id }, "ADMIN")
          .then((fund) => {
            setLoading(false);
            setFundAssetCreationModal({ isModalOpen: false });
            setFundContext({ fund });
          });
      });
  };
  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setFundAssetCreationModal({ isModalOpen: false })}
    >
      &times;
    </Button>
  );
  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Create New Fund Asset</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Asset Name
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter the name.."
                    onChange={onNameChange}
                    value={fundAssetCreationModal.name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="d-flex align-items-center">
                <Label
                  sm={4}
                  className="text-sm-left d-flex flex-column justify-content-center"
                >
                  <span>Asset Initial Capital</span>
                  <small className="font-size-6 text-warning">
                    Max {utils.formatCurrency(unallocatedFundCapital, 5)}
                  </small>
                </Label>
                <Col sm={8}>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    required={true}
                    allowNegativeValue={false}
                    maxLength={20}
                    className="form-control"
                    placeholder="Enter the initial capital.."
                    value={fundAssetCreationModal.capital}
                    onValueChange={onCapitalChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-sm-left">
                  Category
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="categorySelect"
                    type="select"
                    name="categorySelect"
                    onChange={(event) =>
                      setFundAssetCreationModal({
                        fundAssetCategoryId: event.currentTarget.value,
                      })
                    }
                    value={fundAssetCreationModal.fundAssetCategoryId}
                  >
                    <option value="">Select...</option>
                    {fundAssetCreationModal.categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </CustomInput>
                  <Button
                    color={"info"}
                    size={"sm"}
                    className="float-left mt-2 rounded"
                    onClick={() =>
                      setFundAssetCreationModal({
                        mode: CATEGORY_CREATION_MODE,
                      })
                    }
                  >
                    New Category
                  </Button>
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={onDismiss}
              isOpen={errorExists || errorCapital}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>
                  {errorExists
                    ? "Name already in use"
                    : `Asset capital can't be higher than the Fund unallocated capital of ${utils.formatCurrency(
                        unallocatedFundCapital
                      )}`}
                </span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button
                color={"secondary"}
                onClick={() =>
                  setFundAssetCreationModal({ isModalOpen: false })
                }
              >
                Cancel
              </Button>{" "}
              <Button
                disabled={errorExists || errorCapital}
                color={"primary"}
                type={"submit"}
              >
                Confirm
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundAssetCreationModal;

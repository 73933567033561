import React, { useEffect, useState } from "react";

import { fundsApi } from "../../../../../../services/fundServices";
import {
  useFundTransactionModal,
  initialState,
} from "../../../../../../providers/fundTransactionModalProvider";
import ConfirmationModal from "../../../../../ConfirmationModal";
import { useFundContext } from "../../../../../../providers/fundsProvider";
import Loader from "../../../../../Loader";
import CategoryCreationModal from "../../../../CategoryCreationModal";
import FundQuarterTransactionDetailModal from "./FundQuarterTransactionDetailModal";
import FundQuarterTransactionCreationModal from "./FundQuarterTransactionCreationModal";

const CREATION_MODE = 1;
const DETAIL_MODE = 4;
const DELETE_CONFIRMATION_MODE = 5;
const CATEGORY_CREATION_MODE = 2;

const FundQuarterTransactionModal = () => {
  const [loading, setLoading] = useState(false);
  const [fundTransactionModal, setFundTransactionModal] =
    useFundTransactionModal();

  const [fundContext, setFundContext] = useFundContext();
  const fund = fundContext.fund;

  useEffect(() => {
    fundsApi.getFundTransactionCategories({}).then((result) => {
      setFundTransactionModal({ categories: result.data });
      setLoading(false);
    });
  }, [setFundTransactionModal]);

  const onCategorySubmit = (data) => {
    setLoading(true);
    fundsApi.createFundTransactionCategory(data).then((result) => {
      setLoading(false);
      setFundTransactionModal({
        categories: [...fundTransactionModal.categories, result],
        fundTransactionCategoryId: result.id,
        mode: CREATION_MODE,
      });
    });
  };

  const onDeleteFundTransaction = () => {
    setLoading(true);
    fundsApi
      .deleteFundTransaction({ id: fundTransactionModal.id, fundId: fund?.id })
      .then(() =>
        fundsApi
          .getFundQuarters({
            fundId: fund.id,
            id: fundTransactionModal.fundQuarterId,
          })
          .then((selectedFundQuarter) => {
            setLoading(false);
            setFundTransactionModal({ ...initialState });
            let fundQuarters = fundContext.fundQuarters;
            fundQuarters.splice(
              fundQuarters.findIndex(
                (fq) => fq.id === fundTransactionModal.fundQuarterId
              ),
              1,
              selectedFundQuarter
            );
            setFundContext({ selectedFundQuarter, fundQuarters });
          })
      );
  };

  switch (fundTransactionModal.mode) {
    case CREATION_MODE:
      return <FundQuarterTransactionCreationModal />;
    case CATEGORY_CREATION_MODE:
      return (
        <CategoryCreationModal
          loading={loading}
          onSubmit={onCategorySubmit}
          categories={fundTransactionModal.categories}
          onClose={() => setFundTransactionModal({ mode: CREATION_MODE })}
        />
      );
    case DETAIL_MODE:
      return <FundQuarterTransactionDetailModal />;
    case DELETE_CONFIRMATION_MODE:
      const data = {
        onSubmit: onDeleteFundTransaction,
        onClose: () => setFundTransactionModal({ mode: DETAIL_MODE }),
        title: "Remove Transaction",
        body: "Do you confirm you want to remove the transaction?",
      };
      return loading ? <Loader size="sm" /> : <ConfirmationModal {...data} />;
    default:
      return null;
  }
};

export default FundQuarterTransactionModal;

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Button,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import "./CreateAnswer.scss";
import { surveyApi } from "../../services/surveyServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../../components/InformationModal";
import brand from "../../assets/img/brand.png";

const MAX_LENGTH_OPTION = 1000;

const CreateAnswer = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [responses, setResponses] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [survey, setSurvey] = useState();

  useEffect(() => {
    if (token) {
      surveyApi
        .getSurveyByToken(token)
        .then((response) => setSurvey(response.survey))
        .catch((err) =>
          setInformationModal({
            isOpen: true,
            title: "Error",
            body: err.response.data,
          })
        );
    }
  }, [token]);

  useEffect(() => {
    if (survey && survey.questions) {
      let responsesObj = {};
      survey.questions.map((q) => (responsesObj[q.id] = { content: "" }));
      setResponses(responsesObj);
    }
  }, [survey]);

  const onInputChange = (e, questionId) => {
    const newReponses = responses;
    if (e.currentTarget.value.length > MAX_LENGTH_OPTION) {
      e.currentTarget.value = e.currentTarget.value.substring(
        0,
        MAX_LENGTH_OPTION - 1
      );
    }
    newReponses[questionId].content = e.currentTarget.value;
    setResponses(newReponses);
  };

  const createAnswer = (e) => {
    e.preventDefault();
    surveyApi
      .createAnswer({
        token,
        questionsAnswered: responses,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const backToHome = () => navigate("/");

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={backToHome}
      closeButtonText={"Back to Home"}
    />
  ) : (
    <Container fluid className="answerContainer">
      <div className="logo-container py-4">
        <img src={brand} className="rounded p-0 col-12" alt={"Vulcan Fund"} />
      </div>
      <div className="program-container m-4 pl-2">
        <h3>{survey?.program?.name}</h3>
      </div>
      <div className="createAnswerContainer">
        <div>
          {success ? (
            <div className="d-flex align-items-center justify-content-center flex-column my-5">
              <div>
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <div>
                <span>The vote was sent successfully!</span>
              </div>
            </div>
          ) : error ? (
            <div className="d-flex align-items-center justify-content-center flex-column my-5">
              <div>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div>
                <span>Error submitting vote</span>
              </div>
            </div>
          ) : survey ? (
            <>
              <div className="m-3 titleContainer">
                <h3>{survey.name}</h3>
              </div>
              <Form onSubmit={createAnswer}>
                {survey.questions.map((question, i) => (
                  <FormGroup row key={i}>
                    <Label
                      sm={12}
                      className="text-sm-left text-break word-wrap"
                      for="options"
                      dangerouslySetInnerHTML={{ __html: question.content }}
                    />
                    <Col sm={12}>
                      {parseInt(question.questionTypeId) === 1 ? (
                        <>
                          <Input
                            required={true}
                            type="select"
                            name="options"
                            id="options"
                            onChange={(e) => onInputChange(e, question.id)}
                          >
                            <option value="">Select</option>
                            {question.options.map((option, i) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Input>
                        </>
                      ) : (
                        <Input
                          required={true}
                          type="textarea"
                          onChange={(e) => onInputChange(e, question.id)}
                          placeholder="Enter the answer"
                        />
                      )}
                    </Col>
                  </FormGroup>
                ))}
                <Col>
                  <Row className="justify-content-center">
                    <Button disabled={false} color="primary" type="submit">
                      Send
                    </Button>
                  </Row>
                </Col>
              </Form>
            </>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default CreateAnswer;

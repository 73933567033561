import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  UncontrolledAlert,
  Row,
} from "reactstrap";
import Loader from "../Loader";
import Input from "reactstrap/lib/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { usersApi } from "../../services/userServices";
import { useUsers } from "../../providers/usersProvider";
import { emailRegex } from "../../utils/regex";

const STATUS_ACTIVE = "3";

const CreatePrinterModal = ({ users, onClose }) => {
  const [usersContext, setUsersContext] = useUsers();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [nameExists, setNameExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const onNameChange = (event) => {
    const name = event.currentTarget.value;
    setNameExists(users.find((u) => u.name === name) !== undefined);
    setName(name);
  };

  const onEmailChange = (event) => {
    const email = event.currentTarget.value;
    setEmailExists(users.find((u) => u.email === email) !== undefined);
    setEmail(email);
  };

  const onDismiss = () => {
    if (nameExists) {
      setName("");
      setNameExists(false);
    }
    if (emailExists) {
      setEmail("");
      setEmailExists(false);
    }
  };

  const doSubmit = (e) => {
    e.preventDefault();
    if (!emailRegex.test(email.trim().toLowerCase())) {
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
    }
    setLoading(true);
    usersApi
      .createUser({
        name,
        email,
        password,
        isPrinter: true,
        status: STATUS_ACTIVE,
      })
      .then(() => {
        setLoading(true);
        setUsersContext({ printerUsers: { ...usersContext.printerUsers } });
        onClose();
      });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={() => onClose()}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn} className="d-flex justify-content-between">
        Create Printer User
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Name
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    placeholder="Enter the name.."
                    onChange={onNameChange}
                    value={name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Email
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    type="email"
                    placeholder="Enter the email.."
                    onChange={onEmailChange}
                    value={email}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-sm-left">
                  Password
                </Label>
                <Col sm={8}>
                  <Input
                    required={true}
                    maxLength="50"
                    type="password"
                    placeholder="Enter the password.."
                    onChange={(event) => setPassword(event.currentTarget.value)}
                    value={password}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <UncontrolledAlert
              toggle={onDismiss}
              isOpen={nameExists || emailExists || invalidEmail}
              color="warning"
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span>{`This ${nameExists ? "name" : "email"} ${
                  invalidEmail ? "is invalid" : "is already in use"
                }`}</span>
              </div>
            </UncontrolledAlert>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                disabled={nameExists || emailExists}
                color={"primary"}
                type="submit"
              >
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreatePrinterModal;

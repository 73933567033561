import moment from "moment";
import { CUSTOMER_SUPPORT, PRINTER } from "./roles";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";

export const USER_ROLE_ADMIN = 1;
export const USER_ROLE_ADVISOR = 2;
export const USER_ROLE_INVESTOR = 3;
export const USER_ROLE_STAKEHOLDER = 4;
export const USER_ROLE_SPONSOR = 5;
export const USER_ROLE_CUSTOMER_SUPPORT = 6;
export const USER_ROLE_PRINTER = 7;

const FUND_QUARTER_STATUS_CLOSED = "2";

const VIEW_MODE_LIMITED = "1";
const VIEW_MODE_COMPLETE = "2";

const STATEMENTS_MODAL_PREGENERATION_MODE = 2;
const STATEMENTS_MODAL_GENERATION_MODE = 3;
const STATEMENTS_MODAL_PREUPLOAD_MODE = 5;
const STATEMENTS_MODAL_UPLOAD_MODE = 6;

const CLOSE_DOCUMENTS_MODAL_PREGENERATION_MODE = 2;
const CLOSE_DOCUMENTS_MODAL_GENERATION_MODE = 3;
const CLOSE_DOCUMENTS_MODAL_PREUPLOAD_MODE = 5;
const CLOSE_DOCUMENTS_MODAL_UPLOAD_MODE = 6;

const CHART_COLORS = [
  "#1abc9c",
  "#16a085",
  "#2ecc71",
  "#27ae60",
  "#3498db",
  "#2980b9",
  "#9b59b6",
  "#8e44ad",
  "#34495e",
  "#2c3e50",
  "#f1c40f",
  "#f39c12",
  "#e67e22",
  "#d35400",
  "#e74c3c",
  "#c0392b",
  "#ecf0f1",
  "#bdc3c7",
  "#95a5a6",
  "#7f8c8d",
];

const INIT_MODAL_DATA = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

export const utils = {
  sidebarFunds: (programs) =>
    Object.values(
      (programs || []).reduce((funds, program) => {
        const fund = program.programFundInvestments[0]?.fundFundInvestment;
        if (!fund) {
          return funds;
        }
        if (!funds[fund.id]) {
          funds[fund.id] = fund;
        }
        return funds;
      }, {})
    ),
  sidebarSponsors: (programs) =>
    (programs || []).reduce((sponsors, program) => {
      let sponsor = sponsors.find((e) => e.id === program.sponsor.id);
      if (sponsor) {
        if (sponsor.children[program.year]) {
          sponsor.children[program.year].push({
            id: program.id,
            path: `/portal/sponsor/${program.sponsor.id}/program/${program.id}`,
            name: program.name,
          });
        } else {
          sponsor.children[program.year] = [
            {
              id: program.id,
              path: `/portal/sponsor/${program.sponsor.id}/program/${program.id}`,
              name: program.name,
            },
          ];
        }
        return sponsors;
      }
      return [
        ...sponsors,
        {
          id: program.sponsor.id,
          path: `/portal/sponsor/${program.sponsor.id}`,
          name: program.sponsor.name,
          webLogo: program.sponsor.webLogo,
          icon: program.sponsor.icon ? (
            <div
              className="figure img-thumbnail cursor-pointer mx-1"
              style={{
                backgroundPosition: "center",
                backgroundImage: `url(${program.sponsor.icon})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
                alignSelf: "center",
                height: "25px",
                width: "25px",
              }}
            />
          ) : (
            faHandshake
          ),
          children: {
            [program.year]: [
              {
                id: program.sponsor.id,
                path: `/portal/sponsor/${program.sponsor.id}/program/${program.id}`,
                name: program.name,
              },
            ],
          },
        },
      ];
    }, []),
  generateQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${encodeURIComponent(data[d])}`)
      .join("&"),
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number) =>
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 20,
    }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "MM/DD/YYYY") => moment(date).format(format),
  nameInitials: (name) => {
    if (!name?.length) {
      return "";
    }
    if (!/(.+)( )+(.+)/.test(name)) {
      return `${name[0]}${name[1] || ""}`;
    }
    const [, , , lastname] = name.match(/(.+)( )+(.+)/);
    return `${name[0]}${lastname[0]}`;
  },
  getSessionData: (currentUser) => {
    const hasSessionOwner = currentUser?.fakeLogin;
    let viewMode = VIEW_MODE_COMPLETE; //limited, full
    let cookieRole = "investor";
    let user = currentUser;
    if (hasSessionOwner) {
      if (
        utils.userHasRole(currentUser.sessionOwner, "admin") ||
        utils.userHasRole(currentUser.sessionOwner, CUSTOMER_SUPPORT)
      ) {
        viewMode = VIEW_MODE_LIMITED;
      } else {
        //advisor
        cookieRole = utils.userHasRole(currentUser.sessionOwner, "sponsor")
          ? "sponsor"
          : "advisor";
        user = currentUser.sessionOwner;
        if (currentUser.sessionOwner.id === currentUser.id) {
          viewMode = VIEW_MODE_COMPLETE;
        } else {
          viewMode = VIEW_MODE_LIMITED;
        }
      }
    } else {
      cookieRole = utils.userHasRole(currentUser, "advisor")
        ? "advisor"
        : "investor";
    }
    return { viewMode, cookieRole, user };
  },
  userHasRole: (user, role) => {
    if (!user?.roles?.length) {
      return false;
    }
    switch (role) {
      case "admin":
        return Boolean(user.roles.find((role) => role.id === USER_ROLE_ADMIN));
      case "advisor":
        return Boolean(
          user.roles.find((role) => role.id === USER_ROLE_ADVISOR)
        );
      case "investor":
        return Boolean(
          user.roles.find((role) => role.id === USER_ROLE_INVESTOR)
        );
      case "stakeholder":
        return Boolean(
          user.roles.find((role) => role.id === USER_ROLE_STAKEHOLDER)
        );
      case "sponsor":
        return Boolean(
          user.roles.find((role) => role.id === USER_ROLE_SPONSOR)
        );
      case CUSTOMER_SUPPORT:
        return Boolean(
          user.roles.find((role) => role.id === USER_ROLE_CUSTOMER_SUPPORT)
        );
      case PRINTER:
        return Boolean(
          user.roles.find((role) => role.id === USER_ROLE_PRINTER)
        );
      default:
        return false;
    }
  },
  emailIsValid: (email) => {
    if (!email) {
      return false;
    }
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  buildQuartersArray: (fundQuarters, rows) => {
    if (!fundQuarters?.length) {
      return [];
    }
    const returnData = fundQuarters
      .filter((fq) => fq.status === FUND_QUARTER_STATUS_CLOSED)
      .slice(0, rows);
    let lastQ;
    if (!returnData.length) {
      const active = fundQuarters.find((fq) => fq.active);
      lastQ = moment().set("year", active.year).set("Q", active.quarter);
    } else {
      const lastClosed = returnData[returnData.length - 1];
      lastQ = moment()
        .set("year", lastClosed.year)
        .set("Q", lastClosed.quarter);
    }
    while (returnData.length < rows) {
      lastQ.add(-1, "Q");
      returnData.push({
        quarter: lastQ.get("Q"),
        year: lastQ.get("year"),
        distributions: [],
        returnAmount: 0,
        returnPercentage: 0,
        returnPercentageAfterFees: 0,
        membersDistribution: 0,
        yearToDateReturn: 0,
        projectedAnnualizedReturn: 0,
      });
    }
    return returnData;
  },
  getMonth: (month) =>
    moment()
      .set("month", month - 1)
      .format("MMMM"),
  isSingleProgramStatementsGenerationInProgress: (programStatements) =>
    programStatements.mode === STATEMENTS_MODAL_PREGENERATION_MODE ||
    programStatements.mode === STATEMENTS_MODAL_PREUPLOAD_MODE ||
    programStatements.mode === STATEMENTS_MODAL_UPLOAD_MODE ||
    programStatements.mode === STATEMENTS_MODAL_GENERATION_MODE,
  isSingleProgramCloseDocumentsGenerationInProgress: (programCloseDocuments) =>
    programCloseDocuments.mode === CLOSE_DOCUMENTS_MODAL_PREGENERATION_MODE ||
    programCloseDocuments.mode === CLOSE_DOCUMENTS_MODAL_PREUPLOAD_MODE ||
    programCloseDocuments.mode === CLOSE_DOCUMENTS_MODAL_UPLOAD_MODE ||
    programCloseDocuments.mode === CLOSE_DOCUMENTS_MODAL_GENERATION_MODE,
  chartColors: CHART_COLORS,
  initModal: INIT_MODAL_DATA,
};

import React, { useState } from "react";

import {
  Button,
  CardHeader,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import {
  useProgramCreationFlow,
  initialState,
} from "../../../../providers/programCreationFlowProvider";
import { useNavigate } from "react-router-dom";
import { programsApi } from "../../../../services/programServices";
import { utils } from "../../../../utils/utils";
import Loader from "../../../../components/Loader";
import InformationModal from "../../../../components/InformationModal";

const STAKEHOLDERS_STEP = 8;

const ProgramMatchReview = () => {
  const navigate = useNavigate();
  const [programCreationFlow, setProgramCreationFlow] =
    useProgramCreationFlow();

  const [loading, setLoading] = useState(false);
  const [informationModal, setInformationModal] = useState({
    programId: null,
    isOpen: false,
    body: null,
  });

  if (!programCreationFlow.program) {
    return null;
  }

  const completeImport = () => {
    setLoading(true);
    let users = [];
    let advisementsMapping = {};
    programCreationFlow.program.investors.forEach((investor) => {
      users.push(investor);
      if (investor.advisor) {
        if (!investor.advisor.removed) {
          users.push(investor.advisor);
          advisementsMapping[investor.ssNumber] = investor.advisor.email;
        }
        delete investor.advisor;
      }
    });
    const program = { ...programCreationFlow.program };
    delete program.investors;
    program.users = users;
    program.advisementsMapping = advisementsMapping;
    programsApi
      .createProgram({
        ...program,
        stakeholders: programCreationFlow.stakeholders,
      })
      .then((programId) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          programId,
          title: "Alright!",
          body: "Program created succesfully.",
        });
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Oops, there was an error with your request",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error updating the user",
        });
      });
  };

  const investors = programCreationFlow.program.investors;
  const raise = utils.formatCurrency(
    investors.reduce((p, c) => p + c.capital, 0)
  );
  const units = investors.reduce((p, c) => p + c.units, 0).toFixed(2);
  const advisors = investors
    .flatMap((investor) => investor.advisor)
    .filter((a) => a && !a.removed);

  return (
    <>
      <CardHeader className="py-0 h5 mt-4 text-underline body-color text-center col-12">
        Final Review
      </CardHeader>
      {loading ? (
        <Row className="mx-0 justify-content-center mt-3">
          <Loader size="sm" />
        </Row>
      ) : (
        <Col className="col-6 offset-3">
          <Row className="mx-0 justify-content-around mt-3">
            <span className="col-5 px-0 py-3 flex-column text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              <span>Program Raise</span>
              <span className="font-weight-bold">{raise}</span>
            </span>
            <span className="col-5 px-0 py-3 flex-column text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              <span>Program Units</span>
              <span className="font-weight-bold">{units} units</span>
            </span>
          </Row>
          <Row className="mx-0 justify-content-around mt-3">
            <span className="col-5 px-0 py-3 flex-column text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              <span>New Investors</span>
              <span className="font-weight-bold">
                {investors.filter((i) => !i.id).length}
              </span>
            </span>
            <span className="col-5 px-0 py-3 flex-column text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              <span>Returning Investors</span>
              <span className="font-weight-bold">
                {investors.filter((i) => i.id).length}
              </span>
            </span>
          </Row>
          <Row className="mx-0 justify-content-around mt-3">
            <span className="col-5 px-0 py-3 flex-column text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              <span>New Advisors</span>
              <span className="font-weight-bold">
                {
                  Object.keys(
                    advisors
                      .filter((a) => !a.id)
                      .reduce((p, c) => {
                        p[c.email] = true;
                        return p;
                      }, {})
                  ).length
                }
              </span>
            </span>
            <span className="col-5 px-0 py-3 flex-column text-center border rounded bg-lighter d-flex align-items-center justify-content-center">
              <span>Returning Advisors</span>
              <span className="font-weight-bold">
                {advisors.filter((a) => a.id).length}
              </span>
            </span>
          </Row>
          <Row className="mx-0 justify-content-around mt-3">
            <CardHeader className="py-0 h5 mt-4 text-underline body-color text-center col-12">
              PropCo Stakeholders
            </CardHeader>
            <ListGroup flush className="col-12 px-0">
              <ListGroupItem
                className="d-flex justify-content-between border-0 small text-muted px-0"
                tag="div"
              >
                <span>Name</span>
                <span>Ownership</span>
              </ListGroupItem>
              <div className="border rounded">
                {!programCreationFlow.stakeholders.length ? (
                  <ListGroupItem
                    className="d-flex justify-content-center bg-lighter"
                    tag="div"
                  >
                    No Stakeholders added
                  </ListGroupItem>
                ) : (
                  programCreationFlow.stakeholders.map((stakeholder, index) => (
                    <ListGroupItem
                      key={index}
                      className={`d-flex justify-content-between bg-lighter px-4`}
                      tag="div"
                    >
                      <span>{stakeholder.name}</span>
                      <span>
                        {utils.formatPercent(stakeholder.percentage, 100)}
                      </span>
                    </ListGroupItem>
                  ))
                )}
                <ListGroupItem
                  className={`font-weight-bold border-bottom d-flex justify-content-between bg-lighter px-4`}
                  tag="div"
                >
                  <span>InvestCo</span>
                  <span>
                    {utils.formatPercent(
                      100 -
                        programCreationFlow.stakeholders.reduce(
                          (p, c) => p + parseFloat(c.percentage),
                          0
                        ),
                      100
                    )}
                  </span>
                </ListGroupItem>
              </div>
            </ListGroup>
          </Row>
        </Col>
      )}
      <Row className="justify-content-between mt-4 mx-0">
        <Button
          color={"secondary"}
          onClick={() => setProgramCreationFlow({ step: STAKEHOLDERS_STEP })}
        >
          Back
        </Button>{" "}
        <Button
          disabled={loading}
          className="ml-2"
          color={"primary"}
          onClick={completeImport}
        >
          Complete Import
        </Button>
      </Row>
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => {
            if (informationModal.programId) {
              setProgramCreationFlow(initialState);
              navigate(`/back/programs/${informationModal.programId}`);
            } else {
              setInformationModal({ isOpen: false, title: "", body: "" });
            }
          }}
        />
      ) : null}
    </>
  );
};

export default ProgramMatchReview;

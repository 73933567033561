import React, { useState, useCallback, useEffect } from "react";

import { Col, Row, CustomInput } from "reactstrap";

import { fundsApi } from "../../../../services/fundServices";
import { useFundTransactionModal } from "../../../../providers/fundTransactionModalProvider";
import { useFundContext } from "../../../../providers/fundsProvider";
import Loader from "../../../Loader";
import FundMonthTransactions from "./months/FundMonthTransactions";
import FundMonthTransactionModal from "./months/fundMonthTransactionModal/FundMonthTransactionModal";

const MAX_PAGE_SIZE = 999;

const FundMonthsByYear = () => {
  const [fundContext, setFundContext] = useFundContext();
  const [fundMonthTransactionModal] = useFundTransactionModal();
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);

  const setFundContextCb = useCallback(
    (data) => {
      setFundContext(data);
    },
    [setFundContext]
  );

  useEffect(() => {
    setLoading(true);
    fundsApi
      .getAllFundMonths({
        fundId: fundContext.fund.id,
        pageSize: MAX_PAGE_SIZE,
      })
      .then((result) => {
        const fundMonths = result.data;
        const activeFundMonth = fundMonths.find((month) => month.active);
        const years = Object.keys(
          fundMonths.reduce((p, c) => {
            p[c.year] = true;
            return p;
          }, {})
        ).sort((x, y) => x - y);
        setYears(years);
        setFundContextCb({ fundMonths, year: activeFundMonth?.year });
        setLoading(false);
      });
  }, [setFundContextCb, fundContext.fund]);

  return loading ? (
    <Loader size="sm" />
  ) : (
    <Col className="mb-4 mx-0 px-0">
      <Row className="mx-0 mb-3">
        <h3 className="mb-0 d-flex align-items-center justify-content-between col-12 px-0">
          <span>Fiscal Year Transactions</span>
          <div className="mt-n2">
            <CustomInput
              id="monthSelect"
              type="select"
              name="monthSelect"
              onChange={(event) =>
                setFundContext({ year: parseInt(event.currentTarget.value) })
              }
              value={fundContext.year}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </CustomInput>
          </div>
        </h3>
      </Row>
      {(fundContext.fundMonths || [])
        .filter((fundMonth) => fundMonth.year === fundContext.year)
        .sort((x, y) => x.month - y.month)
        .map((fundMonth, index) => (
          <FundMonthTransactions key={index} fundMonth={fundMonth} />
        ))}
      {fundMonthTransactionModal.isModalOpen ? (
        <FundMonthTransactionModal />
      ) : null}
    </Col>
  );
};

export default FundMonthsByYear;

import React, { useReducer, createContext, useContext } from "react";

const DEFAULT_MODE = 1;

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  date: new Date(),
  transactionCategoryId: "",
  fundAssetCategoryId: "",
  type: "",
  mode: DEFAULT_MODE,
  transactionCategories: [],
  assetCategories: [],
  isModalOpen: false,
};

const FundAssetDetailModalContext = createContext(initialState);

const FundAssetDetailModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <FundAssetDetailModalContext.Provider value={stateAndDispatch}>
      {children}
    </FundAssetDetailModalContext.Provider>
  );
};

export const useFundAssetDetailModal = () =>
  useContext(FundAssetDetailModalContext);

export {
  FundAssetDetailModalContext,
  FundAssetDetailModalProvider,
  initialState,
};

import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Container,
  CardFooter,
  Row,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Badge,
  Input,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import { usersApi } from "../../services/userServices";
import { useUsers } from "../../providers/usersProvider";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { utils } from "../../utils/utils";
import CreateAdminModal from "../../components/admin/CreateAdminModal";
import CreateSponsorModal from "../../components/sponsor/CreateSponsorModal";
import EditUserDetailsModal from "../../components/admin/EditUserDetailsModal";
import CreatePrinterModal from "../../components/printer/CreatePrinterModal";
import AdvanceTableWrapper from "../../components/admin/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/admin/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/admin/advanceTable/AdvanceTablePagination";

const states = require("../../assets/states.json");

const STATUS_ACTIVE = "3";

const ADMIN_ROLE = "1";
const ADVISOR_ROLE = "2";
const INVESTOR_ROLE = "3";
const SPONSOR_ROLE = "5";
const CUSTOMER_SUPPORT_ROLE = "6";
const PRINTER_ROLE = "7";
const MAX_PAGE_SIZE = 100;

const TABLE_COLUMNS = [
  {
    roles: [
      ADMIN_ROLE,
      ADVISOR_ROLE,
      INVESTOR_ROLE,
      SPONSOR_ROLE,
      PRINTER_ROLE,
    ],
    accessor: "name",
    Header: "Name",
    headerProps: { className: "text-truncate" },
  },
  {
    roles: [
      ADMIN_ROLE,
      ADVISOR_ROLE,
      INVESTOR_ROLE,
      SPONSOR_ROLE,
      PRINTER_ROLE,
    ],
    accessor: "email",
    Header: "Email",
    headerProps: { className: "text-truncate" },
  },
  {
    roles: [SPONSOR_ROLE],
    accessor: "sponsor",
    Header: "Sponsor",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { sponsor } = rowData.row.original;
      return sponsor?.length ? sponsor[0].name : "-";
    },
  },
  {
    roles: [INVESTOR_ROLE],
    accessor: "state",
    Header: "State",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { state } = rowData.row.original;
      return states.find((item) => item.abbreviation === state)?.name || "-";
    },
  },
  {
    roles: [INVESTOR_ROLE],
    accessor: "city",
    Header: "City",
    headerProps: { className: "text-truncate" },
  },
  {
    roles: [INVESTOR_ROLE],
    accessor: "zipcode",
    Header: "Zip",
    headerProps: { className: "text-truncate" },
  },
  {
    roles: [INVESTOR_ROLE],
    accessor: "address",
    Header: "Address",
    headerProps: { className: "text-truncate" },
  },
  {
    roles: [INVESTOR_ROLE],
    accessor: "phone",
    Header: "Phone",
    headerProps: { className: "text-truncate" },
  },
  {
    roles: [ADMIN_ROLE],
    accessor: "roles",
    Header: "Role",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { roles } = rowData.row.original;
      return roles[0].name;
    },
  },
  {
    roles: [
      ADMIN_ROLE,
      ADVISOR_ROLE,
      INVESTOR_ROLE,
      SPONSOR_ROLE,
      PRINTER_ROLE,
    ],
    accessor: "lastActivity",
    Header: "Last Activity",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { lastActivity, user } = rowData.row.original;
      return user?.status === STATUS_ACTIVE ? (
        lastActivity ? (
          utils.formatDate(lastActivity, "MM/DD/YYYY HH:mm")
        ) : (
          "N/A"
        )
      ) : (
        <Badge color={"warning"}>Not Activated</Badge>
      );
    },
  },
];

const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

const Users = () => {
  const [usersContext, setUsersContext] = useUsers();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    body: null,
  });
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [adminUsers, setAdminUsers] = useState({});
  const [advisorUsers, setAdvisorUsers] = useState({});
  const [investorUsers, setInvestorUsers] = useState({});
  const [sponsorUsers, setSponsorUsers] = useState({});
  const [printerUsers, setPrinterUsers] = useState({});

  const [createAdminModal, setCreateAdminModal] = useState(false);
  const [createSponsorModal, setCreateSponsorModal] = useState(false);
  const [createPrinterModal, setCreatePrinterModal] = useState(false);
  const [editUserDetailsModal, setEditUserDetailsModal] = useState(false);
  const [userSelectedToEdit, setUserSelectedToEdit] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onDelete = (user) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        setLoading(true);
        setConfirmationModal(initConfirmationModal);
        usersApi.deleteUser(user).then(() => {
          if (adminUsers.data) {
            let admins = [...adminUsers.data];
            admins.splice(
              admins.findIndex((a) => a.id === user.id),
              1
            );
            setAdminUsers({
              ...adminUsers,
              data: admins,
              count: adminUsers?.count - 1,
            });
          }
          if (advisorUsers.data) {
            let advisors = [...advisorUsers.data];
            advisors.splice(
              advisors.findIndex((a) => a.id === user.id),
              1
            );
            setAdvisorUsers({
              ...advisorUsers,
              data: advisors,
              count: advisorUsers?.count - 1,
            });
          }
          if (investorUsers.data) {
            let investors = [...investorUsers.data];
            investors.splice(
              investors.findIndex((a) => a.id === user.id),
              1
            );
            setInvestorUsers({
              ...investorUsers,
              data: investors,
              count: investorUsers?.count - 1,
            });
          }
          if (sponsorUsers.data) {
            let sponsors = [...sponsorUsers.data];
            sponsors.splice(
              sponsors.findIndex((a) => a.id === user.id),
              1
            );
            setSponsorUsers({
              ...sponsorUsers,
              data: sponsors,
              count: sponsorUsers?.count - 1,
            });
          }
          if (printerUsers.data) {
            let printers = [...printerUsers.data];
            printers.splice(
              printers.findIndex((a) => a.id === user.id),
              1
            );
            setPrinterUsers({
              ...printerUsers,
              data: printers,
              count: printerUsers?.count - 1,
            });
          }
          setLoading(false);
          setConfirmationModal(initConfirmationModal);
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete User",
      confirmColor: "danger",
      body: `<p>Do you confirm you want to delete ${user.name} and all it's related data?</p><span class="text-warning">This action can't be undone.</span>`,
    });
  };

  const onEdit = (user) => {
    setUserSelectedToEdit(user);
    setEditUserDetailsModal(true);
  };

  const trashIcon = (user) => (
    <FontAwesomeIcon
      key="faTrashAlt"
      icon={faTrashAlt}
      onClick={() => onDelete(user)}
      className="cursor-pointer text-danger"
    />
  );

  const editIcon = (user) => (
    <FontAwesomeIcon
      key="faPen"
      icon={faPen}
      onClick={() => onEdit(user)}
      className="cursor-pointer mx-3 text-warning"
    />
  );

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        role: [ADMIN_ROLE, CUSTOMER_SUPPORT_ROLE].join(","),
        page: usersContext.adminUsers.page - 1,
        search: searchInput || "",
        pageSize: getSizePerPage(usersContext.adminUsers.sizePerPage),
      })
      .then((result) => {
        setAdminUsers(result);
        setLoading(false);
      });
  }, [usersContext.adminUsers, searchInput]);

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        role: ADVISOR_ROLE,
        page: usersContext.advisorUsers.page - 1,
        search: searchInput || "",
        pageSize: getSizePerPage(usersContext.advisorUsers.sizePerPage),
      })
      .then((result) => {
        setAdvisorUsers(result);
        setLoading(false);
      });
  }, [usersContext.advisorUsers, searchInput]);

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        role: INVESTOR_ROLE,
        page: usersContext.investorUsers.page - 1,
        search: searchInput || "",
        pageSize: getSizePerPage(usersContext.investorUsers.sizePerPage),
      })
      .then((result) => {
        setInvestorUsers(result);
        setLoading(false);
      });
  }, [usersContext.investorUsers, searchInput]);

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        role: SPONSOR_ROLE,
        page: usersContext.sponsorUsers.page - 1,
        search: searchInput || "",
        pageSize: getSizePerPage(usersContext.sponsorUsers.sizePerPage),
      })
      .then((result) => {
        setSponsorUsers(result);
        setLoading(false);
      });
  }, [usersContext.sponsorUsers, searchInput]);

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        role: PRINTER_ROLE,
        page: usersContext.printerUsers.page - 1,
        search: searchInput || "",
        pageSize: getSizePerPage(usersContext.printerUsers.sizePerPage),
      })
      .then((result) => {
        setPrinterUsers(result);
        setLoading(false);
      });
  }, [usersContext.printerUsers, searchInput]);

  const tableColumns = [
    ...TABLE_COLUMNS.filter(
      (col) => col.roles.indexOf(usersContext.tab) !== -1
    ),
    {
      accessor: "trashIcon",
      Header: "",
      with: 100,
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-right",
      },
      Cell: (rowData) => {
        const data = rowData.row.original;
        return (
          <>
            {editIcon(data)}
            {data.default ? null : trashIcon(data)}
          </>
        );
      },
    },
  ];
  return (
    <Container fluid>
      <Header>
        <HeaderTitle className="justify-content-between d-block">
          <Row>
            <Col className="col-10">
              <span>Users</span>
            </Col>
            <Col className="col-2">
              <Input
                type="text"
                placeholder="Search"
                onChange={(event) => {
                  setSearchInput(event.currentTarget.value);
                }}
                value={searchInput}
              />
            </Col>
          </Row>
        </HeaderTitle>
      </Header>
      <Row className="mx-0">
        <Nav
          tabs
          className="d-flex justify-content-around col-12 px-0 border-0 users-tabs"
        >
          <NavItem
            className={`${
              usersContext.tab === ADMIN_ROLE
                ? "active font-weight-bold bg-white"
                : "text-muted"
            } cursor-pointer flex-grow-1 text-center py-1`}
            onClick={() => setUsersContext({ tab: ADMIN_ROLE })}
          >
            <NavLink
              to="#"
              className="d-flex justify-content-between align-items-center"
            >
              <span></span>
              <span>Admins</span>
              {usersContext.tab === ADMIN_ROLE ? (
                <FontAwesomeIcon
                  icon={faPlus}
                  onClick={() => setCreateAdminModal(true)}
                  className="cursor-pointer text-primary float-right"
                />
              ) : (
                <span></span>
              )}
            </NavLink>
          </NavItem>
          <NavItem
            className={`${
              usersContext.tab === ADVISOR_ROLE
                ? "active font-weight-bold bg-white"
                : "text-muted"
            } cursor-pointer flex-grow-1 text-center py-1`}
            onClick={() => setUsersContext({ tab: ADVISOR_ROLE })}
          >
            <NavLink to="#">Advisors</NavLink>
          </NavItem>
          <NavItem
            className={`${
              usersContext.tab === INVESTOR_ROLE
                ? "active font-weight-bold bg-white"
                : "text-muted"
            } cursor-pointer flex-grow-1 text-center py-1`}
            onClick={() => setUsersContext({ tab: INVESTOR_ROLE })}
          >
            <NavLink to="#">Investors</NavLink>
          </NavItem>
          <NavItem
            className={`${
              usersContext.tab === SPONSOR_ROLE
                ? "active font-weight-bold bg-white"
                : "text-muted"
            } cursor-pointer flex-grow-1 text-center py-1`}
            onClick={() => setUsersContext({ tab: SPONSOR_ROLE })}
          >
            <NavLink
              to="#"
              className="d-flex justify-content-between align-items-center"
            >
              <span></span>
              <span>Sponsors</span>
              {usersContext.tab === SPONSOR_ROLE && (
                <FontAwesomeIcon
                  icon={faPlus}
                  onClick={() => setCreateSponsorModal(true)}
                  className="cursor-pointer text-primary float-right"
                />
              )}
            </NavLink>
          </NavItem>
          <NavItem
            className={`${
              usersContext.tab === PRINTER_ROLE
                ? "active font-weight-bold bg-white"
                : "text-muted"
            } cursor-pointer flex-grow-1 text-center py-1`}
            onClick={() => setUsersContext({ tab: PRINTER_ROLE })}
          >
            <NavLink
              to="#"
              className="d-flex justify-content-between align-items-center"
            >
              <span></span>
              <span>Printers</span>
              {usersContext.tab === PRINTER_ROLE && (
                <FontAwesomeIcon
                  icon={faPlus}
                  onClick={() => setCreatePrinterModal(true)}
                  className="cursor-pointer text-primary float-right"
                />
              )}
            </NavLink>
          </NavItem>
        </Nav>
      </Row>
      <Row className="mx-0">
        <TabContent
          activeTab={usersContext.tab}
          className="col-12 px-0 users-tabs-content"
        >
          <TabPane tabId={ADMIN_ROLE}>
            <Card>
              <CardBody>
                {loading ? (
                  <Loader />
                ) : adminUsers.data?.length ? (
                  <AdvanceTableWrapper
                    columns={tableColumns}
                    data={adminUsers.data || []}
                    pageSize={getSizePerPage(
                      usersContext.adminUsers.sizePerPage
                    )}
                  >
                    <Card className="d-flex">
                      <CardBody className="overflow-auto min-height-300p">
                        {adminUsers.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={adminUsers?.count}
                          pageCount={adminUsers?.totalPages}
                          currentPage={usersContext.adminUsers.page - 1}
                          onPageChange={(page) =>
                            setUsersContext({
                              adminUsers: { ...usersContext.adminUsers, page },
                            })
                          }
                          pageSize={getSizePerPage(
                            usersContext.adminUsers.sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setUsersContext({
                              adminUsers: {
                                ...usersContext.adminUsers,
                                page: 1,
                                sizePerPage,
                              },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : (
                  <div className="text-center">No results</div>
                )}
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId={ADVISOR_ROLE}>
            <Card>
              <CardBody>
                {loading ? (
                  <Loader />
                ) : advisorUsers.data?.length ? (
                  <AdvanceTableWrapper
                    columns={tableColumns}
                    data={advisorUsers.data || []}
                    pageSize={getSizePerPage(
                      usersContext.advisorUsers.sizePerPage
                    )}
                  >
                    <Card className="d-flex">
                      <CardBody className="overflow-auto min-height-300p">
                        {advisorUsers.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={advisorUsers?.count}
                          pageCount={advisorUsers?.totalPages}
                          currentPage={usersContext.advisorUsers.page - 1}
                          onPageChange={(page) =>
                            setUsersContext({
                              advisorUsers: {
                                ...usersContext.advisorUsers,
                                page,
                              },
                            })
                          }
                          pageSize={getSizePerPage(
                            usersContext.advisorUsers.sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setUsersContext({
                              advisorUsers: {
                                ...usersContext.advisorUsers,
                                page: 1,
                                sizePerPage,
                              },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : (
                  <div className="text-center">No results</div>
                )}
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId={INVESTOR_ROLE}>
            <Card>
              <CardBody>
                {loading ? (
                  <Loader />
                ) : investorUsers.data?.length ? (
                  <AdvanceTableWrapper
                    columns={tableColumns}
                    data={investorUsers.data || []}
                    pageSize={getSizePerPage(
                      usersContext.investorUsers.sizePerPage
                    )}
                  >
                    <Card className="d-flex">
                      <CardBody className="overflow-auto min-height-300p">
                        {investorUsers.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={investorUsers?.count}
                          pageCount={investorUsers?.totalPages}
                          currentPage={usersContext.investorUsers.page - 1}
                          onPageChange={(page) =>
                            setUsersContext({
                              investorUsers: {
                                ...usersContext.investorUsers,
                                page,
                              },
                            })
                          }
                          pageSize={getSizePerPage(
                            usersContext.investorUsers.sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setUsersContext({
                              investorUsers: {
                                ...usersContext.investorUsers,
                                page: 1,
                                sizePerPage,
                              },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : (
                  <div className="text-center">No results</div>
                )}
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId={SPONSOR_ROLE}>
            <Card>
              <CardBody>
                {loading ? (
                  <Loader />
                ) : sponsorUsers.data?.length ? (
                  <AdvanceTableWrapper
                    columns={tableColumns}
                    data={sponsorUsers.data || []}
                    pageSize={getSizePerPage(
                      usersContext.sponsorUsers.sizePerPage
                    )}
                  >
                    <Card className="d-flex">
                      <CardBody className="overflow-auto min-height-300p">
                        {sponsorUsers.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={sponsorUsers?.count}
                          pageCount={sponsorUsers?.totalPages}
                          currentPage={usersContext.sponsorUsers.page - 1}
                          onPageChange={(page) =>
                            setUsersContext({
                              sponsorUsers: {
                                ...usersContext.sponsorUsers,
                                page,
                              },
                            })
                          }
                          pageSize={getSizePerPage(
                            usersContext.sponsorUsers.sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setUsersContext({
                              sponsorUsers: {
                                ...usersContext.sponsorUsers,
                                page: 1,
                                sizePerPage,
                              },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : (
                  <div className="text-center">No results</div>
                )}
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId={PRINTER_ROLE}>
            <Card>
              <CardBody>
                {loading ? (
                  <Loader />
                ) : printerUsers.data?.length ? (
                  <AdvanceTableWrapper
                    columns={tableColumns}
                    data={printerUsers.data || []}
                    pageSize={getSizePerPage(
                      usersContext.printerUsers.sizePerPage
                    )}
                  >
                    <Card className="d-flex">
                      <CardBody className="overflow-auto min-height-300p">
                        {printerUsers.data?.length ? (
                          <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="small"
                            tableProps={{
                              striped: false,
                              className: "mb-0",
                            }}
                          />
                        ) : (
                          <div className="text-center">No results</div>
                        )}
                      </CardBody>
                      <CardFooter>
                        <AdvanceTablePagination
                          totalCount={printerUsers?.count}
                          pageCount={printerUsers?.totalPages}
                          currentPage={usersContext.printerUsers.page - 1}
                          onPageChange={(page) =>
                            setUsersContext({
                              printerUsers: {
                                ...usersContext.printerUsers,
                                page,
                              },
                            })
                          }
                          pageSize={getSizePerPage(
                            usersContext.printerUsers.sizePerPage
                          )}
                          onPageSizeChange={(sizePerPage) =>
                            setUsersContext({
                              printerUsers: {
                                ...usersContext.printerUsers,
                                page: 1,
                                sizePerPage,
                              },
                            })
                          }
                          rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                      </CardFooter>
                    </Card>
                  </AdvanceTableWrapper>
                ) : (
                  <div className="text-center">No results</div>
                )}
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Row>
      {informationModal?.isOpen ? (
        <InformationModal
          title={"Heads Up!"}
          body={informationModal.body}
          onClose={() => setInformationModal({ isOpen: false, body: "" })}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {createAdminModal ? (
        <CreateAdminModal
          users={[
            ...(adminUsers.data || []),
            ...(advisorUsers.data || []),
            ...(investorUsers.data || []),
            ...(printerUsers.data || []),
            ...(sponsorUsers.data || []),
          ]}
          onClose={() => setCreateAdminModal(false)}
        />
      ) : null}
      {createSponsorModal ? (
        <CreateSponsorModal
          users={[
            ...(adminUsers.data || []),
            ...(advisorUsers.data || []),
            ...(investorUsers.data || []),
            ...(printerUsers.data || []),
            ...(sponsorUsers.data || []),
          ]}
          onClose={() => setCreateSponsorModal(false)}
        />
      ) : null}
      {createPrinterModal ? (
        <CreatePrinterModal
          users={[
            ...(adminUsers.data || []),
            ...(advisorUsers.data || []),
            ...(investorUsers.data || []),
            ...(printerUsers.data || []),
            ...(sponsorUsers.data || []),
          ]}
          onClose={() => setCreatePrinterModal(false)}
        />
      ) : null}
      {editUserDetailsModal ? (
        <EditUserDetailsModal
          users={[
            ...(adminUsers.data || []),
            ...(advisorUsers.data || []),
            ...(investorUsers.data || []),
            ...(printerUsers.data || []),
            ...(sponsorUsers.data || []),
          ]}
          user={userSelectedToEdit}
          onClose={() => setEditUserDetailsModal(false)}
        />
      ) : null}
    </Container>
  );
};

export default Users;

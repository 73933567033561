import React from "react";
import { Pie } from "react-chartjs-2";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { useFundContext } from "../../../providers/fundsProvider";

const MAX_TEXT_LENGTH = 20;
const TYPE_INCOME_ID = 1;
const TYPE_INCREASE = "1";
const programIdByName = [];
const categoryIdByName = [];

const truncateText = (text) =>
  text.length > MAX_TEXT_LENGTH
    ? `${text.slice(0, MAX_TEXT_LENGTH - 1)}...`
    : text;

const reduceCapitalTransactionsCategories = (capitalTransactions) =>
  capitalTransactions.reduce((p, c) => {
    if (!p[c.fundCapitalTransactionCategory.name]) {
      p[c.fundCapitalTransactionCategory.name] =
        c.amount * (parseInt(c.type) === TYPE_INCOME_ID ? 1 : -1);
    } else {
      p[c.fundCapitalTransactionCategory.name] =
        p[c.fundCapitalTransactionCategory.name] +
        c.amount * (parseInt(c.type) === TYPE_INCOME_ID ? 1 : -1);
    }
    categoryIdByName[c.fundCapitalTransactionCategory.name] =
      c.fundCapitalTransactionCategory.id;
    return p;
  }, {});

const reduceFundInvestments = (fundFundInvestments) =>
  fundFundInvestments.reduce((p, c) => {
    if (!p[c.programFundInvestment.name]) {
      p[c.programFundInvestment.name] = [];
    }
    programIdByName[c.programFundInvestment.name] = c.programFundInvestment.id;
    p[c.programFundInvestment.name].push(c);
    return p;
  }, {});

const getAssetCurrentCapital = (asset) =>
  asset.capital +
  (asset.transactions || []).reduce(
    (p, c) => p + (parseInt(c.type) === TYPE_INCOME_ID ? c.amount : -c.amount),
    0
  );

const CapitalComposition = ({ legendPosition, chartColors }) => {
  const [fundContext] = useFundContext();
  const fund = fundContext.fund;
  const capitalTransactionsCategories = reduceCapitalTransactionsCategories(
    fund.fundCapitalTransactions
  );
  const fundInvestments = reduceFundInvestments(fund.fundFundInvestments);
  const totalAssetsCapital = fund.assets.reduce(
    (p, c) => p + getAssetCurrentCapital(c),
    0
  );

  const getChart = () => {
    return {
      data: {
        labels: [
          ...Object.keys(fundInvestments).map((programName) =>
            truncateText(programName)
          ),
          ...Object.keys(capitalTransactionsCategories),
        ],
        datasets: [
          {
            data: [
              ...Object.keys(fundInvestments).map((programName) =>
                fundInvestments[programName].reduce(
                  (p, c) => p + c.amount * (c.type === TYPE_INCREASE ? 1 : -1),
                  0
                )
              ),
              ...Object.values(capitalTransactionsCategories),
            ],
            backgroundColor: chartColors,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          position: legendPosition,
          align: "start",
        },
        plugins: {
          colorschemes: {
            scheme: "tableau.Classic20",
          },
          datalabels: {
            formatter: (data) =>
              `${((data / (totalAssetsCapital + fund.balance)) * 100).toFixed(
                2
              )}%`,
            display: true,
            color: "white",
            font: {
              weight: "bold",
              familiy: "Rubik",
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => `$${tooltipItem.formattedValue}`,
            },
          },
        },
      },
    };
  };

  const chart = getChart();

  return (
    <Card className="mb-0 flex-grow-1 justify-content-center align-items-center min-vh-20">
      {Object.keys(capitalTransactionsCategories).length ||
      fund.fundFundInvestments.length ? (
        <>
          <CardHeader>
            <CardTitle tag="h5" className="mb-0 text-center">
              Capital Composition
            </CardTitle>
          </CardHeader>
          <CardBody className="col-12">
            <Pie data={chart.data} options={chart.options} />
          </CardBody>
        </>
      ) : (
        <span className="font-weight-bold">The Fund capital is zero</span>
      )}
    </Card>
  );
};

export default CapitalComposition;

import React, { useEffect, useState, useCallback } from "react";

import { Card, CardBody, Col, Container, Row, Button, Badge } from "reactstrap";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fundsApi } from "../../../services/fundServices";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import ReturnsTable from "../../../components/admin/fundDetails/ReturnsTable";

import FundAsset from "../../../components/admin/fundDetails/FundAsset";
import FundAssetDetail from "../../../components/admin/fundDetails/fundAssetDetail/FundAssetDetail";

import { useFundAssetDetailModal } from "../../../providers/fundAssetDetailModalProvider";
import Loader from "../../../components/Loader";

import {
  useFundAssetCreationModal,
  initialState as fundAssetCreationInitialState,
} from "../../../providers/fundAssetCreationModalProvider";

import FundAssetCreation from "../../../components/admin/fundDetails/fundAssetCreation/FundAssetCreation";
import { utils } from "../../../utils/utils";
import { useFundContext } from "../../../providers/fundsProvider";
import CapitalComposition from "../../../components/admin/fundDetails/CapitalComposition";
import InvestmentAllocation from "../../../components/admin/fundDetails/InvestmentAllocation";
import FundQuartersByYear from "../../../components/admin/fundDetails/fundTransactions/FundQuartersByYear";
import { useFundCapitalTransactionsModal } from "../../../providers/fundCapitalTransactionsModalProvider";
import FundCapitalTransactionsModal from "../../../components/admin/fundDetails/fundCapitalTransaction/FundCapitalTransactionsModal";
import { useAuth } from "../../../providers/authProvider";
import { CUSTOMER_SUPPORT, SPONSOR } from "../../../utils/roles";
import InvestmentTimeline from "../../../components/admin/fundDetails/InvestmentTimeline";
import FundMonthsByYear from "../../../components/admin/fundDetails/fundTransactions/FundMonthsByYear ";
import { useParams } from "react-router-dom";

const MAX_ASSETS_PER_ROW = 4;

const FundDetails = () => {
  const params = useParams();
  const [fundContext, setFundContext] = useFundContext();
  const fund = fundContext.fund;
  const [chartColors, setChartColors] = useState([]);
  const [loading, setLoading] = useState(true);

  const [fundCapitalTransactionsModal, setFundCapitalTransactionsModal] =
    useFundCapitalTransactionsModal();
  const [fundAssetCreationModal, setFundAssetCreationModal] =
    useFundAssetCreationModal();
  const [fundAssetDetailModal] = useFundAssetDetailModal();

  const [authContext] = useAuth();

  const user = authContext.currentUser;

  const setFundContextCb = useCallback(
    (data) => {
      setFundContext({
        ...data,
      });
    },
    [setFundContext]
  );

  useEffect(() => {
    setLoading(true);
    fundsApi.getFunds({ id: params.fundId }, "ADMIN").then((result) => {
      setFundContextCb({ fund: result });
      // generating random color for the charts
      const colors = result?.fundFundInvestments?.map(() => {
        return (
          "#" +
          Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, "0")
        );
      });
      setChartColors(colors);
      setLoading(false);
    });
  }, [params.fundId, fundContext.refresh, setFundContextCb]);

  return loading ? (
    <Loader />
  ) : fund ? (
    <Container fluid>
      <Header>
        <HeaderTitle className="d-flex justify-content-between">
          <Row className="col-12 p-0 align-items-baseline justify-content-between">
            <div className="d-flex flex-column">
              <span>{`${fund.name} ${fund.year}`}</span>
              {fund.isPrivateEquity ? (
                <Badge
                  color={"warning"}
                  pill
                  className="font-size-6 mt-1 align-self-start"
                >
                  Hedge Fund Investments
                </Badge>
              ) : null}
              <small className="text-muted font-size-75 mt-1">
                Capital: {utils.formatCurrency(fund.balance)}
              </small>
            </div>
            {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
              !utils.userHasRole(user, SPONSOR) && (
                <div>
                  <Button
                    color="primary"
                    className="ml-4"
                    onClick={() =>
                      setFundCapitalTransactionsModal({ isModalOpen: true })
                    }
                  >
                    <FontAwesomeIcon icon={faPen} />
                    <span className="ml-2">Manage Fund</span>
                  </Button>
                </div>
              )}
          </Row>
        </HeaderTitle>
      </Header>
      {!fund.isPrivateEquity ? (
        <Row className="mx-0 justify-content-between mb-4">
          <Card className="mb-0 col-12 px-0">
            {!fundContext.fundQuarters?.length ? (
              <div className="my-3">
                <Loader size="sm" />
              </div>
            ) : (
              <ReturnsTable fundQuarters={fundContext.fundQuarters} />
            )}
          </Card>
        </Row>
      ) : null}
      <Row className="mx-0 justify-content-between mb-4 bg-transparent">
        {fund.isPrivateEquity ? (
          <div className="mb-0 col-12 px-0 d-flex bg-transparent">
            <Col className="col-9 bg-transparent d-flex px-0">
              <InvestmentTimeline
                investments={fund.fundFundInvestments}
                chartColors={chartColors}
              />
            </Col>
            <Col className="col-3 bg-transparent d-flex">
              <CapitalComposition
                legendPosition="bottom"
                chartColors={chartColors}
              />
            </Col>
          </div>
        ) : (
          <div className="mb-0 col-12 px-0 d-flex bg-transparent">
            <Col className="col-6 bg-transparent pl-0 d-flex">
              <CapitalComposition legendPosition="left" />
            </Col>
            <Col className="col-6 bg-transparent pr-0 d-flex">
              <InvestmentAllocation
                assets={fund.assets}
                legendPosition={"right"}
              />
            </Col>
          </div>
        )}
      </Row>
      <Row className="mb-4 mx-0">
        <h3 className="mb-3 col-12 px-0">Fund Assets</h3>
        <Row className="mx-0 col-12 px-0">
          {fund.assets?.map((asset, index) => (
            <FundAsset key={index} asset={asset} />
          ))}
          {!utils.userHasRole(user, CUSTOMER_SUPPORT) &&
            !utils.userHasRole(user, SPONSOR) && (
              <div
                onClick={() =>
                  setFundAssetCreationModal(
                    Object.assign(fundAssetCreationInitialState, {
                      categories: fundAssetCreationModal.categories,
                      fund: fund,
                      isModalOpen: true,
                    })
                  )
                }
                className="col-3 pl-0 py-1 "
              >
                <Card
                  className={`${
                    fund.assets?.length % MAX_ASSETS_PER_ROW === 0
                      ? "min-vh-20"
                      : ""
                  } new-asset-card cursor-pointer border`}
                >
                  <CardBody className="d-flex justify-content-center align-items-center">
                    <div className="text-center font-weight-bold">
                      Add New Fund Asset
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
        </Row>
      </Row>
      {!fund.isPrivateEquity ? <FundQuartersByYear /> : <FundMonthsByYear />}
      {fundAssetCreationModal.isModalOpen ? <FundAssetCreation /> : null}
      {fundAssetDetailModal.isModalOpen ? <FundAssetDetail /> : null}
      {fundCapitalTransactionsModal.isModalOpen ? (
        <FundCapitalTransactionsModal />
      ) : null}
    </Container>
  ) : null;
};

export default FundDetails;

import Transaction from "../Transaction";
import React, { memo, useCallback, useState } from "react";

import { usePrograms } from "../../../providers/programsProvider";
import { fundsApi } from "../../../services/fundServices";

const TYPE_INCREASE = "1";

const HedgeFundTransaction = ({
  setInformationModal,
  availableAmountToWithdraw,
  onRefresh,
  transaction = {},
}) => {
  const [programsContext, setProgramContext] = usePrograms();

  const [loading, setLoading] = useState();

  const handleDeleteTransaction = useCallback(async () => {
    const totalAvailableAmountToWithdraw = Object.values(
      availableAmountToWithdraw
    ).reduce((p, c) => p + c, 0);
    if (
      transaction.type === TYPE_INCREASE &&
      totalAvailableAmountToWithdraw < transaction.amount
    ) {
      return setInformationModal({
        isOpen: true,
        title: "Delete Transaction",
        body: "Removing that transaction would result in a negative balance.",
      });
    }
    setLoading(true);
    await fundsApi.deleteFundInvestment({
      id: transaction.id,
      fundId: transaction.fundId,
    });
    setLoading(false);
    const program = { ...programsContext.program };
    const transactionIndex = program.programFundInvestments.findIndex(
      (fundInvestment) => fundInvestment.id === transaction.id
    );
    program.programFundInvestments.splice(transactionIndex, 1);
    setProgramContext(program);
    onRefresh();
  }, [
    setInformationModal,
    programsContext.program,
    setProgramContext,
    transaction,
    onRefresh,
    availableAmountToWithdraw,
  ]);

  return (
    <Transaction
      loading={loading}
      type={transaction.type}
      label={transaction?.fundFundInvestment?.name}
      date={transaction.date}
      amount={transaction.amount}
      onDelete={handleDeleteTransaction}
    />
  );
};

export default memo(HedgeFundTransaction);

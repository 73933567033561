import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  CustomInput,
} from "reactstrap";
import { fundsApi } from "../../../../../../services/fundServices";
import {
  initialState,
  useFundTransactionModal,
} from "../../../../../../providers/fundTransactionModalProvider";
import Loader from "../../../../../Loader";
import CurrencyInput from "react-currency-input-field";
import { useFundContext } from "../../../../../../providers/fundsProvider";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateInput from "../../../../../Forms/DateInput";

const MAX_VALUE = 999999999;

const DELETE_CONFIRMATION_MODE = 5;
const CATEGORY_CREATION_MODE = 2;

const TYPE_INCOME = {
  id: "1",
  name: "Income",
};
const TYPE_EXPENSE = {
  id: "2",
  name: "Expense",
};
const TYPE_INCOME_DDD = {
  id: "3",
  name: "Income DND",
};

const STATUS_OPEN = "1";

const TYPES = [TYPE_INCOME, TYPE_EXPENSE, TYPE_INCOME_DDD];

const FundQuarterTransactionDetailModal = () => {
  const [loading, setLoading] = useState(false);
  const [fundTransactionModal, setFundTransactionModal] =
    useFundTransactionModal();

  const [fundContext, setFundContext] = useFundContext();

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    fundsApi
      .updateFundTransaction({
        id: fundTransactionModal.id,
        fundAssetId: fundTransactionModal.fundAssetId,
        fundId: fundContext.fund.id,
        fundQuarterId: fundTransactionModal.fundQuarterId,
        type: fundTransactionModal.type,
        amount: fundTransactionModal.amount,
        fundTransactionCategoryId:
          fundTransactionModal.fundTransactionCategoryId,
      })
      .then(() =>
        fundsApi
          .getFundQuarters({
            fundId: fundContext.fund.id,
            id: fundTransactionModal.fundQuarterId,
          })
          .then((selectedFundQuarter) => {
            setLoading(false);
            setFundTransactionModal({ ...initialState });
            let fundQuarters = fundContext.fundQuarters;
            fundQuarters.splice(
              fundQuarters.findIndex(
                (fq) => fq.id === fundTransactionModal.fundQuarterId
              ),
              1,
              selectedFundQuarter
            );
            setFundContext({ selectedFundQuarter, fundQuarters });
          })
      );
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setFundTransactionModal({ isModalOpen: false })}
    >
      &times;
    </Button>
  );

  return (
    <Modal
      isOpen={true}
      onClosed={() => setFundTransactionModal({ isModalOpen: false })}
    >
      <ModalHeader close={closeBtn}>Edit Transaction</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Type
                </Label>
                <Col sm={7}>
                  <Input
                    disabled={true}
                    value={
                      TYPES.find(
                        (type) => type.id === fundTransactionModal.type
                      )?.name
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3">
                <Label sm={5} className="text-sm-left">
                  Date
                </Label>
                <Col sm={7}>
                  <DateInput
                    value={new Date(fundTransactionModal.date)}
                    onChange={(date) => setFundTransactionModal({ date })}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Fund Asset
                </Label>
                <Col sm={7}>
                  <CustomInput
                    required={true}
                    id="assetSelect"
                    type="select"
                    name="assetSelect"
                    onChange={(event) =>
                      setFundTransactionModal({
                        fundAssetId: event.currentTarget.value,
                      })
                    }
                    value={fundTransactionModal.fundAssetId}
                  >
                    <option value="">Select...</option>
                    {fundContext.fund.assets.map((asset) => (
                      <option key={asset.id} value={asset.id}>
                        {asset.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              {fundTransactionModal.type === TYPE_EXPENSE.id ? (
                <FormGroup row>
                  <Label sm={5} className="text-sm-left">
                    Category
                  </Label>
                  <Col sm={7}>
                    <CustomInput
                      required={true}
                      id="categorySelect"
                      type="select"
                      name="categorySelect"
                      onChange={(event) =>
                        setFundTransactionModal({
                          fundTransactionCategoryId: event.currentTarget.value,
                        })
                      }
                      value={
                        fundTransactionModal.fundTransactionCategoryId || ""
                      }
                    >
                      <option value="">Select...</option>
                      {fundTransactionModal.categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </CustomInput>
                    <Button
                      color={"info"}
                      size={"sm"}
                      className="float-left mt-2 rounded"
                      onClick={() =>
                        setFundTransactionModal({
                          mode: CATEGORY_CREATION_MODE,
                        })
                      }
                    >
                      New Category
                    </Button>
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row className="mb-0">
                <Label sm={5} className="text-sm-left">
                  Amount
                </Label>
                <Col sm={7}>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    required={true}
                    allowNegativeValue={false}
                    maxLength={20}
                    className="form-control"
                    placeholder="Enter the amount.."
                    value={fundTransactionModal.amount}
                    onValueChange={(value) => {
                      if (!value || value < MAX_VALUE) {
                        setFundTransactionModal({ amount: value });
                      }
                    }}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Col className="p-0">
            <Button
              color={"secondary"}
              onClick={() => setFundTransactionModal({ isModalOpen: false })}
            >
              Cancel
            </Button>
            {fundContext.selectedFundQuarter.status === STATUS_OPEN ? (
              <Button
                className="ml-2"
                color={"danger"}
                onClick={() =>
                  setFundTransactionModal({ mode: DELETE_CONFIRMATION_MODE })
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
              </Button>
            ) : null}
          </Col>
          <Button color={"primary"} type="submit">
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundQuarterTransactionDetailModal;

import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import Col from "reactstrap/lib/Col";

import { usePrograms } from "../../../providers/programsProvider";
import { utils } from "../../../utils/utils";
import InformationModal from "../../InformationModal";

import CreateHedgeFundTransaction from "./CreateHedgeFundTransaction";
import HedgeFundTransaction from "./HedgeFundTransaction";

const TYPE_INCREASE = "1";

const HedgeFundTransactionsModal = ({ onClose }) => {
  const [programsContext] = usePrograms();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [selectedFundId, setSelectedFundId] = useState();
  const [availableAmountToWithdraw, setAvailableAmountToWithdraw] = useState(
    {}
  );
  const [availableAmountToInvest, setAvailableAmountToInvest] = useState();
  const [refresh, setRefresh] = useState();

  const { program } = programsContext;

  useEffect(() => {
    const availableAmountToWithdraw = program.programFundInvestments.reduce(
      (p, c) => {
        p[c.fundId] =
          (p[c.fundId] || 0) + c.amount * (c.type === TYPE_INCREASE ? 1 : -1);
        return p;
      },
      {}
    );
    setAvailableAmountToWithdraw(availableAmountToWithdraw);
  }, [refresh, program.programFundInvestments]);

  useEffect(() => {
    const availableAmountToInvest = program?.programFundInvestments.reduce(
      (accum, investment) =>
        accum -
        investment.amount * (investment.type === TYPE_INCREASE ? 1 : -1),
      program.capital
    );
    setAvailableAmountToInvest(availableAmountToInvest);
  }, [refresh, program.programFundInvestments, program.capital]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      rawBody={informationModal.rawBody}
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>
        Investment Into Hedge Fund Investment
      </ModalHeader>
      <ModalBody>
        <Row form className="mx-0">
          <FormGroup
            row
            className="mx-0 mb-1 d-flex align-items-center justify-content-between col-12 form-group"
          >
            <Label sm={4} className="text-sm-left col-sm-4 px-0">
              Available to Invest
            </Label>
            <Col sm={8} className="font-weight-bold text-right px-0">
              {utils.formatCurrency(availableAmountToInvest)}
            </Col>
          </FormGroup>
          {selectedFundId ? (
            <FormGroup
              row
              className="mx-0 mb-1 d-flex align-items-center justify-content-between col-12 form-group"
            >
              <Label sm={4} className="text-sm-left col-sm-4 px-0">
                Available to Withdraw
              </Label>
              <Col sm={8} className="font-weight-bold text-right px-0">
                {utils.formatCurrency(
                  availableAmountToWithdraw[selectedFundId] || 0
                )}
              </Col>
            </FormGroup>
          ) : null}
        </Row>
        <ListGroup flush className="text-left border rounded mt-2">
          <ListGroupItem
            className="pb-2 border-bottom font-weight-bold text-body text-center bg-light"
            tag="div"
          >
            Transactions
          </ListGroupItem>
          {!program.programFundInvestments?.length ? (
            <ListGroupItem
              className="pb-2 border-bottom font-weight-bold text-body text-center"
              tag="div"
            >
              No transactions to show
            </ListGroupItem>
          ) : (
            program.programFundInvestments
              .sort((ta, tb) =>
                moment(ta.date).isSameOrAfter(moment(tb.date)) ? 1 : -1
              )
              .map((transaction, index) => (
                <HedgeFundTransaction
                  setInformationModal={setInformationModal}
                  availableAmountToWithdraw={availableAmountToWithdraw}
                  onRefresh={() => setRefresh(!refresh)}
                  key={index}
                  transaction={transaction}
                />
              ))
          )}
          <CreateHedgeFundTransaction
            onRefresh={() => setRefresh(!refresh)}
            availableAmountToInvest={availableAmountToInvest}
            availableAmountToWithdraw={availableAmountToWithdraw}
            onFundSelected={(fundId) => setSelectedFundId(parseInt(fundId))}
          />
        </ListGroup>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default HedgeFundTransactionsModal;

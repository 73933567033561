import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";

import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import DocumentPreviewModal from "../../pages/admin/documents/review/DocumentPreviewModal";
import { awsApi } from "../../services/awsServices";
import Loader from "../Loader";
import config from "../../config/config";
import { documentsApi } from "../../services/documentServices";
import { programsApi } from "../../services/programServices";
import download from "downloadjs";
import moment from "moment";

const FILE_TYPE = "pdf";

const DOCUMENT_TYPE_TAX_K1 = 2;
const DOCUMENT_TYPE_TAX_8886 = 5;

const QUARTERLY_TABS = "1";
const TAX_DOCUMENTS_TABS = "2";

const Documents = ({ programId }) => {
  const [activeTab, setActiveTab] = useState(QUARTERLY_TABS);
  const [loading, setLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState({});
  const [documents, setDocuments] = useState([]);
  const [statements, setStatements] = useState([]);
  const [previewModal, setPreviewModal] = useState({});

  useEffect(() => {
    setLoading(true);
    documentsApi.getDocuments({ programId, pageSize: 9999 }).then((results) => {
      setDocuments(
        results.data.filter(
          (d) =>
            d.documentTypeId === DOCUMENT_TYPE_TAX_K1 ||
            d.documentTypeId === DOCUMENT_TYPE_TAX_8886
        )
      );
      setLoading(false);
    });
  }, [programId]);

  useEffect(() => {
    programsApi
      .getStatements({ programId, pageSize: 9999 })
      .then((statements) => {
        programsApi
          .getManualStatements({ programId, pageSize: 9999 })
          .then((manualStatements) => {
            setStatements([...statements.data, ...manualStatements.data]);
          });
      });
  }, [programId]);

  const onPreview = (fileName, id) => {
    setDocumentsLoading({ ...documentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        "investor"
      )
      .then((result) => {
        setDocumentsLoading({ ...documentsLoading, [id]: false });
        setPreviewModal({
          isOpen: true,
          file: result?.pop().signedRequest,
        });
      });
  };

  const onDownload = (fileName, id) => {
    setDocumentsLoading({ ...documentsLoading, [id]: true });
    awsApi
      .signDocuments(
        {
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        },
        "investor"
      )
      .then((result) => {
        setDocumentsLoading({ ...documentsLoading, [id]: false });
        download(result?.pop().signedRequest);
      });
  };

  return (
    <Col className="col-3 min-width-300">
      <Card className="">
        <CardHeader className="pb-0">
          <CardTitle tag="h5" className="mb-0 text-center">
            Documents
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="tab documents-tab">
            <Nav tabs className="d-flex justify-content-around">
              <NavItem
                className={`${
                  activeTab === QUARTERLY_TABS ? "active" : ""
                } cursor-pointer col-6 p-3 text-center d-flex flex-column justify-content-center btn-primary`}
                onClick={() => setActiveTab(QUARTERLY_TABS)}
              >
                <NavLink to="#">Quarterly Statements</NavLink>
              </NavItem>
              <NavItem
                className={`${
                  activeTab === TAX_DOCUMENTS_TABS ? "active" : ""
                } cursor-pointer col-6 p-3 text-center d-flex flex-column justify-content-center btn-primary`}
                onClick={() => setActiveTab(TAX_DOCUMENTS_TABS)}
              >
                <NavLink to="#">Tax Documents</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="p-0 small">
              <TabPane tabId={QUARTERLY_TABS}>
                <ListGroup flush>
                  {loading ? (
                    <div className="my-3">
                      <Loader size="sm" />
                    </div>
                  ) : statements.length ? (
                    statements.map((document, index) => {
                      const program =
                        document.program || document.programStatement?.program;
                      const quarter =
                        document.quarter && document.year
                          ? `Q${document.quarter}-${document.year}`
                          : document.programStatement?.fundQuarter
                          ? `Q${document.programStatement.fundQuarter.quarter}-${document.programStatement.fundQuarter.year}`
                          : moment(document.updatedAt).format("YYYY-MM-DD");
                      return (
                        <ListGroupItem
                          key={index}
                          action
                          className="border-bottom d-flex justify-content-between"
                          tag="div"
                        >
                          <div className="flex-grow-1">
                            {program.name} {quarter}
                          </div>
                          {documentsLoading[document.id] ? (
                            <Loader size="sm" align="end" />
                          ) : (
                            <div className="d-flex align-items-center flex-shrink-0 ml-2">
                              <span
                                className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
                                onClick={() =>
                                  onDownload(document.fileName, document.id)
                                }
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  className="text-primary"
                                  icon={faFileDownload}
                                />
                              </span>
                              <Badge
                                onClick={() =>
                                  onPreview(document.fileName, document.id)
                                }
                                className="cursor-pointer"
                                color={"info"}
                              >
                                Preview
                              </Badge>
                            </div>
                          )}
                        </ListGroupItem>
                      );
                    })
                  ) : (
                    <ListGroupItem
                      action
                      className="border-bottom d-flex justify-content-between"
                      tag="div"
                    >
                      <span>No Documents</span>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </TabPane>
              <TabPane tabId={TAX_DOCUMENTS_TABS}>
                <ListGroup flush>
                  {loading ? (
                    <div className="my-3">
                      <Loader size="sm" />
                    </div>
                  ) : documents.length ? (
                    documents.map((document, index) => (
                      <ListGroupItem
                        key={index}
                        action
                        className="border-bottom d-flex justify-content-between"
                        tag="div"
                      >
                        <div className="flex-grow-1">{document.name}</div>
                        {documentsLoading[document.id] ? (
                          <Loader size="sm" align="end" />
                        ) : (
                          <div className="d-flex align-items-center flex-shrink-0 ml-2">
                            <span
                              className="cursor-pointer mr-2 font-size-1-1 d-flex align-items-center text-muted"
                              onClick={() =>
                                onDownload(document.fileName, document.id)
                              }
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                className="text-primary"
                                icon={faFileDownload}
                              />
                            </span>
                            <Badge
                              onClick={() =>
                                onPreview(document.fileName, document.id)
                              }
                              className="cursor-pointer"
                              color={"info"}
                            >
                              Preview
                            </Badge>
                          </div>
                        )}
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem
                      action
                      className="border-bottom d-flex justify-content-between"
                      tag="div"
                    >
                      <span>No Documents</span>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          onClose={() => setPreviewModal({ isOpen: false, file: null })}
        />
      ) : null}
    </Col>
  );
};

export default Documents;

import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Form,
  CustomInput,
} from "reactstrap";
import { fundsApi } from "../../../../../../services/fundServices";
import {
  initialState,
  useFundTransactionModal,
} from "../../../../../../providers/fundTransactionModalProvider";
import InformationModal from "../../../../../../components/InformationModal";
import Loader from "../../../../../Loader";
import CurrencyInput from "react-currency-input-field";
import { useFundContext } from "../../../../../../providers/fundsProvider";
import { utils } from "../../../../../../utils/utils";
import DateInput from "../../../../../Forms/DateInput";
import moment from "moment";

const CATEGORY_CREATION_MODE = 2;

const MAX_VALUE = 999999999;

const TYPE_INCOME = {
  id: "1",
  name: "Income",
};
const TYPE_EXPENSE = {
  id: "2",
  name: "Expense",
};
const TYPE_INCOME_DDD = {
  id: "3",
  name: "Income DND",
};

const TYPES = [TYPE_INCOME, TYPE_EXPENSE, TYPE_INCOME_DDD];

const FundMonthTransactionCreationModal = () => {
  const [loading, setLoading] = useState(false);
  const [fundTransactionModal, setFundTransactionModal] =
    useFundTransactionModal();

  const [fundContext, setFundContext] = useFundContext();
  const [selectedFundMonth, setSelectedFundMonth] = useState(
    fundContext.selectedFundMonth
  );
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    fundsApi
      .createFundTransaction({
        fundAssetId: fundTransactionModal.fundAssetId,
        fundId: fundContext.fund.id,
        fundMonthId: selectedFundMonth.id,
        type: fundTransactionModal.type,
        amount: fundTransactionModal.amount,
        incomePeriodStart: utils.formatDate(
          fundTransactionModal.incomePeriodStart,
          "YYYY-MM-DD"
        ),
        incomePeriodEnd: utils.formatDate(
          fundTransactionModal.incomePeriodEnd,
          "YYYY-MM-DD"
        ),
        fundTransactionCategoryId:
          fundTransactionModal.fundTransactionCategoryId,
      })
      .then(() =>
        fundsApi
          .getFundMonths({
            fundId: fundContext.fund.id,
            id: selectedFundMonth.id,
          })
          .then((selectedFundMonth) => {
            setLoading(false);
            setFundTransactionModal({ ...initialState });
            let fundMonths = [...fundContext.fundMonths];
            fundMonths.splice(
              fundContext.fundMonths.findIndex(
                (fc) => fc.id === selectedFundMonth.id
              ),
              1,
              selectedFundMonth
            );
            setFundContext({ fundMonths, selectedFundMonth });
          })
      );
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setFundTransactionModal({ isModalOpen: false })}
    >
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal
      isOpen={true}
      onClosed={() => setFundTransactionModal({ isModalOpen: false })}
    >
      <ModalHeader close={closeBtn}>
        <span>Add New Transaction</span>
        <br />
        <small>
          {utils.getMonth(selectedFundMonth.month)}-{selectedFundMonth.year}
        </small>
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Month of Distribution
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <CustomInput
                    id="monthSelect"
                    type="select"
                    name="monthSelect"
                    onChange={(event) =>
                      setSelectedFundMonth(
                        fundContext.fundMonths.find(
                          (month) =>
                            month.id === parseInt(event.currentTarget.value)
                        )
                      )
                    }
                    value={selectedFundMonth.id}
                  >
                    {fundContext.fundMonths.map((fundMonth) => (
                      <option key={fundMonth.id} value={fundMonth.id}>
                        {utils.getMonth(fundMonth.month)}-{fundMonth.year}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Type
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <CustomInput
                    required={true}
                    id="typeSelect"
                    type="select"
                    name="typeSelect"
                    onChange={(event) =>
                      setFundTransactionModal({
                        type: event.currentTarget.value,
                      })
                    }
                    value={fundTransactionModal.type}
                  >
                    <option value="">Select...</option>
                    {TYPES.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3">
                <Label sm={5} className="text-sm-left">
                  Income Period Start
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <DateInput
                    value={fundTransactionModal.incomePeriodStart}
                    onChange={(incomePeriodStart) =>
                      fundTransactionModal.incomePeriodStart &&
                      moment(incomePeriodStart).isAfter(
                        moment(fundTransactionModal.incomePeriodEnd)
                      )
                        ? setInformationModal({
                            isOpen: true,
                            title: "Transaction Creation",
                            body: `The start of the income period cannot be later than the end of the income period.`,
                          })
                        : setFundTransactionModal({ incomePeriodStart })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3">
                <Label sm={5} className="text-sm-left">
                  Income Period End
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <DateInput
                    value={fundTransactionModal.incomePeriodEnd}
                    onChange={(incomePeriodEnd) =>
                      fundTransactionModal.incomePeriodStart &&
                      moment(incomePeriodEnd).isSameOrBefore(
                        moment(fundTransactionModal.incomePeriodStart)
                      )
                        ? setInformationModal({
                            isOpen: true,
                            title: "Transaction Creation",
                            body: `Income Period End can't be same or before than Income Period Start.`,
                          })
                        : setFundTransactionModal({ incomePeriodEnd })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={5} className="text-sm-left">
                  Fund Asset
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <CustomInput
                    required={true}
                    id="assetSelect"
                    type="select"
                    name="assetSelect"
                    onChange={(event) =>
                      setFundTransactionModal({
                        fundAssetId: event.currentTarget.value,
                      })
                    }
                    value={fundTransactionModal.fundAssetId || ""}
                  >
                    <option value="">Select...</option>
                    {fundContext.fund.assets.map((asset) => (
                      <option key={asset.id} value={asset.id}>
                        {asset.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              {fundTransactionModal.type === TYPE_EXPENSE.id ? (
                <FormGroup row>
                  <Label sm={5} className="text-sm-left">
                    Category
                    <small className="ml-1 text-danger">*</small>
                  </Label>
                  <Col sm={7}>
                    <CustomInput
                      required={true}
                      id="categorySelect"
                      type="select"
                      name="categorySelect"
                      onChange={(event) =>
                        setFundTransactionModal({
                          fundTransactionCategoryId: event.currentTarget.value,
                        })
                      }
                      value={fundTransactionModal.fundTransactionCategoryId}
                    >
                      <option value="">Select...</option>
                      {fundTransactionModal.categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </CustomInput>
                    <Button
                      color={"info"}
                      size={"sm"}
                      className="float-left mt-2 rounded"
                      onClick={() =>
                        setFundTransactionModal({
                          mode: CATEGORY_CREATION_MODE,
                        })
                      }
                    >
                      New Category
                    </Button>
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row className="mb-0">
                <Label sm={5} className="text-sm-left">
                  Amount
                  <small className="ml-1 text-danger">*</small>
                </Label>
                <Col sm={7}>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    required={true}
                    allowNegativeValue={false}
                    maxLength={20}
                    className="form-control"
                    placeholder="Enter the amount.."
                    value={fundTransactionModal.amount}
                    onValueChange={(value) => {
                      if (!value || value < MAX_VALUE) {
                        setFundTransactionModal({ amount: value });
                      }
                    }}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            color={"secondary"}
            onClick={() => setFundTransactionModal({ isModalOpen: false })}
          >
            Cancel
          </Button>{" "}
          <Button color={"primary"} type="submit">
            Create
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FundMonthTransactionCreationModal;

export const usersTable = (data) => ({
  dataSource: {
    data: [
      {
        "Registered Name": {
          type: "string",
        },
        "First Name": {
          type: "string",
        },
        "Last Name": {
          type: "string",
        },
        Address: {
          type: "string",
        },
        City: {
          type: "string",
        },
        State: {
          type: "string",
        },
        Zip: {
          type: "string",
        },
        Email: {
          type: "string",
        },
        Phone: {
          type: "string",
        },
        "Program Name": {
          type: "string",
        },
        "Program Year": {
          type: "string",
        },
        "Units/Ownership": {
          type: "string",
        },
        Advisors: {
          type: "string",
        },
        "Account Type": {
          type: "string",
        },
        "SBD Name": {
          type: "string",
        },
        "SBD Rep Name": {
          type: "string",
        },
        "Bank Account": {
          type: "string",
        },
        "Routing Number": {
          type: "string",
        },
        "Payment Method": {
          type: "string",
        },
        "User Active": {
          type: "string",
        },
        "Escrow Dep Name": {
          type: "string",
        },
        "Escrow Amount": {
          type: "string",
        },
        "Unit Price": {
          type: "string",
        },
        "Closed + Pending Subscriptions": {
          type: "string",
        },
        "Closed + Pending Units": {
          type: "string",
        },
        "Notes to Sponsor from MBD": {
          type: "string",
        },
        "Notes to MBD from Sponsor": {
          type: "string",
        },
        Status: {
          type: "string",
        },
        Closing: {
          type: "string",
        },
        "Member Election": {
          type: "string",
        },
        "MBD Received Date": {
          type: "string",
        },
        "MBD Reviewer": {
          type: "string",
        },
        "MBD Reviewer Text": {
          type: "string",
        },
        "MBD Archive Notes": {
          type: "string",
        },
        "GenEligibility Income": {
          type: "string",
        },
        "GenEligibiity Net Worth": {
          type: "string",
        },
        "GenEligibility Trust >$5 Mil Assets": {
          type: "string",
        },
        "GenEligibility Rev Trust": {
          type: "string",
        },
        "GenEligibility LLC/LP/Corp >$5 Mil Assets": {
          type: "string",
        },
        "GenEligibility Entity All Owners Accredited": {
          type: "string",
        },
        "FI Annual Income": {
          type: "string",
        },
        "FI Net Worth": {
          type: "string",
        },
        "FI Tax Bracket": {
          type: "string",
        },
        "SSN/TID": {
          type: "string",
        },
        DOB: {
          type: "string",
        },
        "Legal Address": {
          type: "string",
        },
        "Legal City": {
          type: "string",
        },
        "Legal Address State": {
          type: "string",
        },
        "Legal Address Zip Code": {
          type: "string",
        },
        "Legal Form Ownership": {
          type: "string",
        },
        "Mailing Address": {
          type: "string",
        },
        "Mailing City": {
          type: "string",
        },
        "Mailing State": {
          type: "string",
        },
        "Mailing Zip": {
          type: "string",
        },
        Telephone: {
          type: "string",
        },
        "Email Address": {
          type: "string",
        },
        "Employment Info": {
          type: "string",
        },
        "EI Employed Job Title": {
          type: "string",
        },
        "EI Employed Employer": {
          type: "string",
        },
        "EI Employed Occupation": {
          type: "string",
        },
        "Affiliated BD": {
          type: "string",
        },
        "Affiliated DB Name": {
          type: "string",
        },
        "CPA Name": {
          type: "string",
        },
        "CPA Email": {
          type: "string",
        },
        "ROR Registered in State": {
          type: "string",
        },
        OFAC: {
          type: "string",
        },
        "506(c) Verification": {
          type: "string",
        },
        "Deal Name": {
          type: "string",
        },
        SBD_2: {
          type: "string",
        },
        Rep_2: {
          type: "string",
        },
        "Escrow Amt_2": {
          type: "string",
        },
        "Sub Amt_2": {
          type: "string",
        },
        "Sales Commission": {
          type: "string",
        },
        "Diligence Fee": {
          type: "string",
        },
        "Total to Selling Group (Sum)": {
          type: "string",
        },
        "Wholesale/Marketing Fee": {
          type: "string",
        },
        "MBD Fee": {
          type: "string",
        },
        "Fees to MBD (Sum)": {
          type: "string",
        },
        "TOTAL FEES": {
          type: "string",
        },
      },
      ...data,
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "Registered Name",
        sort: "asc",
      },
      {
        uniqueName: "First Name",
      },
      {
        uniqueName: "Last Name",
      },
      {
        uniqueName: "Address",
      },
      {
        uniqueName: "City",
      },
      {
        uniqueName: "State",
      },
      {
        uniqueName: "Zip",
      },
      {
        uniqueName: "Email",
      },
      {
        uniqueName: "Phone",
        type: "string",
      },
      {
        uniqueName: "Program Name",
      },
      {
        uniqueName: "Program Year",
      },
      {
        uniqueName: "Units/Ownership",
      },
      {
        uniqueName: "Advisors",
      },
      {
        uniqueName: "Account Type",
      },
      {
        uniqueName: "SBD Name",
      },
      {
        uniqueName: "SBD Rep Name",
      },
      {
        uniqueName: "Bank Account",
      },
      {
        uniqueName: "Routing Number",
      },
      {
        uniqueName: "Payment Method",
      },
      {
        uniqueName: "User Active",
      },
    ],
    measures: [],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Users",
    },
  },
});

export const votesTable = (data) => ({
  dataSource: {
    data: data,
  },
  slice: {
    rows: [
      { uniqueName: "Year" },
      { uniqueName: "Program" },
      { uniqueName: "Survey" },
      { uniqueName: "Question" },
      { uniqueName: "Registered Name" },
      { uniqueName: "Investor Name" },
      { uniqueName: "Investor Email" },
      { uniqueName: "Investor Phone Number" },
      { uniqueName: "SBD Name" },
      { uniqueName: "SBD Rep Name" },
      { uniqueName: "Units" },
      { uniqueName: "Answer" },
    ],
    measures: [],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Votes Report",
    },
  },
});

export const returnTable = (data) => ({
  formats: [
    {
      decimalPlaces: 2,
      currencySymbol: "$ ",
    },
  ],
  dataSource: {
    data: data,
  },
  slice: {
    reportFilters: [
      {
        uniqueName: "Strategic Fund",
      },
      {
        uniqueName: "Program",
      },
      {
        uniqueName: "Investment Type",
      },
    ],
    rows: [
      {
        uniqueName: "Strategic Fund",
      },
      {
        uniqueName: "Program",
      },
      {
        uniqueName: "Investor Name",
      },
    ],
    columns: [
      {
        uniqueName: "Quarter",
      },
    ],
    measures: [
      {
        uniqueName: "Return",
        aggregation: "sum",
      },
      {
        uniqueName: "Fee Amount",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "classic",
      title: `Returns`,
    },
    showAggregationLabels: false,
  },
});

export const documentsTable = (data) => ({
  dataSource: {
    data: data,
  },
  slice: {
    rows: [
      {
        uniqueName: "Program",
      },
      {
        uniqueName: "Investor Name",
      },
      {
        uniqueName: "Type",
      },
      {
        uniqueName: "Year",
      },
      {
        uniqueName: "URL",
      },
      {
        uniqueName: "File Name",
      },
    ],
    measures: [],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: `Documents`,
    },
  },
});

export const statementsTable = (data) => ({
  dataSource: {
    data: data,
  },
  slice: {
    rows: [
      {
        uniqueName: "Program",
      },
      {
        uniqueName: "Investor Name",
      },
      {
        uniqueName: "Year",
      },
      {
        uniqueName: "URL",
      },
      {
        uniqueName: "File Name",
      },
    ],
    measures: [],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: `Statements`,
    },
  },
});
